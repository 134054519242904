import React, { useState } from "react";
import OptionsItem from "./OptionsItem";
import { useDispatch } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import { memo } from "react";
import { Form, Table } from "react-bootstrap";
import MatchesItem from "./MatchesItem";
import Lottie from "lottie-react";
import celebrationArr, { randomShow } from "../../utils/images/lottie/celebration.js";


const Options = ({ data }) => {
    const [display, setDisplay] = useState(true)

    const option_lables = ["(A)", "(B)", "(C)", "(D)", "(E)", "(F)", "(G)"];
    const match_lable = ["(P)", "(Q)", "(R)", "(S)", "(T)", "(U)", "(V)"];

    const dispatch = useDispatch()
    const isSubmitted = data.is_submitted

    const borderColor = isSubmitted ? (data.answer_numerical == data.correct_answer ? "#CC2C2C" : "#008444") : "#000"

    function onNumericChange(question_id, answer_numerical) {
        dispatch({ type: userPracticeConstants.UPDATE_PRACTICE_DATA, question_id, answer_numerical })
    }

    const handleloop = () => {
        setDisplay(false)
    }
    
    return (

        <div>
            {isSubmitted && data.is_ans === 'Correct' && display && randomShow[Math.floor(Math.random() * randomShow.length)] && (
                <div style={{
                    width: '100vw',
                    height: "100vh",
                    position: 'fixed',
                    top: '0',
                    left:'0'
                }}>
                    <Lottie
                        style={{
                            height: '100%',
                            overflow: 'hidden'
                        }}
                        animationData={celebrationArr[Math.floor(Math.random() * celebrationArr.length)]}
                        onLoopComplete={handleloop}
                        onClick={handleloop}
                    />
                </div>
            )}

            {
                data.question_type === "numerical" &&
                <input disabled={isSubmitted} style={{ borderColor: borderColor }} placeholder="Type your answer..." className="input" type="number" onChange={(e) => onNumericChange(data.question_id, e.target.value)} />
            }

            {
                data.question_type === "subjective" &&
                <input disabled={isSubmitted} style={{ borderColor: borderColor }} placeholder="Type your answer..." className="input" onChange={(e) => onNumericChange(data.question_id, e.target.value)} />
            }

            {
                (data.question_type === "single-correct" || data.question_type === "multiple-correct") &&
                data.options.map((opt, i) => <OptionsItem isAns={data.is_ans} key={opt.option_id} index={i} option={opt} questionType={data.question_type} question_id={data.question_id} isSubmitted={isSubmitted} />)
            }

            {
                data.question_type === "match-the-column" &&
                <>


                    <Table borderless={true}>
                        <thead>
                            <tr>
                                <th>Column-I</th>
                                <th>Column-II</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(data.matches.length)].map((_, i) => {

                                return <tr key={i}>
                                    <td>
                                        {data.options[i] && <div
                                            style={{
                                                display: "flex",
                                                minWidth: "49%",
                                                alignItems: "baseline"
                                            }}>
                                            <span style={{ marginRight: 5 }} >{option_lables[i]}</span>
                                            <div dangerouslySetInnerHTML={{ __html: data.options[i].option_value }} />
                                        </div>}
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                display: "flex",
                                                minWidth: "49%",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <span style={{ marginRight: 5 }} >{match_lable[i]}</span>
                                            <div dangerouslySetInnerHTML={{ __html: data.matches[i].option_value }} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>

                    <MatchesItem questionId={data.question_id} data={data} options={data.options} matches={data.matches} answered={false} isSubmitted={isSubmitted} />

                </>
            }

        </div >
    )
}

export default memo(Options);