import React, { memo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import VSQno from './VSQno.js'
import { Container, Row, Spinner } from 'react-bootstrap'

function VsQnos() {
  const active_index = useSelector(state => state.test.active_index)
  const questions = useSelector(state => state.test.quiz_details?.questions)
  const scrollRef = useRef();

  useEffect(() => {
    if (active_index > 1) {
      scrollRef.current.scrollLeft = (active_index - 2) * 60;
      
    }
  }, [active_index]);

  return (
    <div id='quiz-Qno' className='me-2 Background'>
      <Container fluid='md' >
        <Row className="scrolling-wrapper  flex-row flex-nowrap">
          <div ref={scrollRef} className='quetion-numbers' style={{
            scrollBehavior:'smooth',
          }}>
            {
              questions
                ?
                questions.map(
                  (qno, id) => {
                    return (
                      <VSQno key={id} question_no={qno.question_no} qno={id} is_correct={qno.is_correct} attempt_state={qno.attempt_state} isActive={id === active_index} />
                    )
                  }
                )
                :
                <Spinner />
            }
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default memo(VsQnos)