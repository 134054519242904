import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { TestReducersConstants } from '../../../Redux/Test/TestReducersConstants.js';

const primaryColor = "#5F46E3";
const isSelected = '#E5F8FF'

function Qno({ qno, isActive, attempt_state }) {
    const dispatch = useDispatch()

    return (
        <div id="Qno-wrapper">
        <div id='Qno' className='d-flex justify-content-center align-items-center m-2 Psudo-Button' style={{
            backgroundColor: isActive ? primaryColor : (attempt_state === "ANSWERED") && isSelected,
            color: isActive && 'white'
        }} onClick={() => {
            dispatch({type:TestReducersConstants.ACTIVE_INDEX, payload: qno})
        }}>
            {qno + 1}
        </div>
            </div>
    )
}

export default memo(Qno)