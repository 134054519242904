import React, { useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiArrowRight } from 'react-icons/fi'
import RaiseQueryPopUpBox from './RaiseQueryPopUpBox.js'


function HelpAndSupportBody({ data }) {
    const [activeCourse, setActiveCourse] = useState(0)
    const faqs = data[activeCourse].faqs
    const [show, setShow] = useState(false)


    return (
        <div>
            <RaiseQueryPopUpBox show={show} close={() => setShow(false) }/>
            {/*---------------------------------------------------------FAQs Buttons------------------------------------------------------------*/}
            <div className='mb-2 p-2 Background'>
                {
                    data.map(
                        (faq, id) => {
                            return (
                                <Button key={id} className='me-3 my-2 px-4' style={{ backgroundColor: activeCourse === id ? "#5F46E3" : "#f3f3f3", color: activeCourse === id ? 'white' : 'black', border: 'none', borderRadius: '18px', }}
                                    onClick={() => {
                                        setActiveCourse(id)
                                    }}>
                                    {faq.categorie}
                                </Button>
                            )
                        }
                    )
                }
            </div>

            {/*--------------------------------------------------------------------FAQ-----------------------------------------------------------------------*/}
            <Accordion >
                {
                    faqs.map(
                        (data, id) => {
                            return (
                                <Accordion.Item eventKey={id} className='my-2'>
                                    <Accordion.Header><strong>{data.question}</strong></Accordion.Header>
                                    <Accordion.Body>{data.solution}</Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                    )
                }

            </Accordion>

            {/*--------------------------------------------------------------------Raise Query-----------------------------------------------------------------------*/}
            <div className='mb-2 p-3 Background d-flex justify-content-between align-items-center' style={{
                position: "absolute",
                width: "100%",
                bottom: "0",
            }}>
                <div className='d-flex align-items-center'>
                    <AiOutlineQuestionCircle size={25} />
                    &nbsp;
                    Got any specific quesions?
                </div>

                <Button className='px-3 py-2' style={{ backgroundColor: "#5F46E3", color: 'white', border: 'none', borderRadius: '12px', }}
                    onClick={() => setShow(true) }  >
                    Raise Query <FiArrowRight size={20} />
                </Button>
            </div>

        </div>
    )
}

export default HelpAndSupportBody