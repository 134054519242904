import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonColors } from "../../../Constants/ButtonsColor/ButtonsColor";
import { FRONTEND_URLS } from "../../../URL/URL";
import { useLocation, useNavigate } from "react-router-dom";
import ESaralLoader from "../../Spinner/ESaralLoader.js";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "./../../../Redux/Reducers/Constants/AppReducerConstant";
import { UpdateData } from "../../../DB/Function.js";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";

import { Nav, NavDropdown, Button } from "react-bootstrap";
import LiveClassComponent from "./LiveClassComponent.js";
import AdmissionForm from "../../Modals/AdmissionForm/AdmissionForm.jsx";
import { useSelector } from "react-redux";


const HomeContent = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isPrimeMember, setIsPrimeMember] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"));
  const name = user?.user?.full_name;
  const subjects = user?.subjects;
  const faculties = user?.faculties;
  const selections = user?.selection;

  const enrollments = user?.enrollments;
  const user_profile_progress = useSelector(state => state.admission.compeltePercentage)
  const isCourseEpired = user?.is_current_subcourse_expired;


  useEffect(() => {

    const access = localStorage.getItem("access") || localStorage.getItem('tkn');

    const isPrime = enrollments?.filter((enrollment) => enrollment.is_current)[0]?.offerings?.length > 0;
    setIsPrimeMember(isPrime)

    if (!access) {
      navigate(FRONTEND_URLS.LOGIN_ROUTE);
    }
  }, [user]);


  const handleCoursClick = () => {
    dispatch({ type: AppReducerConstants.IS_PHONE_DROPDOWN, payload: true, });
  }


  const handleSubjectClick = (node_id) => {
    UpdateData();
    dispatch({ type: AppReducerConstants.SUBJECT_ID, payload: node_id });
    navigate(FRONTEND_URLS.SUBJECT_ROUTE + '/' + node_id);
  }

  return (
    <>
      {
        isPrimeMember && user_profile_progress < 100 &&
        <AdmissionForm />
      }
      {user ? (
        <>
          <section className="Card-Format custom-padding">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <div className=" mb-3">
                <div className="Home-Name"> Hi, {name} 👋</div>
                <div className="Home-Name-subtitle">
                  Let’s start your preparation here.
                </div>
              </div>


              {pathname === FRONTEND_URLS.HOME_ROUTE &&
                <Nav
                  style={{ display: "flex", alignItems: "center" }}
                  className="My_target_modal_main_dashboard"
                  onClick={handleCoursClick}>
                  <b>My Targets :</b>
                  <NavDropdown
                    id="my-target-nav-dropdown"
                    title={
                      enrollments?.find((e) => e.is_current === true)?.subcourses?.find((e) => e.is_current === true)?.name
                    }
                    menuVariant="white">
                    {enrollments?.map((enrollment, id) => {
                      return (
                        enrollment.is_current === false && (
                          <NavDropdown.Item key={id}>
                            {enrollment.course_name}
                          </NavDropdown.Item>
                        )
                      );
                    })}

                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href="#action/3.4"
                      className="d-flex justify-content-center">
                      +
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              }
            </div>

            <Container
              fluid="md"
              className="ms-0"
              style={{
                paddingTop: "1rem",
              }}>
              <Row
                className="d-flex row-cols-auto"
                style={{ columnGap: "2vw" }}>
                {subjects && subjects?.map((subject, id) => {
                  return (
                    <Col
                      key={id}
                      className="Subject-Button Psudo-Button homeBox"
                      style={{
                        backgroundColor:
                          ButtonColors[id % ButtonColors.length].BorderColor,
                      }}
                      onClick={() => handleSubjectClick(subject.node_id)}>
                      <img
                        draggable="false"
                        className="Subject-Button-Img"
                        src={subject.thumbnail}
                        alt={subject.display_name}
                        onError={(e) => {
                          e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                        }}
                      />
                      <div className="Subject-Button-Img-Title">
                        {subject.display_name}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>

          <div
            className="Card-Format"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem 1rem",
              backgroundColor: "#fff",
              marginBottom: "8px"
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div>
                <h5>Sessions</h5>
              </div>
              <div>
                {
                  // !isCourseEpired
                  // &&
                  <p style={{ cursor: "pointer" }} onClick={() => {
                    navigate(FRONTEND_URLS.LIVE, { state: { display_name: 'Live Sessions' } })
                  }}>See All</p>
                }
              </div>
            </div>

            <LiveClassComponent />

          </div>

          {
            faculties &&
            <section className="Card-Format pb-3">
              <div className="Home-Name custom-padding"> Know Your Faculty </div>
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 5,
                  },
                  425: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
                freeMode={true}
                modules={[FreeMode, Pagination]}>
                {faculties && faculties?.map((facultie, id) => {
                  return (
                    <SwiperSlide key={id} className={`px-0 custom-margin-left`}>
                      <img
                        draggable="false"
                        className="Faculity-img"
                        src={facultie.thumbnail}
                        alt={facultie.name}
                        onError={(e) => {
                          e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                        }}
                      />
                      <div className="Subject-Button-Img-Title">
                        {facultie.name}
                      </div>
                      <div className="Home-Name-subtitle ">
                        {facultie.designation.charAt(0).toUpperCase() +
                          facultie.designation.slice(1).toLowerCase()}
                      </div>
                    </SwiperSlide>
                  );
                })}
                <SwiperSlide className={`px-0 custom-margin-left`}></SwiperSlide>
              </Swiper>
            </section>
          }
          {
            selections &&
            <section className="Card-Format">
              <div className="Home-Name custom-padding"> Selections </div>
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                  },
                  425: {
                    slidesPerView: 1.6,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
                freeMode={true}
                modules={[FreeMode, Pagination]}>
                {selections && selections?.map((selection, id) => {
                  return (
                    <SwiperSlide key={id}>
                      <div
                        key={id}
                        className={`px-0 Psudo-Button custom-margin-left`}>
                        <img
                          draggable="false"
                          className="SelectionCarousal"
                          src={selection.thumbnail}
                          alt=""
                          onError={(e) => {
                            e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>
          }
        </>
      ) : (
        <>
          <ESaralLoader />
        </>
      )}
    </>
  );
};

export default HomeContent;
