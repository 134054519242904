import React, { useState, useRef, useEffect } from 'react';
import { Container, } from 'react-bootstrap';
import { mediaBaseUrl } from '../../../../URL/URL';
import ImageShower from './ImageShower';
import ESaralLoader from '../../../Spinner/ESaralLoader.js';
import { useSelector } from 'react-redux';
import NotFound from '../../../Error/NotFound.js';



const ChatMessage = ({ chat, user_id, handleShow }) => {

    function getIndianTime(timeStamp) {
        const today = new Date()
        const date = new Date(timeStamp * 1000)
        var date_1 = date.toDateString().split(" ");
        var date_str = date_1[2] + " " + date_1[1] + " " + date_1[3];
        var MM = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
        var time_str = date.getHours() > 12 ? `${date.getHours() - 12}:${MM} pm` : `${date.getHours()}:${MM} am`
        if (today.toDateString() === date.toDateString()) {
            return time_str
        }
        else {
            if (today.getFullYear() === date.getFullYear()) {
                date_str = date_str.slice(0, -4)
                return date_str + " " + time_str
            }
            return date_str + " " + time_str
        }
    }
    return (
        <div className='m-3 d-flex' style={{ justifyContent: (chat.created_by.id === user_id) && 'flex-end' }}>
            <div style={{
                background: (chat.created_by.id === user_id) ? 'rgb(95, 70, 227)' : 'white',
                borderRadius: (chat.created_by.id === user_id) ? `10px 10px 0px 10px` : `10px 10px 10px 0px`,
                color: (chat.created_by.id === user_id) && 'white',
                boxShadow: '0px 0px 12px -6px black',
                maxWidth: '80%',
                minWidth: '20%',
            }} className='Psudo-Button py-1 px-3'>
                {
                    chat.media_type && chat?.media_url &&
                    <>
                        <img
                            draggable="false"
                            src={`${mediaBaseUrl}${chat?.media_url}`}
                            className='my-3'
                            style={{ maxWidth: '16rem' }}
                            onClick={() => {
                                handleShow(mediaBaseUrl + chat?.media_url);
                            }}
                            alt=''
                            onError={(e) => {
                                e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png');
                            }}
                        />
                        <br />
                    </>
                }
                <p className='mt-1 mb-0 text-break'>{chat.text}</p>
                <span className='m-0 float-right timer-class'>{getIndianTime(chat.created_on)}</span>
            </div>
        </div>
    )
}




const ChatField = () => {

    const user_id = JSON.parse(localStorage.getItem("user"))?.user?.user_id
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState()
    const endRef = useRef()

    const chats = useSelector(state => state.doubt.chats)
    const is_chats = useSelector(state => state.doubt.is_chats)

    const handleClose = () => {
        setShow(false);
        setUrl();
    };

    const handleShow = () => setShow(true);

    useEffect(
        () => {
            if (is_chats && chats.length > 0) {
                setTimeout(
                    () => {
                        endRef?.current?.scrollIntoView({ behavior: "smooth" })
                    }, 500
                )
            }
        }, []
    )

    return (
        <>
            {url && <ImageShower show={show} url={url} handleClose={handleClose} />}

            <Container fluid='md' className='ChatField Background'>
                {
                    is_chats
                        ?
                        chats
                            ?
                            <>
                                {
                                    chats.map(
                                        (chat, id) => <ChatMessage key={id} chat={chat} user_id={user_id} handleShow={handleShow} />
                                    )
                                }
                                <div ref={endRef}></div>
                            </>
                            :
                            <ESaralLoader />
                        :
                        <NotFound />
                }
            </Container>
        </>
    );
};

export default ChatField;