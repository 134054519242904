const DOMAIN_NAME = `https://app.esaral.com`;

// export const BASE = "https://development.esaral.com";
export const BASE = 'https://api.esaral.com'
// export const BASE = 'http://127.0.0.1:8000'
export const BASE2 = 'https://admin.esaral.com'
export const mediaBaseUrl = "https://myesaralbucket.s3.amazonaws.com/";


export const redirect_url = DOMAIN_NAME + "/payment-status";

const start_var = "/";

export const BACKEND_URLS = {
  OTP_SENDER: `${BASE}/v1/users/phone-verification`,
  USER_DATA_PROVIDER: `${BASE}/v1/users/`,
  USER_DATA: `${BASE}/v2/users/home`,
  GET_ALL_TARGETS: `${BASE}/v1/users/targets`,
  GET_ALL_DOUBTS: `${BASE}/v1/doubts/get-all-doubts`,
  GET_MENTORSHIP_DATA: `${BASE}/v1/mentorship/get-mentorship-data`,
  GET_DOUBT_MESSAGES: `${BASE}/v1/doubts/get-doubt-messages`,
  SEND_MESSAGE: `${BASE}/v1/doubts/send-message`,
  GET_SUBCOURSE_CHAPTERS: `${BASE}/v2/contents/get-subcourse-chapters`,
  CRUD_DOUBT: `${BASE}/v1/doubts/crud-doubt`,
  SUBJECT_DATA_API: `${BASE}/v2/contents/get-node-content-tree`,
  VIDEO_OTP: `${BASE}/v2/contents/get-video-otp`,
  UPDATE_PROFILE: `${BASE}/v2/users/student-admission-details`,
  NEW_ENROLLMENTS: `${BASE}/v2/users/enroll`,
  SEARCH_API: `${BASE}/v2/contents/search`,
  GET_TIMETABLE: `${BASE}/v1/mentorship/get-timetable`,
  COURSES: `${BASE}/v2/courses/all-courses`,
  COURSES_DETAILS: `${BASE}/v2/courses/course-details`,
  PROGRESS_REPORT:`${BASE2}/v2/student/student-report`, 



  /*----------------------------------- treak Api --------------------------------------*/
  USAGE: `${BASE}/v2/users/usage`,

  /*----------------------------------- test Api --------------------------------------*/
  CHECK_USER_QUIZ_ATTEMPT: `${BASE}/v2/quizes/check-user-quiz-attempt`,
  GET_QUIZ_DETAILS: `${BASE}/v2/quizes/get-quiz-details`,
  QUIZ_STUDENT_ATTEMPT: `${BASE}/v2/quizes/quiz-student-attempt`,
  GET_QUIZ_RESULTS: `${BASE}/v2/quizes/get-quiz-results`,

  /*---------------------------------- Practice API------------------------------------*/
  GET_QUIZ_QUESTION: `${BASE}/v2/quizes/get-quiz-question-details?quiz_id=`,

  /*----------------------------------- Raise Query Api --------------------------------------*/

  RAISE_TICKET: `${BASE}/v2/support/ticket/`,

  /*----------------------------------- User History --------------------------------------*/
  USER_HISTORY: `${BASE}/v2/users/user-history`,

  /*----------------------------------- Payment Url --------------------------------------*/
  PAYMENT_URL: `${BASE}/v1/enrollment/initiate-payment`,
  // PAYMENT_URL:`http://192.168.0.145:8000/v1/enrollment/initiate-payment`,

  /*----------------------------------- Payment Status --------------------------------------*/
  PAYMENT_STATUS: `${BASE}/v1/enrollment/payment-status`,
  // PAYMENT_STATUS:`http://192.168.0.145:8000/v1/enrollment/payment-status`,


  IMAGE_UPLOAD: `${BASE}/v2/contents/image-upload`,

  STUDENT_ADMISSION_DETAILS:`${BASE}/v2/users/student-admission-details`,
};

export const FRONTEND_URLS = {
  // ROOT:start_var,
  LOGIN_ROUTE: `${start_var}login`,
  HOME_ROUTE: start_var,
  REGISTER_ROUTE: `${start_var}Register`,
  SEARCH_ROUTE: `${start_var}search`,
  ENROLLMENTS: `${start_var}enrollment`,
  TIME_TABLE: `${start_var}timetable`,
  FAQ: `${start_var}faq`,
  ALLSESSION: `${start_var}all-session`,
  SESSION_DETAILS: `${start_var}session-details`,
  MY_ACCOUNT: `${start_var}my-account`,
  COURSES: `${start_var}courses`,
  MENTORSHIP: `${start_var}mentorship`,
  DOUBUT_SOLVE: `${start_var}doubut-solve/doubt`,
  DOUBUT_SOLVE_QUERIES: `${start_var}doubut-solve/queries`,
  HELP_AND_SUPPORT: `${start_var}help-and-support`,
  NEW_ENROLL: `${start_var}new-enroll`,
  PAGE_NOT_FOUND: `${start_var}PageNotFound`,
  PRACTICE_ROOT: `${start_var}practice/`,
  TEST_ROOT: `${start_var}quiz/`,
  TEST_RESULT: `${start_var}quiz/result`,
  PROGRESS_REPORT: `${start_var}progressreport`,

  LIVE: `${start_var}live-session`,
  LIVEMENTORSHIP: `${start_var}live-session/mentorship`,
  ALL: `${start_var}live-session/all`,

  ADMISSION:`${start_var}admission`,

  ERROR: `${start_var}/404`,
  USER_PRACTICE: `${start_var}user/practice`,
  SUBJECT_ROUTE:`${start_var}subject`,

};
