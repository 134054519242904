import React, { useImperativeHandle, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppReducerConstants } from '../../../../Redux/Reducers/Constants/AppReducerConstant.js'

function CourseHeader({ name }, ref) {
    const menu = useSelector(state => state.app.CourseMenu)
    const dispatch = useDispatch()

    const aboutRef = useRef(null);
    const facultyRef = useRef(null);
    const testimonialsRef = useRef(null);
    const pricingRef = useRef(null);

    useImperativeHandle(
        ref, () => ({
            aboutRef,
            facultyRef,
            testimonialsRef,
            pricingRef,
        })
    );

    const refArr = [aboutRef, facultyRef, testimonialsRef, pricingRef]

    function scroller(link, id) {
        const element = document.getElementById(link)
        element.scrollIntoView({ behavior: 'smooth' })
        dispatch({ type: AppReducerConstants.COURSE_MENU, payload: id })
    }


    return (
        <ul className="nav nav-pills Background Course-Header" id='CourseHeader'>

            <div className='Course-name ms-3 mt-2' id='course-name'><strong>{name}</strong></div>

            <div className='d-flex justify-content-center Background Course-header-zIndex'>
                {
                    menu.map(
                        (item, id) => {
                            return (
                                <li key={id} id={id} className="nav-item" ref={refArr[id]} >
                                    <div className={`nav-link ${item.is_true ? 'course-header-active' : 'course-header-inactive'}`}
                                        onClick={() => {
                                            scroller(item.name, id);
                                        }}>
                                        {item.name}
                                    </div>
                                </li>
                            )
                        }
                    )
                }
            </div>

        </ul>
    )
}

export default React.forwardRef(CourseHeader);