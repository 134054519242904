import { stopAddingData } from '../../DB/Function.js';
import { AppReducerConstants } from './Constants/AppReducerConstant';

const initalState = {
    treakIntervalKey: null, userHistory: {}, videoId: null, videoInfo: null, videoTab: null, scrollHeight: 80, videoStatus: 'NA', beforeTabChangeVideoStatus: 'NA', isPDF: null, pdf_name: '',
    isOverlay: false, isLogin: false, tab: 'fill-tab-Learn', doubut: 'tab-tab-Open-0', isVideo: false, isPhoneMenu: false, MenuNumber: 0, isPhoneDropdown: false, isToggle: true,
    live: "Upcoming", DoubtSolveMenu: [
        {
            name: "Open",
            id: 0,
            is_true: true,
            count: 0,
            data: [],
        },
        {
            name: "Solved",
            id: 1,
            is_true: false,
            count: 0,
            data: [],
        }], CourseMenu: [
            { name: 'About', is_true: true }, { name: 'Faculty', is_true: false }, { name: 'Testimonials', is_true: false }, { name: 'Pricing', is_true: false },
        ],
    content_id: 0, subject_id: 0, chapter_id: 0,
    treak: {
        "content": 0,
        "date": '',
        "usage_in_seconds": 32,
        "subcourse": 0,
        "subject": 0,
        "chapter": 0,
        "content_parent_group": "",
        "is_sync": false
    }, isLoading: false, zoom_config: {}, MessageModal: false,

    all_mentors: [],
    liveRecordeddata: [],
    liveChapterData: [], previousPages: [], isAdmissionForm: true, isPlayerReady: false
}



const AppReducer = (state = initalState, action) => {
    switch (action.type) {
        case AppReducerConstants.IS_LOGIN:
            return { ...state, isLogin: action.payload }

        case AppReducerConstants.RESET:

            return {
                treakIntervalKey: null, userHistory: {}, videoId: null, videoInfo: null, videoTab: null, scrollHeight: 80, videoStatus: 'NA', beforeTabChangeVideoStatus: 'NA', isPDF: null, pdf_name: '',
                isOverlay: false, isLogin: false, tab: 'fill-tab-Learn', doubut: 'tab-tab-Open-0', isVideo: false, isPhoneMenu: false, MenuNumber: 0, isPhoneDropdown: false, isToggle: true,
                live: "Upcoming", DoubtSolveMenu: [
                    {
                        name: "Open",
                        id: 0,
                        is_true: true,
                        count: 0,
                        data: [],
                    },
                    {
                        name: "Solved",
                        id: 1,
                        is_true: false,
                        count: 0,
                        data: [],
                    }], CourseMenu: [
                        { name: 'About', is_true: true }, { name: 'Faculty', is_true: false }, { name: 'Testimonials', is_true: false }, { name: 'Pricing', is_true: false },
                    ],
                content_id: 0, subject_id: 0, chapter_id: 0,
                treak: {
                    "content": 0,
                    "date": '',
                    "usage_in_seconds": 32,
                    "subcourse": 0,
                    "subject": 0,
                    "chapter": 0,
                    "content_parent_group": "",
                    "is_sync": false
                }, isLoading: false, zoom_config: {}, MessageModal: false,
            
                all_mentors: [],
                liveRecordeddata: [],
                liveChapterData: [], previousPages: [], isAdmissionForm: true, 
            }

        case AppReducerConstants.TAB:
            return {
                ...state, tab: action.payload
            }

        case AppReducerConstants.DOUBUT:
            return {
                ...state, doubut: action.payload.split('-')[2]
            }


        case AppReducerConstants.IS_PHONE_MENU:
            return {
                ...state, isPhoneMenu: action.payload
            }

        case AppReducerConstants.MENU_NUMBER:
            return {
                ...state, MenuNumber: action.payload
            }

        case AppReducerConstants.IS_PHONE_DROPDOWN:
            return {
                ...state, isPhoneDropdown: action.payload
            }
        case AppReducerConstants.IS_TOGGLE:
            return {
                ...state, isToggle: action.payload
            }

        case AppReducerConstants.LIVE_RECORDED_DATA:
            return {
                ...state,
                liveRecordeddata: action.payload
            }

        case AppReducerConstants.IS_LIVE_DROPDOWN:
            return {
                ...state, isLiveDropDown: action.payload
            }

        case AppReducerConstants.DOUBUT_SOLVE_MENU_COUNT:
            const DoubtSolveMenu1 = [
                {
                    name: "Open",
                    id: 0,
                    is_true: true,
                    count: 0,
                    data: [],
                },
                {
                    name: "Solved",
                    id: 1,
                    is_true: false,
                    count: 0,
                    data: [],
                }]
            const payload = action.payload
            const all_doubts = payload?.all_doubts.all_doubts
            const slug = payload?.slug
            if (all_doubts) {
                for (let i = 0; i < all_doubts.length; i++) {
                    if ((slug === "queries") && (all_doubts[i].department === "MENTORSHIP")) {
                        if ((all_doubts[i].status === "CLOSE") || (all_doubts[i].status === "RESOLVED")) {
                            DoubtSolveMenu1[1].count = DoubtSolveMenu1[1].count + 1
                            DoubtSolveMenu1[1].data.push(all_doubts[i])
                        }
                        else {
                            DoubtSolveMenu1[0].count = DoubtSolveMenu1[0].count + 1
                            DoubtSolveMenu1[0].data.push(all_doubts[i])
                        }
                    }
                    else if ((slug === "doubt") && (all_doubts[i].department === "ACADEMIC")) {
                        if ((all_doubts[i].status === "CLOSE") || (all_doubts[i].status === "RESOLVED")) {
                            DoubtSolveMenu1[1].count = DoubtSolveMenu1[1].count + 1
                            DoubtSolveMenu1[1].data.push(all_doubts[i])
                        }
                        else {
                            DoubtSolveMenu1[0].count = DoubtSolveMenu1[0].count + 1
                            DoubtSolveMenu1[0].data.push(all_doubts[i])
                        }
                    }
                }
            }

            return { ...state, DoubtSolveMenu: DoubtSolveMenu1 }


        case AppReducerConstants.ALL_MENTERS_DATA:
            return {
                ...state,
                all_mentors: action.payload || []
            };


        case AppReducerConstants.DOUBUT_SOLVE_MENU:
            const DoubtSolveMenu = [...state.DoubtSolveMenu]
            const id = action.payload

            for (let i = 0; i < DoubtSolveMenu.length; i++) {

                if (DoubtSolveMenu[i].id === id) {
                    DoubtSolveMenu[i].is_true = true
                }
                else {
                    DoubtSolveMenu[i].is_true = false
                }
            }

            return { ...state, DoubtSolveMenu: DoubtSolveMenu, isLoading: false }

        case AppReducerConstants.IS_VIDEO:
            return {
                ...state, isVideo: action.payload
            }

        case AppReducerConstants.COURSE_MENU:
            const courseMenu = [...state.CourseMenu]

            for (let i = 0; i < courseMenu.length; i++) {
                if (i == action.payload) {
                    courseMenu[i].is_true = true
                }
                else {
                    courseMenu[i].is_true = false
                }
            }

            return {
                ...state, CourseMenu: courseMenu
            }
        case AppReducerConstants.TREACK:
            const { content, date, subject, chapter, is_sync } = action.payload
            const subCourse = JSON.parse(localStorage.getItem('user'))?.enrollments.find((e) => e.is_current == true)?.subcourses.find((e) => e.is_current == true)?.id
            const content_group = state.tab.split('-')[2]
            const content_parent_group = content_group.charAt(0).toUpperCase() + content_group.slice(1)

            return {
                ...state, treak: {
                    "content": Number(content) ?? 0,
                    "date": date ?? '',
                    "usage_in_seconds": 32,
                    "subcourse": Number(subCourse) ?? 0,
                    "subject": Number(subject) ?? 0,
                    "chapter": Number(chapter) ?? 0,
                    "content_parent_group": content_parent_group,
                    "is_sync": is_sync ?? false
                }
            }

        case AppReducerConstants.CONTENT_ID:
            return {
                ...state, content_id: action.payload
            }
        case AppReducerConstants.CHAPTER_ID:
            return {
                ...state, chapter_id: action.payload
            }
        case AppReducerConstants.SUBJECT_ID:
            return {
                ...state, subject_id: action.payload
            }

        case AppReducerConstants.TRACK_INTERVAL_ID:
            stopAddingData(state.treakIntervalKey)
            return {
                ...state, treakIntervalKey: action.payload
            }

        case AppReducerConstants.CLEAR_INTERVAL:
            stopAddingData(state.treakIntervalKey)

            return {
                ...state, treakIntervalKey: null
            }

        case AppReducerConstants.USER_HISTORY:
            const subjectDataFromLocaleStorage = JSON.parse(localStorage.getItem('user'))?.subjects
            const userHistoryData = action.payload

            const sampleDict = {}
            const nodeIdList = []

            for (let i = 0; i < subjectDataFromLocaleStorage.length; i++) {
                const node_id = subjectDataFromLocaleStorage[i]?.node_id;
                nodeIdList.push(node_id)
                sampleDict[node_id] = []
            }

            for (let i = 0; i < userHistoryData.length; i++) {
                const historyData = userHistoryData[i]
                const node_id = userHistoryData[i].subject_node_id

                if (node_id in sampleDict && sampleDict[node_id].length <= 20) {
                    sampleDict[node_id].push(historyData)
                }
            }

            return {
                ...state, userHistory: sampleDict
            }

        case AppReducerConstants.VIDEO_ID:
            const videoId = action.payload

            if (videoId !== state.videoId) {
                return {
                    ...state, videoId: action.payload
                }
            }

        case AppReducerConstants.VIDEO_INFO:
            return {
                ...state, videoInfo: action.payload
            }

        case AppReducerConstants.SCROLL_HEIGHT:
            return {
                ...state, scrollHeight: action.payload
            }

        case AppReducerConstants.IS_PDF:
            return {
                ...state, isPDF: action.payload
            }

        case AppReducerConstants.PDF_NAME:
            return {
                ...state, pdf_name: action.payload
            }

        case AppReducerConstants.IS_LOADING:
            return {
                ...state, isLoading: action.payload
            }

        case AppReducerConstants.VIDEO_TAB:
            return {
                ...state, videoTab: action.payload
            }

        case AppReducerConstants.VIDEO_STATUS:
            return {
                ...state, videoStatus: action.payload
            }

        case AppReducerConstants.BEFORE_TAB_CHANGE_VIDEO_STATUS:
            return {
                ...state,
            }

        case AppReducerConstants.LIVE:

            const live = JSON.parse(localStorage.getItem("user"))?.live

            let sectionIdArr = []

            const sectionObjectArr = [] // [{}, {}, {}]

            const mainDataArr = [
                { name: "Upcoming", data: [], is_active: true },
                { name: 'All', data: [], is_active: false }
            ]

            try {
                const systemTime = Math.floor(new Date().getTime() / 1000.0)

                for (let i = 0; i < live.length; i++) {
                    const section_id_position_in_arr = sectionIdArr.indexOf(live[i].series_id)
                    if (section_id_position_in_arr >= 0) {
                        sectionObjectArr[section_id_position_in_arr].data.push(live[i])
                    }
                    else {
                        sectionIdArr.push(live[i].series_id)
                        sectionObjectArr.push({ series_id: live[i].series_id, series_name: live[i].series_name, series_thumbnail: live[i].series_thumbnail, data: [live[i]] })
                    }
                }

                for (let i = 0; i < live.length; i++) {

                    if (live[i].start_date_time > systemTime) {
                        if (mainDataArr[0].data.length == 0) {
                            mainDataArr[0].data.push({ series_id: live[i].series_id, series_name: live[i].series_name, series_thumbnail: live[i].series_thumbnail, data: [live[i]] })
                        }
                        else {
                            let dataPos = null
                            const data = mainDataArr[0].data
                            for (let j = 0; j < data.length; j++) {
                                if (data[j].series_id == live[i].series_id)
                                    dataPos = j
                                break
                            }
                            mainDataArr[0].data[dataPos].data.push(live[i])
                        }
                    }
                    else {
                        if (mainDataArr[1].data.length == 0) {
                            mainDataArr[1].data.push({ series_id: live[i].series_id, series_name: live[i].series_name, series_thumbnail: live[i].series_thumbnail, data: [live[i]] })
                        }
                        else {
                            let dataPos = null
                            const data = mainDataArr[1].data
                            for (let j = 0; j < data.length; j++) {
                                if (data[j].series_id == live[i].series_id)
                                    dataPos = j
                                break
                            }
                            mainDataArr[1].data[dataPos].data.push(live[i])
                        }
                    }
                }

            }
            catch (err) {
            }


            return {
                ...state, live: mainDataArr
            }

        case AppReducerConstants.LIVE_TAB:

            return {
                ...state,
                live: action.payload,
            };

        case AppReducerConstants.ZOOM_CONFIG:
            return {
                ...state,
                zoom_config: action.payload
            };

        case AppReducerConstants.MESSAGE_MODAL:
            return {
                ...state, MessageModal: !state.MessageModal
            }


        case AppReducerConstants.LIVE_CHAPTER_DATA:
            return {
                ...state,
                liveChapterData: action.payload
            }

        case AppReducerConstants.PREVIOUS_PAGE_ADD:
            const path = action.payload
            return {
                ...state,
                previousPages: [...state.previousPages, path]
            }

        case AppReducerConstants.PREVIOUS_PAGE_REMOVE:
            state.previousPages.pop()

            return {
                ...state,
                previousPages: state.previousPages
            }

        case AppReducerConstants.IS_ADMISSION_FORM:
            return {
                ...state,
                isAdmissionForm: !state.isAdmissionForm
            }
        
        case AppReducerConstants.IS_PLAYER_READY:
            return {
                ...state,
                isPlayerReady: action.payload
            }

        default:
            return state
    }

}

export default AppReducer;