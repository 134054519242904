import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"
import { gtag_event_category, gtag_events } from "../../utils/constants/gTagConstants";
import NewButton from "../Practice1/NewButton";

const PracticeFooter = () => {

    const dispatch = useDispatch();
    const data = useSelector(state => state.userPracticeReducer.practiceData)
    const currentIndex = useSelector(state => state.userPracticeReducer.currentIndex)

    function onPrevPress() {
        dispatch({ type: userPracticeConstants.UPDATE_CURRENT_INDEX, index: currentIndex - 1 })
        window.gtag("event", gtag_events.PreviousQuestionClicked, {
            "event_category": gtag_event_category.PRACTICE_QUIZ,
            "event_label": "Prev",
        })
    }

    function onNextPress() {
        dispatch({ type: userPracticeConstants.UPDATE_CURRENT_INDEX, index: currentIndex + 1 })
        window.gtag("event", gtag_events.NextQuestionClicked, {
            "event_category": gtag_event_category.PRACTICE_QUIZ,
            "event_label": "Next",
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "row-reverse", padding: 12 }}>


            <div >
                <NewButton className="new-btn-outline" disabled={currentIndex === data.length - 1} onClick={onNextPress}>
                    Next <FiChevronRight size={24}/>
                </NewButton>
            </div>
            <div style={{ marginRight: 15, paddingRight:15, borderRight: "2px groove" }}>
                <NewButton className="new-btn-outline" disabled={currentIndex === 0} onClick={onPrevPress}>
                    <FiChevronLeft size={24} /> Prev
                </NewButton>
            </div>
        </div>
    )
}

export default PracticeFooter;