import React from 'react'
import { Accordion } from 'react-bootstrap'
import HeaderWithState from '../Components/Header/HeaderWithState.js'
import { useLocation, useNavigate } from 'react-router-dom/dist/index'

function All() {
  const seriesData = useLocation().state
  const navigate = useNavigate()

  return (
    <>
      <HeaderWithState subject_name={seriesData?.series_name} state={() => { navigate(-1) }} />

      <div className='mt-2'>
        {
          seriesData?.data?.map(
            (item, id) => {
              return (
                <Accordion key={id} flush>
                  <Accordion.Item className='mb-2 mx-0' eventKey={`${id}`} >
                    <Accordion.Header className='Background-Color'>
                      {item.name}
                    </Accordion.Header>
                    <Accordion.Body className='Background-Color  px-0 py-1'>
                      your recordings will be available soon......
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )
            }
          )
        }
      </div>
    </>
  )
}

export default All