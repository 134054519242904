import React from 'react'
import { useDispatch } from 'react-redux';
import { FiArrowLeft  } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FILTER_LIST } from '../../../functions/Functions.js'
import { TestReducersConstants } from '../../../../Redux/Test/TestReducersConstants.js';



function ViewSolutionHeader({ display_name }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selected = useSelector(state => state.test?.filter_option)
    return (
        <header id='quiz-header' className='Background d-flex justify-content-between align-items-center p-3'>
            <div className='d-flex align-items-center'>
                <FiArrowLeft  className='Psudo-Button' size={20} onClick={() => { navigate(-1) }} /> &nbsp;
                <h4 className='m-0'> {display_name} </h4>
            </div>

            <select onChange={(e) => { 
                dispatch({ type: TestReducersConstants.FILTER, payload: e.target.value }) 
                dispatch({type: TestReducersConstants.ACTIVE_INDEX, payload: 0})
                }} 
                value={selected}
                >
                {
                    FILTER_LIST.map(
                        (item, id) => {
                            return (
                                <option key={id} value={item} id={item} selected={item==selected}>{item}</option>
                            )
                        }
                    )
                }
            </select>
        </header>
    )
}

export default ViewSolutionHeader