import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { createRipple } from "../../../Functions/Functions.js";
import ESaralLoader from "../../../Spinner/ESaralLoader.js";
import CourseEnrollButton from "../CourseEnrollButton.js";
import PriceSection from "../PriceSection.js";

function CourseInfo({ course, testimonials, faculties }) {
  const [aboutData, setAboutData] = useState("");

  useEffect(() => {
    dataFetcher(course.about_url);
  }, []);

  function dataFetcher(url) {
    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        setAboutData(data.split("<body>")[1].split("</body>")[0]);
      });
  }

  return (
    <>
      {aboutData ? (
        <div id="CourseInfo">
          <div
            style={{
              height: `100%`,
              overflowY: "auto",
            }}
            id="CourseInfo">
            {/*---------------------------------------------------------About---------------------------------------------------------------------*/}
            <section id="About" className="Card-Format">
              <div className="ms-3 mt-3" style={{ height: "100%" }}>
                <div dangerouslySetInnerHTML={{ __html: aboutData }} />
              </div>
            </section>

            {/*---------------------------------------------------------Faculty---------------------------------------------------------------------*/}
            <section id="Faculty" className="Card-Format mt-3">
              <Container fluid="md">
                <div className="Home-Name Course-heading py-3">Faculty</div>
                <Row className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2">
                  {faculties.map((facultie, id) => {
                    return (
                      <Col
                        key={id}
                        className={`px-0 me-5 ${
                          id === 0 ? "custom-margin-left" : ""
                        }`}>
                        <img
                          draggable="false"
                          className="Faculity-img"
                          src={facultie.image.split("?")[0]}
                          alt={facultie.name}
                          onError={(e) => {
                            e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
                        }}
                        />
                        <div className="Subject-Button-Img-Title">
                          {facultie.name}
                        </div>
                        <div className="Home-Name-subtitle ">
                          {facultie.designation.charAt(0).toUpperCase() +
                            facultie.designation.slice(1).toLowerCase()}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </section>

            {/*---------------------------------------------------------Testimonials---------------------------------------------------------------------*/}
            <section id="Testimonials" className="Card-Format  mt-3">
              <Container fluid="md">
                <div className="Home-Name Course-heading py-3">
                  Testimonials
                </div>
                <Row className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2">
                  {testimonials.map((testimonial, id) => {
                    return (
                      <Col
                        key={id}
                        className={`px-0 me-5 ${
                          id === 0 ? "custom-margin-left" : ""
                        }`}>
                        <div
                          key={id}
                          className="Background-Color-1 m-2 ms-0 course-card p-3"
                          style={{ height: "100%" }}>
                          <div className="d-flex my-2 ">
                            <img
                              draggable="false"
                              src={testimonial.profile_image}
                              className="course-testimonial-img"
                              alt=".."
                              onError={(e) => {
                                e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
                            }}
                            />

                            <div className="mx-4">
                              <strong>{testimonial.student_name}</strong>
                              <div className="course-testimonial-name">
                                {testimonial.qualification}
                              </div>
                            </div>
                          </div>
                          <em>"{testimonial.details}"</em>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </section>

            {/*---------------------------------------------------------Pricing---------------------------------------------------------------------*/}
            <section
              id="Pricing"
              className="Card-Format"
              style={{
                height: "24vh",
              }}>
              <PriceSection
                discounted_fee={course.discounted_fee}
                fee={course.fee}
              />
            </section>
          </div>
          {/*---------------------------------------------------------Course-Button---------------------------------------------------------------------*/}
          <div className="Course-Enroll-now p-3 px-4">
            <CourseEnrollButton course={course} />
          </div>
        </div>
      ) : (
        <>
          <ESaralLoader />
        </>
      )}
    </>
  );
}

export default CourseInfo;
