import Navbar from 'react-bootstrap/Navbar';
import Search from '../../Constants/Search/Search';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant';
import { FRONTEND_URLS } from '../../URL/URL';



function SubjectHeader({ name='' }) {

    const { subject_node_id } = useParams()
    const subjectName = JSON.parse(localStorage.getItem('user'))?.subjects?.find((e) => e.node_id == subject_node_id)?.display_name
    const previousPages = useSelector(state => state.app.previousPages)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handlePreviousPageNavigation = () => {
        const targetRoute = previousPages.length === 0 ? FRONTEND_URLS.HOME_ROUTE : previousPages[previousPages.length - 1];
        navigate(targetRoute);
        dispatch({ type: AppReducerConstants.PREVIOUS_PAGE_REMOVE })
    }

    return (
        <Navbar sticky="top" className='Background Header' id='subject-navbar'>
            <div className='container-xxl' >
                <div className='d-flex align-items-center justify-content-around Psudo-Button'
                    onClick={handlePreviousPageNavigation} >
                    <FiArrowLeft size={25} className='SubjectName' />
                    &nbsp;
                    &nbsp;
                    <div className='SubjectName'>
                        {!!name ? name : subjectName}
                    </div>
                </div>
                <Navbar.Collapse className="justify-content-end" >
                    <Search />
                </Navbar.Collapse>
            </div>
        </Navbar>
    )
}

export default SubjectHeader;