import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FormDropDown from "./FormDropDown";
import { AxiosClient } from "../../../Axios/AxiosClient";
import { BACKEND_URLS } from "../../../../URL/URL";
import DoubutFromUpload from "./DoubutFromUpload";
import AutoHideAlert from "../../../Alerts/AutoHideAlert";
import question from "../../../../Images/LottieImages/question.json";
import LottieImage from "../../../LottieImage/LottieImage";
import { RxCross2 } from "react-icons/rx";
import { AlertReducersConstants } from "../../../../Redux/ReducerConstants/AlertReducersConstants";
import { useDispatch } from "react-redux";
import { handleSetShowAlertModal } from "../../../../helpers/helperFunctions";



function DoubutForm({ setDoubtForm, showDoubtForm }) {
  const user_id = JSON.parse(localStorage.getItem("user"))?.user?.user_id;
  const { phase_id, subcourses } = JSON.parse(localStorage.getItem("user"))?.enrollments.find((e) => e.is_current === true);
  const subcourse_id = subcourses?.find((e) => e.is_current === true).id;
  const [suject, setSubject] = useState();
  const [chapter, setChapter] = useState();
  const [url, setUrl] = useState([]);
  const [warnShow, setWarnShow] = useState(false);
  const questionRef = useRef();
  const dispatch = useDispatch()


  function CrudDoubt() {
    if (!suject || !chapter || !questionRef.current.value) {
      setWarnShow(true);
    } else {
      setWarnShow(false);
      handleClose();

      AxiosClient.post(BACKEND_URLS.CRUD_DOUBT, {
        student_id: Number(user_id),
        phase: Number(phase_id),
        subcourse: Number(subcourse_id),
        subject: Number(suject),
        chapter: Number(chapter),
        question_source: "EXTERNAL",
        question_text: questionRef.current.value,
        question_images: url ? url : [],
        department: "ACADEMIC",
      })
        .then((result) => {
          if (result.data.success) {
            dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.SUCCESS })
            dispatch({ type: AlertReducersConstants.MESSAGE, payload: 'Your Doubt is successfully submit.' })
            dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })
            handleSetShowAlertModal({dispatch, message:'Your Doubt is successfully submit.'})
            window.location.reload();
          }
          else {
            handleSetShowAlertModal({dispatch, message:JSON.stringify(result.data.errors)})
          }
        })
        .catch((err) => {
        });
        setUrl([]);
        setChapter();
        setSubject();
        setWarnShow(false);
    }
  }

  function handleClose() {
    setWarnShow(false);
    setDoubtForm(false);
    setUrl([])
  }

  return (
    <Modal show={showDoubtForm} fullscreen="sm-down">
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div
            style={{
              height: "auto",
              width: "36%",
            }}>
            <LottieImage animationData={question} />
          </div>
          <RxCross2
            size={30}
            onClick={handleClose}
            className="Psudo-Button my-4"
          />
        </div>

        <AutoHideAlert
          message="All Fields are Required."
          show={warnShow}
          setShow={setWarnShow}
        />

        <Form
          onSubmit={(e) => {
            e.preventDefault()
          }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="I-Have-Doubut">I have doubt in</Form.Label>
            <FormDropDown
              subcourse_id={subcourse_id}
              setSubject={setSubject}
              setChapter={setChapter}
            />
            <br />
          </Form.Group>

          <Form.Group className="mb-3" controlId="Doubut-Form-TextArea">
            <Form.Control
              ref={questionRef}
              as="textarea"
              rows={3}
              placeholder="Type your question here..."
            />
          </Form.Group>

          <Form.Group
            style={{
              width: "100%",
            }}>
            <DoubutFromUpload
              url={url}
              setFilePath={setUrl}
              s3Folder="eSaral/doubt_images"
            />
          </Form.Group>

          <br />

          <Button
            variant="primary"
            onClick={() => {
              CrudDoubt();
            }}
            style={{
              width: "100%",
              backgroundColor: "#5F46E3",
              borderColor: "#5F46E3",
            }}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DoubutForm;
