import React from 'react'
import { Button } from 'react-bootstrap'
import { createRipple } from '../Functions/Functions';

function SecondaryButtons({ children, onClick = () =>{}, className = '', style = {} }) {
    return (
        <Button
            variant="outline-dark"
            type="submit"
            className={`Custom-button-ripple mx-0 px-0 ${className}`}
            style={{
                ...style
            }}
            onClick={(e) => {
                createRipple({ event: e, className: "ripple-primary" });
                onClick()
            }}>
            {children}
        </Button>
    )
}

export default SecondaryButtons