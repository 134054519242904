import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ESaralLoader from "../../Spinner/ESaralLoader.js";
import NotFound from "../../Error/NotFound.js";


const Faq = () => {

  const location = useLocation();
  const { faqs } = location.state || { faqs: [] };

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    faqs
      ?
      <div className="faq_head_box_flex">
        <Accordion defaultActiveKey="0">
        {faqs?.map((item, index) => {
          return (
              <Accordion.Item className='mb-2 mx-0' eventKey={`${index}`} onClick={(e) => setActiveIndex(index)}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body className='px-0 py-1' style={{ marginTop: "0.2rem" }}>
                  {
                     activeIndex == index &&
                     ( item.detail ?
                      <div
                        key={index}
                        className="faq_head_desc"
                        style={{ textAlign: "justify", padding: "1rem 1.25rem" }}
                        dangerouslySetInnerHTML={{ __html: item.detail }}
                      />
                      : <ESaralLoader />
                     )
                    }
                </Accordion.Body>
              </Accordion.Item>
          )
        })}
        </Accordion>
      </div>
      :
      <NotFound />
  );
};

export default Faq;
