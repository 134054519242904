import React, { useEffect, useState } from 'react';
import { loginCoursalImage, CaursalImagesLines } from '../../../Images/login-coursal/loginCoursalImages.js';


const LoginCarusal = () => {

    const [show, setShow] = useState(0)


    useEffect(() => {
        const changer = setInterval(() => {
            if (show < 3) {
                setShow((e) => e + 1);
            } else {
                setShow(0);
            }
        }, 2000);

        // Clear interval on component unmount
        return () => clearInterval(changer);
    }, [show]);


    return (
        <>
            <div className='w-100 m-auto' style={{
                height: '100vh'
            }}>
                {/**-------------------------- coursal-1 ---------------------------------*/}
                <div id='cour-0' className='h-100 w-100 position-relative m-auto top-50' style={{
                    maxHeight: '600px', transform: 'translateY(-50%)', display: show == 0 ? '' : 'none',  transition: 'display 0.5s ease-in-out',
                }}>
                    <img src={loginCoursalImage[1][0]} className='position-absolute' style={{
                        width: '40%'
                    }} />
                    <img src={loginCoursalImage[1][4]} className='position-absolute' style={{
                        width: '20%', right: 0, bottom: '20%'
                    }} />
                    <div>
                        <img src={loginCoursalImage[1][1]} className='position-absolute' style={{
                            width: '50%', left: '50%', transform: 'translateX(-50%)', top: '20%'
                        }} />
                        <img src={loginCoursalImage[1][3]} className='position-absolute' style={{
                            width: '26%', left: '50%', top: '50%'
                        }} />
                        <img src={loginCoursalImage[1][2]} className='position-absolute' style={{
                            width: '37%', left: '40%', transform: 'translateX(-50%)', top: '47%'
                        }} />
                    </div>

                    <div className='w-100'>
                        <div className='position-absolute text-center mx-5' style={{ bottom: '10%' }}>
                            <h1 className='login-coursal-heading'>{CaursalImagesLines.Learn.heading}</h1>
                            <p className='login-coursal-subheading'>{CaursalImagesLines.Learn.text}</p>
                        </div>
                    </div>
                </div>
                {/**-------------------------- coursal-2 ---------------------------------*/}
                <div id='cour-1' className='h-100 w-100 position-relative m-auto top-50' style={{
                    maxHeight: '600px', transform: 'translateY(-50%)', display: show == 1 ? '' : 'none',   transition: 'display 0.5s ease-in-out',
                }}>
                    <img src={loginCoursalImage[2][0]} className='position-absolute' style={{
                        width: '25%'
                    }} />
                    <img src={loginCoursalImage[2][1]} className='position-absolute' style={{
                        width: '25%', right: 0,
                    }} />
                    <img src={loginCoursalImage[2][3]} className='position-absolute' style={{
                        width: '25%', bottom: 0,
                    }} />
                    <img src={loginCoursalImage[2][4]} className='position-absolute' style={{
                        width: '25%', right: 0, bottom: 0,
                    }} />

                    <img src={loginCoursalImage[2][2]} className='position-absolute' style={{
                        width: '50%', left: '50%', transform: 'translateX(-50%)', top: '20%'
                    }} />

                    <div className='position-absolute text-center w-100' style={{ bottom: '10%' }}>
                        <h1 className='login-coursal-heading'>{CaursalImagesLines.Practice.heading}</h1>
                        <p className='login-coursal-subheading'>{CaursalImagesLines.Practice.text}</p>
                    </div>

                </div>


                {/**-------------------------- coursal-3 ---------------------------------*/}
                <div id='cour-2' className='h-100 w-100 position-relative m-auto top-50' style={{
                    maxHeight: '600px', transform: 'translateY(-50%)', display: show == 2 ? '' : 'none', 
                }}>
                    <img src={loginCoursalImage[3][0]} className='position-absolute' style={{
                        width: '35%'
                    }} />
                    <img src={loginCoursalImage[3][1]} className='position-absolute' style={{
                        width: '70%', top: '50%', transform: 'translateY(-50%)'
                    }} />
                    <img src={loginCoursalImage[3][2]} className='position-absolute' style={{
                        width: '20%', bottom: 0, right: 0
                    }} />

                    <div className='position-absolute text-center w-100' style={{ bottom: '10%' }}>
                        <h1 className='login-coursal-heading'>{CaursalImagesLines.Test.heading}</h1>
                        <p className='login-coursal-subheading'>{CaursalImagesLines.Test.text}</p>
                    </div>

                </div>

                {/**-------------------------- coursal-4 ---------------------------------*/}
                <div id='cour-3' className='h-100 w-100 position-relative m-auto top-50' style={{
                    maxHeight: '600px', transform: 'translateY(-50%)', display: show == 3 ? '' : 'none'
                }}>
                    <img src={loginCoursalImage[4][0]} className='position-absolute' style={{
                        width: '35%', right: 0,
                    }} />
                    <img src={loginCoursalImage[4][2]} className='position-absolute' style={{
                        width: '20%', bottom: 0
                    }} />
                    <img src={loginCoursalImage[4][1]} className='position-absolute' style={{
                        left: '50%', transform: 'translateX(-50%)', top: '20%'
                    }} />

                    <div className='position-absolute text-center w-100' style={{ bottom: '14%' }}>
                        <h1 className='login-coursal-heading'>{CaursalImagesLines.Revise.heading}</h1>
                        <p className='login-coursal-subheading'>{CaursalImagesLines.Revise.text}</p>
                    </div>
                </div>
            </div>


            <div className='h-100 d-flex justify-content-center align-items-center position-absolute' style={{ maxHeight: '12px', top: 'calc(50% + 280px)', marginLeft:'300px' }}>
                <div className={`coursal-indicator ${show == 0 ? 'coursal-indicator-active' : ''} m-1`}></div>
                <div className={`coursal-indicator ${show == 1 ? 'coursal-indicator-active' : ''} m-1`}></div>
                <div className={`coursal-indicator ${show == 2 ? 'coursal-indicator-active' : ''} m-1`}></div>
                <div className={`coursal-indicator ${show == 3 ? 'coursal-indicator-active' : ''} m-1`}></div>
            </div>
        </>
    );
};

export default LoginCarusal;