import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
 import { AiOutlineArrowRight } from "react-icons/ai";
import { NameTaker, createRipple } from "../../Functions/Functions";
import { useState } from "react";
import { RegisterPageConstantLines } from "../../../Constants/Register/Register";
import { useNavigate } from "react-router-dom";
import { FRONTEND_URLS } from "../../../URL/URL";
import { RegisterConstant } from "./RegisterConstant";
import { useDispatch } from "react-redux";

function RegisterName() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const value = e.target.value;
    // Check if the first character is a number
    if (!/^[0-9]/.test(value)) {
      setName(value);
    }
  };

  return (
    <Container style={{height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
     }}>
      <Row
        className="d-flex justify-content-center"
        style={{
          maxHeight: "720px",
          height: "100vh",
          justifyContent:"center"
        }}>
        <Col sm={12} md={8}>
          <Form
            className="d-flex flex-column"
            style={{
              height: "100%",
            }}
            noValidate
            validated={false}
            onSubmit={(e) => {
              e.preventDefault();
              NameTaker(name, dispatch);
              navigate( FRONTEND_URLS.REGISTER_ROUTE + "/" +RegisterConstant.ATTEMPT_YEAR.toLowerCase(), {state: {name}});}}>
            <Form.Group
              className="d-flex flex-column"
              controlId="formBasicEmail">
              <div className="mt-5">
                <Form.Text className="Form-heading1">Hi👋</Form.Text>
                <br />
                <Form.Text className="Form-heading3">
                  {RegisterPageConstantLines.name.subTitle}
                </Form.Text>
              </div>
              <br />
              <div>
                <Form.Label className="Name-heading3">
                  {RegisterPageConstantLines.name.subTitle2}
                </Form.Label>
                <Form.Control
                  className="mt-1"
                  type="text"
                  placeholder={RegisterPageConstantLines.name.placholder}
                  value={name}
                  //   onChange={(e) => {
                  //     setname(e.target.value);
                  //   }}
                  onChange={handleChange}
                  autoFocus
                />
              </div>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="mt-auto mb-5 Custom-button-ripple"
              style={{ backgroundColor: "#5F46E3", borderColor: "#5F46E3" }}
              onClick={(e) => {
                createRipple({ event: e, className: "ripple-primary" });
              }}>
              Continue &nbsp; <AiOutlineArrowRight color="#fff" />
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterName;
