import React, { memo, useEffect, useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import { correct_response_variants, incorrect_response_variants } from "../../utils/constants/constants";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import MatchesItem from "./MatchesItem";
import VideoPlayer from "./media/videoplayer.component";
// import BottomOffCanvas from "../../components/BottomOffCanvas.jsx";
// import { motivationVideosIds } from "../../config.js";
// import VideoPlayer from "../../components/media/videoplayer.component.js";




const Solution = ({ data }) => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const phone = searchParams.get("phone")
    const email = searchParams.get("email")
    const isProofRead = pathname.includes("proof-read")
    const [showAns, setShowAns] = useState(isProofRead ? true : false)
    const [randInd, setRandInd] = useState(0)
    const [showA, setShowA] = useState(false);
    const [display, setDisplay] = useState(false)

    const teacherName = 'Saransh Gupta'

    const cor_ans = data.options.map((op, i) => op && op.is_solution == true ? (i == 0 ? 'A' : i === 1 ? 'B' : i === 2 ? 'C' : 'D') : null)
    const cor_ans_str = cor_ans && cor_ans.filter(n => n).join(", ")

    useEffect(() => {
        data.is_submitted && setRandInd(Math.floor(Math.random() * correct_response_variants.length));
        isProofRead && dispatch({ type: userPracticeConstants.UPDATE_PRACTICE_SHOW_SOLUTION, question_id: data.question_id })

    }, [data.is_submitted])



    var isAns = "Incorrect"
    if (data.question_type == "numerical") {
        isAns = Number.parseFloat(data.correct_answer) == Number.parseFloat(data.answer_numerical) ? "Correct" : "Incorrect"
    }
    else if (data.question_type === "match-the-column") {
        const incorrect = data.matches.find(({ selected_option_indexes, match_option_indexes }) => selected_option_indexes.length != match_option_indexes.length || selected_option_indexes.find(i => !match_option_indexes.includes(i)))
        isAns = incorrect ? "Incorrect" : "Correct"
    }
    else {
        const inc = data.options.filter((op) => op.is_solution !== op.is_selected)
        isAns = inc.length ? "Incorrect" : "Correct"
    }


    const showSolution = () => {
        dispatch({ type: userPracticeConstants.UPDATE_PRACTICE_SHOW_SOLUTION, question_id: data.question_id })
    }

    const submitSolution = () => {
        if (data.attempt_state === "SKIPPED") {
            return setShowA(true)
        }
        dispatch({ type: userPracticeConstants.SUBMIT_PRACTICE_ANSWER, is_ans: isAns })
    }


    return (

        <div >
            <Toast show={showA} onClose={() => setShowA(!showA)}>
                <Toast.Header>
                    <div style={{ width: "90%" }}>
                        Please solve the question first!
                    </div>
                </Toast.Header>
            </Toast>

            {data.is_submitted &&
                <div>
                    <div style={{ paddingBottom: 15, color: isAns === "Incorrect" ? "#CC2C2C" : "#008444", fontWeight: "bold" }}>
                        {
                            isAns != "Incorrect"
                                ?
                                correct_response_variants[randInd]
                                :
                                // data.attempt_count > 1
                                false
                                    ?
                                    <>
                                        <div >
                                            Your answer is incorrect but you have a message from
                                            <a id='quiz-wrong-message' onClick={() => { setDisplay(true); dispatch({ type: userPracticeConstants.BOTTOM_OFF_CANVAS }); }}> {teacherName} Sir.</a>
                                        </div>
                                        {
                                            display
                                                ?
                                                <>
                                                    <div className="d-none d-md-block" style={{ position: "fixed", height: '100vh', width: "100vw", top: '0', left: '0', zIndex: '10000' }}>
                                                        <div style={{ position: 'absolute', bottom: "60px", float: "right", right: "24px", maxWidth: '360px', width: "100%", }}>
                                                            {/* <div id='vimeo-player-video' dangerouslySetInnerHTML={{ __html: `<div style="padding:60% 0 0 0;position:relative;"><iframe src=${"https://player.vimeo.com/video/" + motivationVideosIds[Math.floor(Math.random() * motivationVideosIds.length)] + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>` }} /> */}
                                                            <div style={{ backgroundColor: '#00000052', borderRadius: '50%', height: "30px", width: "30px", cursor: 'pointer', right: '-13px', bottom: '191px', position: 'absolute' }} className="d-flex justify-content-center align-items-center" onClick={() => { setDisplay(false) }}>
                                                                <div style={{ fontSize: '20px', color: 'black' }}>X</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <BottomOffCanvas /> */}
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    incorrect_response_variants[randInd]
                        }
                    </div>
                </div>
            }
            <div className="mb-4">
                {
                    showAns && (
                        data.question_type == "numerical"
                            ?
                            <div>
                                Correct Answer is <strong> {data.correct_answer}</strong>
                            </div>
                            :
                            data.question_type == "match-the-column"
                                ?
                                <div>
                                    Correct Answer:
                                    <MatchesItem data={data} options={data.options} matches={data.matches} answered={true} />
                                </div>
                                :
                                <div>
                                    Correct Option{cor_ans_str.length > 1 ? 's are' : ' is'} <b>Option {cor_ans_str}</b>
                                </div>
                    )
                }
            </div>

            <div style={{ display: !data.show_solution && "none", paddingBottom: 60 }} >
                <h4>Solution:</h4>

                <br />
                <div dangerouslySetInnerHTML={{ __html: data.solution }} style={{ overflow: "scroll" }} />



                {data.show_solution && data.video_solutions[0] && <VideoPlayer videoUrl={data.video_solutions[0].video_url} phone={phone} />
                    //  <div dangerouslySetInnerHTML={{ __html: `<div style="padding:60% 0 0 0;position:relative;"><iframe src=${"https://player.vimeo.com/video/" + data.video_solutions[0].video_url + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>` }} />
                }
            </div>

            {data.is_submitted ?

                (isAns === "Correct" ?
                    !data.show_solution && <Button variant="outline-primary" className="p-2" style={{ backgroundColor: "#FFF", border: "solid 2px", fontWeight: "600", borderColor: "#5F46E3", color: "#5F46E3", boxShadow: "none", width: "100%" }} onClick={showSolution}>Show Solution</Button>
                    :
                    <>

                        {!data.show_solution && <Button className="mb-3 p-2" style={{ backgroundColor: "#5F46E3", boxShadow: "none", width: "100%" }} onClick={() => { setShowAns(false); submitSolution() }}> I will try again</Button>}


                        {!showAns ? <Button variant="outline-primary" className="p-2" style={{ border: "solid 2px", fontWeight: "600", borderColor: "#5F46E3", color: "#5F46E3", boxShadow: "none", width: "100%" }} onClick={() => setShowAns(!showAns)}>Show correct answer</Button>
                            :
                            !data.show_solution && <Button variant="outline-primary" className="p-2" style={{ backgroundColor: "#FFF", border: "solid 2px", fontWeight: "600", borderColor: "#5F46E3", color: "#5F46E3", boxShadow: "none", width: "100%" }} onClick={showSolution}>Show Solution</Button>
                        }
                    </>


                )
                :
                <Button style={{ backgroundColor: "#5F46E3", boxShadow: "none" }} onClick={submitSolution}>Submit</Button>
            }
        </div>
    )
}

export default memo(Solution);