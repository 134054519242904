import { AxiosClient } from "../Axios/AxiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../URL/URL";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant.js";
import { handleSetShowAlertModal } from "../../helpers/helperFunctions.js";
import { AlertReducersConstants } from "../../Redux/ReducerConstants/AlertReducersConstants.js";



export function createRipple({ event, className }) {
  const button = event.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
  circle.classList.add(className);

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}

export async function UserData({ subcourse_id = 0, phase_id = 0 }) {
  return AxiosClient.post(BACKEND_URLS.USER_DATA, {
    switch_target_subcourse_id: subcourse_id,
    switch_phase_id: phase_id,
    app_version: "web",
  })
    .then((response) => {
      if (response.data.success) {
        const data = response.data.data;

        const live = data?.live;
        localStorage.setItem("livedata", JSON.stringify(live));


        const liveSort = live.sort(
          (a, b) => a.start_date_time - b.start_date_time
        );
        const mainLiveData = {
          Upcoming: {
            name: "Upcoming",
            data: [],
          },
          All: {
            name: "All",
            data: [],
          },
        };

        function addToMainData(dataType, item, series_id_arr) {
          // item is in the array
          if (series_id_arr.indexOf(item.series_id) > -1) {
            // find the element and push the data in this
            dataType.data.forEach((series) => {
              if (series.series_id === item.series_id) {
                series.data.push(item);
              }
            });
          }
          // item is not in the array
          else {
            dataType.data.push({
              series_id: item.series_id,
              series_name: item.series_name,
              series_thumbnail: item.series_thumbnail,
              series_category: item.series_category,
              data: [item],
            });
            series_id_arr.push(item.series_id);
          }
        }

        const series_id_arr_upcoming = [];
        const series_id_arr_all = [];

        for (let i = 0; i < liveSort.length; i++) {
          const systemTime = Math.floor(new Date().getTime() / 1000.0);
          const item = liveSort[i];

          // Upcoming class data + Ongoing class data
          if (systemTime < item.start_date_time || (systemTime >= item.start_date_time && systemTime < item.end_date_time)) {
            addToMainData(mainLiveData.Upcoming, item, series_id_arr_upcoming);
          }
          // All class data
          else {
            addToMainData(mainLiveData.All, item, series_id_arr_all);
          }
        }

        localStorage.setItem(
          "user",
          JSON.stringify({ ...data, live: mainLiveData })
        );
        subcourse_id && window.location.reload();

        return true;
      }
      else {
        localStorage.clear()
        window.location.href =FRONTEND_URLS.LOGIN_ROUTE
      }
    })
    .catch((error) => {
      return false;
    });
}


export function formatString(inputString) {
  let formattedString;

  if (inputString == "/") {
    return "Home";
  } else if (inputString.startsWith("/")) {
    formattedString = inputString.slice(1).split("-");
  }

  formattedString = formattedString
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedString;
}

export function SecToTimeConverter(sec) {
  if (sec == undefined) {
    return "00:00";
  }
  if (sec < 0) {
    throw new Error("Input must be a non-negative number.");
  }

  const hours =
    Math.floor(sec / 3600) < 10
      ? `0${Math.floor(sec / 3600)}`
      : Math.floor(sec / 3600);
  const minutes =
    Math.floor((sec % 3600) / 60) < 10
      ? `0${Math.floor((sec % 3600) / 60)}`
      : Math.floor((sec % 3600) / 60);
  const seconds = sec % 60 < 10 ? `0${sec % 60}` : sec % 60;

  return hours > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
}

export function formatDate(inputDate) {
  // Parse the input date string
  const parts = inputDate.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);

  // Create an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the formatted date
  const formattedDate = `${day}${getDaySuffix(day)} ${monthNames[month - 1]
    } ${year}`;

  // Function to get the day suffix (e.g., 'st', 'nd', 'rd', 'th')
  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return formattedDate;
}

export function NameTaker(name, dispatch) {
  // set the name to the  server
  const format = /^[A-Za-z\u0900-\u097F][A-Za-z\u0900-\u097F\s]*$/;
  if (name.length > 0 && format.test(name)) {
    return AxiosClient.put(BACKEND_URLS.UPDATE_PROFILE, {
      first_name: name,
    })
      .then((resp) => {
        if (resp.data.success == true) {
          UserData();
          return true;
        }
      })
      .catch((err) => {
        handleSetShowAlertModal({ dispatch, message: "Their is an error occured in Saving your updated name. Please Try again after the some time." + err.message })
        return false;
      });
  } else {
    handleSetShowAlertModal({ dispatch, message: "Give the right Information." })
  }
}

//----------------------------------------------------File Upload to amazon s3.----------------------------------------------------//

export const handleUpload = async ({ files, dispatch }) => {
  try {
    const data = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file, index) => {
        data.append('images', file);
      });
    }
    else {
      data.append('images', files);
    }

    const req = await AxiosClient.post(
      BACKEND_URLS.IMAGE_UPLOAD,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (req.data.success) {
      return req.data.data.url
    }
    else {
      handleSetShowAlertModal({ dispatch, message: JSON.stringify(req.data.errors) })
    }
  }
  catch (error) {
  }
};

//-------------------------------------------------------------------------------------------------------------------------------------//

export function VideoFun(video_id, dispatch) {
  AxiosClient.post(BACKEND_URLS.VIDEO_OTP, {
    video_id: video_id,
    video_download: false,
  })
    .then((response) => {
      dispatch({
        type: AppReducerConstants.VIDEO_INFO,
        payload: response.data.data.video_otp,
      });
    })
    .catch((error) => {
      dispatch({ type: AppReducerConstants.VIDEO_INFO, payload: null });
    });
}

export function videoShow({
  video_id,
  navigate,
  subject_node_id,
  node_id,
  dispatch,
}) {
  dispatch({ type: AppReducerConstants.TAB, payload: "fill-tab-Learn" });
  dispatch({ type: AppReducerConstants.IS_VIDEO, payload: true });
  dispatch({ type: AppReducerConstants.VIDEO_ID, payload: video_id });
  dispatch({ type: AppReducerConstants.SCROLL_HEIGHT, payload: 40 });
  VideoFun(video_id, dispatch);
  navigate(FRONTEND_URLS.HOME_ROUTE + subject_node_id + "/" + node_id);
}

export function PDFShow({
  isPDF,
  pdf_name,
  navigate,
  subject_node_id,
  node_id,
  dispatch,
}) {
  dispatch({ type: AppReducerConstants.TAB, payload: "fill-tab-Practice" });
  dispatch({ type: AppReducerConstants.IS_PDF, payload: isPDF });
  dispatch({ type: AppReducerConstants.PDF_NAME, payload: pdf_name });
  navigate(FRONTEND_URLS.HOME_ROUTE + subject_node_id + "/" + node_id);
}


export const isAccessible = ({ available_till_time, available_from_time, dispatch, content_name }) => {
  const available_till = new Date(available_till_time).getTime();
  const available_from = new Date(available_from_time).getTime();
  const server_current_time = Date.now();

  if (available_from && available_from > server_current_time) {
    const available_at = available_from.toLocaleString()
    dispatch({ type: AlertReducersConstants.MESSAGE, payload: `${content_name} will be available from ${available_at}` })
    dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, payload: true })
    return false;
  }
  else if (available_till && available_till < server_current_time) {
    dispatch({ type: AlertReducersConstants.MESSAGE, payload: `${content_name} is not available` })
    dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, payload: true })
    return false;
  }
  return true;
}