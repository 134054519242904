import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FRONTEND_URLS } from "../URL/URL.js";
import { useState } from "react";
import ESaralLoader from "../Components/Spinner/ESaralLoader.js";
import Header from "../Components/Header/Header.js";

const VERSION_NUMBER = `2.17.0`;

function Zoom() {

  const [isJoin, setIsJoin] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const [isStart, setIsStart] = useState(false)
  const { zoom, meetingDetails } = state
  const CLIENT_ID = zoom.clientKey;
  const CLIENT_SECRET = zoom.clientSecret;

  async function startMeeting() {

    try {
      const { ZoomMtg } = await import("@zoomus/websdk");

      const zoomJSLib = `https://source.zoom.us/${VERSION_NUMBER}/lib`;
      const zoomJSAVLib = '/av'
      ZoomMtg.setZoomJSLib(zoomJSLib, zoomJSAVLib);

      ZoomMtg.preLoadWasm()
      ZoomMtg.prepareWebSDK()

      // loads language files, also passes any error messages to the ui
      ZoomMtg.i18n.load('en-US')
      ZoomMtg.i18n.reload('en-US')

      try {
        ZoomMtg.generateSDKSignature({
          meetingNumber: meetingDetails.mn,
          sdkKey: CLIENT_ID,
          sdkSecret: CLIENT_SECRET,
          role: meetingDetails.role,

          success: function (res) {
            const signature = res.result
            setIsStart(true)
            ZoomMtg.init({
              debug: false, //optional
              leaveUrl: window.location.origin + (state.url ?? FRONTEND_URLS.HOME_ROUTE), //required
              // showMeetingHeader: true, //option
              disableInvite: true, //optional
              // disableCallOut: false, //optional
              // disableRecord: false, //optional
              // disableJoinAudio: false, //optional
              // audioPanelAlwaysOpen: true, //optional
              // showPureSharingContent: false, //optional
              // isSupportAV: true, //optional,
              // isSupportChat: true, //optional,
              // screenShare: true, //optional,
              // rwcBackup: ``, //optional,
              // videoDrag: true, //optional,
              // sharingMode: `both`, //optional,?
              // videoHeader: true, //optional,
              success: (success) => {
                ZoomMtg.join({
                  sdkKey: zoom.clientKey,
                  signature: signature, // role in SDK signature needs to be 0
                  meetingNumber: meetingDetails.mn,
                  passWord: meetingDetails.pwd,
                  userName: meetingDetails.name,
                  success: (success) => {

                    if (success?.method === "join") {
                      setIsJoin(true)
                    }
                    var element = document.querySelector('#wc-container-left > div.meeting-info-container.meeting-info-container--left-side');
                    if (element) {
                      element.remove();
                    }
                  },
                  error: (error) => {
                  }
                })
              },
              error: (error) => {
              }
            });

          }
        })
      }
      catch (err) {
      }
    }
    catch (err) {
    }

  }

  useEffect(
    () => {

      window.addEventListener(
        'beforeunload', (e) => {
          e.returnValue = 'you leave the class.'
          return
        }
      )

      window.addEventListener('popstate', function (event) {

        event.preventDefault()
        if (window.confirm("Do you really want to go back?")) {
          // Handle the back navigation
          window.location.replace(FRONTEND_URLS.LIVE)
        } else {
          // Prevent the back navigation and restore the current URL
          navigate(FRONTEND_URLS.LIVE + `/zoom`, { state: state })
        }
      });

      startMeeting()

    }, []
  )

  return (
    <>
      {!isJoin && <Header />}
      {
        isStart
          ?
          <></>
          :
          <ESaralLoader />
      }
    </>
  );
}

export default Zoom;
