import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant";
import ListItems from "./DoubutSolveSideBar/ListItems.js";
import { useNavigate, useParams } from "react-router-dom";
import ESaralLoader from "../../Spinner/ESaralLoader.js";
import { FRONTEND_URLS } from "../../../URL/URL.js";

const DoubutSolvePhoneTab = ({ className = "isPhoneAndTab"}) => {
  const { queries } = useParams();

  const items = useSelector((state) => state.app?.DoubtSolveMenu);
  const doubut = useSelector((state) => state.app?.doubut);
  const isLoading = useSelector((state) => state.app.isLoading);
  const defaultKey = doubut?.split("-")[2] + "-" + doubut?.split("-")[3];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function tabFun(id) {
    navigate(queries == 'queries' ? FRONTEND_URLS.DOUBUT_SOLVE_QUERIES : FRONTEND_URLS.DOUBUT_SOLVE);
    dispatch({ type: AppReducerConstants.DOUBUT, payload: id });
    dispatch({ type: AppReducerConstants.IS_LOADING, payload: true });
    dispatch({
      type: AppReducerConstants.DOUBUT_SOLVE_MENU,
      payload: Number(id.split("-")[3]),
    });
  }

  

  return (
    <div id="DoubutSolvePhoneTab" className={className}>
    
      <Tabs
        variant="underline"
        defaultActiveKey={defaultKey}
        id="tab"
        className="mb-2 p-0"
        justify
        onClick={(e) => {
          tabFun(e.target.id);
        }}>
        {items.map((item, id) => {
          return (
            <Tab
              key={id}
              eventKey={`${item.name}-${item.id}`}
              title={`${item.name} (${item.count})`}
              style={{ color: "black" }}
            />
          );
        })}
      </Tabs>
      {!isLoading ? <ListItems  /> : <ESaralLoader />}
    </div>
  );
};

export default DoubutSolvePhoneTab;
