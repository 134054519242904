import React from 'react'
import NextAndPrevBut from '../../../../components/body/NextAndPrevBut.js'
import VSQueAndBody from './VSQueAndBody.js'


function VSBody() {
  return (
    <div style={{position:'relative'}}>
      <div id='quiz-Que' className=' '>
        <VSQueAndBody />
        <NextAndPrevBut />
      </div>
    </div>
  )
}

export default VSBody