import React, { useEffect } from "react";
import QueNo from "./QueNo";
import "./assets/StudentPractice.css";
import PracticeFooter from "./PracticeFooter";
import PracticeBody from "./PracticeBody";
import PracticeHeader from "./PracticeHeader";
import { useSelector } from "react-redux";


const Practice = () => {

    const loading = useSelector((state) => state.userPracticeReducer.loading)

    useEffect(() => {
        window.Buffer = window.Buffer || require("buffer").Buffer;
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
    ]);

        const practice = document.getElementById('Practice')

        const handleContextMenu = (event) => {
            event.preventDefault();
          };
      
          const handleCopy = (event) => {
            event.preventDefault();
          };
      
          const handleCut = (event) => {
            event.preventDefault();
          };
      
          const handleSelect = (event) => {
            event.preventDefault();
          };
      
          const handleSelectStart = (event) => {
            event.preventDefault();
          };

          const handleDragStart = (event) => {
            event.preventDefault();
          };
      

        practice.addEventListener('contextmenu', handleContextMenu);
        practice.addEventListener('copy', handleCopy);
        practice.addEventListener('cut', handleCut);
        practice.addEventListener('select', handleSelect);
        practice.addEventListener('selectstart', handleSelectStart);
        practice.addEventListener("dragstart", handleDragStart);


        return () => {
            practice.removeEventListener('contextmenu', handleContextMenu);
            practice.removeEventListener('copy', handleCopy);
            practice.removeEventListener('cut', handleCut);
            practice.removeEventListener('select', handleSelect);
            practice.removeEventListener('selectstart', handleSelectStart);
            practice.removeEventListener('dragstart', handleDragStart);
        };

    }, [loading])
    return (

        <div className="app" id='Practice'>

            < div className="header">
                <PracticeHeader />
                <QueNo />
            </div>


            {loading ?
                <div className="spinner-container" id="">
                    <div className="loading-div">
                        <div className="loading-spinner" />
                    </div>
                </div>
                :
                <>
                    <div className="content" style={{
                      backgroundColor:"white"
                    }}>
                        <PracticeBody />
                    </div>

                    <div className="footer">
                        <PracticeFooter />
                    </div>
                </>
            }
        </div>
    )
}

export default Practice;