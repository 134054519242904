import React, { memo } from 'react'
import ResultPageCard from './ResultPageCard.js'
import { result_images } from '../../../../Images/Result/ResultImage.js'
import { useDispatch, useSelector } from 'react-redux';
import { QuizStart, TimeDifference } from '../../../functions/Functions.js';
import PrimaryButton from '../../../components/buttons/PrimaryButton.js';
import SecondaryButton from '../../../components/buttons/SecondaryButton.js';
import { useNavigate, useParams } from 'react-router-dom';
import { FRONTEND_URLS } from '../../../../URL/URL.js';
import { TestReducersConstants } from '../../../../Redux/Test/TestReducersConstants.js';



function ResultContent() {
    const {quiz_attempt_id} = useParams()
    const quiz_attempt_status = useSelector(state => state.test.student_attempt?.quiz_attempt_status);
    const quiz_id = useSelector(state => state.test.quiz_id);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div id='quiz-Que' className='Background ResultContent result-content'>
            <div className=' m-auto ResultContentWidth ResultContentFlexBox' >
                <div>
                    <h4 className='text-center p-4'>Quick Summary</h4>

                    <div style={{ width: '98.5%' }} className='justify-content-center '>
                        <ResultPageCard logo={result_images.Rank} title={'Rank'} value={quiz_attempt_status.rank} />
                    </div>

                    <div style={{ width: '100%' }} className='d-flex justify-content-center '>
                        <ResultPageCard logo={result_images.Correct} title={'Correct'} value={quiz_attempt_status.correct_answers} />
                        <ResultPageCard logo={result_images.Incorrect} title={'Incorrect'} value={quiz_attempt_status.incorrect_answers} />
                        <ResultPageCard logo={result_images.Skipped} title={'Skipped'} value={quiz_attempt_status.skipped_answers} />
                    </div>

                    <div style={{ width: '100%' }} className='d-flex justify-content-center'>
                        <ResultPageCard logo={result_images.Time_Taken} title={'Time Taken'}
                            value={
                                TimeDifference(quiz_attempt_status.start_time, quiz_attempt_status.submission_time)
                            }
                        />
                        <ResultPageCard logo={result_images.Marks} title={'Marks'}
                            value={
                                <strong>
                                    {quiz_attempt_status.total_marks}/{quiz_attempt_status.quiz_total_marks}
                                </strong>
                            }
                        />
                    </div>
                </div>

                <div className='new-btn-group'>
                    <SecondaryButton name='View Solution' onClick={() => {navigate(FRONTEND_URLS.TEST_RESULT + '/' + quiz_attempt_id + '/solution')}}/>
                    <PrimaryButton name='Test Again' style={{width:'100%'}} onClick={() => {
                        // QuizStart(quiz_id,dispatch)
                        dispatch({type:TestReducersConstants.RESET})
                        navigate(FRONTEND_URLS.TEST_ROOT, {state:{quiz_id}})
                    }} />
                </div>

            </div>
        </div>
    )

}

export default memo(ResultContent)