import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";
import LottieImage from "../../../Components/LottieImage/LottieImage.js";
import question from "../../../Images/LottieImages/question.json";
import { Form } from "react-bootstrap";
import FormDropDown from "../DoubutSolve/DoubtSolveChatComp/FormDropDown.js";
import DoubutFromUpload from "../DoubutSolve/DoubtSolveChatComp/DoubutFromUpload.js";
import Button from "react-bootstrap/Button";
import { AxiosClient } from "../../Axios/AxiosClient.js";
import { BACKEND_URLS } from "../../../URL/URL.js";
import { AlertReducersConstants } from "../../../Redux/ReducerConstants/AlertReducersConstants.js";
import { useDispatch } from "react-redux";

const RaiseQueryModal = ({ setRQueryForm, getRQueryForm }) => {
  const [url, setUrl] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false)


  const user_id = JSON.parse(localStorage.getItem("user"))?.user?.user_id;
  const { phase_id, subcourses } = JSON.parse(localStorage.getItem("user"))?.enrollments.find((e) => e.is_current === true);
  const subcourse_id = subcourses?.find((e) => e.is_current === true).id;
  const questionRef = useRef();
  const messageRef = useRef();
  const dispatch = useDispatch()

  function handleClose() {
    setRQueryForm(false);
    setUrl([])
  }

  function CrudDoubt() {
    if (!!questionRef.current.value || !!messageRef.current.value) {

      handleClose();
      AxiosClient.post(BACKEND_URLS.CRUD_DOUBT, {
        student_id: Number(user_id),
        phase: Number(phase_id),
        subcourse: Number(subcourse_id),
        subject: "",
        chapter: "",
        question_source: "EXTERNAL",
        question_text: questionRef.current.value,
        message: messageRef.current.value,
        question_image: "", // Deprecat
        question_images: url, //more than 1 image
        department: "MENTORSHIP",
      })
        .then((result) => {
          if (result.data.success) {
            dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.SUCCESS })
            dispatch({ type: AlertReducersConstants.MESSAGE, payload: 'Your query is successfully submit.' })
            dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })
            setUrl([])
          }
        })
        .catch((err) => {
          setIsInvalid(true)
          dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.DANGER })
          dispatch({ type: AlertReducersConstants.MESSAGE, payload: err })
          dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })
          setUrl([])
        });
    }
    else {
      setIsInvalid(true)

      dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.DANGER })
      dispatch({ type: AlertReducersConstants.MESSAGE, payload: "Query is required." })
      dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })

      questionRef.current.style.border = '2px solid red'

    }
  }


  return (
    <Modal show={getRQueryForm} fullscreen="sm-down">
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div
            style={{
              height: "auto",
              width: "36%",
            }}>
            <LottieImage animationData={question} />
          </div>
          <RxCross2
            size={30}
            onClick={handleClose}
            className="Psudo-Button my-4"
          />
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
          }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="I-Have-Doubut">Raise Query</Form.Label>
          </Form.Group>

          <Form.Group className="mb-3" controlId="Doubut-Form-TextArea">
            <label className="mb-2" >
              Query
            </label>
            <Form.Control
              ref={questionRef}
              as="textarea"
              rows={3}
              placeholder="Write your details"
              required
              isInvalid={isInvalid}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="Doubut-Form-TextArea">
            <label className="mb-2" >
              Details (optional)
            </label>
            <Form.Control
              ref={messageRef}
              as="textarea"
              rows={3}
              placeholder="Write your details"
            />
          </Form.Group>

          <Form.Group
            style={{
              width: "100%",
            }}>
            <DoubutFromUpload
              url={url}
              setFilePath={setUrl}
              s3Folder="eSaral/doubt_images"
            />
          </Form.Group>

          <br />

          <Button
            variant="primary"
            onClick={CrudDoubt}
            style={{
              width: "100%",
              backgroundColor: "#5F46E3",
              borderColor: "#5F46E3",
            }}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RaiseQueryModal;
