import React from 'react'
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function MentorData() {

    const mentors = useSelector(state => state.mentorship.mentors)


    return (

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "1.5rem 1rem",
                // margin: "0 1.5rem",
                marginBottom: "8px",
                backgroundColor: "#fff",
            }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <div>
                    <h5>Mentors</h5>
                </div>

            </div>
            <div className="mentor_card_flex"> {
                mentors?.length <= 0 ?
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <strong>You have no mentor yet. A mentor will be assigned to you soon 😊.</strong>
                    </div>
                    :
                    mentors.map((mentor, index) => {
                        return (
                            <Container key={index} fluid='md' className='Background padding_mentor'>
                                <div className='mentor-Card  d-flex align-items-center p-2' style={{
                                    height:'100% !important'
                                }}>
                                    <img
                                        src={mentor?.profile_image || require('../../Images/img_all/Male_Student.png')}
                                        className="mentor_img me-2"
                                        onError={(e) => {
                                            e.target.src = require('../../Images/img_all/Male_Student.png')
                                        }}
                                    />
                                    <div>
                                        <strong>{mentor.name}</strong>
                                        <div>+91 {mentor.phone}</div>
                                    </div>
                                </div>
                            </Container>
                        );
                    })
            }
            </div>
        </div>
    )
}

export default MentorData