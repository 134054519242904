import React from 'react'
import { FiLock } from 'react-icons/fi'

function Lock({color='black'}) {
    return (
        <>
            <FiLock className='h-auto' style={{ zIndex: '100000', }} color={color} />
            <div className='position-absolute' style={{ backgroundColor: 'rgba(0, 0, 0, 0.67)', zIndex: '10000', width: '100%', height: '100%', borderRadius: '8px', }} />
        </>
    )
}

export default Lock