import Accordion from 'react-bootstrap/Accordion';
import NotFound from '../../Error/NotFound';
import ListItem from './ListItem.js';


function Practice({ data, showVideo = () => { } }) {

    function ContentType(data, key = 0) {
        return <ListItem key={key} data={data} showVideo={showVideo} />
    }

    function TestType(data) {
        return (

            data?.content_data.length > 0
                ?
                data?.content_data?.map(
                    (item, key) => {
                        return ContentType(item, key)
                    }
                )
                :
                <NotFound />
        )
    }

    return (
        <div className='mt-0 h-100'>
            {
                (data?.length > 0)
                    ?
                    data[0]?.node_type === "PRACTISE" || data[0]?.node_type === "REVISE"
                        ?
                        data[0]?.content_data &&
                        <div className='overflow-auto' style={{
                            height: "80vh"
                        }}>
                            {
                                (data[0]?.content_data).map(
                                    (content, id) => {
                                        return (
                                            <Accordion key={id} flush defaultActiveKey="0">
                                                <Accordion.Item className='mb-2 mx-0' eventKey={`${id}`} >
                                                    <Accordion.Header>{content?.display_name}</Accordion.Header>
                                                    <Accordion.Body className='Background-Color px-0 py-1'>
                                                        {
                                                            content?.node_type === "COLLECTION"
                                                            &&
                                                            content?.sheet_content
                                                            &&
                                                            (content?.sheet_content)?.map(
                                                                (data, i) => {
                                                                    return (
                                                                        data?.node_type === "CONTENT" && ContentType(data, i)
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )
                                    }
                                )
                            }
                        </div>
                        :
                        <div className='overflow-auto' style={{
                            height: "80vh"
                        }}>
                            {
                                data[0]?.node_type === "TEST"
                                    ?
                                    TestType(data[0])
                                    :
                                    data?.map(
                                        (ele, id) => {
                                            return ele?.node_type === "CONTENT" && ContentType(ele, id)
                                        }
                                    )
                            }
                        </div>
                    :
                    <NotFound />
            }
        </div>
    );
}

export default Practice;