import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ResultDataFetcher, convertDate } from '../../../functions/Functions.js'
import { FRONTEND_URLS } from '../../../../URL/URL.js'


function ResultTable() {
    const quiz_attempts = useSelector(state => state.test.student_attempt?.quiz_attempts)
    const quiz_attempts_len = quiz_attempts.length
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div id='quiz-Qno' className='me-2 Background py-4 pe-2 overflow-y-auto ResultTable' style={{ minWidth: '300px' }}>

            {
                quiz_attempts.map(
                    (quiz_attempt, id) => {
                        return (
                            <div key={id} className={`Home-menu-item Psudo-Button ${quiz_attempt.is_current && "menu-item-active"} d-flex align-items-center`}
                                style={{ padding: !quiz_attempt.is_current && '10px 0px' }}
                                onClick={(e) => {
                                    ResultDataFetcher(dispatch, quiz_attempts[id].quiz_attempt_id)
                                    navigate(FRONTEND_URLS.TEST_RESULT+'/'+quiz_attempts[id].quiz_attempt_id)
                                }}>
                                {quiz_attempt.is_current && <div className="active-item"></div>}
                                <div style={{ paddingRight: "20px", }}></div>
                                <div>Attempt({quiz_attempts_len-id}) - {convertDate(quiz_attempt.attempted_on)}</div>
                            </div>
                        )
                    }
                )
            }

        </div>
    )
}

export default memo(ResultTable)