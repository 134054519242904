import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ImageShower = ({ handleClose, show, url }) => {

    return (
        <div className='ImageViewer'>
            <Modal size='md' className='below md' show={show} onHide={handleClose} >
                <Modal.Header closeButton></Modal.Header>
                <img  
                draggable="false" 
                src={url} 
                onError={(e) => {
                    e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
                }}
                />
            </Modal>
        </div>
    );
}

export default ImageShower;