import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Subject from "./Pages/Subject/Subject";
import Register from "./Pages/Register/Register";
import NewEnroll from "./Components/Body/Home/NewEnroll";
import DoubutSolve from "./Pages/DoubutSolve/DoubutSolve";
import Search from "./Pages/Search/Search";
import CourseDetailsDeskTop from "./Components/Body/Courses/DeskTopView/CourseDetailsDeskTop.js";
import CourseDetails from "./Components/Body/Courses/CourseDetails.js";
import Quiz from "./Test/Quiz/Quiz.js";
import Result from "./Test/Quiz/result/Result.js";
import NotFound from "./Pages/NotFound/NotFound.js";
import MSubject from "./Pages/Subject/MSubject.js";
import MChapter from "./Pages/Chapter/MChapter.js";
import { openDB } from "idb";
import ESubject from "./Pages/Subject/ESubject.js";
import { FRONTEND_URLS } from "./URL/URL.js";
import Zoom from "./Zoom/Zoom.js";
import PaymentStatus from "./Components/PaymentStatus/PaymentStatus.js";
import RedirectPractice from "./Test/Practice/RedirectPractice.js";
import Practice from "./Test/Practice/Practice.js";
import Redirect from "./Pages/Redirect/Redirect.jsx";
import MainAlert from "./Components/Alerts/MainAlert.js";
import AlertModal from "./Components/message-modal/AlertModal.jsx";
import WaitingComponent from "./Components/WaitingComponent/WaitingComponent.jsx";
import { UserData } from "./Components/Functions/Functions.js";
import { DATABASE_NAME, DATABASE_VERSION, TABEL_NAME } from "./DB/Function.js";



const App = () => {
  let isDeskTop = false;
  const access = localStorage.getItem("access")  || localStorage.getItem('tkn');
  
  
  if (window.innerWidth > 773) {
    isDeskTop = true;
  }
  
  useEffect(() => {
    if (access) { 
      UserData(access)
    }
    try {
      openDB(DATABASE_NAME, DATABASE_VERSION, {
        upgrade(db) {
          db.createObjectStore(TABEL_NAME, { autoIncrement: true });
        },
      });
    } catch (error) {
    }
  }, []);

  return (
    <>
    {/* <FloatingComponent />  */}
    <WaitingComponent />
    <MainAlert />
    <AlertModal />
    <Routes>
      {
        access
          ?
          <>
            <Route path="/" element={<Home />} />
            <Route path="/my-account" element={<Home />} />
            <Route path="/mentorship" element={<Home />} />
            <Route path={FRONTEND_URLS.TIME_TABLE} element={<Home />} />
            <Route path={FRONTEND_URLS.PROGRESS_REPORT} element={<Home />} />
            <Route path="/help-and-support" element={<Home />} />
            <Route path="/courses" element={<Home />} />
            <Route path="/live-session" element={<Home />} />
            <Route path="/faq" element={<Home />} />
            <Route path={FRONTEND_URLS.ALLSESSION} element={<Home />} />
            <Route path={FRONTEND_URLS.SESSION_DETAILS} element={<Home />} />
            <Route path="/live-session/:mentorship" element={<Home />} />
            <Route path={FRONTEND_URLS.ADMISSION} element={<Home />} />
            <Route path="/live-session/zoom" element={<Zoom />} />
            {isDeskTop ? (
              <>
                <Route path="/courses/:course_id" element={<CourseDetailsDeskTop />} />
                <Route path={`${FRONTEND_URLS.SUBJECT_ROUTE}/:subject_node_id`} element={<Subject />} />
              </>
            ) : (
              <>
                <Route path={`${FRONTEND_URLS.SUBJECT_ROUTE}/:subject_node_id`} element={<MSubject />} />
                <Route
                  path={`${FRONTEND_URLS.SUBJECT_ROUTE}/:subject_node_id/:chapter_node_id`}
                  element={<MChapter />}
                />
                <Route path="/exam/:exam_node_id" element={<ESubject />} />
                <Route path="/courses/:course_id" element={<CourseDetails />} />
              </>
            )}
            <Route path="/doubut-solve/:queries" element={<DoubutSolve />} />
            <Route path="/payment-status" element={<PaymentStatus />} />
            <Route path="/doubut-solve/:queries/:doubt_id" element={<DoubutSolve />} />
            <Route path="/search" element={<Search />} />
            <Route path="/search/:search_id" element={<Search />} />
            <Route path="/new-enroll" element={<NewEnroll />} />
            <Route path="/practice/" element={<Practice />} />
            <Route path="/practice/:quiz_id" element={<Practice />} />
            <Route path="/quiz/:quiz_id" element={<Quiz />} />
            <Route path="/user/quiz-sheet/:quiz_id" element={<Quiz />} />  {/** temperary */}
            <Route path="/quiz/result/:quiz_attempt_id" element={<Result />} />
            <Route path="/quiz/" element={<Quiz />} />
            <Route
              path="/quiz/result/:quiz_attempt_id/:state"
              element={<Result />}
            />
          </>
          :
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/register/:register_var" element={<Register />} />
          </>
      }

      <Route path="/user/practice/:quiz_id" element={<RedirectPractice />} />
      <Route path="/user/practice-sheet/:quiz_id" element={<Practice />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
    </>

  );
};

export default App;
