import { AxiosClient } from "../Components/Axios/AxiosClient"
import { BACKEND_URLS } from "../URL/URL"

export const admissionFormKeys = {
"FIRST_NAME": "first_name",
"EMAIL": "email",
"GENDER": "gender",
    "DATE_OF_BIRTH": "date_of_birth",
    "PROFILE_IMAGE": "profile_image",
    "FATHER_NAME": "father_name",
    "MOTHER_NAME": "mother_name",
    "CATEGORY": "category",
    "IS_PWD": "is_pwd",
    "SELF_PHONE": "self_phone",
    "SELF_WHATSAPP": "self_whatsapp",
    "FATHER_PHONE": "father_phone",
    "FATHER_WHATSAPP": "father_whatsapp",
    "FATHER_EMAIL": "father_email",
    "MOTHER_PHONE": "mother_phone",
    "MOTHER_WHATSAPP": "mother_whatsapp",
    "MOTHER_EMAIL": "mother_email",
    "INSTITUTE_NAME": "institute_name",
    "INSTITUTE_STATE": "institute_state",
    "INSTITUTE_CITY": "institute_city",
    "INSTITUTE_BOARD" :"institute_board",
    "SCHOOL_NAME": "school_name",
    "SCHOOL_STATE": "school_state",
    "SCHOOL_CITY": "school_city",
    "SCHOOL_BOARD": "school_board",
    "PERMANENT_PINCODE": "permanent_pincode",
    "PERMANENT_STATE": "permanent_state",
    "PERMANENT_CITY": "permanent_city",
    "PERMANENT_ADDRESS1": "permanent_address1",
    "PERMANENT_ADDRESS2": "permanent_address2",
    "PERMANENT_LANDMARK": "permanent_landmark",
    "CURRENT_PINCODE": "current_pincode",
    "CURRENT_STATE": "current_state",
    "CURRENT_CITY": "current_city",
    "CURRENT_ADDRESS1": "current_address1",
    "CURRENT_ADDRESS2": "current_address2",
    "CURRENT_LANDMARK": "current_landmark",
    "USER_PROFILE_PROGRESS": "user_profile_progress"
}



export const indian_states = {
    "AP": "Andhra Pradesh",
    "AR": "Arunachal Pradesh",
    "AS": "Assam",
    "BR": "Bihar",
    "CG": "Chhattisgarh",
    "GA": "Goa",
    "GJ": "Gujarat",
    "HR": "Haryana",
    "HP": "Himachal Pradesh",
    "JH": "Jharkhand",
    "KA": "Karnataka",
    "KL": "Kerala",
    "MH": "Maharashtra",
    "MP": "Madhya Pradesh",
    "MN": "Manipur",
    "ML": "Meghalaya",
    "MZ": "Mizoram",
    "NL": "Nagaland",
    "OD": "Odisha",
    "PB": "Punjab",
    "RJ": "Rajasthan",
    "SK": "Sikkim",
    "TN": "Tamil Nadu",
    "TR": "Tripura",
    "TG": "Telangana",
    "UP": "Uttar Pradesh",
    "UK": "Uttarakhand",
    "WB": "West Bengal",
    "AN": "Andaman & Nicobar",
    "CH": "Chandigarh",
    "DN": "Dadra & Nagar Haveli and Daman & Diu",
    "DL": "Delhi",
    "JK": "Jammu & Kashmir",
    "LA": "Ladakh",
    "LD": "Lakshadweep",
    "PY": "Puducherry"
}


export const education_boards = {
    "CBSE": "CBSE - Central Board of Secondary Education",
    "ISC": "ISC - Indian School Certificate",
    "ICSE": "ICSE - Indian School Certificate Examinations",
    "NIOS": "NIOS - National Institute of Open Schooling",
    "UP Board": "UP Board - Board of High School and Intermediate Education Uttar Pradesh",
    "JKBOSE": "JKBOSE - Jammu and Kashmir State Board of School Education",
    "RBSE": "RBSE - Board of Secondary Education Rajasthan",
    "HPBOSE": "HPBOSE - Himachal Pradesh Board of School Education",
    "MPBSE": "MPBSE - Madhya Pradesh Board of Secondary Education",
    "CGBSE": "CGBSE - Chhattisgarh Board of Secondary Education",
    "PSEB": "PSEB - Punjab School Education Board",
    "BSEH": "BSEH - Haryana Board of School Education (HBSE)",
    "BSEB": "BSEB - Bihar School Examination Board",
    "GSEB": "GSEB - Gujarat Secondary and Higher Secondary Education Board",
    "MSBSHSE": "MSBSHSE - Maharashtra State Board Of Secondary and Higher Secondary Education",
    "BIEAP": "BIEAP - Andhra Pradesh Board of Intermediate Education",
    "BSEAP": "BSEAP - Andhra Pradesh Board of Secondary Education",
    "WBBSE": "WBBSE - West Bengal Board of Secondary Education",
    "WBCHSE": "WBCHSE - West Bengal Council of Higher Secondary Education",
    "OTHER": "Other"
}


export const genderArr = {
    "MALE": "MALE",
    "FEMALE": "FEMALE",
    "OTHER": "OTHER"
}


export const categories = {
    'OPEN': 'OPEN (GENERAL)',
    'OBC-NCL': 'OBC-NCL',
    'SC': 'SC',
    "ST": 'ST',
    'EWS': 'EWS'
}


export const updateAdmissionFormDetails = async (data) => {
    try {
        const response = await AxiosClient.put(BACKEND_URLS.STUDENT_ADMISSION_DETAILS, data);
        // Handle successful response if needed
        return response.data;
    } catch (error) {
        // Handle different types of errors
        if (error.response) {
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an error
        }
        throw error;
    }
}


export const getAdmissionFormDetails = async () => {
    try {
        const response = await AxiosClient.get(BACKEND_URLS.STUDENT_ADMISSION_DETAILS);
        return response.data;
    } catch (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
        throw error;
    }
};



/**
 * Calculates the percentage of non-empty fields present in the object
 * compared to the total number of fields.
 * 
 * @param {Object} obj - The object containing fields.
 * @param {number} totalFields - The total number of fields.
 * @returns {number} - The percentage of non-empty fields present in the object.
 */
export function calculateFieldPercentage(obj, totalFields=34) {
    if (!obj || typeof obj !== 'object' || typeof totalFields !== 'number' || totalFields <= 0) {
        throw new Error('Invalid input');
    }

    const presentFields = Object.values(obj).filter(value => value !== null && value !== undefined && value !== '').length;
    const percentage = (presentFields / totalFields) * 100;
    return percentage;
}
