import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import LottieImage from '../LottieImage/LottieImage'
import loader from "../../Images/LottieImages/esaral_loader.json";



function WaitingComponent() {

    const isWaitingWrapper = useSelector(state => state.alert.isWaitingWrapper)
    return (
        isWaitingWrapper 
        ?
        <>
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(0 0 0 / 70%)",
                    zIndex: "10000",
                    padding: "15%",
                    bottom: 0,
                    right: 0,
                    top: 0,
                    overflow: "hidden",
                }}>
                <LottieImage animationData={loader} />
            </div>
            <div
                style={{
                    position: "absolute",
                    color: 'white',
                    zIndex: "10000",
                    overflow: "hidden",
                    fontSize: '20px',
                    bottom: '40%',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}>
                Please wait...
            </div>
        </>
        : <></>
    )
}

export default WaitingComponent