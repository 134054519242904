import { memo, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { getCookie } from "../../helpers/cookies.js";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants.js";
import { FiArrowLeft } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import { AxiosClient } from "../../Components/Axios/AxiosClient.js";
import { quizServices } from "./services/quiz.js";
import { FRONTEND_URLS } from "../../URL/URL.js";


const PracticeHeader = () => {
    const navigate = useNavigate()
    const quiz_id = Number(useLocation().state?.quiz_id | useParams()?.quiz_id)
    const display_name = useLocation().state?.display_name

    const dispatch = useDispatch()

    const [quizData, setQuizData] = useState([])

    useEffect(() => {
        const pathname = window.location.pathname

        if (pathname.includes('/user/practice-sheet') && getCookie("quiz_auth") != quiz_id) {
            navigate('/404')
        }
        dispatch({ type: userPracticeConstants.PRACTICE_REQUEST })

        if (quiz_id) {
            dispatch({ type: userPracticeConstants.PRACTICE_REQUEST })

            quizServices.getQuizQuestions(quiz_id)
                .then(ques => {
                    dispatch({ type: userPracticeConstants.GET_PRACTICE_DATA, data: ques, quiz_id })

                })
                .catch(err => dispatch({ type: userPracticeConstants.PRACTICE_REQUEST_FAILED, errors: err }))

            quizServices.getRelatedQuizes(quiz_id)
                .then((quizes) => {
                    if (quizes) {
                        document.title = quizes.name
                        setQuizData(quizes.value)
                    }
                })
        } else {
            quizServices.getRelatedQuizes(42)
                .then((quizes) => {
                    document.title = quizes.name
                    setQuizData(quizes.value)
                })
            navigate('/user/practice/42')
        }
    }, [])


    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

            <div>
                <FiArrowLeft size={25} className="Psudo-Button" onClick={() => { navigate(-1) }} />
            </div>

            <div style={{ fontSize: 18, fontWeight: "bold" }}>
                {/* {quizName} */}
                {display_name?.length >0 ? display_name : "Practice Sheet"}
            </div>

            <div >
                {quizData.length > 0 && <Dropdown >
                    <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                        <b>{quizData.find((val) => val.id == quiz_id)?.name}</b>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {quizData.map((val) => <Dropdown.Item key={val.id} href={`${FRONTEND_URLS.USER_PRACTICE}/${val.id}`}>{val.name}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>}
            </div>
        </div>
    )
}

export default memo(PracticeHeader);