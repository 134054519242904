import React from 'react'
import QueAndOptions from './QueAndOptions.js'
import NextAndPrevBut from './NextAndPrevBut.js'


function Que() {
  return (
    <div style={{position:'relative'}}>
      <div id='quiz-Que' className=' '>
        <QueAndOptions />
        <NextAndPrevBut />
      </div>
    </div>
  )
}

export default Que