import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosClient } from "../../Components/Axios/AxiosClient.js";
import { BACKEND_URLS, FRONTEND_URLS } from "../../URL/URL.js";
import SubjectHeader from "../../Components/Header/SubjectHeader.js";
import { GoBook } from "react-icons/go";
import ESaralLoader from "../../Components/Spinner/ESaralLoader.js";
import ChapterCard from "./MobileComp/ChapterCard.js";
import IndexTable from "./MobileComp/IndexTable.js";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant.js";
import UserHistory from "./MobileComp/UserHistory.js";

function MSubject() {
  const { subject_node_id } = useParams();
  const [data, setData] = useState();
  const [isIndex, setIsIndex] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const subjectDataPromise = AxiosClient.post(
          BACKEND_URLS.SUBJECT_DATA_API,
          {
            node_id: Number(subject_node_id),
          }
        );

        const userHistoryPromise = AxiosClient.get(BACKEND_URLS.USER_HISTORY);

        const [subjectResult, userHistoryResult] = await Promise.all([
          subjectDataPromise,
          userHistoryPromise,
        ]); //

        if (!ignore && subjectResult.data.success) {
          localStorage.setItem(
            "@subject",
            JSON.stringify(subjectResult.data.data)
          );
          const data = subjectResult.data.data;

          if (data.node_content_tree?.content) {
            navigate(FRONTEND_URLS.HOME_ROUTE + "exam/" + subject_node_id);
          }

          setData(data.node_content_tree);
          dispatch({
            type: AppReducerConstants.SUBJECT_ID,
            payload: subject_node_id,
          });
        }

        if (!ignore) {
          dispatch({
            type: AppReducerConstants.USER_HISTORY,
            payload: userHistoryResult.data.data,
          });
        }
      } catch (error) {
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <>
      {data ? (
        <>
          <SubjectHeader />
          <div
            className="overflow-y-auto"
            style={{
              height: "90vh",
            }}>
            {/* <UserHistory /> */}
            {data.map((item, id) => {
              return (
                <ChapterCard
                  key={id}
                  item={item}
                  id={id}
                  node_id={item.node_id}
                />
              );
            })}
            {isIndex ? (
              <div
                style={{ transform: "translateX(50%)" }}
                className="subject-Index subject-bottom Psudo-Button"
                onClick={() => {
                  setIsIndex(false);
                }}>
                <GoBook size={20} /> &nbsp; Index
              </div>
            ) : (
              <IndexTable data={data} setIsIndex={setIsIndex} />
            )}
          </div>
        </>
      ) : (
        <ESaralLoader />
      )}
    </>
  );
}

export default MSubject;
