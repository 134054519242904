export const loginCoursalImage = {
    1: {
        0: require('./1-0.png'),
        1: require('./1-1.png'),
        2: require('./1-2.png'),
        3: require('./1-3.png'),
        4: require('./1-4.png'),
    },
    2: {
        0: require('./2-0.png'),
        1: require('./2-1.png'),
        2: require('./2-2.png'),
        3: require('./2-3.png'),
        4: require('./2-4.png'),
    },
    3: {
        0: require('./3-0.png'),
        1: require('./3-1.png'),
        2: require('./3-2.png'),
    },
    4: {
        0: require('./4-0.png'),
        1: require('./4-1.png'),
        2: require('./4-2.png'),
    }
}

export const CaursalImagesLines = {
    Learn:{
        heading: "Learn",
        text: "Kota's top IITian and Doctor faculties. Amazing visualisation to understand any concept easily.",
      },
      Practice:{
        heading: "Practice",
        text: "Chapter-wise sheets and PYQs with detailed solutions.",
      },
      Revise:{
        heading: "Revise",
        text: "Quickly revise the syllabus with crystallized videos, mind maps and notes.",
      },
      Test:{
        heading: "Test",
        text: "Latest Pattern tests from expert faculties with detailed analysis.",
      },
  }