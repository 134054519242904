import React, { useEffect, useState } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import rightAns from '../../utils/images/lottie/right-ans.json'
import Lottie from "lottie-react";



const OptionsItem = ({ option, questionType, question_id, isSubmitted, index, isAns }) => {
    const dispatch = useDispatch()

    const onOptionChange = () => {
        if (!isSubmitted) {
            dispatch({ type: userPracticeConstants.UPDATE_PRACTICE_DATA, question_id, option_id: option.option_id })
        }
    }


    const img = questionType === "single-correct" ? (option.is_selected ? require("../../utils/images/Radio_checked.png") : require("../../utils/images/Radio_Unchecked.png")) :
        (option.is_selected ? require("../../utils/images/Checkbox_Checked.png") : require("../../utils/images/Checkbox_Unchecked.png"));


    var opt
    switch (index) {
        case 0:
            opt = "(A.)"
            break;
        case 1:
            opt = "(B.)"
            break;
        case 2:
            opt = "(C.)"
            break;
        case 3:
            opt = "(D.)"
            break;
    }
    

    return (
        <div style={{ cursor: "pointer", marginInline: "-1rem" }}>

            {/* <div className={`opt ${isSubmitted? (option.is_solution? "opt-correct":option.is_selected&& "opt-incorrect"):option.is_selected&&"opt-selected" }`}  onClick={onOptionChange}> */}
            <div className={`opt ${option.is_selected && "opt-selected justify-content-between mt-2"} ms-2 align-items-center`} onClick={onOptionChange} style={{ paddingLeft: '0px' }}>
                {/* {opt} */}
                <div className="d-flex">
                    <img src={img} style={{ height: 25, width: 25, }} className="ms-1 me-3" />
                    <div style={{ fontSize: "1rem" }} dangerouslySetInnerHTML={{ __html: option.option_value }} />
                </div>
                {
                    option.is_selected && isSubmitted && (isAns == 'Correct') &&
                    <div style={{
                        width: '40px'}}>
                        <div style={{
                            width: '40px',
                            top: '-6px',
                            position: 'relative'
                        }}>
                            <Lottie style={{
                                height: '100%',
                                overflow: 'hidden'
                            }} animationData={rightAns} />
                        </div>
                    </div>
                }
            </div>


        </div>




    )
}

export default memo(OptionsItem);