import React from 'react'
import { Col } from 'react-bootstrap';
import { ButtonColors } from '../../Constants/ButtonsColor/ButtonsColor';
import { FRONTEND_URLS } from '../../URL/URL';
import { useNavigate } from 'react-router-dom'




function MentorshipStaticCards() {
  const navigate = useNavigate()


  const handleAllSessionsClick = () => {
    navigate(FRONTEND_URLS.ALLSESSION, { state: { display_name: 'All Sessions' } });
  };


  const CardArr = [
    {
      display_name: "Personalized TimeTable",
      thumbnail: require("../../Images//img_all/mentorship/timetable.png"),
      onPress: () => navigate(FRONTEND_URLS.TIME_TABLE, { state: { display_name: "Personalized TimeTable" } }),
    },
    {
      display_name: "Progress Report",
      thumbnail: require("../../Images/img_all/mentorship/report.png"),
      onPress: () => {
        navigate(FRONTEND_URLS.PROGRESS_REPORT, { state: { display_name: "Progress Report" } });
      },
    },
    {
      display_name: "My Queries",
      thumbnail: require("../../Images/img_all/mentorship/queries.png"),
      onPress: () => navigate(FRONTEND_URLS.DOUBUT_SOLVE_QUERIES, { state: { display_name: "My Queries" } }),

    },
    {
      display_name: "All Sessions",
      thumbnail: require("../../Images/img_all/AllSessions.png"),
      onPress: handleAllSessionsClick,
    },
    // {
    //     display_name: 'Leaderboard',
    //     thumbnail: '',
    //     onPress: () => {}
    // },
  ];


  return (
    CardArr?.map((subject, id) => (
      <Col
        key={id}
        className="Subject-Button Psudo-Button homeBox"
        style={{
          backgroundColor:
            ButtonColors[id % ButtonColors.length].BorderColor,
        }}
        onClick={subject.onPress}>
        <img
          draggable="false"
          className="Subject-Button-Img"
          src={subject.thumbnail}
          alt={subject.display_name}
          onError={(e) => {
            e.target.src = require('../../Images/DefaultImage/video_default_thumb.png')
          }}
        />
        <div className="Subject-Button-Img-Title">
          {subject.display_name}
        </div>
      </Col>
    ))
  )
}

export default MentorshipStaticCards