import React, { useState } from "react";
import { Accordion, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addDataInDb } from "../../../DB/Function.js";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant.js";
import { VideoFun } from "../../Functions/Functions.js";
import PDFAndVideoHandler from "../../PDFAndVideoHandler/PDFAndVideoHandler.jsx";
import ListItem from "../Subject/ListItem.js";
import NotFound from "../../Error/NotFound.js";




const SessionDetails = () => {

  const dispatch = useDispatch()
  const {series_id} = useLocation().state;
  const sessionData = useSelector(state => state.mentorship.sessions)

  const { tab, chapter_id, content_id } = useSelector((state) => state.app);
  const treakIntervalKey = useSelector(state => state.app.treakIntervalKey)


  const [treackData, setTreakData] = useState({})

  function showVideo({ video_id, content_id }) {
    VideoFun(video_id, dispatch);
    dispatch({ type: AppReducerConstants.VIDEO_ID, payload: video_id })
    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: true })
    dispatch({ type: AppReducerConstants.IS_PLAYER_READY, payload: true });

    const treakD = {
      content: content_id,
      subject: "",
      chapter: "",
      content_parent_group: "mentorship",
    }
    dispatch({ type: AppReducerConstants.SCROLL_HEIGHT, payload: 40 })
    setTreakData(treakD)
    if (treakIntervalKey) {
      dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
    }
    dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treakD) })
  
  }


  function onLoadSuccessFun() {
    const content_group = tab.split('-')[2];
    const content_parent_group = content_group.charAt(0).toUpperCase() + content_group.slice(1);

    const treakData = {
      content: content_id ?? '',
      subject: treackData?.subject,
      chapter: chapter_id ?? '',
      content_parent_group: content_parent_group,
    };
    if (treakIntervalKey) {
      dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
    }
    dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treakData) });
  }

  return (
      <Container className="p-0 h-100 overflow-hidden">
        <PDFAndVideoHandler key={'PDFAndVideoHandler'} onLoadSuccessFun={onLoadSuccessFun} treackData={treackData} />
        <div className='mt-0 h-100'>
          <div className='overflow-auto' style={{
            height: "85vh"
          }}>
            {sessionData?.map((item, index) => {
              return (
                item?.series_id && item.series_id == series_id && (item.recorded_node.length > 0 || item.session_pdf.length > 0) &&
                <div className="">
                  <Accordion key={index} flush>
                    <Accordion.Item className='mb-2 mx-0' eventKey={`${index}`} >
                      <Accordion.Header>{item.name}</Accordion.Header>
                      <Accordion.Body className='px-0 py-1' style={{
                        borderRadius: '8px'
                      }}>
                        {item.recorded_node.length > 0 &&
                          item.recorded_node.map((itm, indx) => {
                            return (
                              <ListItem data={{
                                content_data: itm,
                                display_name: itm.content_info.name
                              }} key={indx} showVideo={showVideo} />
                            )
                          })
                        }
                        {item.session_pdf.length > 0 &&
                          item?.session_pdf.map((itm, indx) => {
                            return (
                              <ListItem data={{ content_data: itm, display_name: itm.content_info.name }} key={indx} showVideo={showVideo} />
                            )
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )
            })}

          </div>
        </div>
      </Container>
  );
};

export default SessionDetails;
