import React from 'react'
import { FRONTEND_URLS } from '../URL/URL';
import LottieImage from '../Components/LottieImage/LottieImage';
import liveLottie from "../Images/Live/live.json";
import { formatEpochTimeRange } from './Function';
import { useNavigate } from 'react-router-dom';
import { startClass } from '../Zoom/Function';
import { handleSetShowAlertModal } from '../helpers/helperFunctions';
import { useDispatch } from 'react-redux';




function LiveClassesComponent({ liveClass }) {
    const navigate = useNavigate()
    const systemTime = Math.floor(new Date().getTime() / 1000)
    const dispatch = useDispatch()

    return (
        liveClass
        &&
        <div>
            <div style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem", paddingLeft: liveClass.end_date_time >= systemTime && systemTime >= liveClass.start_date_time ? "1rem" : "2rem", backgroundColor: "#f8f8f8", borderRadius: "12px", border: "1px solid #f3f3f4" }}
                className={`Background d-flex align-items-center justify-content-between pe-4 my-1 chapter-name Psudo-Button`}
                onClick={() => {
                    const isLive =
                        liveClass.end_date_time >= systemTime &&
                        systemTime >= liveClass.start_date_time;
                    if (isLive) {
                        startClass(liveClass, navigate, FRONTEND_URLS.HOME_ROUTE);
                    } else {
                handleSetShowAlertModal({ dispatch, message: 'Your class is starting soon.'})
                    }
                }}
            >
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-start",
                    }}>
                    {liveClass.end_date_time >= systemTime && systemTime >= liveClass.start_date_time &&
                        <div style={{ minWidth: "30px", maxWidth: "30px" }}>
                            <LottieImage
                                animationData={liveLottie}
                                Isloop={true}
                            />
                        </div>
                    }
                    <div className="py-2">
                        <div
                            className="LIVE-NOW"
                            style={{ fontWeight: "400", marginBottom: "0.3rem" }}>
                            {liveClass.name}
                        </div>
                        <div className="LIVE-NOW-SERIES-NAME">
                            {liveClass.series_name}
                        </div>
                    </div>
                </div>
                <div className="LIVE-NOW-TIME">
                    {formatEpochTimeRange(
                        liveClass.start_date_time,
                        liveClass.end_date_time
                    )}
                </div>
            </div>
        </div>
    )
}

export default LiveClassesComponent