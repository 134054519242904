import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { FiCheck, FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FRONTEND_URLS } from "../../../URL/URL.js";
import SwiperComp from "../../swiper/SwiperComp.jsx";
import { SwiperSlide } from "swiper/react";

function AllCourses({ courses, testimonalies }) {
  const [activeCourse, setActiveCourse] = useState(0);
  const course = courses[activeCourse].data;
  const navigate = useNavigate();

  return (
    <div className="">
      {/*---------------------------------------------------------Course Buttons------------------------------------------------------------*/}
      <div className="ms-4 my-3">
        {courses.map((course, id) => {
          return (
            <Button
              key={id}
              className="me-3 my-2 px-4"
              style={{
                backgroundColor: activeCourse === id ? "#5F46E3" : "#f3f3f3",
                color: activeCourse === id ? "white" : "black",
                border: "none",
                borderRadius: "18px",
              }}
              onClick={() => {
                setActiveCourse(id);
              }}>
              {course.target_name}
            </Button>
          );
        })}
      </div>

      {/*--------------------------------------------------------------------Courses-----------------------------------------------------------------------*/}
      {/* <div className='d-flex overflow-x-auto' > */}
      <SwiperComp>
        {course.map((data, id) => {
          return (
            <SwiperSlide
              key={id}
              id="AllCourses-Card"
              className={`d-flex flex-column justify-content-between Background-Color-1 px-3 py-4 mt-2 me-3 Psudo-Button ${
                id == 0 && "ms-4"
              } h-100`}
              style={{ width: "auto", borderRadius: "24px" }}
              onClick={() => {
                navigate(FRONTEND_URLS.COURSES + "/" + data.course_id);
              }}>
              <h4>{data.short_name}</h4>
              <div>
                {(data?.bullet_points).length > 0
                  ? data.bullet_points.split(",").map((bullet_point, id) => {
                      return (
                        <p key={id} className="m-0">
                          {" "}
                          <FiCheck color="#008444" size={25} /> {bullet_point}
                        </p>
                      );
                    })
                  : null}
              </div>

              <div className="d-flex flex-column align-items-center">
                <img
                  style={{
                    maxWidth: "200px",
                  }}
                  draggable={false}
                  src={data.thumbnail}
                  className="course-img my-3"
                  alt="data.short_name"
                  onError={(e) => {
                    e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                }}
                />

                <div className="d-flex justify-content-center">
                  <Button
                    variant="outline-primary"
                    className="w-100"
                    style={{
                      width: "70%",
                      color: "rgb(95, 70, 227)",
                      borderColor: "rgb(95, 70, 227)",
                    }}
                    onClick={() => {}}>
                    <del className="Price-cancel ">
                      {" "}
                      {"\u20b9" + data.phase_fee}
                    </del>{" "}
                    {"\u20b9" + data.discounted_fee}
                  </Button>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </SwiperComp>
      {/* </div> */}

      <br />
      {/*--------------------------------------------------------------------testimonalies-----------------------------------------------------------------------*/}
      <div>
        <strong className="ms-4">
          I <FiHeart color="red" fill="red" /> eSaral
        </strong>

        <div
          className="All-Course-Testimonalies All_Course_Testimonials_edit"
          style={{ paddingBottom: "2rem" }}>
          {testimonalies.map((testimonalie, id) => {
            return (
              <div
                className={`px-0  ${id === 0 ? "custom-margin-left" : ""}`} key={id}>
                <div
                  key={id}
                  className={`Testimonalie Background-Color-1 m-2 course-card p-3`}
                  style={{ height: "100%",  }}>
                  <div className="d-flex my-2 ">
                    <img
                      draggable={false}
                      src={testimonalie.profile_image}
                      className="course-testimonial-img"
                      alt=".."
                      onError={(e) => {
                        e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                    }}
                    />

                    <div className="mx-4">
                      <strong>{testimonalie.student_name}</strong>
                      <div className="course-testimonial-name">
                        {testimonalie.qualification}
                      </div>
                    </div>
                  </div>

                  <em>"{testimonalie.details}"</em>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AllCourses;
