import React from "react";
import SiderBarContent from "../Body/Home/SiderBarContent";
import { Logo } from "../../Constants/Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant";
import Offcanvas from "react-bootstrap/Offcanvas";

const MSideBar = () => {

  const isPhoneMenu = useSelector((state) => state.app.isPhoneMenu);
  const dispatch = useDispatch();

  return (
    <Offcanvas
      show={isPhoneMenu}
      onHide={() =>
        dispatch({
          type: AppReducerConstants.IS_PHONE_MENU,
          payload: false,
        })
      }
      style={{ maxWidth: "250px" }}>
      <Offcanvas.Header>
        <Offcanvas.Title>{Logo}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="ps-0">
        <SiderBarContent />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MSideBar;
