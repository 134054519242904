import { doubtReducerConstant } from "../ReducerConstants/doubtReducerConstant";

const initialState = {
    chats:[],
    is_chats: false
}


const dobtReducer = (state = initialState,action) => {
    switch (action.type) {
        case doubtReducerConstant.CHATS:
            return {
                ...state,
                chats: action.payload
            }
        case doubtReducerConstant.IS_CHATS:
            return {
                ...state, 
                is_chats: action.payload
            }
        default:
            return {
                ...state
            }
    }
}


export default dobtReducer