import React from 'react'
import { isSubCourseExperied } from '../../Body/Function/Function.js'
import Lock from '../../Lock/Lock.js'
import playBut from '../../../Images/VideoPlay/play button.png'

function IsFreeOrNot({is_free=false}) {
    return (
        <div className='chapter-img position-absolute d-flex justify-content-center align-items-center'>
            {
                is_free
                    ?
                    <img  draggable="false" src={playBut} className='h-auto' style={{ zIndex: '1000', width: '16%' }} alt='' />
                    :
                    isSubCourseExperied() == false
                        ?
                        <img  draggable="false" src={playBut} className='h-auto' style={{ zIndex: '1000', width: '16%' }} alt='' />
                        :
                        <Lock color='white' />
            }
        </div>
    )
}

export default IsFreeOrNot