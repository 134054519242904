import celebraion1 from './celebration1.json'
import celebraion2 from './celebration2.json'
import celebraion3 from './celebration3.json'
import celebraion4 from './celebration4.json'
import celebraion5 from './celebration5.json'
import party1 from './party1.json'
import trophy1 from './trophy1.json'

const celebrationArr = [celebraion1, celebraion2, celebraion3, celebraion4, celebraion5]

export const randomShow = [true, false]
export const party = party1
export const trophy = trophy1
export default celebrationArr