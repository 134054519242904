import React, { useEffect, useState } from "react";
import SideBar from "../../SideBar/SideBar";
import { Col, Row } from "react-bootstrap";
import SubjectSiderBarContetnt from "./SubjectSiderBarContetnt";
import SubjectContentBody from "./SubjectContentBody/SubjectContentBody";
import ESaralLoader from "../../Spinner/ESaralLoader.js";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant.js";

const SubjectBody = ({ data }) => {
  const ChapterNames = data ?? [];
  const [learn, setlearn] = useState();
  const [practice, setpractice] = useState();
  const [revise, setRevise] = useState();
  const [test, setTest] = useState();
  const [nodeId, setNodeId] = useState();

  const dispatch = useDispatch();

  function LeacturesUpdater(id) {
    if (id == -1) {
      setlearn(ChapterNames?.content.learn);
      setpractice(ChapterNames?.content?.practise);
      setRevise(ChapterNames?.content?.revise);
      setTest(ChapterNames?.content?.test);
      setNodeId(ChapterNames?.node_id);
    } else {
      dispatch({
        type: AppReducerConstants.CHAPTER_ID,
        payload: ChapterNames[id]?.node_id,
      });
      setlearn(ChapterNames[id]?.content?.learn);
      setpractice(ChapterNames[id]?.content?.practise);
      setRevise(ChapterNames[id]?.content?.revise);
      setTest(ChapterNames[id]?.content?.test);
      setNodeId(ChapterNames[id]?.node_id);
    }
  }

  useEffect(() => {
    if (data?.content) {
      LeacturesUpdater(-1);
    } else {
      LeacturesUpdater(0);
    }
  }, []);

  return data ? 
    <div className="container-xxl Vertical-gap overflow-hidden">
      <Row>
        <SideBar>
          {ChapterNames && (
            <SubjectSiderBarContetnt
              ChapterNames={ChapterNames}
              LeacturesUpdater={LeacturesUpdater}
            />
          )}
        </SideBar>

        <Col xs={12} md={9} className="p-0 overflow-hidden">
          <SubjectContentBody
            nodeId={nodeId}
            learn={learn}
            test={test}
            revise={revise}
            practice={practice}
          />
        </Col>
      </Row>
    </div>
 : 
    <ESaralLoader />
};

export default SubjectBody;
