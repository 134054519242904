import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosClient } from "../../Axios/AxiosClient";
import { Col, Container, Row } from "react-bootstrap";
import { ButtonColors } from "../../../Constants/ButtonColors/ButtonColors";
import LottieImage from "../../LottieImage/LottieImage";
import { BACKEND_URLS, FRONTEND_URLS } from "../../../URL/URL";
import pencil from "../../../Images/LottieImages/pencil-animation.json";
import targetLottie from "../../../Images/LottieImages/target.json";
import { UserData } from "../../Functions/Functions";
import ESaralLoader from "../../Spinner/ESaralLoader.js";
import { RxCross2 } from "react-icons/rx";

const NewEnroll = () => {
  const [target, setTarget] = useState();
  const navigate = useNavigate();
  const [targets, setTargets] = useState();

  function EnrollStudent(id) {
    AxiosClient.post(BACKEND_URLS.NEW_ENROLLMENTS, {
      target_course_id: id,
    })
      .then((resp) => {
        if (resp.data.success) {
          UserData();
          navigate(FRONTEND_URLS.HOME_ROUTE);
        }
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    let ignore = false;
    setTarget(null);
    AxiosClient.get(BACKEND_URLS.GET_ALL_TARGETS)
      .then((resp) => {
        if (!ignore) {
          setTarget(resp.data.data.classes);
        }
      })
      .catch((err) => {
      });

    return () => {
      ignore = true;
    };
  }, []);

  function CancelButtonFun() {
    return (
      <Row className="justify-content-end">
        <RxCross2
          size={25}
          className="w-auto m-2 Psudo-Button"
          onClick={() => {
            navigate(FRONTEND_URLS.HOME_ROUTE);
          }}
        />
      </Row>
    );
  }

  return (
    <div className="Form-phone Attempt-year-Form">
      {!target ? (
        <>
          <ESaralLoader />
        </>
      ) : !targets ? (
        <Container style={{}}>
          <Row
            className="d-flex justify-content-center "
            style={{
              // maxHeight: "720px",
              height: "100vh",
            }}>
            <Col sm={12} md={6} className="Background">
              {CancelButtonFun()}

              <Row className="Form-lottie-1 d-flex justify-content-center m-auto">
                <LottieImage animationData={pencil} Isloop={false} />
              </Row>

              <Row className="m-auto  justify-content-center">
                {target.map((item, key) => {
                  return (
                    key % 2 === 0 && (
                      <Row id={key} className="d-flex justify-content-center">
                        {target[key] ? (
                          <Col
                            className=" Year-Button grid-button Psudo-Button m-2"
                            key={key}
                            style={{
                              border: `1px solid ${
                                ButtonColors[key % ButtonColors.length]
                                  .background
                              }`,
                              background: ButtonColors[key].BorderColor,
                            }}
                            onClick={() => {
                              setTargets(target[key].targets);
                            }}>
                            {target[key].class_name}
                          </Col>
                        ) : (
                          <div className=" Year-Button grid-button m-2"></div>
                        )}

                        {target[key + 1] ? (
                          <Col
                            className=" Year-Button grid-button Psudo-Button m-2"
                            key={key + 1}
                            style={{
                              border: `1px solid ${
                                ButtonColors[key + (1 % ButtonColors.length)]
                                  .background
                              }`,
                              background: ButtonColors[key + 1].BorderColor,
                            }}
                            onClick={() => {
                              setTargets(target[key + 1].targets);
                            }}>
                            {target[key + 1].class_name}
                          </Col>
                        ) : (
                          <div className=" Year-Button grid-button m-2"></div>
                        )}
                      </Row>
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container style={{}}>
          <Row
            className="d-flex justify-content-center"
            style={{
              // maxHeight: "720px",
              height: "100vh",
            }}>
            <Col sm={12} md={6} className="Background">
              {CancelButtonFun()}

              <Row className="Form-lottie-1 d-flex justify-content-center m-auto">
                <LottieImage animationData={targetLottie} Isloop={false} />
              </Row>

              <Row className=" d-flex justify-content-center">
                {targets.map((item, key) => {
                  return (
                    key % 2 === 0 && (
                      <Row className="d-flex justify-content-center">
                        {targets[key] ? (
                          <div
                            className="Exam-card grid-button Psudo-Button m-2 d-flex align-items-start"
                            key={key}
                            style={{
                              border: `1px solid ${
                                ButtonColors[key % ButtonColors.length]
                                  .background
                              }`,
                              background: ButtonColors[key].BorderColor,
                              width: "max-content !important",
                            }}
                            onClick={() => {
                              EnrollStudent(targets[key].target_course_id);
                            }}>
                            <img style={{height:"75px"}}
                              draggable="false"
                              src={targets[key]?.thumbnail}
                              alt=""
                              onError={(e) => {
                                e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                            }}
                            />
                            <div>
                              <strong>{targets[key]?.target_name}</strong>
                            </div>
                            <div>{targets[key]?.tag_line}</div>
                          </div>
                        ) : (
                          <div className="Exam-card grid-button m-2 d-flex align-items-start"></div>
                        )}

                        {targets[key + 1] ? (
                          <Col
                            className="Exam-card grid-button Psudo-Button m-2 d-flex align-items-start"
                            key={key + 1}
                            style={{
                              border: `1px solid ${
                                ButtonColors[key + (1 % ButtonColors.length)]
                                  .background
                              }`,
                              background: ButtonColors[key + 1].BorderColor,
                            }}
                            onClick={() => {
                              EnrollStudent(targets[key].target_course_id);
                            }}>
                            <img 
                              draggable="false"
                              src={targets[key + 1]?.thumbnail}
                              style={{
                                width: "36px",
                                height: "36px", 
                                flexShrink: "0",
                              }}
                              alt=""
                              onError={(e) => {
                                e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                            }}
                            />
                            <div>
                              <strong>{targets[key + 1]?.target_name}</strong>
                            </div>
                            <p>
                              <strong>{targets[key + 1]?.tag_line}</strong>
                            </p>
                          </Col>
                        ) : (
                          <div className="Exam-card grid-button m-2 d-flex align-items-start"></div>
                        )}
                      </Row>
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default NewEnroll;
