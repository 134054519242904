import React from 'react'
import { RxCross2 } from 'react-icons/rx'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import PDFOpener from '../PDF/PDF'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant'
import ESaralLoader from '../Spinner/ESaralLoader'
import { Col, Row } from 'react-bootstrap'




function PDFAndVideoHandler() {

    const dispatch = useDispatch()
    const { isVideo, videoId, isPDF, pdf_name, isPlayerReady } = useSelector((state) => state.app);

    function cancelVideo() {
        dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
        dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });
        dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });
        dispatch({ type: AppReducerConstants.IS_PLAYER_READY, payload: false });
    }

    function handleCloseFun() {
        dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
        dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
    }

    return (
        <div style={{
            position: 'sticky',
            top: '0px'
        }}>
            {
                isVideo &&
                <div style={{ position: "relative", }}>
                    <RxCross2
                        size={25}
                        className="Psudo-Button"
                        style={{
                            float: "right",
                            zIndex: "1000",
                            right: ".5rem",
                            position: "absolute",
                        }}
                        strokeWidth={1}
                        onClick={cancelVideo}
                    />
                    {
                        !isPlayerReady
                        // true
                        &&
                        <Row className='Background justify-content-center mt-2' style={{ marginRight: "0px" }} id='video-player' >
                            <Col md={12} className='pe-0 d-flex justify-content-center '>
                                <div className="ratio ratio-16x9 video-iframe" id='VideoPlay'
                                    style={{
                                        maxWidth: `80%`,
                                    }}
                                >
                                    <ESaralLoader />
                                </div>
                            </Col>
                        </Row>

                    }
                    {
                        videoId
                        &&
                        <VideoPlayer />
                    }
                </div>
            }
            {isPDF && <PDFOpener timetable={isPDF.content_info} name={pdf_name} handleClose={handleCloseFun} />}
        </div>
    )
}

export default PDFAndVideoHandler