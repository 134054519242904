import React from 'react'
import NotFound from './no-search-results.png'

function NoSearchResult() {
  return (
    <div style={{
        width: '100%',
        height:'89%', 
    }} className='d-flex flex-column align-items-center justify-content-center'>
        <img  draggable="false" className='Search-Image' src={NotFound} alt='' />
    </div>
  )
}

export default NoSearchResult