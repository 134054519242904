import React, { useEffect, useState } from 'react'

function VSSolution({ solution, is_correct, marks_obtained, attempt_state, options, question_type, correct_answer }) {

    const option_arr = ["A", "B", "C", "D"]
    const [answer, setAnswer] = useState([])

    useEffect(
        () => {
            for (let i = 0; i < options.length; i++) {
                if (options[i].is_solution) {
                    setAnswer((answer) => [...answer, option_arr[i]])
                }
            }
        }, []
    )

    return (
        <div className='ms-3'>
            <br />
            <div>
                {
                    question_type === "numerical"
                        ?
                        <>
                            Correct Answer is <strong> {correct_answer}</strong>
                        </>
                        :
                        <>
                            Correct Answer is <strong>Option {answer}</strong>
                        </>
                }
                {
                    attempt_state == "SKIPPED"
                        ?
                        <div style={{ color: '#6E7191' }}> <strong>You Skipped this Question</strong> </div>

                        :
                        <div style={{ color: is_correct ? '#008444' : '#CC2C2C' }}> <strong>Your Answer is {is_correct ? `Correct` : `Incorrect`}</strong> </div>
                }
                Marks Scored:  <strong>{marks_obtained}</strong>
            </div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: solution }} />
        </div>
    )
}

export default VSSolution