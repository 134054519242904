import React from 'react'
import { useSelector } from 'react-redux';
import { FRONTEND_URLS } from '../../URL/URL';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { ButtonColors } from '../../Constants/ButtonsColor/ButtonsColor';

function FaqCards() {
    const faqs = useSelector(state => state.mentorship.faqs)
    const navigate = useNavigate()

    const handleFAQClick = (faqs, display_name) => {
        navigate(FRONTEND_URLS.FAQ, { state: { faqs, display_name } });
      };

    return (
        <>
            {
                faqs?.map((item, index) => {
                    return (
                        <Col
                            key={index}
                            className="Subject-Button Psudo-Button homeBox"
                            style={{
                                backgroundColor:
                                    ButtonColors[index % ButtonColors.length].BorderColor,
                            }}
                            onClick={() => handleFAQClick(item.faqs, item.category_name)}>
                            <img
                                draggable="false"
                                className="Subject-Button-Img"
                                src={item?.icon || require('../../Images/DefaultImage/video_default_thumb.png')}
                                alt="icon"
                                onError={(e) => {
                                    e.target.src = require('../../Images/DefaultImage/video_default_thumb.png')
                                }}
                            />
                            <div className="Subject-Button-Img-Title">
                                {item.category_name}
                            </div>
                        </Col>
                    );
                }
                )
            }
        </>
    )
}

export default FaqCards