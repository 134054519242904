import React, { useEffect, useRef, useState } from 'react';
import { AxiosClient } from '../../Axios/AxiosClient';
import { BACKEND_URLS, FRONTEND_URLS, } from '../../../URL/URL';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { createRipple } from '../../Functions/Functions'
import UploadPDFToS3 from './UploadPDFToS3';
import DoubutSolveHeader from './DoubtSolveChatComp/DoubutSolveHeader';
import ChatField from './DoubtSolveChatComp/ChatField';
import { GrAttachment } from 'react-icons/gr'
import { useSelector } from 'react-redux';
import ESaralLoader from '../../Spinner/ESaralLoader.js';
import NotFound from '../../Error/NotFound.js';
import { useDispatch } from 'react-redux';
import { doubtReducerConstant } from '../../../Redux/ReducerConstants/doubtReducerConstant.js';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js';

const DoubutSolveChat = () => {

    const { doubt_id } = useParams()
    const [status, setStatus] = useState()
    const [UploadMediaToS3, setUploadMediaToS3] = useState()
    const [loading, setLoading] = useState()

    const data = useSelector(state => state.app.DoubtSolveMenu)?.find((e) => e.is_true === true)?.data?.find((e) => e.id == doubt_id)
    const subject_name = data?.subject_name
    const chapter_name = data?.chapter_name
    const messageRef = useRef()

    const dispatch = useDispatch()
    const is_chats = useSelector(state => state.doubt.is_chats)


    let statusCond = status === 'RESOLVED' || status === 'CLOSE'

    async function ChatFinder(doubt_id) {

        const response = await AxiosClient.post(BACKEND_URLS.GET_DOUBT_MESSAGES, { doubt_id })

        if (response?.data?.success) {
            setLoading(false)
            dispatch({ type: doubtReducerConstant.CHATS, payload: response.data.data.chat })
            dispatch({ type: doubtReducerConstant.IS_CHATS, payload: true })
            setStatus(response.data.data.doubt.status)
            return true
        }
        else {
            setLoading(false)
            return false
        }
    }

    function messageSender({ text, media_url }) {
        AxiosClient.post(
            BACKEND_URLS.SEND_MESSAGE,
            {
                "doubt_id": doubt_id,
                "text": text.trim() ?? '',
                "media_url": media_url ?? '',
                "media_type": media_url ? 'IMAGE' : '',
            }
        ).then(
            (res) => {
                if (res.data.success) {
                    messageRef.current.value = ''
                }
            }
        ).catch(
            (err) => {
            }
        )
        setUploadMediaToS3()
    }

    function MessageValidator(e) {
        createRipple({ event: e, className: "ripple-primary" });

        const text = messageRef.current.value;
        const image = (UploadMediaToS3 && UploadMediaToS3.length > 0) ? UploadMediaToS3[0] : '';

        if ((text && text.length !== 0) || image) {
            messageSender({ text: text, media_url: image });
        } else {
            handleSetShowAlertModal({ dispatch, message: 'Your message is not valid. Either enter a message or upload a image.' })
        }
    }


    function IsOpenedOrResolved(status) {
        AxiosClient.put(
            BACKEND_URLS.CRUD_DOUBT,
            {
                "doubt_id": doubt_id,
                "status": status,  //"OPEN", "CLOSE", "RESOLVED",
            }
        ).then(
            (res) => {
                if (res.data.success) {
                    ChatFinder(doubt_id)
                }
            }
        ).catch(
            (err) => {
            }
        )
    }


    useEffect(
        () => {
            setLoading(true)
            if (!isNaN(Number(doubt_id))) {
                ChatFinder(doubt_id)
            }
            else {
                dispatch({ type: doubtReducerConstant.IS_CHATS, payload: false })
            }
            setLoading(false)

            const interval = 20;
            const timeInterval = setInterval(() => {
                ChatFinder(doubt_id)
            }, interval * 1000);

            return () => {
                clearInterval(timeInterval)
            }

        }, [doubt_id]
    )

    return (
        <div className='Background' style={{
            height: '100%',
        }}>
            {
                !loading
                    ?
                    is_chats
                        ?
                        <>
                            <DoubutSolveHeader subject_name={subject_name} chapter_name={chapter_name} link={FRONTEND_URLS.DOUBUT_SOLVE} />
                            <ChatField />
                            <Container
                                fluid='xxs'
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    // width: '-webkit-fill-available',
                                    maxWidth: '982px',
                                }} className='p-3 Background'>

                                {
                                    statusCond
                                        ?
                                        status === 'CLOSE'
                                            ?
                                            <></>
                                            :
                                            <>
                                                <Row className='BoxShadowUpper pt-3 px-3 d-flex align-items-center justify-content-center' >
                                                    <Col md={3} >
                                                        <strong> Do you still have any doubt? </strong>
                                                    </Col>
                                                    <Col md={1}>
                                                        <Button variant='outline-primary' style={{
                                                            width: "100%",
                                                            color: 'rgb(95, 70, 227)',
                                                            // backgroundColor: 'rgb(95, 70, 227)',
                                                            borderColor: 'rgb(95, 70, 227)',
                                                        }} onClick={() => {
                                                            IsOpenedOrResolved("OPEN")
                                                        }}>Yes</Button>
                                                    </Col>
                                                    <Col md={1}>
                                                        <Button style={{
                                                            width: "100%",
                                                            backgroundColor: 'rgb(95, 70, 227)',
                                                            borderColor: 'rgb(95, 70, 227)',
                                                        }} onClick={() => {
                                                            IsOpenedOrResolved("CLOSE")
                                                        }}>No</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        :
                                        // {/*--------------------------------------------------------chat input filled and sender button--------------------------------------------------------*/ }
                                        <Form onSubmit={(e) => e.preventDefault()}>
                                            <Row style={{
                                                overflow: 'hidden'
                                            }}>
                                                <Col xs={9} sm={10} className='d-flex pe-0'>
                                                    <div className='Styled-Input-Border'
                                                        style={{
                                                            borderStyle: 'solid none solid solid ',
                                                            borderRight: 0,
                                                            borderTopLeftRadius: "50%",
                                                            borderBottomLeftRadius: "50%",
                                                            borderColor: '#5F46E3',
                                                        }}
                                                    ></div>
                                                    <Form.Control type='text' id='formBasicEmail' placeholder='type here your message'
                                                        ref={messageRef} style={{
                                                            borderRight: "none",
                                                            borderLeft: "none",
                                                            borderRadius: '0'
                                                        }}

                                                    />
                                                    <UploadPDFToS3 s3Folder='eSaral/doubt_images' setFilePath={setUploadMediaToS3} url={UploadMediaToS3} style={{
                                                        borderStyle: 'solid solid solid none',
                                                        borderLeft: 0,
                                                        borderTopRightRadius: "50%",
                                                        borderBottomRightRadius: "50%",
                                                        borderColor: '#5F46E3',
                                                    }} className='d-flex align-items-center Styled-Input-Border Psudo-Button' >
                                                        <GrAttachment />
                                                    </UploadPDFToS3>
                                                </Col>
                                                <Col xs={3} sm={2} >
                                                    <Button variant="primary" type="submit" className='Custom-button-ripple' style={{ backgroundColor: '#5F46E3', borderColor: '#5F46E3', width: '100%' }} onClick={MessageValidator}  >
                                                        Send
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                }
                                {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                            </Container>
                        </>
                        :
                        <NotFound message='Please select a doubt or raise a new doubt.' />
                    :
                    <ESaralLoader />
            }

        </div >
    );
};

export default DoubutSolveChat;