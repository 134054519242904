import React from 'react'
import { Button } from 'react-bootstrap'
import { createRipple } from '../Functions/Functions';

function PrimaryButtons({ children, onClick = () => {}, className = '', style = {}, variant="primary"  }) {
    return (
        <Button
            variant={variant}
            type="submit"
            className={`Custom-button-ripple ${className}`}
            style={{ backgroundColor:style.backgroundColor ? style.backgroundColor : "#5F46E3", borderColor: style.borderColor ? style.borderColor : "#5F46E3", ...style }}
            onClick={(e) => {
                createRipple({ event: e, className: "ripple-primary" });
                onClick()
            }}
            
            >
            {children}
        </Button>
    )
}

export default PrimaryButtons