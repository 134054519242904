import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NameTaker } from "../Functions/Functions";
import { useNavigate } from "react-router-dom";
import { FRONTEND_URLS } from "../../URL/URL";
import { useDispatch } from "react-redux";

export default function VerticalModal(props) {
  const navigate = useNavigate();
  const dispatch =useDispatch()

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    // setValidated(true);
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body closeButton>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              autoFocus
              value={props.name}
              onChange={(e) => {
                props.setname(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="number"
              placeholder={props.phone_number}
              disabled
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              if (NameTaker(props.name, dispatch)) {
                props.onHide();
                navigate(FRONTEND_URLS.MY_ACCOUNT);
              }
            }}
            style={{
              width: "100%",
              backgroundColor: "#5F46E3",
              borderColor: "#5F46E3",
            }}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
