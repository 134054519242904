import React, { useEffect, useState } from "react";
import { BACKEND_URLS, FRONTEND_URLS } from "../../../URL/URL";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "./../../../Redux/Reducers/Constants/AppReducerConstant";
import { useNavigate } from "react-router-dom";
import RaiseQueryModal from "./RaiseQueryModal.js";
import { AxiosClient } from "../../Axios/AxiosClient.js";
import LiveClassDropDown from "../../../LiveClass/LiveClassDropDown.js";
import MentorData from "../../mentorship/MentorData.jsx";
import MentorshipStaticCards from "../../mentorship/MentorshipStaticCards.jsx";
import { MentorshipReducerConstant } from "../../../Redux/ReducerConstants/mentorshipReducerConstant.js";
import LiveClassesMentorship from "../../mentorship/LiveClassesMentorship.jsx";
import FaqCards from "../../mentorship/FaqCards.jsx";
import { Container, Row } from "react-bootstrap";



const user_id = JSON.parse(localStorage.getItem('user'))?.user?.user_id;

const Mentorship = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getRQueryForm, setRQueryForm] = useState(false);

  useEffect(
    () => {
      const url = `${BACKEND_URLS.GET_MENTORSHIP_DATA}?student_id=${user_id}`

      let ignore = false;

      AxiosClient.get(url).then(
        (result) => {
          if (!ignore && result.data.success) {

            dispatch({ type: MentorshipReducerConstant.MENTORS, payload: result.data.data.mentors })
            dispatch({ type: MentorshipReducerConstant.FAQS, payload: result.data.data.faqs })
            dispatch({ type: MentorshipReducerConstant.SESSIONS, payload: result.data.data.sessions })
            navigate(FRONTEND_URLS.MENTORSHIP)
          }
        }
      ).catch(
        (err) => {
        }
      )

      return () => { ignore = true; dispatch({ type: AppReducerConstants.RESET }); }
    }, []
  )

  const handleShow = () => setRQueryForm(true);

  return (
    <>
      <LiveClassDropDown isMentorship={true} />
      <LiveClassesMentorship />
      <RaiseQueryModal
        setRQueryForm={setRQueryForm}
        getRQueryForm={getRQueryForm}
      />

      <MentorData />
      <Container fluid="md" className="ms-0"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Row
          className="d-flex row-cols-auto"
          style={{
            columnGap: "2vw",
            padding: "1rem"
          }}>
          <MentorshipStaticCards />
          <FaqCards />
        </Row>
      </Container>

      <button onClick={handleShow} className="raiseQueryBtn_inner DoubutFormButton Psudo-Button">
        Raise Query
      </button>
    </>

  );
};

export default Mentorship;
