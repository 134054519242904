import React from "react";
import Lottie from "lottie-react";

/**
 * 
 * @animationData It is data of the animation which is required
 * @loop It is boolean value which is by default is set to true
 * @returns 
 */
const LottieImage = ({animationData, Isloop=true, onLoopComplete=()=>{}}) => <Lottie style={{
    height:'100%',
    overflow:'hidden'
}} animationData={animationData} loop={Isloop} onLoopComplete={onLoopComplete} />;

export default LottieImage;