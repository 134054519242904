import Search from '../../Images/Search/Search.png'
import NotFound from '../../Images/Search/no-results.png'

export const SearchImageLines = {
    SearchHere : 'Search for concepts, videos, sheets, notes, etc',
    ContentNotReady: 'Content for this section is not ready at the moment. Please check later.',
}

export const searchImages = {
    SearchHere : Search,
    NotFound :  NotFound,
}