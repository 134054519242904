import React, { useEffect, useRef, useState } from 'react'
import { addDataInDb } from '../../DB/Function.js';
import { useDispatch, useSelector } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant.js';
import { useParams } from 'react-router-dom';



function VideoPlayer() {
    const videoPlayer = useRef(null)
    const dispatch = useDispatch()
    const vidInfo = useSelector((state) => state.app.videoInfo);

    const treakIntervalKey = useSelector(state => state.app.treakIntervalKey)
    const tab = useSelector(state => state.app.tab)
    const videoId = useSelector((state) => state.app.videoId);
    const [player, setPlayer] = useState(null)
    const [videState, setState] = useState('playing')
    
    const [playbackRate, setPlaybackRate] = useState(32)

    
    const { subject_node_id } = useParams();
    const { chapter_id, content_id } = useSelector((state) => state.app);

    const content_group = tab?.split('-')[2];
    const content_parent_group = content_group?.charAt(0).toUpperCase() + content_group?.slice(1);

    const treackData = {
        content: content_id,
        subject: subject_node_id,
        chapter: chapter_id,
        content_parent_group: content_parent_group,
    };

    function Treak() {
        if (treakIntervalKey) {
            dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
        }
        dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treackData, playbackRate) })
    }

    useEffect(() => {
        if (vidInfo) {
            if (!player) {
                const player = new window.VdoPlayer(videoPlayer.current)
                setPlayer(player)
            }
            else {
                window.player = player;
                dispatch({ type: AppReducerConstants.IS_PLAYER_READY, payload: true });

                function playerPlayState() {
                    setState('playing')
                    dispatch({ type: AppReducerConstants.VIDEO_STATUS, payload: 'playing' })
                    dispatch({ type: AppReducerConstants.VIDEO_TAB, payload: tab })
                    Treak()
                }

                function playerRateChange() {
                    const playRate = 32 * player.video.playbackRate
                    setPlaybackRate(playRate)

                    if (videState == 'playing') {
                        if (treakIntervalKey) {
                            dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
                        }
                        dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treackData, playRate) })
                    }
                }

                function playerPause() {
                    setState('pause')

                    dispatch({ type: AppReducerConstants.VIDEO_STATUS, payload: 'pause' })
                    dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
                }

                function playerEnded() {
                    setState('ended')
                    dispatch({ type: AppReducerConstants.VIDEO_STATUS, payload: 'ended' })
                    dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
                }


                player.video.addEventListener("play", playerPlayState);
                player.video.addEventListener("ratechange", playerRateChange);
                player.video.addEventListener("pause", playerPause);
                player.video.addEventListener("ended", playerEnded);


                return () => {
                    player.video.removeEventListener("play", playerPlayState);
                    player.video.removeEventListener("ratechange", playerRateChange);
                    player.video.removeEventListener("pause", playerPause);
                    player.video.removeEventListener("ended", playerEnded);
                }
            }
        }
        else {
        }

    }, [videoId, player, vidInfo]);


    return (
        vidInfo
        &&
        <div className='pe-0 d-flex justify-content-center Background' id='video-player' style={{ borderRadius: '8px' }}>
            <div className="ratio ratio-16x9 video-iframe" id='VideoPlay' >
                <iframe
                    id='videoPlayer'
                    title='iframe'
                    ref={videoPlayer}
                    src={`https://player.vdocipher.com/v2/?otp=${vidInfo?.otp}&playbackInfo=${vidInfo?.playbackInfo}&autoplay=true`}
                    allow="encrypted-media"
                    allowFullScreen
                />
            </div>
        </div>
    )
}

export default VideoPlayer