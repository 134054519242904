import React from "react";
import { Col, Row } from "react-bootstrap";
import DoubutSolveChat from "./DoubutSolveChat";
import { useParams } from "react-router-dom";
import DoubutSolvePhoneTab from "./DoubutSolvePhoneTab.js";
import DoubutFormButton from "./DoubtSolveChatComp/DoubutFormButton.js";



const DoubutSolveContent = () => {
  const { doubt_id } = useParams();

  return (
    <div
      className="container-xxl pt-md-2"
      style={{height: "90vh"}}>
      <Row
        style={{height: "100%",}}>
        <Col
          md={3}
          className={`Background md-3 Home-menu-item sideBar px-0 pb-3`}>
          <DoubutSolvePhoneTab className="isDesktop" />
        </Col>

        <Col className="mx-xs-0 ms-md-2 px-0 isPhoneAndTabDoubutHeight">
          <DoubutSolveChat />
          {isNaN(Number(doubt_id)) && <DoubutFormButton />}
        </Col>
      </Row>
    </div>
  );
};

export default DoubutSolveContent;
