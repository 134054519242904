import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosClient } from '../../Components/Axios/AxiosClient.js'
import { BACKEND_URLS } from '../../URL/URL.js'
import { TestReducersConstants } from '../../Redux/Test/TestReducersConstants.js'
import Que from '../components/body/Que.js'
import Qnos from '../components/qno/Qnos.js'
import Header from '../components/header/Header.js'
import ESaralLoader from '../../Components/Spinner/ESaralLoader.js';
import { handleSetShowAlertModal } from '../../helpers/helperFunctions.js';
import '../CSS/Quiz.css'
import '../CSS/PhoneQuiz.css'


function Quiz() {
  const locationState = useLocation().state;
  const { quiz_id: paramQuizId } = useParams();
  const quiz_id = locationState ? locationState.quiz_id : paramQuizId;
  const questions = useSelector(state => state.test.const_quiz_details?.questions)
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (!quiz_id) {
        handleSetShowAlertModal({ dispatch, message: "please provide a valid quiz_id." })
        return
      }

      let ignore = false;

      AxiosClient.post(
        BACKEND_URLS.GET_QUIZ_DETAILS,
        {
          "quiz_id": quiz_id,
          "student_attempt": true
        }
      ).then(
        (res) => {
          if (!ignore) {
            if (res.data.success) {
              dispatch({ type: TestReducersConstants.RESET })
              dispatch({ type: TestReducersConstants.TEST_DATA, payload: res.data.data })
              if (window?.ReactNativeWebView) {
                window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'loaded' }));
              }
            }
            else {
              handleSetShowAlertModal({ dispatch, message: JSON.stringify(res.data.errors) })
              if (window?.ReactNativeWebView) {
                window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'error', message: res.data.error.join(', ') }));
              }
            }
          }
        }
      ).catch(
        (err) => {
          handleSetShowAlertModal({ dispatch, message: JSON.stringify(err) })
          if (window?.ReactNativeWebView) {
            window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'error', message: err.data.errors.join(', ') }));
          }
        }
      )


      const handleContextMenu = (event) => {
        event.preventDefault();
      };

      const handleCopy = (event) => {
        event.preventDefault();
      };

      const handleCut = (event) => {
        event.preventDefault();
      };

      const handleSelect = (event) => {
        event.preventDefault();
      };

      const handleSelectStart = (event) => {
        event.preventDefault();
      };
      const handleDragStart = (event) => {
        event.preventDefault();
      };

      const quiz = document.getElementById('Quiz')

      quiz.addEventListener('contextmenu', handleContextMenu);
      quiz.addEventListener('copy', handleCopy);
      quiz.addEventListener('cut', handleCut);
      quiz.addEventListener('select', handleSelect);
      quiz.addEventListener('selectstart', handleSelectStart);
      quiz.addEventListener("dragstart", handleDragStart);



      return () => {
        ignore = true;
        quiz.removeEventListener('contextmenu', handleContextMenu);
        quiz.removeEventListener('copy', handleCopy);
        quiz.removeEventListener('cut', handleCut);
        quiz.removeEventListener('select', handleSelect);
        quiz.removeEventListener('selectstart', handleSelectStart);
        quiz.removeEventListener('dragstart', handleDragStart);
      
      };
    }, []
  )

  return (
    <div id='Quiz' className='quiz-grid CommanQuizElement'>
      {
        quiz_id
          ?
          questions
            ?
            <>
              <Header />
              <Qnos />
              <Que />
            </>
            :
            <ESaralLoader />
          :
          // <NotFound />
          <>Not found</>
      }
    </div>
  )
}


export default Quiz
