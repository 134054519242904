import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TestReducersConstants } from '../../../Redux/Test/TestReducersConstants.js';

function SectionSelection() {
    const dispatch = useDispatch()
    const sections = useSelector(state => state.test.quiz_details?.sections)
    const questions = useSelector(state => state.test.quiz_details?.questions)
    const active_index = useSelector(state => state.test.active_index)

    const handleChangeSection = (e) => {
        const selectedSectionId = e.target.value;
        dispatch({ type: TestReducersConstants.SECTION, payload: selectedSectionId });
    };

    return (
        <>
            {
                questions
                    && sections
                    && sections.length > 1 ? (
                        <select
                            id="section"
                            value={questions && questions[active_index]?.section_id}
                            onChange={handleChangeSection}
                        >
                            {sections.map((section, id) => (
                                <option key={id} value={section.section_id}>
                                    {section.section_name}
                                </option>
                            ))}
                        </select>
                    )
                    :
                    <div />
            }
        </>
    )
}

export default SectionSelection