import React, { memo } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux'
import Question from '../../../../components/body/QueAndOptions/Question.js';
import VSOptions from './VSOptions.js';
import VSSolution from './VSSolution.jsx';
import { Spinner } from 'react-bootstrap';


function VSQueAndBody() {
  const active_index = useSelector(state => state.test.active_index)
  const questions = useSelector(state => state.test?.quiz_details?.questions);

  return (
    <div id='QueAndOptions' className='Background mb-2'>
      {
        questions
          ?
          <Carousel interval={null} indicators={false} controls={false} activeIndex={active_index} >
            {
              questions.map(
                (question, id) => {
                  return (
                    <Carousel.Item key={id}>
                      <Question question_type_label={question?.question_type_label} question_text={question?.question_text} question_type={question?.question_type} matches={question?.matches} options={question?.options} />
                      <VSOptions is_correct={question?.is_correct} answer_options={question?.answer_options} question_id={question?.question_id} matches={question?.matches} options={question?.options} question_type={question?.question_type} answer_numerical={question?.answer_numerical} attempt_state={question?.attempt_state}/>
                      <VSSolution solution={question?.solution} is_correct={question?.is_correct} marks_obtained={question?.marks_obtained} attempt_state={question?.attempt_state} options={question?.options}  correct_answer={question?.correct_answer} question_type={question?.question_type} />
                    </Carousel.Item>
                  )
                }
              )
            }
          </Carousel>
          :
          <Spinner />
      }
    </div>
  )

}

export default VSQueAndBody