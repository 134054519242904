import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppReducerConstants } from "../Redux/Reducers/Constants/AppReducerConstant.js";
import SecondaryButton from "./../Test/components/buttons/SecondaryButton";
import { FRONTEND_URLS } from "../URL/URL.js";
import LiveClassesComponent from "./LiveClassesComponent.js";

const LiveClassDropDown = ({ isMentorship }) => {
  const isLiveDropDown = useSelector((state) => state.app.isLiveDropDown);

  const live = JSON.parse(localStorage.getItem("user"))?.live?.Upcoming?.data;
  const [liveClasses, setLiveClasses] = useState([])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  const arr = ["Android", "iPhone"];


  function HideDropDown() {
    dispatch({ type: AppReducerConstants.IS_LIVE_DROPDOWN, payload: false });
  }

  function AllLiveSessions() {
    const isSupported = arr.some((platform) => userAgent.includes(platform));

    if (isSupported && window.innerWidth < 720) {
      // link:- "https://play.google.com/store/apps/details?id=com.esaral.exam.preparation&hl=en_IN&gl=US"
    }
    if (isMentorship === true) {
      navigate(FRONTEND_URLS.LIVEMENTORSHIP);
    } else {
      navigate(FRONTEND_URLS.LIVE);
    }

  }

  useEffect(
    () => {
      let data = [];
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);

      const todayEndTimestamp = Math.floor(todayEnd.getTime() / 1000);
      const todayStartTimestamp = Math.floor(todayStart.getTime() / 1000);


      if (live && live.length > 0) {
        for (let index = 0; index < live.length; index++) {
          const element = live[index].data;
          // Filter sessions that end before the end of today
          const filteredSessions = element.filter(session => session.end_date_time <= todayEndTimestamp && session.start_date_time >= todayStartTimestamp);
          data.push(...filteredSessions);
        }
        // Sort the sessions by start_date_time
        data.sort((a, b) => a.start_date_time - b.start_date_time);

        setLiveClasses(data)
      }

    }, []
  )
  return (
    <Offcanvas
      show={isLiveDropDown}
      onHide={HideDropDown}
      placement="bottom"
      backdrop={true}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>LIVE Sessions</Offcanvas.Title>
      </Offcanvas.Header>
      <>
        <Offcanvas.Body className="OffcanvasBody px-0 py-1 Background">
          {
            liveClasses.length > 0 ?
              liveClasses?.map((liveClass, id) => {
                
                const isMentorshipClass = (isMentorship === true)
                const isOntheMentorshipPage = (liveClass.series_category == "MENTORSHIP") && isMentorshipClass

                if (!isMentorshipClass) {
                  return <LiveClassesComponent liveClass={liveClass} key={id} />
                }

                else if (isOntheMentorshipPage){
                  return <LiveClassesComponent liveClass={liveClass} key={id} />
                }
              })
              :
              <div className="d-flex justify-content-center align-items-center h-100">
                <strong>No any live classes yet.</strong>
              </div>
          }
        </Offcanvas.Body>

        <Offcanvas.Header className="d-flex justify-content-center Psudo-Button MainText p-1">
          <Offcanvas.Title
            onClick={() => {
              HideDropDown();
            }}>
            <SecondaryButton
              onClick={AllLiveSessions}
              name={"View All Sessions"}
              style={{
                width: "279px",
                height: "50px",
                flexShrink: "0",
                backgroundColor: "#5F46E3",
                strokeWidth: "1px",
                border: "#5F46E3",
              }}
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
      </>
    </Offcanvas >
  );
};

export default LiveClassDropDown;
