import React from 'react';
import { Col, Navbar, Row } from 'react-bootstrap';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';



const DoubutSolveHeader = ({ subject_name, chapter_name, link = -1 }) => {
    const navigate = useNavigate()

    return (
        <Navbar sticky="top" className='BoxShadow Background isPhoneAndTab' style={{ height: '10vh', }}>
            {/* <Navbar.Brand > */}
                {/* <Row className='align-items-center d-flex'> */}
                    <div className='d-flex Psudo-Button mx-2'>
                        <FiArrowLeft size={25} onClick={() => {
                            navigate(link)
                        }} />
                    </div>
                    <div>
                        {subject_name && <div style={{
                            fontSize: "18px",
                        }}><strong>{subject_name}</strong></div>}

                        {chapter_name && <div style={{
                            fontSize: "14px",
                        }}>{chapter_name}</div>}
                    </div>
                {/* </Row> */}
            {/* </Navbar.Brand> */}
        </Navbar>
    );
};

export default DoubutSolveHeader;