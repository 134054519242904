export const AlertReducersConstants = {
    IS_ALERT: 'IS_ALERT',
    MESSAGE: 'MESSAGE',
    VARIANT: 'VARIANT',
    HEADING:'HEADING',
    IS_ALERT_MODAL:'IS_ALERT_MODAL',
    AlertVariants:{
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        SUCCESS: 'success',
        DANGER: 'danger',
        WARNING: 'warning',
        INFO: 'info',
        LIGHT: 'light',
        DARK: 'dark'
    },

    IS_WAITING_WRAPPER:'IS_WAITING_WRAPPER'
}