import React from "react";
import { AxiosClient } from "../../Axios/AxiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../../URL/URL";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import target from "../../../Images/LottieImages/target.json";
import { RegisterPageConstantLines } from "../../../Constants/Register/Register";
import { ButtonColors } from "../../../Constants/ButtonColors/ButtonColors";
import LottieImage from "../../LottieImage/LottieImage";
import { UserData } from "../../Functions/Functions";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant";

const Exam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {targets, name: name} = useLocation().state;


  function EnrollStudent(id) {
    AxiosClient.post(BACKEND_URLS.NEW_ENROLLMENTS, {
      target_course_id: id,
    })
      .then((resp) => {
        if (resp.data.success) {
          UserData();
          dispatch({ type: AppReducerConstants.IS_LOGIN, payload: true });
          navigate(FRONTEND_URLS.HOME_ROUTE);
        }
      })
      .catch((err) => {
      });
  }

  return (
    <div className="Form-phone Attempt-year-Form">
      {
        <>
          <Container style={{height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
         }}>
            <Row
              className="d-flex justify-content-center"
              style={{
                maxHeight: "720px",
                height: "100vh",
              }}>
              <Col sm={12} md={8}>
                <Form
                  className="d-flex flex-column"
                  style={{
                    height: "100%",
                  }}
                  noValidate
                  validated={false}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}>
                  <Form.Group
                    className="d-flex flex-column"
                    controlId="formBasicEmail">
                    <div className="mt-5">
                      <Form.Text className="Form-heading1">
                        Hi, {name ?? ''}👋
                      </Form.Text>
                      <br />
                      <Form.Text className="Form-heading3">
                        {RegisterPageConstantLines.Exam.subTitle}
                      </Form.Text>
                    </div>
                    <div className="Form-lottie">
                      <LottieImage animationData={target} Isloop={false} />
                    </div>
                  </Form.Group>

                  <Container style={{display:"flex"}}>
                    {targets.map((item, key) => {
                      return (
                        key % 2 === 0 && (
                          <Row>
                            {targets[key] && (
                              <Col
                                className="Exam-card grid-button Psudo-Button m-2 d-flex align-items-start"
                                key={key}
                                style={{
                                  border: `1px solid ${
                                    ButtonColors[key % ButtonColors.length]
                                      .background
                                  }`,
                                  background: ButtonColors[key].BorderColor,
                                }}
                                onClick={() => {
                                  EnrollStudent(targets[key].target_course_id);
                                }}>
                                <img 
                                style={{height:"75px"}}
                                  draggable="false"
                                  src={targets[key].thumbnail}
                                  alt=""
                                  onError={(e) => {
                                    e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                                }}                                />
                                <div>
                                  <strong>{targets[key].target_name}</strong>
                                </div>
                                <div>{targets[key].tag_line}</div>
                              </Col>
                            )}

                            {targets[key + 1] && (
                              <Col
                                className="Exam-card grid-button Psudo-Button m-2 d-flex align-items-start"
                                key={key + 1}
                                style={{
                                  border: `1px solid ${
                                    ButtonColors[
                                      key + (1 % ButtonColors.length)
                                    ].background
                                  }`,
                                  background: ButtonColors[key + 1].BorderColor,
                                }}
                                onClick={() => {
                                  EnrollStudent(targets[key].target_course_id);
                                }}>
                                <img
                                  draggable="false"
                                  src={targets[key + 1].thumbnail}
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    flexShrink: "0",
                                  }}
                                  alt=""
                                  onError={(e) => {
                                    e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                                }}  
                                />
                                <div>
                                  <strong>
                                    {targets[key + 1].target_name}
                                  </strong>
                                </div>
                                <p>
                                  <strong>{targets[key + 1].tag_line}</strong>
                                </p>
                              </Col>
                            )}
                          </Row>
                        )
                      );
                    })}
                  </Container>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      }
    </div>
  );
};

export default Exam;
