import { AxiosClient } from "../../../Components/Axios/AxiosClient";

export default {
    getVDOinfo: async function (video_id) {
        try {
            const response = await AxiosClient.get("https://dev.vdocipher.com/api/meta/" + video_id)

            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getVideoOTP: async function (video_id, phone = "") {
        const res = await AxiosClient.post("/v2/contents/get-video-otp", {
            "video_id": video_id,
            "phone": phone,
            "video_download": false
        })
        if (res.data.success) {
            return res.data.data;
        } else {
            throw res.data.errors.join(", ")
        }
    },
};