import React from 'react'

function NotFound() {
    return (
        <h4 className='d-flex justify-content-center align-items-center' style={{width:"100vw", height:'100vh'}}>
            Page Not Found
        </h4>
    )
}

export default NotFound