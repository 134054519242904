import React, { useEffect, useState } from 'react';
import { HomeMenuList } from '../../../Constants/SideBar/SideBar';
import { FiEdit2, FiLogOut, FiTarget } from 'react-icons/fi';
import { Container, Row } from 'react-bootstrap';
import { BsClock } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import { FRONTEND_URLS } from '../../../URL/URL';
import VerticalModal from '../../Modals/VerticalModal';
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant.js';
import { useSelector } from 'react-redux';
import MessageModal from '../../message-modal/MessageModal.jsx';
import { useDispatch } from 'react-redux';
import { AlertReducersConstants } from '../../../Redux/ReducerConstants/AlertReducersConstants.js';



const MyAccount = () => {
    const user = JSON.parse(localStorage.getItem('user'))?.user
    const enrollments = JSON.parse(localStorage.getItem('user'))?.enrollments
    const [isModal, setIsModal] = useState(false)
    const [name, setname] = useState(user?.full_name)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logout = useSelector(state => state.test.MessageModal)

    function LogOut() {
        localStorage.clear()
        navigate(FRONTEND_URLS.LOGIN_ROUTE)
    }

    const setLogout = () => {
        dispatch({ type: AppReducerConstants.MESSAGE_MODAL })
    }


    return (
        <Container fluid='md' className='p-0'>
            <MessageModal id1='message-modal-class-1' id2={'message-modal-class-2'} title='No question' message={`Do you really want to logout?`} butText='LOG OUT' seconday_but="CANCEL" fun={LogOut} pri_but_vai={"danger"} />
            {/*-------------------------------------- My Profile -----------------------------------*/}
            {isModal &&
                <VerticalModal
                    show={isModal}
                    onHide={() => setIsModal(false)}
                    name={name}
                    phone_number={user.phone_number}
                    setname={setname}
                />
            }
            <Container fluid='md' className='Background p-4'>
                <span className='px-0'>{HomeMenuList[1][0]} &nbsp; My Profile </span>
                <Row className='Account-Inner-Card my-3'>
                    <strong>{!isModal ? name : user?.full_name}</strong>
                    <div>+91 {user?.phone_number}</div>
                    <div
                        className='My-Account-But Psudo-Button'
                        onClick={() => {
                            navigate(FRONTEND_URLS.ADMISSION)
                            // setIsModal(true)
                        }}
                    ><FiEdit2 /> Edit
                    </div>
                </Row>
            </Container>

            {/*-------------------------------------- My Targets -----------------------------------*/}
            <Container fluid='md' className='Background p-4 mt-2'>
                <span className='px-0'><FiTarget size={22} /> &nbsp; My Targets </span>
                {
                    enrollments?.map(
                        (enrollment, id) => {
                            return (
                                <Row key={id} className='Account-Inner-Card my-3 p-2 '>
                                    <div className='d-flex justify-content-between'><strong>{enrollment.course_name}
                                        {/* ({enrollment.subcourses.find(e => e.is_current == true )?.name})   */}
                                    </strong> {enrollment.subcourses.find(e => e.is_current == true) && <div className='Enrolled'>Enrolled</div>} </div>
                                    <div>Started on: {enrollment.enroll_date}</div>
                                    {/* <div className='My-Account-But Psudo-Button'> <BsClock /> See History </div> */}
                                </Row>
                            )
                        }
                    )
                }
            </Container>

            {/*-------------------------------------- Logout -----------------------------------*/}
            <div className='Background p-4 mt-2 Log-out' onClick={setLogout}>
                <span className='Psudo-Button'>
                    <FiLogOut className='ms-0 me-2 ' style={{
                        marginLeft: '20px'
                    }} /> Logout
                </span>
            </div>

        </Container>
    );
};

export default MyAccount;