import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SearchImageLines, searchImages } from '../../../Constants/Search/SearchImgLines';
import { AxiosClient } from '../../Axios/AxiosClient';
import { BACKEND_URLS } from '../../../URL/URL';
import SubjectContentBody from '../Subject/SubjectContentBody/SubjectContentBody';
import { VideoFun } from '../../Functions/Functions';
import ESaralLoader from '../../Spinner/ESaralLoader.js';
import NoSearchResult from './../../../Images/noSearchResult/NoSearchResult';
import { useDispatch } from 'react-redux';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js';

const notFound = 'notFound'

const SearchBody = () => {
    const { search_id } = useParams();
    const [testData, setTestData] = useState();
    const [reviseData, setReviseData] = useState();
    const [practice, setpractice] = useState();
    const [learn, setlearn] = useState();
    const dispatch = useDispatch()


    useEffect(
        () => {
            let ignore = false;

            if (search_id) {
                AxiosClient.post(
                    BACKEND_URLS.SEARCH_API,
                    { subject: 0, chapter: 0, search_str: search_id }
                ).then(
                    (res) => {
                        if (!ignore && res.data.success) {
                            const data = res.data.data.search_results;
                            setTestData(data?.test);
                            setpractice(data?.practise);
                            setReviseData(data?.revise);
                            setlearn(data?.learn);
                        }
                        else {
                            setlearn(notFound);
                            handleSetShowAlertModal({ dispatch, message: res?.data?.errors })
                        }
                    }
                ).catch(
                    (err) => {
                    }
                )
            }

            return () => { ignore = true; }

        }, [search_id]
    )

    return (
        <div className='container-xxl Vertical-gap SearchBody' >
            <Row style={{
                height: '100vh',
            }}  >
                {
                    search_id
                        ?
                        learn ?

                            (learn == notFound) || (reviseData.length == 0 && testData.length == 0 && practice.length == 0 && learn.length == 0)
                                ?
                                <NoSearchResult />
                                :
                                <>
                                    <SubjectContentBody
                                        learn={learn}
                                        test={testData}
                                        revise={reviseData}
                                        practice={practice}
                                    />
                                </>
                            :
                            <ESaralLoader />
                        :
                        <div style={{
                            width: '100%',
                            height: '100%'
                        }} className='d-flex flex-column align-items-center justify-content-center Background'>
                            <img draggable="false" className='Search-Image' src={searchImages.SearchHere} alt='' />
                            <div className='NoSearchResult'>{SearchImageLines.SearchHere}</div>
                        </div>
                }
            </Row>
        </div>
    );
};

export default SearchBody;