import React, { useState } from "react";
import { BiSolidPlusCircle } from "react-icons/bi";
import DoubutForm from "./DoubutForm";
import RaiseQueryModal from "../../Home/RaiseQueryModal";
import { useParams } from "react-router-dom";



const DoubutFormButton = () => {

  const { doubt_id, queries } = useParams();

  const [showDoubtForm, setDoubtForm] = useState(false);
  const [getRQueryForm, setRQueryForm] = useState(false);
  const handleShow2 = () => setRQueryForm(true);
  const handleShow = () => setDoubtForm(true);

  return (
    <>
      {queries == 'queries' && isNaN(Number(doubt_id)) 
      ? <>
        <RaiseQueryModal
          setRQueryForm={setRQueryForm}
          getRQueryForm={getRQueryForm}
        />
        <button onClick={handleShow2} className="raiseQueryBtn_inner DoubutFormButton Psudo-Button">
          Raise Query
        </button>
      </> 
      :
       <>
        <DoubutForm setDoubtForm={setDoubtForm} showDoubtForm={showDoubtForm} />
        <BiSolidPlusCircle
          size={72}
          className="DoubutFormButton Psudo-Button"
          onClick={handleShow}
        />
      </>
      }
    </>
  );
};

export default DoubutFormButton;
