import React, { memo, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux'
import Question from './QueAndOptions/Question.js'
import Options from './QueAndOptions/Options.js'
import ESaralLoader from '../../../Components/Spinner/ESaralLoader.js'
import { useDispatch } from 'react-redux';
import { TestReducersConstants } from '../../../Redux/Test/TestReducersConstants.js';


function QueAndOptions() {
  const active_index = useSelector(state => state.test.active_index)
  const questions = useSelector(state => state.test?.quiz_details?.questions);
  const dispatch = useDispatch()

  const [startTouchXY, setStartTouchXY] = useState({ X: 0, Y: 0 })


  function onTouchEnd(e) {

      const degree = Math.atan((startTouchXY.Y - e.changedTouches[0].clientY) / (startTouchXY.X - e.changedTouches[0].clientX)) * (180 / Math.PI)
      const modDeg = degree < 0 ? (-1 * degree) : degree
      if (modDeg < 30) {

          if (startTouchXY.X - e.changedTouches[0].clientX > 60) {
              active_index < questions.length - 1 && dispatch({type: TestReducersConstants.NEXT })
          }

          else if (startTouchXY.X - e.changedTouches[0].clientX < -60) {
              active_index > 0 && dispatch({ type: TestReducersConstants.PREV })
          }
      }
  }


  return (
    <div id='QueAndOptions' className='Background mb-2'>
      {
        questions
          ?
          <Carousel interval={null} indicators={false} controls={false} activeIndex={active_index} onTouchStart={e => {
            setStartTouchXY({ X: e.changedTouches[0].clientX, Y: e.changedTouches[0].clientY }) }} onTouchEndCapture={onTouchEnd} >
            {
              questions.map(
                (question, id) => {
                  return (
                    <Carousel.Item key={id}>
                      <Question paragraph={question?.paragraph} question_type_label={question?.question_type_label} question_text={question?.question_text} question_type={question?.question_type} matches={question?.matches} options={question?.options} />
                      <Options question_id={question?.question_id} matches={question?.matches} section_id={question?.section_id} options={question?.options} question_type={question?.question_type} answer_numerical={question?.answer_numerical} />
                    </Carousel.Item>
                  )
                }
              )
            }
          </Carousel>
          :
          <ESaralLoader />
      }
    </div>
  )

}

export default memo(QueAndOptions)