import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import QueNoItem from "./QueNoItem";


const QueNo = () => {
    const scrollRef = useRef()
    const userPracticeReducer = useSelector(state => state.userPracticeReducer)
    const data = userPracticeReducer.practiceModData
    const currentIndex = userPracticeReducer.currentIndex

    useEffect(() => {
        if (currentIndex > 1) scrollRef.current.scrollLeft = (currentIndex - 2) * 60
    }, [currentIndex])

    
    return (
        <div ref={scrollRef} className="horizontal-scroll" >

            {data?.map((item, i) => <QueNoItem key={i} index={i} questionNo={item.questionNo} isSubmitted={item.is_submitted} isCurrent={currentIndex===i} />)}
        </div>
    )

}

export default QueNo;