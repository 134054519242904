import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";
import { memo } from "react";
import { gtag_event_category, gtag_events } from "../../utils/constants/gTagConstants";


const QueNoItem = ({questionNo, index, isCurrent, isSubmitted}) => {
    const dispatch = useDispatch()

    function onIndexPress(i) {
        dispatch({type: userPracticeConstants.UPDATE_CURRENT_INDEX, index: i })
        // window.gtag("event", gtag_events.SWITCH_QUESTION, {
        //     "event_category": gtag_event_category.PRACTICE_QUIZ,
        //     "event_label": questionNo,
        // })
    }


    return <div key={index} className="index-btn" style={{backgroundColor: isCurrent ? "#5F46E3" :isSubmitted?"#E5F8FF": "#E6E7E9", color: isCurrent ? "#FFF" : "#0D1015"}} onClick={() => onIndexPress(index)} >
        {questionNo}
    </div>

}

export default memo(QueNoItem);