export const HelpAndSupportSampleData = {
    "success": true,
    "data": {
        "categories": [
            "TEST",
            "VIDEO"
        ],
        "faqs": [
            {
                "category": "VIDEO",
                "question": "how to raise a ticket?",
                "solution": "details of the question."
            },
            {
                "category": "TEST",
                "question": "how to submit test?",
                "solution": "details of test submission."
            },
            {
                "category": "VIDEO",
                "question": "how to open a video",
                "solution": "details for opening a video"
            }
        ]
    },
    "meta": {},
    "errors": [],
    "error_code": null
}