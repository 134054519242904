import { openDB } from "idb";
import moment from "moment";
import { AxiosClient } from "../Components/Axios/AxiosClient.js";
import { BACKEND_URLS } from "../URL/URL.js";

export const DATABASE_NAME = 'eSaral'
export const DATABASE_VERSION = 1
export const TABEL_NAME = 'table'

export const addDataInDb = (treakData, seconds = 32) => {

  console.log({treakData, seconds})

  const USAGE_IN_SECOND = seconds;
  const Const_IN_SECOND = 32;
  const { content, subject, content_parent_group, is_sync, chapter } = treakData

  let FirstTime = true

  if (FirstTime) {
    async function iiFe() {
      try {
        const data = {
          "content": Number(content),
          "date": moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
          "usage_in_seconds": 0,
          "subcourse": Number(JSON.parse(localStorage.getItem('user'))?.enrollments.find((e) => e.is_current == true)?.subcourses.find((e) => e.is_current == true)?.id),
          "subject": Number(subject),
          "chapter": Number(chapter),
          "content_parent_group": content_parent_group,
          "is_sync": is_sync || false
        }


        const db1 = await openDB(DATABASE_NAME, DATABASE_VERSION);
        const tx = db1.transaction(TABEL_NAME, "readwrite");
        const store = tx.objectStore(TABEL_NAME);

        const result = await store.add(data);
        FirstTime = false

        await tx.done;

      } catch (error) {
      }
    }

    iiFe()

  }

  const intervalId = setInterval(async () => {

    try {
      const data = {
        "content": Number(content),
        "date": moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        "usage_in_seconds": USAGE_IN_SECOND,
        "subcourse": Number(JSON.parse(localStorage.getItem('user'))?.enrollments.find((e) => e.is_current == true)?.subcourses.find((e) => e.is_current == true)?.id),
        "subject": Number(subject),
        "chapter": Number(chapter),
        "content_parent_group": content_parent_group,
        "is_sync": is_sync || false
      }

      const db1 = await openDB(DATABASE_NAME, DATABASE_VERSION);
      const tx = db1.transaction(TABEL_NAME, "readwrite");
      const store = tx.objectStore(TABEL_NAME);

      const result = await store.add(data);

      await tx.done;

    } catch (error) {
    }

  }, Const_IN_SECOND * 1000);



  return intervalId
}




export function stopAddingData(intervalId) {
  clearInterval(intervalId);
  return true
}


export async function retrieveData() {
  try {
    const db = await openDB(DATABASE_NAME, DATABASE_VERSION);
    const data = await db.getAll(TABEL_NAME);
    db.close();
    return data;
  } catch (error) {
    return null;
  }
}


export async function UpdateData() {

  try {
    const db = await openDB(DATABASE_NAME, DATABASE_VERSION);
    const dataKeys = await db.getAllKeys(TABEL_NAME);
    const data = await db.getAll(TABEL_NAME);

    if (data.length) {

      const res = await AxiosClient.post(BACKEND_URLS.USAGE, {
        "content_usage_list": data
      });

      // Start a new transaction in readwrite mode
      var transaction = db.transaction(TABEL_NAME, 'readwrite');

      // Access the object store
      var objectStore = transaction.objectStore(TABEL_NAME);

      if (res.data.success) {

        for (let i = 0; i < dataKeys.length; i++) {
          //   // Specify the key you want to update
          var keyToUpdate = dataKeys[i]; // Replace this with the actual key

          var request = objectStore.delete(keyToUpdate);
          request.onsuccess = function (event) {
          };

          request.onerror = function (event) {
          };
        }

      }
    }
  }
  catch (error) {
    return null;
  }

}

