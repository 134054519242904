import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';



const Countdown = ({submitTestFun}) => {
    const [remainingTime, setRemainingTime] = useState();

    const endTime = useSelector(state => state.test.student_attempt?.end_time && state.test.student_attempt?.end_time?.includes('+') ?  state.test.student_attempt?.end_time :  state.test.student_attempt?.end_time + '+0000' )

    const dispatch = useDispatch()
    

    useEffect(() => {
        const interval = setInterval(() => {
            const difference = new Date(endTime).getTime() - new Date().getTime();
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            if(difference === 30000){
                handleSetShowAlertModal({ dispatch, message: 'last 30 seconds are remainning.' })
            }

            if(difference<=0){
                submitTestFun()
                clearInterval(interval)   
            }

            setRemainingTime(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div>
            <strong>
                {
                    remainingTime
                        ?
                        <p className='m-0'>{remainingTime}</p>
                        :
                        <p>Loading...</p>
                }
            </strong>
        </div>
    );
};

export default Countdown;
