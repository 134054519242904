import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CheckBoxs } from '../../../../../Images/checkBox/CheckBoxs';
import MatchTheColumn from '../../../../../Images/MatchTheColumn/MatchTheColumn.js';
import { RadioButtons } from '../../../../../Images/RadioButtons/RadioButtons.js';



function VSOptions({ options, matches, question_type, answer_numerical, is_correct, question_id, answer_options, attempt_state }) {
    const [inputValue, setInputValue] = useState( attempt_state=='SKIPPED' ? '' : answer_numerical)

    if (question_type === "single-correct") {
        return options?.map(
            (option, id) => {
                return (
                    <div key={id} className={`d-flex align-items-center m-3 Psudo-Button option-selected  ${answer_options.find((e) => e.option_id === option.option_id) ? option.is_solution ? "correct" : "wrong" : option.is_solution && "correct"}`} onClick={() => {
                    }} >
                        <img  draggable="false" src={answer_options.find((e) => e.option_id === option.option_id) ? RadioButtons.checked : RadioButtons.unchecked} alt='' style={{ width: '25px' }} />
                        <div className='d-flex align-items-center mx-3 ' dangerouslySetInnerHTML={{ __html: option.option_value }} />
                    </div>
                )
            }
        )
    }

    else if (question_type === "multiple-correct") {
        return options?.map(
            (option, id) => {
                return (
                    <div key={id} className={`d-flex align-items-center m-3 Psudo-Button option-selected  ${answer_options.find((e) => e.option_id === option.option_id) ? option.is_solution ? "correct" : "wrong" : null}`} onClick={() => {
                    }}  >
                        <img  draggable="false" src={answer_options.find((e) => e.option_id === options[id]?.option_id) ? CheckBoxs.checked : CheckBoxs.unchecked} alt='' style={{ width: '25px' }} />
                        <div className='d-flex align-items-center mx-3 ' dangerouslySetInnerHTML={{ __html: option.option_value }} />
                    </div>
                )
            }
        )
    }
    else if (question_type === "match-the-column") {
        return <table className='table-class m-3 mt-1' style={{
            width: '30%',
        }}>
            <tbody>
                <tr>
                    <td className='td-class'></td>
                    {
                        matches.map(
                            (i, id) => {
                                return <td key={id} className='td-class'>({String.fromCharCode(80 + id)})</td>
                            }
                        )
                    }
                </tr>
                {
                    options.map(
                        (ele, i) => {
                            return (
                                <tr key={i}>
                                    {
                                        [...Array(matches.length + 1)].map(
                                            (ke, j) => {
                                                return (
                                                    <td className='td-class' key={j}>
                                                        {
                                                            j === 0
                                                                ?
                                                                <>({String.fromCharCode(65 + i)})</>
                                                                :
                                                                <img  draggable="false" src={ matches[j - 1].selected_option_indexes.includes(i) ? matches[j - 1].match_option_indexes.includes(i) ? MatchTheColumn.checkBoxCorrect : MatchTheColumn.checkBoxInCorrect : matches[j - 1].match_option_indexes.includes(i) ? MatchTheColumn.boxCorrect : null} style={{
                                                                    width:'24px', height:'auto',
                                                                }} alt='' />
                                                        }
                                                    </td>
                                                )
                                            }
                                        )
                                    }
                                </tr>
                            )
                        }
                    )
                }
            </tbody>
        </table>

    }
    else if (question_type === "numerical") {
        return (
            <>
                <input type='text' className={`mx-3 input ${attempt_state =='SKIPPED' ? '' : is_correct ? "correct" : "wrong"}`} value={inputValue} onChange={(e) => {
                    setInputValue(e.target.value)
                }} onBlur={() => {
                }} disabled />
            </>
        )
    }
}

export default memo(VSOptions)