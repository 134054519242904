import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import LiveClassesComponent from '../../LiveClass/LiveClassesComponent'
import { useNavigate } from 'react-router-dom'
import { FRONTEND_URLS } from '../../URL/URL'

function LiveClassesMentorship() {
    const [liveClasses, setLiveClasses] = useState([])
    const navigate = useNavigate()
    
    useEffect(
        () => {
            const live = JSON.parse(localStorage.getItem("user"))?.live?.Upcoming?.data;
            let data = [];
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);

            const todayEndTimestamp = Math.floor(todayEnd.getTime() / 1000);

            if (live && live.length > 0) {
                for (let index = 0; index < live.length; index++) {
                    if (live[index].series_category === "MENTORSHIP") {
                        const element = live[index].data;
                        const filteredSessions = element.filter(session => session.end_date_time <= todayEndTimestamp);
                        data.push(...filteredSessions);
                    }
                }
                data.sort((a, b) => a.start_date_time - b.start_date_time);

                setLiveClasses(data.slice(0, 2))
            }

        }, []
    )



    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "0.5rem 1rem",
                // margin: "0 1.5rem",
                backgroundColor: "#fff",
                marginBottom: "8px",
            }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <div>
                    <h5>Sessions</h5>
                </div>
                <div>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        navigate(FRONTEND_URLS.LIVEMENTORSHIP)
                    }}>See All</p>
                </div>
            </div>

            <Offcanvas.Body className="OffcanvasBody px-0 py-1" style={{ backgroundColor: "#fff" }}>
                {
                    liveClasses.length > 0 ?
                        liveClasses?.map((liveClass, id) => {
                            return <LiveClassesComponent liveClass={liveClass} key={id} />
                        })
                        :
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <strong>No any live classes yet.</strong>
                        </div>
                }
            </Offcanvas.Body>
        </div>

    )
}

export default LiveClassesMentorship