import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import DoubutSolveContent from '../../Components/Body/DoubutSolve/DoubutSolveContent';
import { useDispatch } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant';
import { BACKEND_URLS, FRONTEND_URLS } from '../../URL/URL';
import { AxiosClient } from '../../Components/Axios/AxiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import MSideBar from '../../Components/SideBar/MSideBar.js';
import ESaralLoader from '../../Components/Spinner/ESaralLoader.js';
import CourseEpired from '../../Components/CourseEpired/CourseEpired.jsx';



const DoubutSolve = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const { queries: slug } = useParams();

    const user = JSON.parse(localStorage.getItem('user'))
    const user_id = user?.user?.user_id;
    const isCourseEpired = user?.is_current_subcourse_expired;




    useEffect(
        () => {
            setLoading(true)
            const url = `${BACKEND_URLS.GET_ALL_DOUBTS}?status=&search&assignee&student=${user_id}&page=1&size=25`
            let ignore = false;
            AxiosClient.get(url).then(
                (result) => {
                    if (!ignore && result.data.success) {
                        dispatch({ type: AppReducerConstants.DOUBUT_SOLVE_MENU_COUNT, payload: { all_doubts: result.data.data, slug } })
                        if (slug === "queries") {
                            navigate(FRONTEND_URLS.DOUBUT_SOLVE_QUERIES)

                        } else {
                            navigate(FRONTEND_URLS.DOUBUT_SOLVE)
                        }
                        setLoading(false)
                    }
                }
            ).catch(
                (err) => {
                    setLoading(false)
                }
            )

            return () => { ignore = true; dispatch({ type: AppReducerConstants.RESET }); }
        }, []
    )

    return (
        loading
            ?
            <ESaralLoader />
            :
            <>
                {/* <PhoneHomeDropDown /> */}
                <MSideBar />
                <Header />
                {
                    isCourseEpired
                        ?
                        <CourseEpired heading='Doubt Solving Not Available' subheading='It seems you have not enrolled for doubt solving yet. If this seems to be an error then contact support.' />
                        :
                        <DoubutSolveContent />
                }
            </>
    );
};

export default DoubutSolve;