import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubjectBody from "../../Components/Body/Subject/SubjectBody";
import { AxiosClient } from "../../Components/Axios/AxiosClient";
import { BACKEND_URLS, FRONTEND_URLS } from "../../URL/URL";
import ESaralLoader from "../../Components/Spinner/ESaralLoader.js";
import SubjectHeader from "../../Components/Header/SubjectHeader.js";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant.js";
import { ButtonColors } from "../../Constants/ButtonsColor/ButtonsColor.js";
import { UpdateData } from "../../DB/Function.js";
import { useSelector } from "react-redux";



const Subject = () => {

  const { subject_node_id } = useParams();
  const { pathname: location, state } = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const loading = useSelector(state => state.app.isLoading)

  const [data, setData] = useState(null);
  const [nodeType, setNodeType] = useState();
  const [nodeContentTree, setNodeContentTree] = useState([]);


  const handleGettingData = (subject_node_id) => {
    setData(null);
    dispatch({ type: AppReducerConstants.IS_LOADING, payload: true })

    const live = JSON.parse(localStorage.getItem("livedata"));
    const filteredSubjects = live?.filter(
      (cls) => cls.subject_id == subject_node_id
    );

    dispatch({ type: AppReducerConstants.LIVE_RECORDED_DATA, payload: filteredSubjects })

    if (subject_node_id) {

      AxiosClient.post(BACKEND_URLS.SUBJECT_DATA_API, {
        node_id: Number(subject_node_id),
      }).then((result) => {

        if (result.data.success) {
          localStorage.setItem("@subject", JSON.stringify(result.data.data));

          const resData = result?.data?.data
          const node_content_tree = resData?.node_content_tree
          const node_type = node_content_tree?.[0]?.node_type

          if (["SUBJECT", "COLLECTION"].includes(node_type)) {
            setData([resData]);
          }
          else if (node_type === 'CHAPTER') {
            setData(resData.node_content_tree);
          }
          else {
            if (Array.isArray(resData)) {
              setData(resData);
            }
            else {
              setData(resData?.node_content_tree ? [resData?.node_content_tree] : []);
            }
          }
          setNodeType(node_type);

          setNodeContentTree(result?.data?.data?.node_content_tree);
          dispatch({
            type: AppReducerConstants.SUBJECT_ID,
            payload: subject_node_id,
          });
          dispatch({ type: AppReducerConstants.IS_LOADING, payload: false })
        }
      }).catch(
        (err) => {
        }
      )
    }
  }


  useEffect(() => {

    handleGettingData(subject_node_id)

    return () => {
      dispatch({ type: AppReducerConstants.IS_LOADING, payload: false })
      dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
      dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });
      dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });
      dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
    };
  }, [subject_node_id]);



  const handleOnClick = (subject) => {
    UpdateData();
    dispatch({ type: AppReducerConstants.SUBJECT_ID, payload: subject.node_id, });
    dispatch({ type: AppReducerConstants.PREVIOUS_PAGE_ADD, payload: location })
    dispatch({ type: AppReducerConstants.IS_LOADING, payload: true })
    navigate(FRONTEND_URLS.SUBJECT_ROUTE + '/' + subject.node_id, { state: { display_name: subject.display_name } });
  }




  return (
    loading
      ?
      <ESaralLoader />
      :
      data ?
        <>
          <SubjectHeader name={state?.display_name} key={state?.display_name} />
          {
            ["SUBJECT", "COLLECTION"].includes(nodeType) ?
              <div style={{ height: "100vh" }} key={subject_node_id}>
                <Container
                  fluid="md"
                  className="ms-0"
                  style={{ maxWidth: "100%", marginTop: "2rem" }}
                >
                  <Row
                    className="d-flex row-cols-auto"
                    style={{
                      columnGap: "1.5rem",
                      marginLeft: "2rem",
                      justifyContent: "center",
                    }}
                  >
                    {
                      nodeContentTree?.map((subject, id) => {
                        return (
                          <Col
                            key={id}
                            className="Subject-Button Psudo-Button homeBox"
                            style={{
                              backgroundColor:
                                ButtonColors[id % ButtonColors.length].BorderColor,
                            }}
                            onClick={() => handleOnClick(subject)}
                          >
                            <img
                              draggable="false"
                              className="Subject-Button-Img"
                              src={subject?.thumbnail}
                              alt={subject?.display_name}
                              onError={(e) => {
                                e.target.src = require('../../Images/DefaultImage/video_default_thumb.png')
                              }}
                            />
                            <div className="Subject-Button-Img-Title">
                              {subject.display_name}
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </Container>
              </div>
              :
              <div style={{ height: "100vh" }}>
                <SubjectBody
                  data={data}
                />
              </div>
          }
        </>
        :
        <ESaralLoader />

  );
};

export default Subject;
