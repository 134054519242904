import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { AxiosClient } from '../../Axios/AxiosClient.js';
import { BACKEND_URLS } from '../../../URL/URL.js';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import succedImg from '../../../Images/RaiseQuery/succeed.png'



function RaiseQueryPopUpBox({ show, close }) {
    const name = JSON.parse(localStorage.getItem('user'))?.user?.full_name
    const messageRef = useRef(null)
    const navigate = useNavigate()
    const [refernceId, setRefernceId] = useState()

    function SubmitTicket(message) {
        AxiosClient.post(
            BACKEND_URLS.RAISE_TICKET, {
            "message": message
        }
        ).then(
            (res) => {
                if (res.data.success) {
                    setRefernceId(res.data.data.reference_id)
                }
            }
        ).catch(
            (err) => {
            }
        )
    }

    function QueryDone() {
        setRefernceId()
        close()
    }

    return (
        <Modal show={show} fullscreen='sm-down'>
            {
                refernceId
                    ?
                    <Modal.Body className='d-flex justify-content-center align-items-center'>

                        <RxCross2 size={30} onClick={QueryDone} className='Psudo-Button my-4 float-end' style={{
                            position: "absolute",
                            top: '0',
                            right: "20px",
                        }} />


                        <div className='text-center '>
                            <img  
                            draggable="false" 
                            src={succedImg} 
                            alt='success' 
                            loading='lazy' 
                            style={{ width: '50%', }} 
                            onError={(e) => {
                                e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                            }}
                            />

                            <div style={{
                                maxWidth: '70%',
                                margin: 'auto',
                            }}>
                                <h4>Successfully Submitted</h4>
                                <p>Your Reference Id is <strong>{refernceId}</strong>.
                                <br />
                                Someone from the eSaral team will reach out to you within 2 days.</p>
                            </div>

                            <Button className='px-3 py-2' style={{ backgroundColor: "#5F46E3", color: 'white', border: 'none', borderRadius: '12px', }}
                                onClick={QueryDone}  >
                                Done
                            </Button>
                        </div>

                    </Modal.Body>
                    :
                    <Modal.Body>

                        <div className='d-flex align-items-center' id='RiseQuery'>
                            <FiArrowLeft size={23} onClick={close} className='Psudo-Button my-4' />
                            &nbsp;
                            Raise a Query
                        </div>

                        <Form onSubmit={(e) => {
                           e.preventDefault()
                        }} style={{
                            height: "84%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}>
                            <div >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                    <strong>Hi, {name}👋</strong>

                                    <p>Please enter your question/query below and feel free to be as elaborate as you can. We’ll make sure that it is answered/solved.</p>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="Doubut-Form-TextArea">
                                    <Form.Control ref={messageRef} as="textarea" rows={6} placeholder='Type your question here...' />
                                </Form.Group>
                            </div>

                            <Button variant="primary" onClick={() => {
                                SubmitTicket(messageRef.current.value)
                            }} style={{
                                width: '100%',
                                backgroundColor: '#5F46E3',
                                borderColor: '#5F46E3',
                            }}
                            >
                                Submit  <FiArrowRight size={20} />
                            </Button>
                        </Form>
                    </Modal.Body>
            }
        </Modal >
    )
}

export default RaiseQueryPopUpBox