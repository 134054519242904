import Navbar from "react-bootstrap/Navbar";
import { Logo } from "../../Constants/Logo/Logo";
import Search from "../../Constants/Search/Search";
import { BiMenuAltLeft } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant";
import { Nav, NavDropdown, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HomeMenuRoutes } from "../../Constants/SideBar/SideBar";
import { FiArrowLeft } from "react-icons/fi";
import { FRONTEND_URLS } from "../../URL/URL.js";




function Header() {
  const { pathname, state } = useLocation();
  const { doubt_id, queries } = useParams();
  const dispatch = useDispatch();
  const enrollments = JSON.parse(localStorage.getItem("user"))?.enrollments;
  const navigate = useNavigate();
  const window_width = window.innerWidth < 773;
  const headerNavigation = queries == 'doubt' ? '/' : queries == 'queries' ? '/mentorship' : -1



  const handleBackButtonClick = () => {
    if (pathname == FRONTEND_URLS.ADMISSION) {
      let text = "your changes are not saved. \n Do you really want to leave this page.";
      if (window.confirm(text) == true) {
        navigate(headerNavigation);
      }
    }
    else {
      navigate(headerNavigation);
    }
  }


  return pathname === FRONTEND_URLS.ALL && window_width ? (
    <></>
  ) : (
    <Navbar
      sticky="top"
      className="Background Header mb-2"
      style={{ display: doubt_id && window_width && "none" }}>
      <div className="container-xxl">
        {HomeMenuRoutes.includes(
          pathname[0] + pathname.substring(1).split("/")[0]
        ) && (
            <Navbar.Brand href="/" className="isDesktop">
              {Logo}
            </Navbar.Brand>
          )}
        {HomeMenuRoutes.includes(
          pathname[0] + pathname.substring(1).split("/")[0]
        ) ? (
          pathname === FRONTEND_URLS.HOME_ROUTE && (
            <Nav
              className="My_target_modal_main"
              onClick={() => {
                dispatch({
                  type: AppReducerConstants.IS_PHONE_DROPDOWN,
                  payload: true,
                });
              }}>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title={
                  enrollments?.find((e) => e.is_current === true)?.course_name
                }
                menuVariant="white">
                {enrollments?.map((enrollment, id) => {
                  return (
                    enrollment.is_current === false && (
                      <NavDropdown.Item key={id}>
                        {enrollment.course_name}
                      </NavDropdown.Item>
                    )
                  );
                })}

                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="#action/3.4"
                  className="d-flex justify-content-center">
                  +
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )

        ) : 
        <div onClick={handleBackButtonClick} className="Psudo-Button d-flex align-items-center">
          <FiArrowLeft
            size={25}
            />
            <div className='SubjectName mx-2'>
            {queries == 'doubt' ? 'Doubt Solving' : queries == 'queries' ? 'Queries' : state?.display_name ?? ''}
            </div>
            </div>
        }
        <Navbar.Collapse
          className="justify-content-end header_link_resp"
          style={{ gap: "1rem" }}>
          <Search />
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
