import React, { useEffect } from 'react';
import LoginBody from '../../Components/Body/Login/LoginBody';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_URLS } from '../../URL/URL.js';


const Login = () => {
    const navigate = useNavigate()
    useEffect(
        () => {
            const access = localStorage.getItem('access') || localStorage.getItem('tkn')
            if(access){
                navigate(FRONTEND_URLS.HOME_ROUTE)
            }
        }, []
    )

    return (
        <LoginBody />
    );
};

export default Login;