import { FRONTEND_URLS } from "../URL/URL.js"

export function startClass(item, navigate, url = FRONTEND_URLS.LIVE) {

    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.user?.user_id
    const subcourse_id = user?.enrollments.filter(enrollment => enrollment.is_current)[0]?.subcourses?.filter(subcourse => subcourse.is_current)[0]?.id || null
    const full_name = user?.user?.full_name

    const userName = `${full_name.replace(/_/g, "")}_${userId}_${subcourse_id}`;

    const state = {
        zoom: item.keys,
        meetingDetails: {
            mn: item.stream_url,
            pwd: item.password,
            name: userName ?? '',
            role: 0,
        },
        url: url,
    }

    navigate(FRONTEND_URLS.LIVE + `/zoom`, { state: state })
}