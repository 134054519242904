import Modal from 'react-bootstrap/Modal';
import '../../CSS/Quiz.css'
import { useSelector } from 'react-redux';

function ConfirmationPopup(props) {
    const questions = useSelector(state => state.test.quiz_details?.questions )
    const totalQue = questions?.length
    const attemptQue = questions?.filter((question) => question.attempt_state === "ANSWERED").length;

    return (
        <Modal
            className="ConfirmationPopup"
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body >
                <div className="Quiz-Conifrem">
                    Are you sure you want to Submit?
                </div>

                <div className="Quiz-Conifrem-subheading">You have attempted {attemptQue} out of {totalQue} questions.</div>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <button type="button" className="btn btn-primary"
                        onClick={() => {
                            props.onHide()
                            props.onsubmitFun()
                        }}
                    >Submit</button>
                    <button type="button" className="btn btn-outline-primary" onClick={props.onHide} >Cancel</button>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default ConfirmationPopup;
