import React, { useEffect, useState } from "react";
import LottieImage from "../LottieImage/LottieImage.js";
import paymentDone from "../../Images/PDone/paymentDone.js";
import pFail from "../../Images/PFail/PFail.js";
import { AxiosClient } from "../Axios/AxiosClient.js";
import { BACKEND_URLS, FRONTEND_URLS } from "../../URL/URL.js";
import axios from "axios";
import ESaralLoader from "../Spinner/ESaralLoader.js";
import SecondaryButton from "../../Test/components/buttons/SecondaryButton.js";
import PrimaryButton from "../../Test/components/buttons/PrimaryButton.js";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentStatus = () => {
    const [status, setStatus] = useState()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(
        () => {
            const Inst_id = sessionStorage.getItem('Inst_id')

            if (Inst_id) {
                AxiosClient.post(BACKEND_URLS.PAYMENT_STATUS,
                    {
                        "payment_request_id": Inst_id
                    },
                ).then(
                    (res) => {
                        if (res.data.success) {
                            setStatus(res.data.data.payment_status)
                        }
                        
                    }
                ).catch(
                    (err) => {
                    }
                )
            }

        }, []
    )


    return (
            <div className="d-flex justify-content-center align-items-center overflow-hidden" style={{
                height: '100vh',
                width: '100vw',
            }}>
                <div className="h-50 w-75 d-flex flex-column align-items-center" >
                    {
                        status
                            ?
                            <>
                                <LottieImage animationData={status == 'Completed' ? paymentDone : pFail} />

                                <div className="payment-successful my-3">Payment is {status == 'Completed' ? "Completed" : 'fail'}</div>

                                <div className="buttons-for-payment">
                                    <PrimaryButton name='Home' onClick={()=> {navigate(FRONTEND_URLS.HOME_ROUTE, {replace:true })}}/>
                                    {status != 'Completed' && <PrimaryButton name='Retry' onClick={() => { navigate(-1,  {replace:true }) }}/>}
                                </div>
                            </>
                            :
                            <ESaralLoader />
                    }
                </div>
            </div>
    )
}

export default PaymentStatus