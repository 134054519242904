import React from 'react'

function VideoThumbnail({ thumbnail }) {
  return (
    <img
      draggable="false"
      className='chapter-img'
      src={thumbnail}
      alt=''
      onError={(e) => {
        e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
      }}
    />
  )
}

export default VideoThumbnail