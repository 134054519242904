import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { admissionFormKeys, calculateFieldPercentage, categories, genderArr, updateAdmissionFormDetails } from '../../../helpers/admissionForm';
import { useSelector } from 'react-redux';
import ButtonGroup from './ButtonGroup';
import { useDispatch } from 'react-redux';
import { admissionReducerConstant } from '../../../Redux/ReducerConstants/admissionReducerConstant';
import AutoHideAlert from '../../Alerts/AutoHideAlert';
import { FiEdit2 } from "react-icons/fi";
import { handleUpload } from '../../Functions/Functions';
import { mediaBaseUrl } from '../../../URL/URL';
import { BiLoader } from 'react-icons/bi';
import ESaralLoader from '../../Spinner/ESaralLoader';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';





function AdmissionFormPersonalDetails() {
    const dispatch = useDispatch()

    const imageRef = useRef()

    const admissionFormData = useSelector(state => state.admission.data);
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [imageLoading, setImageLoading] = useState(false)


    const [formData, setFormData] = useState({
        name: admissionFormData.first_name,
        dob: admissionFormData.date_of_birth,
        phone_number: admissionFormData.phone_number,
        isSelfWhatsappSame: admissionFormData.phone_number == admissionFormData.self_whatsapp,
        selfWhatsappNumber: admissionFormData.self_whatsapp,
        alternateNumber: admissionFormData.self_phone,
        category: categories[admissionFormData.category],
        gender: genderArr[admissionFormData.gender],
        isPWD: admissionFormData.is_pwd,
        fatherName: admissionFormData.father_name,
        fatherNumber: admissionFormData.father_phone,
        isFatherWhatsappSame: admissionFormData.father_phone == admissionFormData.father_whatsapp,
        fatherWhatsappNumber: admissionFormData.father_whatsapp,
        fatherEmail: admissionFormData.father_email,
        motherName: admissionFormData.mother_name,
        motherNumber: admissionFormData.mother_phone,
        isMotherWhatsappSame: admissionFormData.mother_phone == admissionFormData.mother_whatsapp,
        motherWhatsappNumber: admissionFormData.mother_whatsapp,
        motherEmail: admissionFormData.mother_email,
        profileImage: admissionFormData.profile_image
    });

    const [errors, setErrors] = useState({});
    const errorRefs = useRef({});

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (name.includes('Number')) {
            value = value.replace(/\D/g, '')
            if (value.length > 10) {
                value = value.substring(0, 10);
            }
        }

        if (name.toLowerCase().includes('name') ){
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelfWhatsappNumber = () => {
        const isSame = !formData.isSelfWhatsappSame;
        setFormData(prevState => ({
            ...prevState,
            isSelfWhatsappSame: isSame,
            selfWhatsappNumber: isSame ? admissionFormData.phone_number : ''
        }));
    };

    const handleFatherWhatsappNumber = () => {
        const isSame = !formData.isFatherWhatsappSame;
        setFormData(prevState => ({
            ...prevState,
            isFatherWhatsappSame: isSame,
            fatherWhatsappNumber: isSame ? formData.fatherNumber : ''
        }));
    };

    const handleMotherWhatsappNumber = () => {
        const isSame = !formData.isMotherWhatsappSame;
        setFormData(prevState => ({
            ...prevState,
            isMotherWhatsappSame: isSame,
            motherWhatsappNumber: isSame ? formData.motherNumber : ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        const regexPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!formData.name) {
            newErrors.name = 'Name is required';
            setMessage('Name is required')
            handleAlert()
        }
        if (!formData.gender) {
            newErrors.gender = 'Gender is required';
            setMessage('Gender is required')
            handleAlert()
        }
        if (!formData.phone_number) {
            newErrors.phone_number = 'Phone number is required';
            setMessage('Phone number is required')
            handleAlert()
        }
        if (!formData.alternateNumber) {
            newErrors.alternateNumber = 'Alternate number is required';
            setMessage('Alternate number is required')
            handleAlert()
        }
        if (!formData.selfWhatsappNumber && !formData.isSelfWhatsappSame) {
            newErrors.selfWhatsappNumber = 'Whatsapp number is required';
            setMessage('Whatsapp number is required')
            handleAlert()
        }
        if (!formData.dob) {
            newErrors.dob = 'Date of birth is required';
            setMessage('Date of birth is required')
            handleAlert()
        }
        if (!formData.category) {
            newErrors.category = 'Category is required';
            setMessage('Category is required')
            handleAlert()
        }
        if (!formData.fatherName) {
            newErrors.fatherName = 'Father\'s name is required';
            setMessage('Father\'s name is required')
            handleAlert()
        }
        if (!formData.fatherNumber) {
            newErrors.fatherNumber = 'Father\'s number is required';
            setMessage('Father\'s number is required')
            handleAlert()
        }
        if (!formData.fatherWhatsappNumber) {
            newErrors.fatherWhatsappNumber = 'Father\'s whatsapp number is required';
            setMessage('Father\'s whatsapp number is required')
            handleAlert()
        }
        if (!formData.motherName) {
            newErrors.motherName = 'Mother\'s name is required';
            setMessage('Mother\'s name is required')
            handleAlert()
        }
        if (!formData.motherNumber) {
            newErrors.motherNumber = 'Mother\'s number is required';
            setMessage('Mother\'s number is required')
            handleAlert()
        }
        if (!formData.motherWhatsappNumber) {
            newErrors.motherWhatsappNumber = 'Mother\'s whatsapp number is required';
            setMessage('Mother\'s whatsapp number is required')
            handleAlert()
        }

        if (formData.alternateNumber?.length != 10) {
            newErrors.alternateNumber = 'Please enter correct number.'
            setMessage('Please enter correct alternative number')
            handleAlert()
        }

        if (formData.selfWhatsappNumber?.length != 10 && !formData.isSelfWhatsappSame) {
            newErrors.selfWhatsappNumber = 'Please enter correct number.'
            setMessage('Please enter correct whatsapp number')
            handleAlert()
        }

        if (formData.fatherNumber?.length != 10) {
            newErrors.fatherNumber = 'Please enter correct number.'
            setMessage('Please enter correct father number')
            handleAlert()
        }

        if (formData.motherNumber?.length != 10) {
            newErrors.motherNumber = 'Please enter correct number.'
            setMessage('Please enter correct mother number')
            handleAlert()
        }

        if (formData.fatherWhatsappNumber?.length != 10 && !formData.isFatherWhatsappSame) {
            newErrors.fatherWhatsappNumber = 'Please enter correct number.'
            setMessage('Please enter correct father whatsapp number')
            handleAlert()
        }

        if (formData.motherWhatsappNumber?.length != 10 && !formData.isMotherWhatsappSame) {
            newErrors.motherWhatsappNumber = 'Please enter correct number.'
            setMessage('Please enter correct mother whatsapp number')
            handleAlert()
        }

        if (formData.fatherEmail) {
            if (!regexPattern.test(formData.fatherEmail)) {
                newErrors.fatherEmail = 'Please enter correct father email address.'
                setMessage('Please enter correct father email address.')
                handleAlert()
            }
        }

        if (formData.motherEmail) {
            if (!regexPattern.test(formData.motherEmail)) {
                newErrors.motherEmail = 'Please enter correct mother email address.'
                setMessage('Please enter correct mother email address.')
                handleAlert()
            }
        }


        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            const firstErrorKey = Object.keys(newErrors)[0];
            if (errorRefs.current[firstErrorKey]) {
                // errorRefs.current[firstErrorKey].scrollIntoView({ behavior: 'smooth' });
            }
        }

        return Object.keys(newErrors).length === 0;
    };

    const handleUpdateData = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const formInfo = {
                [admissionFormKeys.FIRST_NAME]: formData.name,
                [admissionFormKeys.GENDER]: formData.gender,
                [admissionFormKeys.SELF_PHONE]: formData.alternateNumber,
                [admissionFormKeys.SELF_WHATSAPP]: formData.selfWhatsappNumber,
                [admissionFormKeys.DATE_OF_BIRTH]: formData.dob,
                [admissionFormKeys.CATEGORY]: formData.category,
                [admissionFormKeys.IS_PWD]: formData.isPWD,
                [admissionFormKeys.FATHER_NAME]: formData.fatherName,
                [admissionFormKeys.FATHER_PHONE]: formData.fatherNumber,
                [admissionFormKeys.FATHER_WHATSAPP]: formData.fatherWhatsappNumber,
                [admissionFormKeys.FATHER_EMAIL]: formData.fatherEmail,
                [admissionFormKeys.MOTHER_NAME]: formData.motherName,
                [admissionFormKeys.MOTHER_PHONE]: formData.motherNumber,
                [admissionFormKeys.MOTHER_WHATSAPP]: formData.motherWhatsappNumber,
                [admissionFormKeys.MOTHER_EMAIL]: formData.motherEmail,
                [admissionFormKeys.PROFILE_IMAGE]: formData.profileImage
            }

            const percentage = calculateFieldPercentage(formInfo)
            dispatch({ type: admissionReducerConstant.COMPELTE_PERCENTAGE, payload: percentage })
            formInfo[admissionFormKeys.USER_PROFILE_PROGRESS] = percentage

            const data = await updateAdmissionFormDetails(formInfo);
            if (data.success) {
                dispatch({ type: admissionReducerConstant.PAGE, payload: 2 })
            } else {
                handleSetShowAlertModal({ dispatch, message: 'Please try after some time. There is issue.'})
            }
        } catch (error) {
        }
    };


    const handleAlert = () => {
        setShowAlert(true)
        setTimeout(
            () => {
                setShowAlert(false)
            }, 2000
        )
    }

    async function handleImageUpload(e) {
        setImageLoading(true)
        const files = Array.from(e.target.files)
        if (files.length > 1) {
            handleSetShowAlertModal({ dispatch, message: 'you allow maximum 1 images to upload.'})
            return
        }
        const urls = await handleUpload({
            files: files, dispatch
        });
        setImageLoading(false)
        setFormData(prevState => ({
            ...prevState,
            profileImage: urls[0]
        }))
    }

    return (
        <>
            <div style={{
                position: 'fixed',
                right: 10,
                top: 10,
                zIndex: 10000,
                width: 'auto'
            }}>
                <AutoHideAlert message={message} setShow={setShowAlert} show={showAlert} key={100} />
            </div>

            <Form.Group className="mb-3" controlId="formBasicName" ref={el => errorRefs.current.name = el}>
                <Form.Label>Click Your Photo</Form.Label>
                <div className='d-flex align-items-center'>
                    <div className='position-relative me-5'>
                        {
                            imageLoading
                                ?
                                <div className="student_img"
                                    style={{
                                        height: '300px',
                                        width: '300px',
                                        borderRadius: '50%',
                                    }}>
                                    <ESaralLoader />
                                </div>
                                :
                                <img
                                    src={formData.profileImage ? formData.profileImage.includes(mediaBaseUrl) ? formData.profileImage : mediaBaseUrl + formData.profileImage : require('../../../Images/img_all/Male_Student.png')}
                                    onError={(e) => {
                                        e.target.src = require('../../../Images/img_all/Male_Student.png');
                                    }}
                                    className="student_img"
                                    style={{
                                        height: '300px',
                                        width: '300px',
                                        borderRadius: '50%',
                                    }}
                                    alt="Mentor"
                                />
                        }
                        <div className="edit-image-btn" onClick={() => imageRef.current.click()}>
                            <FiEdit2 className="camera-icon" />
                            <input
                                ref={imageRef}
                                type="file"
                                className="file-input"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                        </div>
                    </div>
                    <div>
                        <div><strong>Photo Guidelines</strong></div>
                        <div>Plain Background</div>
                        <div>Clear Face Visibility</div>
                        <div>Formal Attire</div>
                    </div>
                </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName" ref={el => errorRefs.current.name = el}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.name}
                    style={errors.name ? { borderColor: 'red' } : {}}

                />
                {errors.name && <div style={{ color: 'red' }}>* {errors.name}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicGender" ref={el => errorRefs.current.gender = el}>
                <Form.Label>Gender</Form.Label>
                <Form.Select
                    name="gender"
                    onChange={handleChange}
                    value={formData.gender}
                    isInvalid={!!errors.gender}
                    style={errors.gender ? { borderColor: 'red' } : {}}
                >
                    <option>---select your gender---</option>
                    {Object.keys(genderArr).map((gender, id) => (
                        <option key={id} value={gender}>{genderArr[gender]}</option>
                    ))}
                </Form.Select>
                {errors.gender && <div style={{ color: 'red' }}>* {errors.gender}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPhoneNumber" ref={el => errorRefs.current.phone_number = el}>
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter your phone number"
                    disabled
                    value={formData.phone_number}
                    isInvalid={!!errors.phone_number}
                    style={errors.phone_number ? { borderColor: 'red' } : {}}
                />
                {errors.phone_number && <div style={{ color: 'red' }}>* {errors.phone_number}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicAlternateNumber" ref={el => errorRefs.current.alternateNumber = el}>
                <Form.Label>Alternate Contact Number</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Enter alternate phone number"
                    name="alternateNumber"
                    onChange={handleChange}
                    value={formData.alternateNumber}
                    isInvalid={!!errors.alternateNumber}
                    style={errors.alternateNumber ? { borderColor: 'red' } : {}}
                />
                {errors.alternateNumber && <div style={{ color: 'red' }}>* {errors.alternateNumber}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Same as contact number (whatsapp)."
                    name="isSelfWhatsappSame"
                    defaultChecked={formData.isSelfWhatsappSame}
                    onClick={handleSelfWhatsappNumber}
                />
            </Form.Group>

            {!formData.isSelfWhatsappSame && (
                <Form.Group className="mb-3" controlId="formBasicWhatsappNumber" ref={el => errorRefs.current.selfWhatsappNumber = el}>
                    <Form.Label>Whatsapp Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter whatsapp number"
                        name="selfWhatsappNumber"
                        onChange={handleChange}
                        value={formData.selfWhatsappNumber}
                        isInvalid={!!errors.selfWhatsappNumber}
                        style={errors.selfWhatsappNumber ? { borderColor: 'red' } : {}}
                    />
                    {errors.selfWhatsappNumber && <div style={{ color: 'red' }}>* {errors.selfWhatsappNumber}</div>}
                </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formBasicDOB" ref={el => errorRefs.current.dob = el}>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                    type="date"
                    name="dob"
                    onChange={handleChange}
                    value={formData.dob}
                    required
                    isInvalid={!!errors.dob}
                    style={errors.dob ? { borderColor: 'red' } : {}}
                />
                {errors.dob && <div style={{ color: 'red' }}>* {errors.dob}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCategory" ref={el => errorRefs.current.category = el}>
                <Form.Label>Category</Form.Label>
                <Form.Select
                    name="category"
                    onChange={handleChange}
                    value={formData.category}
                    required
                    isInvalid={!!errors.category}
                    style={errors.category ? { borderColor: 'red' } : {}}
                >
                    <option>---select category---</option>
                    {Object.keys(categories).map((category, id) => (
                        <option key={id} value={category}>{categories[category]}</option>
                    ))}
                </Form.Select>
                {errors.category && <div style={{ color: 'red' }}>* {errors.category}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPWD">
                <Form.Check type="checkbox" label="is_pwd" name="isPWD" onChange={handleChange} defaultChecked={formData.isPWD} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicFatherName" ref={el => errorRefs.current.fatherName = el}>
                <Form.Label>Father's Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Father's Name"
                    name="fatherName"
                    value={formData.fatherName}
                    onChange={handleChange}
                    isInvalid={!!errors.fatherName}
                    style={errors.fatherName ? { borderColor: 'red' } : {}}
                />
                {errors.fatherName && <div style={{ color: 'red' }}>* {errors.fatherName}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicFatherNumber" ref={el => errorRefs.current.fatherNumber = el}>
                <Form.Label>Father's Number</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Father's Number"
                    name="fatherNumber"
                    value={formData.fatherNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.fatherNumber}
                    style={errors.fatherNumber ? { borderColor: 'red' } : {}}
                />
                {errors.fatherNumber && <div style={{ color: 'red' }}>* {errors.fatherNumber}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Same as contact number (whatsapp)."
                    name="isFatherWhatsappSame"
                    defaultChecked={formData.isFatherWhatsappSame}
                    onClick={handleFatherWhatsappNumber}
                />
            </Form.Group>
            {!formData.isFatherWhatsappSame && (
                <Form.Group className="mb-3" controlId="formBasicFatherWhatsappNumber" ref={el => errorRefs.current.fatherWhatsappNumber = el}>
                    <Form.Label>Father's Whatsapp Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Father's Whatsapp Number"
                        name="fatherWhatsappNumber"
                        value={formData.fatherWhatsappNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.fatherWhatsappNumber}
                        style={errors.fatherWhatsappNumber ? { borderColor: 'red' } : {}}
                    />
                    {errors.fatherWhatsappNumber && <div style={{ color: 'red' }}>* {errors.fatherWhatsappNumber}</div>}
                </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formBasicFatherEmail" ref={el => errorRefs.current.fatherEmail = el}>
                <Form.Label>Father's Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Father's Email"
                    name="fatherEmail"
                    value={formData.fatherEmail}
                    onChange={handleChange}
                    isInvalid={!!errors.fatherEmail}
                    style={errors.fatherEmail ? { borderColor: 'red' } : {}}
                />
                {errors.fatherEmail && <div style={{ color: 'red' }}>* {errors.fatherEmail}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMotherName" ref={el => errorRefs.current.motherName = el}>
                <Form.Label>Mother's Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Mother's Name"
                    name="motherName"
                    value={formData.motherName}
                    onChange={handleChange}
                    isInvalid={!!errors.motherName}
                    style={errors.motherName ? { borderColor: 'red' } : {}}
                />
                {errors.motherName && <div style={{ color: 'red' }}>* {errors.motherName}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMotherNumber" ref={el => errorRefs.current.motherNumber = el}>
                <Form.Label>Mother's Number</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="Mother's Number"
                    name="motherNumber"
                    value={formData.motherNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.motherNumber}
                    style={errors.motherNumber ? { borderColor: 'red' } : {}}
                />
                {errors.motherNumber && <div style={{ color: 'red' }}>* {errors.motherNumber}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Same as contact number (whatsapp)."
                    name="isMotherWhatsappSame"
                    defaultChecked={formData.isMotherWhatsappSame}
                    onClick={handleMotherWhatsappNumber}
                />
            </Form.Group>
            {!formData.isMotherWhatsappSame && (
                <Form.Group className="mb-3" controlId="formBasicMotherWhatsappNumber" ref={el => errorRefs.current.motherWhatsappNumber = el}>
                    <Form.Label>Mother's Whatsapp Number</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Mother's Whatsapp Number"
                        name="motherWhatsappNumber"
                        value={formData.motherWhatsappNumber}
                        onChange={handleChange}
                        isInvalid={!!errors.motherWhatsappNumber}
                        style={errors.motherWhatsappNumber ? { borderColor: 'red' } : {}}
                    />
                    {errors.motherWhatsappNumber && <div style={{ color: 'red' }}>* {errors.motherWhatsappNumber}</div>}
                </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formBasicMotherEmail" ref={el => errorRefs.current.motherEmail = el}>
                <Form.Label>Mother's Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Mother's Email"
                    name="motherEmail"
                    value={formData.motherEmail}
                    onChange={handleChange}
                    isInvalid={!!errors.motherEmail}
                    style={errors.motherEmail ? { borderColor: 'red' } : {}}
                />
                {errors.motherEmail && <div style={{ color: 'red' }}>* {errors.motherEmail}</div>}
            </Form.Group>

            <ButtonGroup primaryFun={handleUpdateData} />
        </>
    );
}

export default AdmissionFormPersonalDetails;
