import { memo, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { Link } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import { Col, Container, Row } from "react-bootstrap";
import { LoginPageConstantLines } from "../../../Constants/LoginLines/LoginPageConstantLines";
import MainAlert from "../../Alerts/MainAlert";



function LoginFormOtp({ OTP, setOTP, verifiOTP, number, OTPSender, nonce }) {

  const [seconds, setSeconds] = useState(60);
  const [isNumber, setIsNumber] = useState(false);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        }
        clearInterval(timer);
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer); // Clear the interval on component unmount
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    verifiOTP();
  }
  
  
  const handleOtpChange = (otp) => {
    if (otp.length < 4) {
      setIsNumber(false)      
      const OtpBoxes = document.getElementsByClassName('Otp_Box');
      for (let i = 0; i < OtpBoxes.length; i++) {
          OtpBoxes[i].style.border = "";
      }
    }
    else {
      setIsNumber(nonce ? false : true);
    }
  }

  return (
    <Container style={{}}>
      <Row
        className="d-flex justify-content-center m-auto"
        style={{
          maxHeight: "720px",
          height: "100%",
        }}>
        <Col sm={12} md={8}>
          <Form
            className="d-flex flex-column"
            style={{
              height: "100vh",
              justifyContent:'center'
            }}
            noValidate
            validated={false}
            onSubmit={handleSubmit}>
            <Form.Group
              className="d-flex flex-column"
              controlId="formBasicEmail">
              <div className="mt-5">
                <Form.Label className="Form-heading1">
                  {LoginPageConstantLines.otp.heading}
                </Form.Label>
                <br />
                <Form.Text className="Form-heading3">
                  {LoginPageConstantLines.otp.subTitle} {number}
                </Form.Text>
              </div>
              <OTPInput
                value={OTP}
                onChange={(otp) => {
                  setOTP(otp)
                  handleOtpChange(otp)
                }
                }
                numInputs={4}
                inputType="number"
                inputStyle="Otp_Box"
                containerStyle="Otp_Conta"
                shouldAutoFocus={true}
                autocomplete="one-time-code"
                renderInput={(props) => <input {...props} />}
              />

              {isNumber && (
                <div className="d-flex align-items-center warn">
                  <FiAlertCircle /> &nbsp; Please enter a valid OTP
                </div>
              )}

              <Form.Text muted>
                {LoginPageConstantLines.otp.resendOtp}{" "}
                {seconds > 30 ? (
                  seconds - 32 < 10 ? (
                    `0${seconds - 30}`
                  ) : (
                    seconds - 30
                  )
                ) : (
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      OTPSender(number);
                    }}
                    className="otp-box-but">
                    Resend
                  </Link>
                )}
              </Form.Text>
              {seconds === 0 && (
                <Form.Text muted>
                  {LoginPageConstantLines.otp.issue}{" "}
                  <a
                    href="https://www.esaral.com/contact"
                    className="otp-box-but">
                    Contact Us
                  </a>
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default memo(LoginFormOtp);
