import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant';
import AdmissionBodyStart from './AdmissionBodyStart';
import { Offcanvas } from 'react-bootstrap';

function AdmissionForm() {

  const isAdmissionForm = useSelector(state => state.app.isAdmissionForm)
  const dispatch = useDispatch()

  const onHide = () => {
    dispatch({ type: AppReducerConstants.IS_ADMISSION_FORM })
  }


  return (
    <Offcanvas
      show={isAdmissionForm}
      onHide={onHide}
      placement="bottom"
      backdrop={true}>
      <Offcanvas.Header>
        <Offcanvas.Title>🎓 Welcome to eSaral</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="OffcanvasBody p-3 Background">
        <AdmissionBodyStart />
      </Offcanvas.Body>
    </Offcanvas>
  );
}


export default AdmissionForm