import React from "react";
import "./swiper.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";

function SwiperComp({ children, direction = "horizontal" }) {
  return (
    <Swiper
      direction={direction}
      breakpoints={{
        320: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        425: {
          slidesPerView: 1.6,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }}
      freeMode={true}
      modules={[FreeMode, Pagination]}
      autoHeight={true}>
      {children}
    </Swiper>
  );
}

export default SwiperComp;
