import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant.js';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { UpdateData } from '../../../DB/Function.js';

const SubjectSiderBarContetnt = ({ ChapterNames, LeacturesUpdater = () => {} }) => {
  const liveRecordeddata = useSelector(state => state.app.liveRecordeddata)
  const [liveChaptersName, setLiveChapterNames] = useState([])
  const [defaulTab, setDefaultTab] = useState('Live')
  const [chapters, setChapters] = useState([])
  const [activeChapter, setActiveChapter] = useState(0)
  const dispatch = useDispatch()

  const tab = useSelector((state) => state.app.tab)?.split('-')[2];


  useEffect(
    () => {

      const data = [];
      const nodeIds = []
      if (liveRecordeddata?.length > 0) {

        for (let index = 0; index < liveRecordeddata?.length; index++) {
          const element = liveRecordeddata[index];
          const chapterId = element.chapter_id;

          if (chapterId && !nodeIds.includes(chapterId)) {
            data.push({
              node_id: chapterId,
              display_name: element.chapter
            });
            nodeIds.push(chapterId);
          }
        }
      }

      if (data.length > 0) {
        setLiveChapterNames(data)
        setChapters(data)
        ContentChangerFun(0, data[0].node_id);
      }
      else {
        setDefaultTab('Lecture')
        setChapters(ChapterNames)
        dispatch({ type: AppReducerConstants.IS_TOGGLE, payload: false });
      }

      return () => {
        dispatch({ type: AppReducerConstants.IS_TOGGLE, payload: true });
      }
    }, []
  )

  function ContentChangerFun(id, node_id) {
    setActiveChapter(id);
    LeacturesUpdater(id);
    dispatch({ type: AppReducerConstants.TAB, payload: 'fill-tab-'+tab });
    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });

    dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
    dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });
    dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
    
    const newLectureLiveData = [];

    if ( !!liveRecordeddata && liveRecordeddata.length > 0) {
      const FilterChapters = [...liveRecordeddata]?.filter((cls) => cls?.chapter_id === node_id)
      for (let index = 0; index < FilterChapters.length; index++) {
        const element = FilterChapters[index];
        if (element?.recorded_node.length) {
          const recordedNodes = element.recorded_node.map((item) => ({
            content_data: {
              available_from: 0,
              available_till: 0,
              ...item,
              content_type: "VIDEO"
            },
            content_id: item.content_id,
            content_info: item.content_info,
            duration: item.content_info.duration,
            encrypted_file_url: item.content_info.encrypted_file_url,
            name: item.content_info.name,
            original_file_url: item.content_info.original_file_url,
            pdf_password: item.content_info.pdf_password,
            thumbnail: item.content_info.thumbnail,
            video_id: item.content_info.video_id,
            video_status: item.content_info.video_status,
            content_type: item.content_type,
            node_id: item.node_id,
            description: "",
            display_name: item.content_info.name,
            is_free: false,
            is_shareable: false,
            long_name: item.content_info.name,
            node_link_id: null,
            node_name: item.content_info.name,
            node_type: "CONTENT",
            order: null,
            parent_node_id: null,
            total_duration: item.content_info.duration,
          }));
          newLectureLiveData.push(...recordedNodes);
        }
        if (element?.session_pdf) {
          const sessionPDFs = element.session_pdf.map((item) => ({
            content_data: {
              available_from: 0,
              available_till: 0,
              ...item,
              content_type: "PDF"
            },
            content_id: item.content_id,
            content_info: item.content_info,
            duration: item.content_info.duration,
            encrypted_file_url: item.content_info.encrypted_file_url,
            name: item.content_info.name,
            original_file_url: item.content_info.original_file_url,
            pdf_password: item.content_info.pdf_password,
            thumbnail: item.content_info.thumbnail,
            video_id: item.content_info.video_id,
            video_status: item.content_info.video_status,
            content_type: item.content_type,
            node_id: item.node_id,
            description: "",
            display_name: item.content_info.name,
            is_free: false,
            is_shareable: false,
            long_name: item.content_info.name,
            node_link_id: null,
            node_name: item.content_info.name,
            node_type: "CONTENT",
            order: null,
            parent_node_id: null,
            total_duration: item.content_info.duration,
          }));
          newLectureLiveData.push(...sessionPDFs);
        }
      }
    }
    dispatch({ type: AppReducerConstants.LIVE_CHAPTER_DATA, payload: newLectureLiveData })

    UpdateData(); // send track data to server.
  }

  const handleChange = (e) => {
    ContentChangerFun(0, ChapterNames[0]?.node_id);
    setDefaultTab(e.target.id.split('-')[2])
    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });
    dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });
    dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
    dispatch({ type: AppReducerConstants.SCROLL_HEIGHT, payload: 80 });
    dispatch({ type: AppReducerConstants.IS_PDF, payload: null });

    if (e.target.id === 'fill-tab-Live') {
      setChapters(liveChaptersName)
      dispatch({ type: AppReducerConstants.IS_TOGGLE, payload: true });
    }
    else {
      setChapters(ChapterNames)
      dispatch({ type: AppReducerConstants.IS_TOGGLE, payload: false });
    }
  }

  return (
    <>
      {
        <Tabs
          variant="underline"
          defaultActiveKey={defaulTab}
          activeKey={defaulTab}
          id="fill"
          style={{
            width: "100%",
          }}
          fill
          onClick={handleChange}
        >
          {liveChaptersName.length > 0 && <Tab eventKey="Live" title="Live" />}
          <Tab eventKey="Lecture" title="Lecture" />
        </Tabs>
      }

      <div className='Home-Scroll-Content py-2 ps-0 Background'>
        {
          chapters.length > 0
            ?
            chapters?.map(
              (ChapterName, id) => {
                return (
                  <React.Fragment key={id}>
                    <div key={id} onClick={() => { ContentChangerFun(id, ChapterName.node_id) }} style={{
                      display: "flex",
                      alignItems: "center",
                    }} className={`Home-menu-item Psudo-Button ${id === activeChapter ? "menu-item-active" : ""}`}>

                      {id === activeChapter && <div className="active-item"></div>}

                      <div style={{
                        paddingRight: '20px',
                      }}></div>
                      <div style={{
                        padding: '10px 0px',
                      }}>
                        {ChapterName?.display_name}
                      </div>
                    </div>
                    <hr className="hr-class" />
                  </React.Fragment>
                )
              }
            )
            :
            <div style={{
              display: "flex",
              alignItems: "center",
              height: '100%',
              justifyContent: "center"
            }}>
              No chapters find.
            </div>
        }
      </div>
    </>
  );
};

export default SubjectSiderBarContetnt;