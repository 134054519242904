import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { TestReducersConstants } from '../../../../Redux/Test/TestReducersConstants';

const primaryColor = "#5F46E3";
const isSkipped = '#E6E7E9';
const isCorrect = '#E1F9E3';
const isWrong = '#FFE8DC';

function VSQno({ qno, isActive, attempt_state, is_correct, question_no }) {
    const dispatch = useDispatch()

    return (
        <div id="Qno-wrapper">
            <div id='Qno' className='d-flex justify-content-center align-items-center m-2 Psudo-Button' style={{
                backgroundColor: isActive ? primaryColor : (attempt_state === "SKIPPED") ? isSkipped : (attempt_state === "ANSWERED" && is_correct) ? isCorrect : isWrong,
            }} onClick={() => {
                dispatch({ type: TestReducersConstants.ACTIVE_INDEX, payload: qno })
            }}>
                {question_no}
            </div>
        </div>
    )
}

export default memo(VSQno)