import React from 'react';
import { SearchImageLines, searchImages } from '../../Constants/Search/SearchImgLines';



const NotFound = ({message}:{message?: string}) => {
    return (
        <div style={{
            width: '100%',
        }} className='d-flex flex-column align-items-center'>
            <img  draggable="false" className='Search-Image' src={searchImages.NotFound} alt='' />
            <div className='text-center '> {message?.length > 0 ? message : SearchImageLines.ContentNotReady}</div>
        </div>
    );
};

export default NotFound;