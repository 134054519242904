import React, { useEffect, useRef } from 'react';
import { Form, Navbar } from 'react-bootstrap';
import { FiArrowLeft  } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { FRONTEND_URLS } from '../../URL/URL';

const SearchHeader = () => {
    const navigate = useNavigate();
    const { search_id } = useParams();
    const searchRef = useRef(null);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.value = search_id ? search_id : ""; // Set the initial value here
        }
    }, []);

    return (
        <Navbar sticky="top" className='Background Header Search' >
            <div className='container-xxl ' >
                <div className='d-flex align-items-center'>
                    <FiArrowLeft  size={25} className='me-3 Psudo-Button' onClick={() => {
                        navigate(FRONTEND_URLS.HOME_ROUTE)
                    }} />
                    <Form className='d-flex' onSubmit={(e) => {
                        e.preventDefault()
                        navigate(FRONTEND_URLS.SEARCH_ROUTE + '/' + searchRef.current.value)
                    }} onBlur={(e) => {
                        e.preventDefault()
                        navigate(FRONTEND_URLS.SEARCH_ROUTE + '/' + searchRef.current.value)
                    }}>
                        <Form.Control ref={searchRef} className='Search-Header' placeholder='Search here' autoFocus />
                    </Form>
                </div>
            </div>
        </Navbar>
    );
};

export default SearchHeader;