import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";


const MatchesItem = ({ questionId, options, matches, answered, isSubmitted }) => {
    const dispatch = useDispatch()
    const option_lables = ["(A)", "(B)", "(C)", "(D)", "(E)", "(F)", "(G)"];
    const match_lable = ["(P)", "(Q)", "(R)", "(S)", "(T)", "(U)", "(V)"];

    const onOptionChange = (opIndex, mtIndex) => {
        dispatch({ type: userPracticeConstants.UPDATE_PRACTICE_DATA, question_id: questionId, opIndex, mtIndex })
    }

    return (
        <Table className="text-center" style={{ width: "auto" }}>
            <thead><tr>
                <td></td>
                {matches.map((_, i) => <td className="p-3" key={i}>{match_lable[i]}</td>)}
            </tr></thead>
            <tbody>
                {
                    options.map((option, opIndex) => <tr key={opIndex}>
                        <td className="p-3">{option_lables[opIndex]}</td>
                        {matches.map((_, mtIndex) => {
                            return <td key={mtIndex} className="p-3">
                                <Form.Check
                                    disabled={isSubmitted || answered}
                                    type="checkbox"
                                    id={`match-box${opIndex}${mtIndex}`}
                                    checked={answered ? _.match_option_indexes.includes(opIndex) : _.selected_option_indexes.includes(opIndex)}
                                    onChange={() => onOptionChange(opIndex, mtIndex)}
                                />
                            </td>
                        })}
                    </tr>)
                }
            </tbody>
        </Table>
    )
}

export default MatchesItem;