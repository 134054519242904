import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ResultHeader from './Header/ResultHeader.js'
import ResultContent from './Content/ResultContent.js'
import ResultTable from './Table/ResultTable.js'
import { ResultDataFetcher } from '../../functions/Functions.js'
import { ViewSolution } from './View Solution/ViewSolution.js'
import { Spinner } from 'react-bootstrap'



function Result() {
  const { quiz_attempt_id, state } = useParams()
  const dispatch = useDispatch()
  const data = useSelector(state => state.test)


  useEffect(
    () => {
      ResultDataFetcher(dispatch, quiz_attempt_id)
    }, []
  )

  if (state == "solution") {
    return <ViewSolution />
  }

  return (
    <div id='Quiz' className='quiz-grid CommanQuizElement Result'>
      {
        data.student_attempt?.quiz_attempts
          ?
          <>
            <ResultHeader />
            <ResultTable />
            <ResultContent />
          </>
          :
          <Spinner />
      }
    </div>
  )
}

export default Result