import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { AlertReducersConstants } from '../../Redux/ReducerConstants/AlertReducersConstants';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function MainAlert() {

    const dispatch = useDispatch()
    const message = useSelector(state => state.alert.message)
    const isAlert = useSelector(state => state.alert.isAlert)
    const variant = useSelector(state => state.alert.variant)

    const handleSetShow = () => dispatch({ type: AlertReducersConstants.IS_ALERT, paylaod: false })


    useEffect(
        () => {
            setTimeout(
                () => {
                    handleSetShow()
                }, 2000
            )
        }, [message]
    )

    if (isAlert) {
        return (
            <div style={{
                position: 'fixed',
                right: 20,
                top: 20,
                zIndex:10000
            }}>
                <Alert variant={variant} onClose={handleSetShow} dismissible>
                    {message}
                </Alert>
            </div>
        );
    }

}

export default MainAlert;