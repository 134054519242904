export function formatEpochTimeRange(startTimestamp, endTimestamp) {
    // Convert epoch timestamps to JavaScript Date objects
    const startDate = new Date(startTimestamp * 1000);
    const endDate = new Date(endTimestamp * 1000);

    // Format the time in 12-hour format with AM/PM
    const startTime = startDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    const endTime = endDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });

    // Create the desired format
    const formattedTimeRange = `${startTime} - ${endTime}`;

    return formattedTimeRange;
}