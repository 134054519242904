import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import SubjectBody from "../../Components/Body/Subject/SubjectBody";
import { AxiosClient } from "../../Components/Axios/AxiosClient";
import { BACKEND_URLS } from "../../URL/URL";
import { useParams } from "react-router-dom";
import ESaralLoader from "../../Components/Spinner/ESaralLoader.js";
import SubjectHeader from "../../Components/Header/SubjectHeader.js";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant.js";

const ESubject = () => {

  const { exam_node_id } = useParams();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [name, setName] = useState();

  useEffect(() => {
    let ignore = false;
    setData(null);

    AxiosClient.post(BACKEND_URLS.SUBJECT_DATA_API, {
      node_id: Number(exam_node_id),
    }).then((result) => {
      if (!ignore && result.data.success) {
        localStorage.setItem("@subject", JSON.stringify(result.data.data));
        setData(result.data.data);
        setName(result.data.data.node_content_tree.display_name);
      }
    });

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <>
      {data ?
        <>
          <SubjectHeader name={name} />
          <SubjectBody data={data?.node_content_tree} />
        </>
        :
        <ESaralLoader />
      }
    </>
  );
};

export default ESubject;
