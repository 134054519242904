import React, { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { BASE2, FRONTEND_URLS } from '../../URL/URL';


function Redirect() {

    const path = useLocation()
    const access = localStorage.getItem("access")  || localStorage.getItem('tkn');
    const navigate = useNavigate()


    useEffect(
        () => {
            if (path.pathname === '/student/student-report') {
                window.location.replace(BASE2 + path.pathname + path.search)
            }

            else {
                if (access) {
                    navigate(FRONTEND_URLS.HOME_ROUTE)
                }
                else {
                    navigate(FRONTEND_URLS.LOGIN_ROUTE)
                    window.location.reload()
                }
            }
        }, [path.pathname]
    )


    return (
        <div className='d-flex justify-content-center align-items-center'>Redirecting...</div>
    )
}

export default Redirect