import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

/**
 * 
 * @mess "takes the message which shown.",
 * @modalShow -------------
 * @setModalShow |--------this two are the arguments which control the modal.
 * @returns 
 */

function InformationModal(props) {
    return (
        <Modal
            {...props}
            size="xs"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            style={{
                width: '250px',
                margin: 'auto',
            }}>
            <Modal.Body >
                <div>
                    <strong>
                        {props.mess}
                    </strong>
                </div>
                <div className='d-flex flex-row-reverse'>
                    <Button onClick={props.onHide}>Close</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InformationModal