import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_URLS } from '../../URL/URL';

const SIZE = 30
const Search = () => {
    const navigate = useNavigate()
    return (
        <FiSearch size={SIZE} className='Psudo-Button' onClick={() => {
            navigate(FRONTEND_URLS.SEARCH_ROUTE)
          }}/>
    );
};

export default Search;