export const RegisterPageConstantLines = {
  name: {
    subTitle: "Let’s customize your eSaral journey",
    subTitle2: "What’s your name?",
    placholder: "Enter your name here",
    warning1: "Please remove special Characters.",
    warning2: "First enter the name.",
  },
  AttemptYear: {
    subTitle: "Let’s customize your eSaral journey",
    subTitle1: "I am studying in class",
  },
  Exam: {
    subTitle: "Let’s customize your eSaral journey",
    subTitle1: "I am preparing for",
  },
};
