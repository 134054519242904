import React, { useState, useEffect } from 'react';
import { BASE2 } from '../../../URL/URL';
import ESaralLoader from '../../Spinner/ESaralLoader';

const ProgressReport = () => {
  const [loading, setLoading] = useState(true);

  const user_id = JSON.parse(localStorage.getItem('user'))?.user?.user_id;
  const user_name = JSON.parse(localStorage.getItem('user'))?.user?.full_name;
  const url = `${BASE2}/student/student-report?student_id=${user_id}&student_name=${user_name}`;

  useEffect(() => {
    const iframe = document.getElementById('progress-report-iframe');
    const handleLoad = () => {
      setLoading(false);
    };
    iframe.addEventListener('load', handleLoad);
    return () => {
      iframe.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div style={{ height: "92vh", position: 'relative' }}>
      {loading && <ESaralLoader />}
      <iframe
        id="progress-report-iframe"
        src={url}
        width="100%"
        height="100%"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default ProgressReport;
