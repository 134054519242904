import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import HomeBody from "../../Components/Body/Home/HomeBody";
import MSideBar from "../../Components/SideBar/MSideBar";
import PhoneHomeDropDown from "../../Components/Body/Home/PhoneHomeDropDown";
import { FRONTEND_URLS } from "../../URL/URL";
import { useNavigate } from "react-router-dom";
import { getBrowserVersion } from "../../helpers/helperFunctions";
import { MdOutlineRotate90DegreesCw } from "react-icons/md";




const Home = () => {

  const navigate = useNavigate()
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [isPortrait, setIsPortrait] = useState(0) // 0 for mobile, 1 for tablet, 2 for desktop

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  function checkAndAlignPortrait() {
    console.log({ height, width })
    if (width < 775 && height > width && height >= 775) {
      setIsPortrait(1)
    }
    else if (width < 775 && height < 775) {
      setIsPortrait(0)
    }
    else {
      setIsPortrait(2)
    }
  }

  useEffect(
    () => {
      if (!getBrowserVersion()) {
        alert(`you'r browser is not updated. Please use latest browser or update your browser. or contact support.`)
        localStorage.clear()
        window.location.href = FRONTEND_URLS.LOGIN_ROUTE
      }
      if (!('indexedDB' in window)) {
        alert(`you'r browser is not supported by us. Please use latest browser. or contact support.`)
        localStorage.clear()
        navigate(FRONTEND_URLS.LOGIN_ROUTE)
      }



    }, []
  )

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    checkAndAlignPortrait();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimensions]);

  return (
    <>
      {
        isPortrait == 1
          ?
          <div style={{
            width: "100vw",
            height: "100vh",
            overflow: 'hidden',
            backgroundColor: '#f0f0f0',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
            padding: '20px',
            boxSizing: 'border-box'
          }}>
            <div style={{
              fontSize: '24px',
              marginBottom: '20px',
              fontWeight: 'bold',
              letterSpacing: '1px'
            }}>
              Rotate Your Device
            </div>
              <img src={require('../../Images/rotate-phone.png')} />
            <div style={{
              fontSize: '18px',
              marginTop: '20px',
              color: '#555',
              maxWidth: '300px'
            }}>
              For the best experience, please rotate your device to landscape mode.
            </div>
          </div>
          :
          isPortrait == 0
            ?
            <div style={{
              width: "100vw",
              height: "100vh",
              overflow: 'hidden',
              backgroundColor: '#f5f5f5',
              color: '#333',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              fontFamily: 'Arial, sans-serif',
              textAlign: 'center',
              padding: '20px',
              boxSizing: 'border-box'
            }}>
              <div style={{
                fontSize: '28px',
                fontWeight: 'bold',
                color: '#d9534f',
                marginBottom: '20px'
              }}>
                Smartphone Not Supported
              </div>
              <div style={{
                fontSize: '18px',
                color: '#555',
                marginBottom: '30px',
                maxWidth: '400px'
              }}>
                We currently do not support smartphones. For the best experience, please install our app from the Play Store.
              </div>
              <a href="https://play.google.com/store/apps/details?id=com.esaral.exam.preparation" target="_blank" rel="noopener noreferrer" style={{
                backgroundColor: '#0d6efd',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                textDecoration: 'none',
                fontWeight: 'bold',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s, transform 0.3s'
              }}>
                Get the App on Play Store
              </a>
              <div style={{
                fontSize: '14px',
                color: '#999',
                marginTop: '20px'
              }}>
                Thank you for understanding!
              </div>
            </div>

            :
            <>
              <PhoneHomeDropDown />
              <MSideBar />
              <Header />
              <HomeBody />
            </>
      }
    </>
  );
};

export default Home;
