import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import playBut from '../../../Images/VideoPlay/play button.png'
import { convertDuration, isSubCourseExperied } from '../../../Components/Body/Function/Function.js'
import { useNavigate, useParams } from 'react-router-dom'
import { FRONTEND_URLS } from '../../../URL/URL.js'
import { FiLock } from 'react-icons/fi'
import Lock from '../../../Components/Lock/Lock.js'
import IsFreeOrNot from '../../../Components/ContentLock/IsFreeOrNot/IsFreeOrNot.js'
import VideoTime from '../../../Components/ContentLock/VideoTime/VideoTime.js'
import VideoThumbnail from '../../../Components/ContentLock/VideoThumbnail/VideoThumbnail.js'
import { useDispatch } from 'react-redux'
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant.js'
import { VideoFun, videoShow } from '../../../Components/Functions/Functions.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { lockText } from '../../../Components/Lock/LockText.js'
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js'


function ChapterCard({ item, id, node_id }) {
    const { subject_node_id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()


    return (
        <>
            {/*--------------------Whole Card---------------*/}
            < div className='Background my-3' id={id} >

                {/*--------------------Chapter Heading---------------*/}
                < div className='d-flex justify-content-between align-items-center Psudo-Button' onClick={() => {
                    navigate(FRONTEND_URLS.SUBJECT_ROUTE + '/' + subject_node_id + '/' + node_id)
                }}>
                    <div className='px-3 py-2'>
                        <div className='chapter-list-header-name'>{item?.display_name}</div>
                        <div className='chapter-list-header-subdomain'>Lectures: {item?.content?.learn.length}</div>
                    </div>
                    <IoIosArrowForward size={20} color='rgba(51, 58, 71, 1)' />
                </div >

                {/*--------------------Video List---------------*/}
                < div className='d-flex overflow-x-auto' >
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 10,
                            },
                            425: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            }
                        }}
                        freeMode={true}
                        modules={[FreeMode]}
                    >
                        {
                            item?.content?.learn.map(
                                (ele, id) => {
                                    return (
                                        <SwiperSlide key={id} className='m-2 position-relative Psudo-Button' onClick={() => {
                                            if (isSubCourseExperied() == false) {
                                                if (ele?.content_data?.content_type.toUpperCase() === "VIDEO") {
                                                    videoShow({ video_id: ele?.content_data?.content_info?.video_id, navigate, node_id, subject_node_id, dispatch })
                                                }
                                            }
                                            else {
                                                handleSetShowAlertModal({ dispatch, message: lockText.mess1 })
                                            }
                                        }
                                        }>
                                            <>
                                                <>
                                                    <IsFreeOrNot is_free={ele?.is_free} />
                                                    <VideoTime duration={ele?.content_data?.content_info?.duration} />
                                                </>
                                                <VideoThumbnail thumbnail={ele?.content_data?.content_info?.thumbnail} />
                                            </>
                                            <div className='chapter-lecture-name text-break py-2'>{ele.display_name}</div>
                                        </SwiperSlide>
                                    )
                                }
                            )
                        }
                        <SwiperSlide></SwiperSlide>

                    </Swiper>
                </div >
            </div >
        </>
    )
}

export default ChapterCard