import React from "react";
import { HomeMenuList } from "../../../Constants/SideBar/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { formatString } from "../../Functions/Functions";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant";
import { version } from "../../../version/version.js";
import { BASE } from "../../../URL/URL.js";

const SiderBarContent = () => {

  const parms = formatString(useLocation().pathname);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div>
        {HomeMenuList.map((item, id) => {
          return (
            <React.Fragment key={id}>
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "center",
                padding:
                  HomeMenuList[id][1] ===
                    parms[0] + parms.substring(1).split("/")[0]
                    ? "0px 0px"
                    : "10px 0px",

              }}
              className={`Home-menu-item Psudo-Button ${HomeMenuList[id][1] === parms[0] + parms.substring(1).split("/")[0]
                ? "menu-item-active"
                : ""
                }`}
              onClick={() => {
                navigate(HomeMenuList[id][2]);
                dispatch({
                  type: AppReducerConstants.IS_PHONE_MENU,
                  payload: false,
                });
              }}>
              {HomeMenuList[id][1] ===
                parms[0] + parms.substring(1).split("/")[0] && (
                  <div className="active-item"></div>
                )}
              <div
                style={{
                  paddingRight: "20px",
                }}></div>
              <div
                style={{
                  paddingRight: "20px",
                }}>
                {item[0]}
              </div>
              <div>{item[1]}</div>
            </div>
            <hr className="hr-class" />
            </React.Fragment>
          );
        })}
      </div>

      <div className="w-100 text-center">
        <span>version {version}</span>
        <div>
          {BASE == "https://development.esaral.com" && "Testing"}
        </div>
      </div>
    </div>
  );
};

export default SiderBarContent;
