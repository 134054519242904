import React, { useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import pencil from "../../../Images/LottieImages/pencil-animation.json";
import { RegisterPageConstantLines } from "../../../Constants/Register/Register";
import { ButtonColors } from "../../../Constants/ButtonColors/ButtonColors";
import { BACKEND_URLS, FRONTEND_URLS } from "../../../URL/URL";
import LottieImage from "../../LottieImage/LottieImage";
import { AxiosClient } from "../../Axios/AxiosClient";
import { Col, Container, Form, Row } from "react-bootstrap";
import { RegisterConstant } from "./RegisterConstant";
import ESaralLoader from "../../Spinner/ESaralLoader.js";

const AttemptYear = () => {
  const [target, setTarget] = useState();
  const navigate = useNavigate();
  const name = useLocation().state?.name || ''

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    let ignore = false;
    setTarget(null);
    AxiosClient.get(BACKEND_URLS.GET_ALL_TARGETS)
      .then((resp) => {
        if (!ignore) {
          setTarget(resp.data.data.classes);
        }
      })
      .catch((err) => {
      });

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="Form-phone Attempt-year-Form">
      {!target ? (
        <>
          <ESaralLoader />
        </>
      ) : (
        <Container style={{height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
         }}>
          <Row
            className="d-flex justify-content-center"
            style={{
              maxHeight: "720px",
              height: "100vh",
            }}>
            <Col sm={12} md={8}>
              <Form
                className="d-flex flex-column"
                style={{
                  height: "100%",
                }}
                noValidate
                validated={false}
                onSubmit={handleSubmit}>
                <Form.Group
                  className="d-flex flex-column"
                  controlId="formBasicEmail">
                  <div className="mt-5">
                    <Form.Text className="Form-heading1">
                      Hi, {name}👋
                    </Form.Text>
                    <br />
                    <Form.Text className="Form-heading3">
                      {RegisterPageConstantLines.name.subTitle}
                    </Form.Text>
                  </div>
                  <div className="Form-lottie">
                    <LottieImage animationData={pencil} Isloop={false} />
                  </div>
                </Form.Group>

                <Container>
                  {target.map((item, key) => {
                    return (
                      key % 2 === 0 && (
                        <Row>
                          {target[key] && (
                            <Col
                              className="Year-Button grid-button Psudo-Button m-2"
                              key={key}
                              style={{
                                border: `1px solid ${
                                  ButtonColors[key % ButtonColors.length]
                                    .background
                                }`,
                                background:
                                  ButtonColors[key % ButtonColors.length]
                                    .BorderColor,
                              }}
                              onClick={() => {
                                navigate(
                                  FRONTEND_URLS.REGISTER_ROUTE +
                                    "/" +
                                    RegisterConstant.EXAM.toLowerCase(),
                                  { state:{ targets: target[key].targets, name}}
                                );
                              }}>
                              {target[key].class_name}
                            </Col>
                          )}
                          {target[key + 1] && (
                            <Col
                              className="Year-Button grid-button Psudo-Button m-2"
                              key={key + 1}
                              style={{
                                border: `1px solid ${
                                  ButtonColors[key + (1 % ButtonColors.length)]
                                    .background
                                }`,
                                background:
                                  ButtonColors[(key + 1) % ButtonColors.length]
                                    .BorderColor,
                              }}
                              onClick={() => {
                                navigate(
                                  FRONTEND_URLS.REGISTER_ROUTE +
                                    "/" +
                                    RegisterConstant.EXAM.toLowerCase(),
                                  { state: target[key + 1].targets }
                                );
                              }}>
                              {target[key + 1].class_name}
                            </Col>
                          )}
                        </Row>
                      )
                    );
                  })}
                </Container>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default AttemptYear;
