import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant.js';

function MessageModal({ message, title, butText = 'Submit', seconday_but='', fun=()=>{}, pri_but_vai='#000', id1='', id2='' }) {
    const show = useSelector(state => state.test.MessageModal)
    const dispatch = useDispatch()

    const setShow = () => {
        dispatch({ type: AppReducerConstants.MESSAGE_MODAL })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={setShow}
                backdrop="static"
                keyboard={false}
                centered
                
            >
                <Modal.Body>
                    <div className='py-3'>
                       <strong> {message} </strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id={id1 ? id1 : ''} variant={pri_but_vai? pri_but_vai :"primary"} type="submit"
                        onClick={ () => {
                            if (fun) {
                                fun()
                            }
                            else {
                                setShow()
                            }
                        }
                        }
                        style={{
                            backgroundColor: pri_but_vai ? '' : '#5F46E3',
                            borderColor: pri_but_vai ? '' : '#5F46E3',
                        }}>
                        {butText}
                    </Button>
                    {
                        seconday_but
                        &&
                        <Button id={id2 ? id2 : ""} variant="light" type="submit"
                        onClick={setShow}
                        >
                        {seconday_but}
                    </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MessageModal;