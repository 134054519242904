export const AppReducerConstants = {
    IS_OVERLAY: 'ISOVERLAY',
    IS_LOGIN: 'IS_LOGIN',
    TAB: 'TAB',
    DOUBUT:"DOUBUT",
    IS_VIDEO: "IS_VIDEO",
    IS_TOGGLE:"IS_TOGGLE",
    IS_PHONE_MENU : "IS_PHONE_MENU",
    MENU_NUMBER : "MENU_NUMBER",
    IS_PHONE_DROPDOWN : "IS_PHONE_DROPDOWN",
    DOUBUT_SOLVE_MENU : "DOUBUT_SOLVE_MENU",
    DOUBUT_SOLVE_MENU_COUNT : "DOUBUT_SOLVE_MENU_COUNT",
    ALL_MENTERS_DATA : "ALL_MENTERS_DATA",
    RESET: "RESET",
    COURSE_MENU: 'COURSE_MENU',
    TREACK: "TREACK",
    CONTENT_ID:"CONTENT_ID",
    CHAPTER_ID:"CHAPTER_ID",
    SUBJECT_ID:'SUBJECT_ID',
    TRACK_INTERVAL_ID:"TRACK_INTERVAL_ID",
    CLEAR_INTERVAL:'CLEAR_INTERVAL',
    USER_HISTORY:'USER_HISTORY',
    VIDEO_ID:'VIDEO_ID',
    VIDEO_INFO:'VIDEO_INFO',
    SCROLL_HEIGHT:'SCROLL_HEIGHT',
    IS_PDF:'IS_PDF',
    PDF_NAME:'PDF_NAME',
    IS_LOADING: 'IS_LOADING',
    VIDEO_TAB:'VIDEO_TAB',
    VIDEO_STATUS:'VIDEO_STATUS',
    BEFORE_TAB_CHANGE_VIDEO_STATUS:"BEFORE_TAB_CHANGE_VIDEO_STATUS",
    IS_LIVE_DROPDOWN:"IS_LIVE_DROPDOWN",
    LIVE: 'LIVE',
    LIVE_TAB:'LIVE_TAB',
    ZOOM_CONFIG:'ZOOM_CONFIG',
    MESSAGE_MODAL :'MESSAGE_MODAL',
    LIVE_RECORDED_DATA:"LIVE_RECORDED_DATA",
    LIVE_CHAPTER_DATA :"LIVE_CHAPTER_DATA",
    PREVIOUS_PAGE_ADD:"PREVIOUS_PAGE_ADD",
    PREVIOUS_PAGE_REMOVE:"PREVIOUS_PAGE_REMOVE",
    IS_ADMISSION_FORM:'IS_ADMISSION_FORM',
    IS_PLAYER_READY:'IS_PLAYER_READY'
}