import React, { createRef, useState } from "react";
import { handleUpload } from "../../../Functions/Functions";
import { GiCancel } from "react-icons/gi";
import { mediaBaseUrl } from "../../../../URL/URL";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FaRegImage } from "react-icons/fa";
import { BiLoader } from "react-icons/bi";
import { handleSetShowAlertModal } from "../../../../helpers/helperFunctions";
import { useDispatch } from "react-redux";

const DoubutFromUpload = ({
  url,
  setFilePath,
  accept = "image/*",
}) => {
  const fileUpload = createRef();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const angle = 2;
  const shift = 20;

  const handleFile = () => {
    fileUpload.current.click();
  };

  async function handleChange(e) {
    setLoading(true)
    const files = Array.from(e.target.files)
    if (files.length > 5) {
      handleSetShowAlertModal({dispatch, message:'you allow maximum 5 images to upload.'})
      setLoading(false)
      return
    }
    const urls = await handleUpload({
      files: files, dispatch
    });
    setFilePath(urls)
    setLoading(false)
  }


  return (
    <>
      {
        loading
          ?
          <Container className="Preview-Image2">
            <Row>
              <BiLoader />
            </Row>
          </Container>
          :
          url?.length > 0 ? (
            <Container className="Preview-Image2">
              <Row>
                <Col xs={6} className="text-center">
                  <div className="image-container">
                    {url?.map((image, index) => (
                      <img
                        key={index}
                        draggable="false"
                        src={mediaBaseUrl + image}
                        style={{
                          position: "absolute",
                          transform: `translate(${shift * index}px, ${6 * index}px) rotate(${angle * index}deg)`,
                          zIndex: index === hoveredIndex ? url.length : index,
                          transition: "all 0.3s ease-in-out",
                          transform: index === hoveredIndex ? 'none' : `translate(${shift * index}px, -${1 * index}px) rotate(${angle * index}deg)`,
                          maxWidth: index === hoveredIndex ? '150%' : 'auto',  // Adjust as needed
                          maxHeight: index === hoveredIndex ? '150%' : '24vh',  // Adjust as needed
                        }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onError={(e) => { e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png') }}
                      />
                    ))}
                  </div>
                </Col>

                <Col
                  xs={6}
                  style={{
                    color: "#5F46E3",
                  }}
                  className="Psudo-Button m-auto text-center"
                  onClick={() => {
                    setFilePath();
                  }}>
                  Remove Image
                </Col>
              </Row>
            </Container>
          ) : (
            <>
              <Form.Control
                type="file"
                accept={accept}
                ref={fileUpload}
                onChange={handleChange}
                multiple
                hidden
              />
              <div
                onClick={handleFile}
                className={`p-0 m-0 `}>
                <Button
                  variant="outline-dark"
                  type="submit"
                  className="Custom-button-ripple mx-0 px-0"
                  style={{
                    width: "100%",
                  }}>
                  <FaRegImage /> Attach Image
                </Button>
              </div>
            </>
          )}
    </>
  );
};

export default DoubutFromUpload;
