import { decode, encode } from 'base-64';


export function setCookie(key, value, expirationMins, path) {
  const expirationTime = new Date();
  expirationTime.setMilliseconds(expirationMins * 1000 * 60);

  const cookieString =
    encode(key) +
    "=" +
    encode(value) +
    "; expires=" +
    expirationTime.toUTCString() +
    "; path=" +
    (path || "/");
  document.cookie = cookieString;
}

export function getCookie(cookieName) {
  const name = encode(cookieName) + "=";
  const decodedCookie = decodeURIComponent(document.cookie);

  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      const value = cookie.substring(name.length, cookie.length);
      return decode(value);
    }
  }
  return null;
}


