import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FRONTEND_URLS } from '../../../../URL/URL.js'
import { Col, Row } from 'react-bootstrap'
import { ButtonColors } from '../../../../Constants/ButtonColors/ButtonColors.js'



function ListItems({ }) {
    const { paramm, doubt_id } = useParams();
    const navigate = useNavigate()
    const slug = paramm?.queries;
    const items = useSelector(state => state.app.DoubtSolveMenu).find((e) => e.is_true == true)?.data


    return (
        <div >
            {slug === "queries" ? <>
                <div className='overflow-x-hidden ' style={{ height: '80vh' }} >
                    {
                        items?.length > 0
                            ?
                            items?.map(
                                (doubt, key) => {
                                    return (
                                        <Row key={key} style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: doubt.id == doubt_id ? '0px 0px' : '10px 0px',
                                        }}
                                            className={`Home-menu-item Psudo-Button w-100 my-2 ${doubt.id == doubt_id && "menu-item-active"}`}
                                            onClick={() => {
                                                if (doubt.department === "MENTORSHIP") {
                                                    navigate(FRONTEND_URLS.DOUBUT_SOLVE_QUERIES + '/' + doubt.id)
                                                } else {
                                                    navigate(FRONTEND_URLS.DOUBUT_SOLVE + '/' + doubt.id)
                                                }
                                            }}
                                        >
                                            <Col xs={1}>
                                                {doubt.id == doubt_id && <div className="active-item"></div>}
                                            </Col>
                                            <Col xs={3} className='Dobut-Card' style={{
                                                backgroundColor: ButtonColors[key % ButtonColors.length].background,
                                                color: ButtonColors[key % ButtonColors.length].BorderColor
                                            }}>
                                                <strong>{doubt?.comment?.charAt(0).toUpperCase()}</strong>
                                            </Col>
                                            <Col xs={9}>
                                                <div style={{ paddingRight: '20px', }}></div>
                                                <div><strong>{doubt?.comment}</strong></div>
                                            </Col>
                                        </Row>
                                    )
                                }
                            )
                            :
                            <div className='isPhoneAndTab d-flex align-items-center justify-content-center h-100'>
                                you don't have any open doubt 😊.
                            </div>
                    }
                </div>
            </> : <>
                <div className='overflow-x-hidden ' style={{ height: '80vh' }} >
                    {
                        items?.length > 0
                            ?
                            items?.map(
                                (doubt, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Row key={key} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: doubt.id == doubt_id ? '0px 0px' : '10px 0px',
                                            }}
                                                className={`Home-menu-item Psudo-Button w-100 my-2 ${doubt.id == doubt_id && "menu-item-active"}`}
                                                onClick={() => {
                                                    if (doubt.department == "MENTORSHIP") {
                                                        navigate(FRONTEND_URLS.DOUBUT_SOLVE_QUERIES + '/' + doubt.id)

                                                    } else {
                                                        navigate(FRONTEND_URLS.DOUBUT_SOLVE + '/' + doubt.id)
                                                    }
                                                }}
                                            >
                                                <Col xs={1}>
                                                    {doubt.id == doubt_id && <div className="active-item"></div>}
                                                </Col>
                                                <Col xs={3} className='Dobut-Card' style={{
                                                    backgroundColor: ButtonColors[key % ButtonColors.length].background,
                                                    color: ButtonColors[key % ButtonColors.length].BorderColor
                                                }}>
                                                    {doubt.department === "MENTORSHIP" ?
                                                        <strong>{doubt?.comment?.charAt(0).toUpperCase()}</strong>
                                                        :
                                                        <strong>{doubt?.subject_name?.charAt(0).toUpperCase()}</strong>
                                                    }
                                                </Col>
                                                <Col xs={9}>
                                                    <div style={{ paddingRight: '20px', }}></div>


                                                    {doubt.department === "MENTORSHIP" ? <>
                                                        <div><strong>{doubt?.comment}</strong></div>
                                                    </> : <>
                                                        <div><strong>{doubt?.subject_name}</strong></div>
                                                    </>}
                                                    <div>{doubt?.chapter_name}</div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )
                                }
                            )
                            :
                            <div className='isPhoneAndTab d-flex align-items-center justify-content-center h-100'>
                                you don't have any open doubt 😊.
                            </div>
                    }
                </div>
            </>
            }
        </div>
    )
}

export default ListItems


