import React, { useState } from "react";
import Practice from "../Practice";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppReducerConstants } from "../../../../Redux/Reducers/Constants/AppReducerConstant";
import { useParams } from "react-router-dom";
import { addDataInDb } from "../../../../DB/Function.js";
import { VideoFun } from "../../../Functions/Functions.js";
import SubjectLearn from "../../../../Constants/SubjectLearn/SubjectLearn.js";
import ContentTab from "../../../Tabs/ContentTab.js";
import PDFAndVideoHandler from "../../../PDFAndVideoHandler/PDFAndVideoHandler.jsx";

/***
 * @learn 'this is list of all the lectures.'
 * @test 'this is test data.'
 * @revise 'this is the revise data.'
 * @practice 'this is practice.'
 ***/

const SubjectContentBody = ({ nodeId, learn, test, revise, practice }) => {
  
  const dispatch = useDispatch();
  
  const { subject_node_id } = useParams();

  const { tab } = useSelector((state) => state.app);
  const treakIntervalKey = useSelector(state => state.app.treakIntervalKey)

  const content_group = tab?.split("-")[2];
  const content_parent_group = content_group?.charAt(0).toUpperCase() + content_group?.slice(1);

  function showVideo({ video_id, content_id }) {

    VideoFun(video_id, dispatch);
    dispatch({ type: AppReducerConstants.VIDEO_ID, payload: video_id });
    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: true });
    dispatch({ type: AppReducerConstants.IS_PLAYER_READY, payload: true });
    
    onLoadSuccessFun({ content_id, chapter: nodeId })
  }

  function onLoadSuccessFun({ content_id, chapter }) {
    const treakD2 = {
      content: content_id,
      subject: subject_node_id,
      chapter: chapter,
      content_parent_group: content_parent_group,
    }
    if (treakIntervalKey) {
      dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
    }
    dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treakD2) })
  }


  return (
    <div className="ms-xs-0 ms-md-2 overflow-hidden">
      <Container className="p-0 h-100">
        <ContentTab learn={learn} test={test} revise={revise} practice={practice} />

        <PDFAndVideoHandler key={'PDFAndVideoHandler'} />

        {tab === "fill-tab-Learn" ?
          <SubjectLearn
            data={learn}
            showVideo={showVideo}
          />
          : tab === "fill-tab-Practice" ?
            <Practice data={practice} showVideo={showVideo} />
            : tab === "fill-tab-Revise" ?
              <Practice data={revise} showVideo={showVideo} />
              :
              <Practice data={test} showVideo={showVideo} />
        }
      </Container>
    </div>
  );
};

export default SubjectContentBody;
