import { AlertReducersConstants } from "../ReducerConstants/AlertReducersConstants";

const initialState = {
    isAlert: false,
    message: '',
    isAlertModal:false,
    variant: AlertReducersConstants.AlertVariants.SUCCESS,
    heading:'',
    isWaitingWrapper: false
}


const AlertReducers = (state = initialState, action) => {

    switch (action.type) {
        case AlertReducersConstants.IS_ALERT:
            return {
                ...state,
                isAlert: action.payload
            }

        case AlertReducersConstants.IS_ALERT_MODAL:
            return {
                ...state,
                isAlertModal: action.payload
            }

        case AlertReducersConstants.MESSAGE:
            return {
                ...state,
                message: action.payload
            }

        case AlertReducersConstants.VARIANT:
            return {
                ...state,
                variant: action.payload
            }
        
        case AlertReducersConstants.HEADING:
            return {
                ...state,
                heading: action.payload
            }


        case AlertReducersConstants.IS_WAITING_WRAPPER:
            return {
                ...state,
                isWaitingWrapper: action.payload
            }


        default:
            return {
                ...state,
            }

    }

}


export default AlertReducers