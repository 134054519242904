import React from 'react'
import { convertDuration } from '../../Body/Function/Function.js'

function VideoTime({duration}) {
    return (
        <div className='chapter-img position-absolute d-flex justify-content-end align-items-end' style={{ zIndex: '1000', }}>
            <span className='lecture-time'> {convertDuration(duration)} </span>
        </div>

    )
}

export default VideoTime