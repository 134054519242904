import React from 'react'
import { Container } from 'react-bootstrap'
import LottieImage from '../LottieImage/LottieImage'
import PrimaryButton from '../../Test/components/buttons/PrimaryButton'

function CourseEpired({heading='', subheading=''}) {

    const handleKnowMoreClick = () => {
        console.log("handleKnowMoreClick")

    }

    const handleContactSupport = () => {
        console.log("handleContactSupport")
    }

    return (
        <Container className='h-100'>
            <div className='d-flex flex-column align-items-center justify-content-center Background h-100'>
                <div style={{
                    maxWidth: '500px'
                }}>
                    <LottieImage animationData={require('../../Images/LottieImages/expired.json')} />
                </div>
                <div style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#0D1015',
                }}>{heading}</div>
                <div style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '30px',
                    textAlign: 'center',
                    color: '#333A47',
                    maxWidth:'300px'
                }}>
                    {subheading}
                </div>
                {/* <PrimaryButton name='Know More' style={{
                    background: "rgb(95, 70, 227)",
                    color: 'white',
                    width: '189px',
                    height: '48px',
                    borderRadius:'100px'
                }} onClick={handleKnowMoreClick} />
                
                <PrimaryButton name='Contact Support' style={{
                    width: '189px',
                    height: '48px',
                    borderRadius:'100px'
                }} onClick={handleContactSupport} /> */}
            </div>
        </Container>
    )
}

export default CourseEpired