import React from 'react'
import NotFound from '../../Components/Error/NotFound';
import { useSelector } from 'react-redux';
import ListItem from '../../Components/Body/Subject/ListItem';


const SubjectLearn = ({ data, showVideo, }) => {
    const { isVideo } = useSelector((state) => state.app);
    const isToggle = useSelector(state => state.app.isToggle)
    const liveChapterData = useSelector(state => state.app.liveChapterData)
    const lectureLiveData = isToggle ? liveChapterData : data


    return (
        <div style={{ height: "100vh" }}>
            <div className="mb-5" style={{ height: isVideo ? "40%" : '85%' }}>
                <div className="justify-content-lg-center m-0 mb-2 h-100"
                    style={{
                        overflowY: "auto",
                        maxHeight: "95%"
                    }}
                    id='main-page'
                >
                    <div className="mx-0 px-0">
                        {
                            lectureLiveData?.length > 0 ?
                                lectureLiveData?.map((lecture, key) => <ListItem key={key} data={lecture} showVideo={showVideo} />)
                                :
                                <NotFound />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubjectLearn;
