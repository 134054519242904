import React, { useState } from "react";
import Question from "./Question";
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from "react-redux";
import Solution from "./Solution";
import Options from "./Options";
import { userPracticeConstants } from "../../Redux/ReducerConstants/userPracticeConstants";




const PracticeBody = () => {
    const dispatch = useDispatch()
    const userPracticeReducer = useSelector(state => state.userPracticeReducer)
    const [isAns, setIsAns] = useState('')

    const [startTouchXY, setStartTouchXY] = useState({ X: 0, Y: 0 })

    const data = userPracticeReducer.practiceModData
    const currentIndex = userPracticeReducer.currentIndex

    function onTouchEnd(e) {
        const degree = Math.atan((startTouchXY.Y - e.changedTouches[0].clientY) / (startTouchXY.X - e.changedTouches[0].clientX)) * (180 / Math.PI)
        const modDeg = degree < 0 ? (-1 * degree) : degree
        if (modDeg < 30) {

            if (startTouchXY.X - e.changedTouches[0].clientX > 60) {
                currentIndex < data.length - 1 && dispatch({ type: userPracticeConstants.UPDATE_CURRENT_INDEX, index: currentIndex + 1 })
            }

            else if (startTouchXY.X - e.changedTouches[0].clientX < -60) {
                currentIndex > 0 && dispatch({ type: userPracticeConstants.UPDATE_CURRENT_INDEX, index: currentIndex - 1 })
            }
        }
    }

    return (
        <div className="quiz-question">
            <Carousel interval={null} indicators={false} controls={false} activeIndex={currentIndex} onTouchStart={e => { setStartTouchXY({ X: e.changedTouches[0].clientX, Y: e.changedTouches[0].clientY }) }} onTouchEndCapture={onTouchEnd} >
                {data?.map((que) => <Carousel.Item key={que.questionNo} style={{ paddingInline: "1rem" }}>

                    <Question
                        question_type={que.question_type}
                        label={que.question_type_label}
                        paragraph={que.paragraph}
                        instructions={que.instructions}
                        question_text={que.question_text}
                    />
                    <Options data={que} />
                    <br />
                    <Solution data={que} />
                </Carousel.Item>
                )}
            </Carousel>
        </div>
    )
}

export default PracticeBody;