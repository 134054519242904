import React, { memo, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { TestReducersConstants } from '../../../../Redux/Test/TestReducersConstants';
import { RadioButtons } from '../../../../Images/RadioButtons/RadioButtons';
import { CheckBoxs } from '../../../../Images/checkBox/CheckBoxs';
import { handleSetShowAlertModal } from '../../../../helpers/helperFunctions';


function Options({ options, matches, question_type, answer_numerical, question_id, section_id }) {
    const dispatch = useDispatch()
    const quiz_attempt_id = useSelector(state => state.test.student_attempt?.quiz_attempt_id)
    const active_index = useSelector(state => state.test.active_index)
    const question = useSelector(state => state.test.quiz_details?.questions[active_index])
    const sections = useSelector(state => state.test.quiz_details?.sections)
    function isMaxQuestion() {
        const section = sections?.find(section => section?.section_id === section_id);
        return (section?.max_questions > section?.attempt_questions) || (question?.attempt_state == "ANSWERED");
    }

    const [inputValue, setInputValue] = useState(answer_numerical)

    if (question_type === "single-correct") {
        return options?.map(
            (option, id) => {
                return (
                    <button key={id} className='reomve-button-css' onClick={() => {
                        if (!isMaxQuestion()) {
                            handleSetShowAlertModal({ dispatch, message: 'reached limit.' })
                        }
                    }}>
                        <div key={id} className={`d-flex align-items-center m-3 Psudo-Button option-selected ${option?.is_selected && "selected"}`} onClick={() => {
                            if (isMaxQuestion()) {
                                dispatch({ type: TestReducersConstants.IS_ANSWERED, payload: { option_id: option.option_id, quiz_attempt_id: quiz_attempt_id, question_id: question_id } })
                            }
                        }} >
                            <img draggable="false" src={option?.is_selected ? RadioButtons.checked : RadioButtons.unchecked} alt='' style={{ width: '25px' }} />
                            <div className='d-flex align-items-center mx-3 ' dangerouslySetInnerHTML={{ __html: option.option_value }} />
                        </div>
                    </button>
                )
            }
        )
    }

    else if (question_type === "multiple-correct") {
        return options?.map(
            (option, id) => {
                return (
                    <button key={id} className='reomve-button-css' onClick={() => {
                        if (!isMaxQuestion()) {
                            handleSetShowAlertModal({ dispatch, message: 'reached limit.' })
                        }
                    }}>
                        <div key={id} className={`d-flex align-items-center m-3 Psudo-Button option-selected ${option?.is_selected && "selected"}`} onClick={() => {
                            if (isMaxQuestion()) {
                                dispatch({ type: TestReducersConstants.IS_ANSWERED, payload: { option_id: option.option_id, quiz_attempt_id: quiz_attempt_id, question_id: question_id } })
                            }
                        }}  >
                            <img draggable="false" src={option?.is_selected ? CheckBoxs.checked : CheckBoxs.unchecked} alt='' style={{ width: '25px' }} />
                            <div className='d-flex align-items-center mx-3 ' dangerouslySetInnerHTML={{ __html: option.option_value }} />
                        </div>
                    </button>
                )
            }
        )
    }
    else if (question_type === "match-the-column") {
        return <table className='table-class m-3 mt-1' style={{
            width: '30%',
        }}>
            <tbody>
                <tr>
                    <td className='td-class'></td>
                    {
                        matches.map(
                            (i, id) => {
                                return <td key={id} className='td-class'>({String.fromCharCode(80 + id)})</td>
                            }
                        )
                    }
                </tr>
                {
                    options.map(
                        (ele, i) => {
                            return (
                                <tr key={i}>
                                    {
                                        [...Array(matches.length + 1)].map(
                                            (ke, j) => {
                                                return (
                                                    <td className='td-class' key={j}>
                                                        {
                                                            j === 0
                                                                ?
                                                                <>({String.fromCharCode(65 + i)})</>
                                                                :
                                                                <button className='reomve-button-css' onClick={() => {
                                                                    if (!isMaxQuestion()) {
                                                                        handleSetShowAlertModal({ dispatch, message: 'reached limit.' })
                                                                    }
                                                                }}>
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        id={`${i}-${j}`}
                                                                        onChange={(e) => {
                                                                            if (isMaxQuestion()) {
                                                                                dispatch({ type: TestReducersConstants.OPTION_MATCHES, payload: { i: i, j: j - 1, match_id: matches[j - 1].match_id, question_typee: question_type, option_idd: options[i].option_id, quiz_attempt_idd: quiz_attempt_id, question_idd: question_id } })
                                                                            }
                                                                        }
                                                                        }
                                                                        defaultChecked={matches[j - 1].selected_option_indexes.includes(i)}
                                                                    />
                                                                </button>
                                                        }
                                                    </td>
                                                )
                                            }
                                        )
                                    }
                                </tr>
                            )
                        }
                    )
                }
            </tbody>
        </table>
    }
    else if (question_type === "numerical" || question_type === "subjective") {
        return (
            <>
                <button className='reomve-button-css' style={{
                    marginBottom: "10%",
                }} onClick={() => {
                    if (!isMaxQuestion()) {
                        handleSetShowAlertModal({ dispatch, message: 'reached limit.' })
                    }
                }}>
                    <input type='text' className='mx-3 input ' value={inputValue} onChange={(e) => {
                        if (isMaxQuestion()) {
                            setInputValue(e.target.value)
                        }
                    }} onBlur={() => {
                        if (isMaxQuestion()) {
                            dispatch({ type: TestReducersConstants.IS_NUMERICAL, payload: { answer_numerical: inputValue } })
                        }
                    }
                    } />
                </button>
            </>
        )
    }
}


export default memo(Options)