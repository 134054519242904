import { admissionReducerConstant } from "../ReducerConstants/admissionReducerConstant";

const initalState = {
    data: {},
    error: '',
    loading: false,
    compeltePercentage:0,
    page:1
}


const admissionFormReducer = (state = initalState, action) => {

    switch (action.type) {
        case admissionReducerConstant.GET_DATA:
            return {
                ...state,
                data: action.payload,
                compeltePercentage: action.payload.user_profile_progress
            }
        case admissionReducerConstant.Loading:
            return {
                ...state,
                loading: action.payload
            }

        case admissionReducerConstant.ERROR:
            return {
                ...state,
                error: action.payload
            }
        case admissionReducerConstant.COMPELTE_PERCENTAGE:
            return {
                ...state, 
                compeltePercentage: action.payload
            }

        case admissionReducerConstant.PAGE:
            return {
                ...state, 
                page: action.payload
            }

        default:
            return {
                ...state
            }
    }
}



export default admissionFormReducer