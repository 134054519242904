import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import SideBar from "../../SideBar/SideBar";
import HomeContent from "./HomeContent";
import SiderBarContent from "./SiderBarContent";
import { useLocation } from "react-router-dom";
import MyAccount from "./MyAccount";
import Courses from "./Courses";
import Mentorship from "./Mentorship";
import HelpAndSupport from "./HelpAndSupport";
import TimeTable from "../TimeTable/TimeTable";
import Live from "../../../LiveClass/Live.js";
import All from "../../../LiveClass/All.js";
import Faq from "./Faq.js";
import AllSession from "./AllSession.js";
import SessionDetails from "./SessionDetails.js";
import ProgressReport from "../progressreport/ProgressReport.js";
import Admission from "../../Modals/AdmissionForm/Admission.jsx";
import { useDispatch } from "react-redux";
import { admissionReducerConstant } from "../../../Redux/ReducerConstants/admissionReducerConstant.js";
import { getAdmissionFormDetails } from "../../../helpers/admissionForm.js";
import { FRONTEND_URLS } from "../../../URL/URL.js";
import LiveClasses from "../../../LiveClass/LiveClasses.js";





const HomeBody = () => {
  const parms = useLocation().pathname;
  const dispatch = useDispatch()

  const handleGetAdimssionFormData = async () => {
    try {
      const data = await getAdmissionFormDetails()
      if (data.success) {
        dispatch({ type: admissionReducerConstant.GET_DATA, payload: data.data })
      }
      else {
        dispatch({ type: admissionReducerConstant.ERROR, payload: data.errors })
      }
    }
    catch (error) {
      dispatch({ type: admissionReducerConstant.ERROR, payload: error })
    }
    finally {
      dispatch({ type: admissionReducerConstant.Loading, payload: false })
    }
  }

  useEffect(() => {
    handleGetAdimssionFormData()
  }, []);



  return (
    <div className="container-xxl Vertical-gap" >
      <Row>
        <SideBar>
          <SiderBarContent />
        </SideBar>

        <Col
          className="Home-Scroll-Content p-0 pe-md-0 ps-md-2"
          style={{
            position: "relative",
            overflowX: "hidden",
            height: '93vh'
          }}>
          {
            FRONTEND_URLS.MY_ACCOUNT === parms ?
              <MyAccount />
              : FRONTEND_URLS.COURSES === parms ?
                <Courses />
                : FRONTEND_URLS.TIME_TABLE === parms ?
                  <TimeTable />
                  : FRONTEND_URLS.MENTORSHIP === parms ?
                    <Mentorship />
                    : FRONTEND_URLS.HELP_AND_SUPPORT === parms ?
                      <HelpAndSupport />
                      : FRONTEND_URLS.LIVE === parms ?
                        <Live />
                        : FRONTEND_URLS.ALL === parms ?
                          <LiveClasses />
                            :
                            FRONTEND_URLS.LIVEMENTORSHIP === parms
                              ?
                              <LiveClasses />
                              : FRONTEND_URLS.FAQ === parms ?
                                <Faq />
                                : FRONTEND_URLS.ALLSESSION === parms ?
                                  <AllSession />
                                  : FRONTEND_URLS.SESSION_DETAILS === parms ?
                                    <SessionDetails />
                                    : FRONTEND_URLS.PROGRESS_REPORT === parms ?
                                      <ProgressReport />
                                      : FRONTEND_URLS.LIVE === parms ?
                                        <Live />
                                        :
                                        FRONTEND_URLS.ADMISSION === parms || parms.includes(FRONTEND_URLS.ADMISSION)
                                          ?
                                          <Admission />
                                          :
                                          <HomeContent />
          }
        </Col>
      </Row>
    </div>
  );
};

export default HomeBody;
