import React, { useEffect, useRef, useState } from 'react'
import { AxiosClient } from '../../Axios/AxiosClient.js';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../URL/URL.js';
import CourseHeader from './TabAndMobileView/CourseHeader.js';
import CourseInfo from './TabAndMobileView/CourseInfo.js';
import { useNavigate, useParams } from 'react-router-dom';
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant.js';
import { useDispatch, useSelector } from 'react-redux';
import { FiArrowLeft } from 'react-icons/fi'
import ESaralLoader from '../../Spinner/ESaralLoader.js';
import InformationModal from '../../Modals/InformationModal.js';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js';

const BUTTON_HIGHT = 40;

function CourseDetails() {
    const [course, setCourse] = useState()
    const [faculty, setFaculty] = useState()
    const [testimonials, setTestimonials] = useState()
    const { course_id } = useParams()
    const scrollRef = useRef(null)
    const menu = useSelector(state => state.app.CourseMenu)
    const activeMenu = menu?.find(item => item.is_true)?.name
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [modalShow,setModalShow] = useState()

    useEffect(
        () => {

            let ignore = false;
            setCourse(null)
            setFaculty(null)
            setTestimonials(null)

            AxiosClient.post(
                BACKEND_URLS.COURSES_DETAILS, {
                "course_id": course_id
            }
            ).then(
                (res) => {
                    if (!ignore) {
                        if (res.data.success) {
                            setCourse(res.data.data.course)
                            setFaculty(res.data.data.faculty)
                            setTestimonials(res.data.data.testimonials)
                        }
                        else {
                            handleSetShowAlertModal({ dispatch, message: 'something went wrong. Please try after some time.' })
                        }
                    }
                }
            ).catch(
                (err) => {
                }
            )

            return () => {
                ignore = true;
            }
        }, []
    )

    function HightLighter() {
        const CourseDetailsEle = document.getElementById('CourseDetails');
        const courseEle = CourseDetailsEle.scrollTop;

        const sections = document.querySelectorAll('#CourseInfo section');
        const courseImg = document.getElementById('course-img');
        const courseImgHeight = courseImg.clientHeight;

        const courseName = document.getElementById('course-name')

        const windowHight = window.innerHeight;


        if (courseImgHeight >= courseEle) {
            // Img is showing
            courseImg.style.opacity = 1 - (courseEle / courseImgHeight)
            if ((courseEle >= courseImgHeight - 40)) {
                courseName.style.transform = `translateX(${30 * (courseEle / (courseImgHeight))}px)`
                courseName.style.transitionDuration = '.2s'
            }
            else {
                // Img is not showing
                courseName.style.transform = `translateX(0)`
            }

        }

        for (let i = 0; i < sections.length; i++) {
            const { bottom, top } = sections[i].getBoundingClientRect();

            if (((windowHight - top) > BUTTON_HIGHT) && ((windowHight - bottom) < BUTTON_HIGHT) && (activeMenu != menu[i].name)) {
                dispatch({
                    type: AppReducerConstants.COURSE_MENU, payload: i
                })
                break;
            }
        }
    }

    return (
        <>
            {
                course
                    ?
                    <div id='CourseDetails' style={{}} onScroll={HightLighter} >
                        <FiArrowLeft id='back-arrow' size={25} onClick={() => { navigate(FRONTEND_URLS.COURSES) }} className='m-2 my-3 Psudo-Button' style={{
                            position: "absolute",
                            zIndex: '1000',
                        }} />
                        <div>
                            <img
                                draggable={false}
                                id='course-img'
                                src={course.thumbnail.split('?')[0]}
                                alt=''
                                style={{
                                    // height: '25vh',
                                    width: '100%',
                                }}
                                data-translatey='0'
                                onError={(e) => {
                                    e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                                }}
                            />
                        </div>

                        <div >
                            <CourseHeader name={course.name} ref={scrollRef} />
                            <CourseInfo testimonials={testimonials} faculties={faculty} course={course} />
                        </div>

                    </div>
                    :
                    <>
                        <ESaralLoader />
                    </>
            }
        </>
    )
}


export default CourseDetails;