import React from 'react'
import { DefualtImages } from '../../../Constants/DefaultImages/DefaultImages.js'

function AllImageType({data}) {
  return (
    <img  draggable="false" className='' style={{
        width: '100%',
        maxWidth: '44px',
    }}
        src={data?.content_data?.content_type === "PDF"
            ?
            DefualtImages.pdf
            :
            data?.content_data?.content_type === "QUIZ"
                ?
                DefualtImages.quiz
                :
                data?.content_data?.content_type === "VIDEO"
                    ?
                    data?.content_data?.content_info?.thumbnail ?? DefualtImages.img
                    :
                    null} alt='' />
  )
}

export default AllImageType