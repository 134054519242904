export const LoginPageConstantLines = {
    'phoneNumber': {
      'heading': "Enter Phone Number",
      'subTitle':"We’ll send you an OTP for verification",
      'warning': "Incorrect Number."
    },
    'otp': {
      'heading':'Verify OTP',
      'subTitle': 'OTP sent to +91 ', 
      'warning':'OTP is not correct.',
      'resendOtp':'Didn’t recieve the OTP?',
      'issue': 'Still facing difficulties? ',
    }
  }
  