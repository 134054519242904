import { FiCalendar, FiHelpCircle, FiHome, FiUser } from "react-icons/fi";
import { GoBook } from "react-icons/go";
import { PiStudentBold } from "react-icons/pi";
import { BiMessageAltCheck } from "react-icons/bi";
import { FRONTEND_URLS } from "../../URL/URL";

const ICON_Size = 22;

export const HomeMenuList = [
  [<FiHome size={ICON_Size} />, "Home", FRONTEND_URLS.HOME_ROUTE],
  [<FiUser size={ICON_Size} />, "My Account", FRONTEND_URLS.MY_ACCOUNT],
  [<PiStudentBold size={ICON_Size} />, 'Mentorship', FRONTEND_URLS.MENTORSHIP],
  [<BiMessageAltCheck size={ICON_Size} />,"Doubt Solving",FRONTEND_URLS.DOUBUT_SOLVE],
];

export const HomeMenuList2 = [
  [<FiHome size={ICON_Size} />, "Home", FRONTEND_URLS.HOME_ROUTE],
   [<GoBook size={ICON_Size} />, "Mentorship", FRONTEND_URLS.MENTORSHIP],
];

export const HomeMenuNameList = {
  HOME_ROUTE: FRONTEND_URLS.HOME_ROUTE,
  MY_ACCOUNT:FRONTEND_URLS.MY_ACCOUNT,
  COURSES:FRONTEND_URLS.COURSES,
  TIME_TABLE:FRONTEND_URLS.TIME_TABLE,
  MENTORSHIP:FRONTEND_URLS.MENTORSHIP,
  HELP_AND_SUPPORT:FRONTEND_URLS.HELP_AND_SUPPORT,
  LIVE:FRONTEND_URLS.LIVE,
  ALL:FRONTEND_URLS.ALL,
  FAQ:FRONTEND_URLS.FAQ,
  ALLSESSION:FRONTEND_URLS.ALLSESSION,
  SESSION_DETAILS:FRONTEND_URLS.SESSION_DETAILS,
  PROGRESS_REPORT:FRONTEND_URLS.PROGRESS_REPORT,
  LIVEMENTORSHIP:FRONTEND_URLS.LIVEMENTORSHIP,
  ADMISSION:FRONTEND_URLS.ADMISSION
}

export const HomeMenuRoutes = [
  FRONTEND_URLS.HOME_ROUTE,
  FRONTEND_URLS.MY_ACCOUNT,
  FRONTEND_URLS.DOUBUT_SOLVE,
  FRONTEND_URLS.HELP_AND_SUPPORT,
  FRONTEND_URLS.MENTORSHIP
];
