import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { LoginPageConstantLines } from "../../../Constants/LoginLines/LoginPageConstantLines";
import { AiOutlineArrowRight } from "react-icons/ai";
import { createRipple } from "../../Functions/Functions";
import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import flag from "../../../Images/india-flag.png";

function LoginFormNumber({ PhoneRef, OTPSender }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(true);

  const handleInputChange = () => {
    const input = PhoneRef.current.value;
    if (input.length > 10) {
      PhoneRef.current.value = input.slice(0, 10);
    }
    setIsNumberValid(/^[0-9]{10}$/.test(input));
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const input = PhoneRef.current.value;
    if (input === "" || !/^[0-9]{10}$/.test(input)) {
      setIsNumberValid(false);
    } else {
      setIsNumberValid(true);
      OTPSender(input);
    }
  };

  return (
    <Form
      className="d-flex flex-column LoginFormNumber m-auto"
      style={{
        height: "100%",
      }}
      noValidate
      validated={false}
      onSubmit={handleSubmit}>
      <Form.Group className="d-flex flex-column" controlId="formBasicEmail">
        <div className="mt-5">
          <Form.Label className="Form-heading1">
            {LoginPageConstantLines.phoneNumber.heading}
          </Form.Label>
          <br />
          <Form.Text className="Form-heading3">
            {LoginPageConstantLines.phoneNumber.subTitle}
          </Form.Text>
        </div>

        <div className="d-flex mt-5 align-items-center">
          <div
            className="form-control w-auto pe-0 d-flex ps-1 d-flex align-items-center"
            style={{
              border: `2px solid var(--primary-color)`,
              borderColor:
                !isNumberValid && isSubmit ? "#CC2C2C" : "var(--primary-color)",
              borderWidth: "2px 0px 2px 2px",
              borderRadius: "8px 0px 0px 8px",
            }}>
            <span>+91</span> &nbsp;{" "}
            <img src={flag} width={"24px"} height={"18px"} /> &nbsp;{" "}
            <span>|</span>
          </div>
          <Form.Control
            ref={PhoneRef}
            onInput={handleInputChange}
            className=""
            style={{
              borderColor: !isNumberValid && isSubmit ? "#CC2C2C" : "",
              borderWidth: "2px 2px 2px 0px",
              borderRadius: "0px 8px 8px 0px",
            }}
            type="number"
            maxLength="10"
            placeholder="Enter your Number"
            autoFocus
          />
        </div>
        {!isNumberValid && isSubmit && (
          <div className="d-flex align-items-center warn">
            <FiAlertCircle /> &nbsp; Please enter a valid number first
          </div>
        )}
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="mb-5 Custom-button-ripple phoneStyle"
        style={{ backgroundColor: "#5F46E3", borderColor: "#5F46E3" }}
        onClick={(e) => {
          createRipple({ event: e, className: "ripple-primary" });
        }}>
        Continue &nbsp; <AiOutlineArrowRight color="#fff" />
      </Button>
    </Form>
  );
}

export default LoginFormNumber;
