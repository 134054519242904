import React from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { FiArrowLeft  } from 'react-icons/fi';



const HeaderWithState = ({ subject_name, state }) => {

    return (
        <Navbar sticky="top" className='BoxShadow Background'>
            <Navbar.Brand >
                <Container >
                    <Row className='align-items-center'>
                        <Col className='d-flex Psudo-Button'>
                            <FiArrowLeft size={25}  onClick={ state } />
                        </Col>
                        <Col >
                            <div style={{
                                fontSize: "18px",
                            }}>
                                <strong>{subject_name}</strong>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Navbar.Brand>
        </Navbar>
    );
};

export default HeaderWithState;