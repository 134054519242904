import React, { useEffect, useState } from 'react'
import { AxiosClient } from '../../Axios/AxiosClient.js'
import { BACKEND_URLS } from '../../../URL/URL.js'
import { Container } from 'react-bootstrap'
import AllCourses from '../Courses/AllCourses.js'
import ESaralLoader from '../../Spinner/ESaralLoader.js'
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js'
import { useDispatch } from 'react-redux'

const Courses = () => {

  const [courses, setCourses] = useState() 
  const [testimonalies, setTestimonalies] = useState() 
  const dispatch = useDispatch()

  useEffect(
    () => {
      let ignore = false;
      setTestimonalies(null)
      setCourses(null)
      AxiosClient.post(
        BACKEND_URLS.COURSES
      ).then(
        (res) => {
          if (!ignore && res.data.success === true) {
            setTestimonalies(res.data.data.testimonials)
            const data = res.data.data.courses;

            const filterCourses = []
            const targetIds = []

            for (let i = 0; i < data.length; i++) {

              if (targetIds.length === 0) {
                targetIds.push(data[i].target_id)
                filterCourses.push({ target_id: data[i].target_id, target_name: data[i].target_name, data: [data[i]] })
              }
              else {
                const isTargetId = targetIds.indexOf(data[i].target_id)

                if (isTargetId >= 0) {
                  filterCourses[isTargetId].data = [...filterCourses[isTargetId].data, data[i]]
                }
                else {
                  targetIds.push(data[i].target_id)
                  filterCourses.push({ target_id: data[i].target_id, target_name: data[i].target_name, data: [data[i]] })
                }
              }
            }

            setCourses(filterCourses)
          }
          else {
            handleSetShowAlertModal({dispatch, message:res.data.message})
          }
        }
      ).catch(
        (err) => {
        }
      )

      return () => { ignore = true; }
    }, []
  )


  return (
    courses
      ?
      <Container fluid='md' className='Background pt-1 pb-4 mx-0 px-0'>
        <AllCourses courses={courses} testimonalies={testimonalies} />
      </Container>
      :
      <><ESaralLoader /></>
  )
}

export default Courses
