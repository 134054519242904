import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant'
import { FRONTEND_URLS } from '../../../URL/URL'
import PrimaryButtons from '../../Buttons/PrimaryButtons'

function AdmissionBodyStart() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onHide = () => {
        dispatch({ type: AppReducerConstants.IS_ADMISSION_FORM })
    }

    return (
        <>
            <p className='admission-p'>
                Awesome job on kicking off your journey toward
                your dream college! 🚀
            </p>
            <p className='admission-p'>
                Before we start, you need to fill out your admission form. It's super quick!
            </p>
            <p className='admission-p'>
                This way, we can personalise your learning experience just for you.
            </p>
            <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                <PrimaryButtons className='w-100 my-2' onClick={() => {
                    navigate(FRONTEND_URLS.ADMISSION )
                }}>
                    Get Started
                </PrimaryButtons>
                <Link to='' onClick={onHide} style={{
                    textDecoration: 'none',
                    color: 'black'
                }} className='my-2'>
                    I’ll do it later
                </Link>
            </div>
        </>
    )
}

export default AdmissionBodyStart