//---------------------------------------------- Button-colors---------------------------------- //
export const ButtonColors = [
    {
      "background": '#FFB023',
      "BorderColor": '#FFF3DC',
    },
    {
      "background": '#3090E8',
      "BorderColor": '#E5F8FF',
    },{
      "background": '#5F46E3',
      "BorderColor": '#ECE9FF',
    },{
      "background": '#C91E5C',
      "BorderColor": '#FFEAF4',
    },{
      "background": '#D65E25',
      "BorderColor": '#FFE8DC',
    },{
      "background": '#008444',
      "BorderColor": '#E1F9E3',
    },
  ]