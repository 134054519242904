import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppReducerConstants } from "../../../Redux/Reducers/Constants/AppReducerConstant";
import { useNavigate } from "react-router-dom";
import { FRONTEND_URLS } from "../../../URL/URL";

import { AlertReducersConstants } from "../../../Redux/ReducerConstants/AlertReducersConstants";
import { UserData } from "../../Functions/Functions";


const PhoneHomeDropDown = () => {
  const enrollments = JSON.parse(localStorage.getItem("user"))?.enrollments;
  const isPhoneDropdown = useSelector((state) => state.app.isPhoneDropdown);
  const dispatch = useDispatch();
  const navigate = useNavigate(0);

  function HideDropDown() {
    dispatch({
      type: AppReducerConstants.IS_PHONE_DROPDOWN,
      payload: false,
    });
  }

  const handleClick = ({enroll_id, phase_id}) => {
    UserData({
      subcourse_id: enroll_id,
      phase_id: phase_id,
      dispatch
    });
    dispatch({ type: AppReducerConstants.IS_PHONE_DROPDOWN, payload: false});
    dispatch({ type: AlertReducersConstants.IS_WAITING_WRAPPER, payload: true});
  }

  return (
    <Offcanvas
      show={isPhoneDropdown}
      onHide={HideDropDown}
      placement="bottom"
      backdrop={true}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>My Targets</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="OffcanvasBody px-0 py-1">
        {enrollments?.map((enrollment, id) => {
          return (
            <div
              key={id}
              className={`Card-Format  ${
                enrollments.length - 1 !== id && `pb-1`
              } px-0`}>
              {enrollment.subcourses.length > 1 ? (
                <>
                  <div className="px-3 Psudo-Button py-3">
                    {enrollment.course_name}
                  </div>
                  {enrollment.subcourses.map((enroll, id) => {
                    return (
                      <div style={{paddingLeft:"1rem",paddingRight:"1rem"}}
                        key={id}
                        className="Background-Color-1 py-2 mt-1 Psudo-Button d-flex justify-content-between" 
                        onClick={ () => handleClick({enroll_id:enroll.id, phase_id: enrollment.phase_id})}>

                        <div>{enroll.name} </div>
                        {enroll.is_current && (
                          <div className="current">(Current)</div>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  className="px-3 Psudo-Button py-3 d-flex justify-content-between"
                  onClick={() => handleClick({enroll_id: enrollment.subcourses[0].id, phase_id:enrollment.phase_id})}>

                  <div>{enrollment.course_name}</div>
                  {enrollment.is_current === true && (
                    <div className="current"> (Current)</div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </Offcanvas.Body>
      <Offcanvas.Header className="d-flex justify-content-center Psudo-Button MainText">
        <Offcanvas.Title
          onClick={() => {
            HideDropDown();
            navigate(FRONTEND_URLS.NEW_ENROLL);
          }}>
          + Add New
        </Offcanvas.Title>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default PhoneHomeDropDown;
