import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AxiosClient } from '../../../Axios/AxiosClient';
import { BACKEND_URLS } from '../../../../URL/URL';

const FormDropDown = ({subcourse_id, setSubject, setChapter}) => {

    const [data, setData] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);

    useEffect(() => {
        let ignore = false;
        setData([])
        if (subcourse_id) {
            AxiosClient.post(BACKEND_URLS.GET_SUBCOURSE_CHAPTERS, { subcourse_id })
                .then((res) => {
                    if (!ignore && res.data.success) {
                        setData(res.data.data);
                    }
                })
                .catch((err) => {
                });
        }

        return () => { ignore = true }
        
    }, []);

    const handleSubjectChange = (e) => {
        const selectedSubjectId = e.target.value;
        if (selectedSubject !== 'Subject') {
            const chosenSubject = data?.find((subject) => Number(subject.id) === Number(selectedSubjectId))?.chapters;
            setSubject(selectedSubjectId)
            setSelectedSubject(chosenSubject);
        }
        else {
            setSubject()
            setSelectedSubject()
        }
    };

    const handleChapterChange = (e) => {
        const selectedChapterId = e.target.value;
        if ( selectedChapterId !== 'Chapter'){
            setChapter(e.target.value);
        }
        else {
            setChapter()
        }
    }

    return (
        <>
            <Form.Select aria-label="Default select example" onChange={handleSubjectChange}>
                <option value={'Subject'}>Subject</option>
                {data?.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                        {subject.name}
                    </option>
                ))}
            </Form.Select>
            <br />
            { (
                <Form.Select aria-label="Default select example" onChange={handleChapterChange}>
                    <option>Chapter</option>
                    {selectedSubject?.map((chapter) => (
                        <option key={chapter.id} value={chapter.id}>
                            {chapter.name}
                        </option>
                    ))}
                </Form.Select>
            )}
        </>
    );
};

export default FormDropDown;
