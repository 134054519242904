import { combineReducers } from "redux";
import AppReducer from "./Reducers/Reducer";
import TestReducers from "./Test/TestReducers.js";
import userPracticeReducer from './User/userPracticeReducer';
import AlertReducers from "./AlertReducers/index.js";
import admissionFormReducer from "./admission/index.js";
import Mentorship from "./mentorship/index.js";
import dobtReducer from "./doubt/index.js";

const rootReducer = combineReducers({
    app : AppReducer,
    test : TestReducers,
    userPracticeReducer,
    alert: AlertReducers,
    admission: admissionFormReducer,
    mentorship: Mentorship,
    doubt: dobtReducer
})

export default rootReducer