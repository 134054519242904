import React from 'react';
import SearchBody from '../../Components/Body/Search/SearchBody';
import SearchHeader from '../../Components/Header/SearchHeader';

const Search = () => {


    return (
        <>
            <SearchHeader />
            <SearchBody />
        </>
    );
};

export default Search;