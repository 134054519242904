import React, { useEffect, useState } from "react";
import SubjectContentBody from "../../Components/Body/Subject/SubjectContentBody/SubjectContentBody.js";
import { VideoFun } from "../../Components/Functions/Functions.js";
import SubjectHeader from "../../Components/Header/SubjectHeader.js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppReducerConstants } from "../../Redux/Reducers/Constants/AppReducerConstant.js";

function MChapter() {

  const { chapter_node_id } = useParams();

  const ChapterNames = JSON.parse(
    localStorage.getItem("@subject")
  )?.node_content_tree?.find((e) => e.node_id == chapter_node_id);

  const [learn, setlearn] = useState();
  const [practice, setpractice] = useState();
  const [revise, setRevise] = useState();
  const [test, setTest] = useState();
  const [nodeId, setNodeId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setRevise(ChapterNames?.content?.revise);
    setlearn(ChapterNames?.content?.learn);
    setpractice(ChapterNames?.content?.practise);
    setTest(ChapterNames?.content?.test);
    setNodeId(ChapterNames?.node_id);
    dispatch({
      type: AppReducerConstants.CHAPTER_ID,
      payload: ChapterNames?.node_id,
    });
  }, []);

  return (
    <div>
      <SubjectHeader route={-1} name={ChapterNames.display_name} />
      <SubjectContentBody
        chapter_node_id={chapter_node_id}
        nodeId={nodeId}
        learn={learn}
        test={test}
        revise={revise}
        practice={practice}
      />
    </div>
  );
}

export default MChapter;
