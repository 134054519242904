import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppReducerConstants } from '../../../../Redux/Reducers/Constants/AppReducerConstant.js'

function CourseMenuDeskTop() {
    const menu = useSelector(state => state.app.CourseMenu)
    const dispatch = useDispatch()

    function scroller(link, id) {
        const element = document.getElementById(link)
        element.scrollIntoView({ behavior: 'smooth' })
        dispatch({ type: AppReducerConstants.COURSE_MENU, payload: id })
    }


    return (
        <div id='course-toc'>
            <ul className="nav nav-pills Course-Header " id='CourseHeaderDeskTop'>
                <div className='d-flex flex-column justify-content-evenly '
                    style={{ width: '100%' }}
                >
                    {
                        menu.map(
                            (item, id) => {
                                return (
                                    <li key={id} id={id} className="mx-3 my-2" >
                                        <div className={`Psudo-Button ${item.is_true ? 'course-header-active' : 'course-header-inactive'}`}
                                            onClick={() => {
                                                scroller(item.name, id);
                                            }}>
                                            {item.name}
                                        </div>
                                    </li>
                                )
                            }
                        )
                    }
                </div>
            </ul>
        </div>
    )
}

export default CourseMenuDeskTop