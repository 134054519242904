import React, { memo } from 'react'
import PrimaryButton from '../buttons/PrimaryButton.js'
import { useDispatch, useSelector } from 'react-redux'
import { TestReducersConstants } from '../../../Redux/Test/TestReducersConstants.js';


function NextAndPrevBut() {
    const { prev_disable, next_disable } = useSelector((state) => (
        {
            prev_disable: state.test.active_index === 0,
            next_disable: state.test.active_index === (state.test?.quiz_details?.questions?.length - 1)
        }
    ))

    
    const dispatch = useDispatch()


    function onPrev() {
        if (!prev_disable) {
            dispatch({ type: TestReducersConstants.PREV })
        }
    }

    function onNext() {
        if (!next_disable) {
            dispatch({ type: TestReducersConstants.NEXT })
        }
    }

    return (
        <div id='NextAndPrevBut' className='Background p-2'>
            <div className='text-end'>
                <PrimaryButton name='Prev' onClick={onPrev} disabled={prev_disable} />
                <PrimaryButton name='Next' onClick={onNext} disabled={next_disable} />
            </div>
        </div>
    )
}

export default memo(NextAndPrevBut)