import { AxiosClient } from "../../../Components/Axios/AxiosClient"


export const quizServices = {
    getQuizQuestions,
    getRelatedQuizes,
}

async function getRelatedQuizes(quizId) {
    try {
        const res = await AxiosClient(`/v2/general/get-related-quizes?id=${quizId}`)
        const data = res.data
        if (data.success) {
            return data.data
        }
    } catch (error) {
        throw error
    }
}


async function getQuizQuestions(quizId) {
    return AxiosClient(`/v2/quizes/get-quiz-question-details?quiz_id=${quizId}`)
        .then(res => {
            if (res.data.success) {
                return res.data.data
            }
        })
        .catch((err) => { throw err });
}
