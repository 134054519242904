import Alert from 'react-bootstrap/Alert';

function AutoHideAlert({ message, show, setShow }) {
    if (show) {
        return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                    {message}
            </Alert>
        );
    }

}

export default AutoHideAlert;