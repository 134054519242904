import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { BACKEND_URLS, FRONTEND_URLS } from '../../../URL/URL.js'
import AllImageType from './AllImageType.js'
import { AxiosClient } from '../../Axios/AxiosClient.js'
import QuizInstructionModal from '../../../Test/Quiz/QuizInstructionModal.js'
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant.js'
import LottieImage from '../../LottieImage/LottieImage.js'
import videoPlay from '../../../Images/LottieImages/playing image.json'
import playBut from '../../../Images/VideoPlay/play button.png'
import { convertDuration, isSubCourseExperied } from '../Function/Function.js'
import { useDispatch, useSelector } from 'react-redux'
import { FiLock } from 'react-icons/fi'
import { lockText } from '../../Lock/LockText.js'
import { handleSetShowAlertModal, indexedDBStuff } from '../../../helpers/helperFunctions.js'
import { isAccessible } from '../../Functions/Functions.js'
import { AlertReducersConstants } from '../../../Redux/ReducerConstants/AlertReducersConstants.js'
import { UpdateData } from '../../../DB/Function.js'


function ListItem({ data, showVideo = () => { } }) {

    const navigate = useNavigate()
    const [quizID, setquizID] = useState()
    const [InstructionShow, setInstructionShow] = useState(false);
    const [InstructionData, setInstructionData] = useState();
    const dispatch = useDispatch()
    const { videoId } = useSelector(state => state.app)
    const location = useLocation()?.pathname

    function CheckingQuizStatus(quiz_id) {

        AxiosClient.post(
            BACKEND_URLS.CHECK_USER_QUIZ_ATTEMPT,
            {
                "quiz_id": quiz_id
            }
        ).then(
            (res) => {
                if (res.data.success) {
                    dispatch({ type: AlertReducersConstants.IS_WAITING_WRAPPER, payload: false });
                    const quiz_status = res.data.data.status;
                    const latest_attempt_id = res.data.data.latest_attempt_id
                    if (quiz_status === "TEST_ATTEMPTED") {
                        dispatch({ type: AppReducerConstants.PREVIOUS_PAGE_ADD, payload: location })
                        navigate(FRONTEND_URLS.TEST_RESULT + '/' + latest_attempt_id)
                    }
                    else if (quiz_status === "LIMIT_EXHAUSTED") {
                        handleSetShowAlertModal({ dispatch, message: "You exchausted limit." })
                    }
                    else if (["START_TEST", "IN-PROGRESS_STATE"].includes(quiz_status)) {
                        setInstructionShow(true)
                        setInstructionData(data?.content_data?.content_info?.instructions)
                        setquizID(data?.content_data?.content_info?.quiz_id)
                    }

                }
            }
        ).catch(
            (err) => {
            }
        )
    }


    const handleVideoPlay = () => {
        if (videoId !== data?.content_data?.content_info?.video_id) {
            if (data?.content_data?.is_free == true || isSubCourseExperied() == false) {
                dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
                dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });

                dispatch({ type: AppReducerConstants.IS_PLAYER_READY, payload: false });

                dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });

                dispatch({ type: AppReducerConstants.CONTENT_ID, payload: data.content_data.content_id })
                showVideo({ video_id: data?.content_data?.content_info?.video_id, content_id: data?.content_data?.content_id })
            }
            else {
                handleSetShowAlertModal({ dispatch, message: lockText.mess1 })
            }
        }
    }

    const handlePDFAndTest = () => {
        if (data?.content_data?.is_free == true || isSubCourseExperied() == false) {
            dispatch({ type: AppReducerConstants.CONTENT_ID, payload: data.content_data.content_id })
            if (data?.content_data?.content_type?.toUpperCase() === "PDF") {
                dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
                dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
                dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
                dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });
                dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null });

                dispatch({ type: AppReducerConstants.IS_PDF, payload: data?.content_data })
                dispatch({ type: AppReducerConstants.PDF_NAME, payload: data?.display_name })
            }

            else if (data?.content_data?.content_info?.quiz_type?.toUpperCase() === "PRACTICE-QUIZ") {  //"PRACTICE-QUIZ"
                navigate(FRONTEND_URLS.PRACTICE_ROOT, { state: { quiz_id: data?.content_data?.content_info?.quiz_id, display_name: data?.content_data?.display_name } })  // data?.content_data?.content_info?.quiz_id ?? 
            }


            else if (data?.content_data?.content_info?.quiz_type?.toUpperCase() === "QUIZ") {
                const available_from_time = data.available_from
                const available_till_time = data.available_till
                const content_name = data.display_name
                if (isAccessible({ available_from_time, available_till_time, dispatch, content_name })) {
                    dispatch({ type: AlertReducersConstants.IS_WAITING_WRAPPER, payload: true });
                    CheckingQuizStatus(data?.content_data?.content_info?.quiz_id, dispatch)
                }
            }
            else {
                dispatch({ type: AlertReducersConstants.MESSAGE, payload: data?.content_data?.content_info?.content_message })
                dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, payload: true })
            }

        }
        else {
            handleSetShowAlertModal({ dispatch, message: lockText.mess1 })
        }
    }

    const handleContent = () => {
        if(indexedDBStuff({dispatch})) {
            if (data?.content_data?.content_type == "VIDEO") {
                handleVideoPlay()
            }
            else {
                handlePDFAndTest()
            }
        }
        UpdateData(); // send track data to server.
    }

    return (
        // (data.available_till > Date.now()) || (data.available_till == 0) &&
        <>
            <QuizInstructionModal show={InstructionShow} setShow={setInstructionShow} InstructionData={InstructionData} quiz_id={quizID} />
            <Row className='Background my-1 py-3 Psudo-Button chapter-name d-flex align-items-center m-0' style={{
                width: "100%",
                height: "100%",
                backgroundColor: (data?.content_data?.content_type?.toUpperCase() === "PDF") ? '' : (videoId === data?.content_data?.content_info?.video_id) ? "rgb(0 0 0 / 8%)" : '',
                borderRadius: "8px",
                maxHeight: '70px',
                alignContent: 'center'
            }} onClick={handleContent}>
                {
                    data?.content_data?.content_type == "VIDEO"
                        ?
                        <Col xs={4} md={2} className='pe-0 d-flex justify-content-center position-relative '>
                            {
                                (videoId === data?.content_data?.content_info?.video_id)
                                    ?
                                    <>
                                        <div className='Lecture-Img position-absolute p-3' style={{ zIndex: '1000', }}>
                                            <LottieImage animationData={videoPlay} />
                                        </div>
                                        <div className='Lecture-Img position-absolute' style={{ backgroundColor: 'rgba(0, 0, 0, 0.67)', zIndex: '100', }} />
                                    </>
                                    :
                                    <>
                                        <div className='Lecture-Img position-absolute d-flex justify-content-center align-items-center'>
                                            {
                                                data?.is_free == true || isSubCourseExperied() == false
                                                &&
                                                <img draggable="false" src={playBut} className='w-25 h-auto' style={{ zIndex: '1000', }} alt='' />
                                            }
                                        </div>
                                        <div className='Lecture-Img position-absolute d-flex justify-content-end align-items-end' style={{ zIndex: '1000', }}>
                                            <span className='lecture-time'> {convertDuration(data?.content_data?.content_info?.duration)} </span>
                                        </div>
                                    </>
                            }
                            <img
                                draggable="false"
                                className='Lecture-Img'
                                src={data?.content_data?.content_info?.thumbnail}
                                alt=''
                                onError={(e) => {
                                    e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                                }}
                            />
                        </Col>
                        :
                        <Col xs={3} md={2} className='text-center'>
                            <AllImageType data={data} />
                        </Col>
                }
                <Col xs={6} md={8} >
                    {data?.display_name}
                </Col>
                <Col xs={2} md={2}>
                    {
                        !(data?.is_free == true || isSubCourseExperied() == false)
                        && <FiLock className='h-auto' style={{ zIndex: '100000', }} color={'black'} />
                    }
                </Col>
            </Row>
        </>
    )
}

export default ListItem