import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { AlertReducersConstants } from '../../Redux/ReducerConstants/AlertReducersConstants';
import PrimaryButton from '../../Test/components/buttons/PrimaryButton';


function AlertModal(props) {

    const dispatch = useDispatch()
    const message = useSelector(state => state.alert.message)
    const isAlert = useSelector(state => state.alert.isAlertModal)
    // const heading = useSelector(state => state.alert.heading)
    const heading = 'Warning'

    const handleSetShow = () => dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, paylaod: false })

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleSetShow}
            backdrop="static"
            show={isAlert}
            keyboard={false}
        >
            <Modal.Header>
                {/* <Modal.Title> */}
                    {heading}
                    {/* </Modal.Title> */}
                    </Modal.Header>
            <Modal.Body>
           {/* {heading && <h4>{heading}</h4>} */}
            {message && <div className='my-2'>{message}</div>}
                <div className='d-flex justify-content-end'>
                    <PrimaryButton name='Close' onClick={handleSetShow} />
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default AlertModal