import React, { createRef, useState } from 'react';
import { uploadFile } from 'react-s3';
import Form from 'react-bootstrap/Form';
import * as buffer from "buffer"
import { Button } from 'react-bootstrap';
import { GiCancel } from 'react-icons/gi';
import { mediaBaseUrl } from '../../../URL/URL';
import { handleUpload } from '../../Functions/Functions';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';
import { useDispatch } from 'react-redux';


window.Buffer = buffer.Buffer;

/**
 * 
 * @setFilePath {UploadMediaToS3} '***Required*** It is the function which set the path of image at s3. e.g.:- setValue()'
 * @s3Folder {s3Folder} '***Required*** it is the path where we want to add the our content.'
 * @accept {accept} '***Optional*** takes the constraint that what it is take from the user file manager. Basically media type. And by Default it is set to take the images.'
 * @style @className {style, className} '***Optional*** this is are optional if we want to set the style or classname then it is required.
 * @children {children} '***Optional*** this is optional if we do want to add our ui not by default.'
 * @url {url} '***Required*** this is url function of useState(). this is conditional rendering of preview.'
 * @returns 
 */


function UploadPDFToS3({ children = '', url, setFilePath, s3Folder, accept = 'image/*', style = {}, className = '', isDisabled=false }) {
    const fileUpload = createRef()
    const dispatch = useDispatch()

    const handleFile = () => {
        fileUpload.current.click()
    }

    async function handleChange(e) {
        const file_name = Array.from(e.target.files)
        if (file_name.length > 5){
            handleSetShowAlertModal({dispatch, message:'you allow maximum 5 images to upload.'})
            return
        }
        const urls = await handleUpload({ files: file_name, dispatch })
        setFilePath(urls)
    }

    return (
        <>

            {
                url &&
                <div className={`position-absolute top-0 md-6 ms-3 Preview-Image`} >
                    <GiCancel size={30} color='red' className='Psudo-Button ms-2' style={{
                        float: 'right',
                    }} onClick={() => {
                        setFilePath()
                    }} />
                    <img  
                    draggable="false" 
                    src={mediaBaseUrl + url} 
                    style={{ width: '100%' }} 
                    onError={(e) => {
                        e.target.src = require('../../../Images/DefaultImage/video_default_thumb.png')
                    }}
                    />
                </div>
            }

            <Form.Control type="file" accept={accept} ref={fileUpload} onChange={handleChange}  hidden />
            <div onClick={() => {
                handleFile();
            }} className={`p-0 m-0 ${className}`} style={style}>
                {
                    children
                        ?
                        children
                        :
                        <Button variant="primary" type="submit" className='Custom-button-ripple mx-0 px-0' style={{
                            backgroundColor: '#5F46E3',
                            borderColor: '#5F46E3',
                            width: '100%'
                        }}
                        // disabled={isDisabled} 
                        >
                            upload
                        </Button>
                }
            </div>
        </>
    )
}
export default UploadPDFToS3;