import { AxiosClient } from "../../Components/Axios/AxiosClient.js";
import { handleSetShowAlertModal } from "../../helpers/helperFunctions.js";
import { TestReducersConstants } from "../../Redux/Test/TestReducersConstants.js";
import { BACKEND_URLS, FRONTEND_URLS } from "../../URL/URL.js";

export function findLongestList(list1, list2) {
  if (list1.length >= list2.length) {
    return list1.length;
  } else {
    return list2.length;
  }
}


export function questionSubmit({ quiz_id, quiz_attempt_id, answer_numerical, question_id, matches, options }) {
  /**
   * takes this arguments and make a request to save the answer. And the arguments are as follows:-
   * @quiz_id @quiz_attempt_id @answer_numerical @question_id @matches @options 
   */
  AxiosClient.post(
    BACKEND_URLS.QUIZ_STUDENT_ATTEMPT,
    {
      "quiz_id": Number(quiz_id),
      "quiz_attempt_status": "IN-PROGRESS",     //"COMPLETED" or "IN-PROGRESS"
      "quiz_attempt_id": Number(quiz_attempt_id),
      "question_answers": [
        {
          "question_id": Number(question_id),
          "attempt_state": ((options?.length > 0) || (matches?.length > 0) || answer_numerical) ? "ANSWERED" : 'SKIPPED',   // "ANSWERED": 'SKIPPED'
          "answer_numerical": answer_numerical ? Number(answer_numerical) : "",
          "options": options,
          "matches": matches
        }
      ]
    }
  ).then(
    (res) => {
      if (res.data.success == false) {
        alert('There is err in saving the answers.', res.data.errors)
      }
    }
  ).catch(
    (err) => {
    }
  )
}


export function SubmitTest({ dispatch, navigate, quiz_id, quiz_attempt_id, previosPage }) {
  AxiosClient.post(
    BACKEND_URLS.QUIZ_STUDENT_ATTEMPT,
    {
      "quiz_id": Number(quiz_id),
      "quiz_attempt_status": "COMPLETED",     //"COMPLETED" or "IN-PROGRESS"
      "quiz_attempt_id": Number(quiz_attempt_id),
      "question_answers": []
    }
  ).then(
    (res) => {
      if (res.data.success) {
        dispatch({type: TestReducersConstants.RESET})
        navigate( FRONTEND_URLS.TEST_RESULT + '/' + quiz_attempt_id, {state: previosPage} )
        if (window?.ReactNativeWebView) {
          window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'submit', quiz_attempt_id }));
        }
      }
      else{
        handleSetShowAlertModal({ dispatch, message: JSON.stringify(res.data.errors) })
        if (window?.ReactNativeWebView) {
          window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'error', quiz_attempt_id, message: res.data.errors.join(', ') }));
        }
      }
    }
  ).catch(
    (err) => {
      if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'error', quiz_attempt_id, message: err.message }));
      }
    }
  )
}


export function TimeDifference(start_time, end_time) {

  const start = new Date(start_time);
  const end = new Date(end_time);

  // Calculate the absolute time difference in seconds
  const timeDifferenceSeconds = Math.abs(Math.floor((end - start) / 1000));

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(timeDifferenceSeconds / 3600);
  const minutes = Math.floor((timeDifferenceSeconds % 3600) / 60);
  const seconds = timeDifferenceSeconds % 60;

  // Construct the formatted string
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} hrs `;
  }

  if (minutes > 0 || (hours === 0 && minutes === 0)) {
    formattedTime += `${minutes} min `;
  }

  if (seconds > 0 || (hours === 0 && minutes === 0 && seconds === 0)) {
    // formattedTime += `${minutes} min `;
    formattedTime += `${seconds} sec`;
  }

  return formattedTime.trim();

}

export function convertDate(inputDate) {
  // Parse the input string to a Date object
  const inputDateTime = new Date(inputDate);

  // Check if the Date object is valid
  if (isNaN(inputDateTime.getTime())) {
      return null;
  }

  // Format the Date object to the desired output format
  const options = { day: '2-digit', month: 'short', year: '2-digit' };
  const date = inputDateTime.toLocaleDateString('en-US', options).split(',');
  const dateFor = date[0].split(' ')
  const outputDate = dateFor[1] + '-' + dateFor[0] + '-' + date[1]

  return outputDate;
}


export function ResultDataFetcher(dispatch, quiz_attempt_id) {
  AxiosClient.post(
    BACKEND_URLS.GET_QUIZ_RESULTS,
    {
      "quiz_attempt_id": Number(quiz_attempt_id)
    }
  ).then(
    (res) => {
      if (res.data.success) {
        const data = res.data.data
        const quiz_details = { ...data.quiz_details, questions: data.question_answers }
        const student_attempt = { quiz_attempt_status: data.quiz_attempt_status, quiz_attempts: data.quiz_attempts }
        dispatch({ type: TestReducersConstants.TEST_DATA, payload: { quiz_details, student_attempt } })
      }
      else {
        handleSetShowAlertModal({ dispatch, message: JSON.stringify(res.data.errors) })
      }
    }
  ).catch(
    (err) => {
    }
  )
}

export const  FILTER_LIST = ["All","Incorrect","Correct","Skipped"]