import axios from "axios";
import { BASE, FRONTEND_URLS } from "../../URL/URL";
import base64 from 'base-64'

const AxiosClient = axios.create({ 
  baseURL: BASE,
  // timeout: 10000, 
});

// Function to set the Authorization header
const setAuthorizationHeader = (config) => {
  let token = localStorage.getItem('access');
  if (window?.ReactNativeWebView) {
    token = JSON.parse(base64.decode(localStorage.getItem('tkn')))?.access
    localStorage.setItem('access', token)
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

AxiosClient.interceptors.request.use(
  // Add the Authorization header before making the request
  setAuthorizationHeader,
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

AxiosClient.interceptors.response.use(
  (response) => {
    // Successful response
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
    } else {
    }
  }
);

export { AxiosClient };
