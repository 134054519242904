import React, { useEffect, useState } from "react";
import { FRONTEND_URLS } from "../../../URL/URL";
import { useLocation, useNavigate } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import NotFound from "../../Error/NotFound";
import { useSelector } from "react-redux";



const AllSession = () => {
    const navigate = useNavigate();
    const sessionData = useSelector(state => state.mentorship.sessions)

    const [uniqueValuesArrayOrig, setuniqueValuesArrayOrig] = useState();

    useEffect(() => {
        if (sessionData && sessionData.length > 0) {
            const uniqueValuesArray = Object.values(sessionData.reduce((acc, item) => {
                acc[item.series_name] = item;
                return acc;
            }, {}));
            setuniqueValuesArrayOrig(uniqueValuesArray);
        }
    }, [sessionData]);

    const handleAllSessionsClick = (series_id, seriesName) => {
        navigate(FRONTEND_URLS.SESSION_DETAILS, { state: { series_id, seriesName, display_name: seriesName } });
    };



    return (
        <div className="faq_head_box_flex" style={{ gap: "0.5rem" }}>
            {
                uniqueValuesArrayOrig
                    ?
                    uniqueValuesArrayOrig?.map((item, index) => {
                        return (
                            <div className="Card-Format custom-padding Psudo-Button d-flex align-items-center justify-content-between" key={index} onClick={() => handleAllSessionsClick(item.series_id, item.series_name)}>
                                <p className="faq_head_title">{item.series_name}</p>
                                <SlArrowRight className="all_session_icon" />
                            </div>
                        )
                    })
                    :
                    <NotFound key={'notFound'} />
            }
        </div>
    );
};

export default AllSession;
