import React from "react";
import loader from "../../Images/LottieImages/esaral_loader.json";
import LottieImage from "../LottieImage/LottieImage.js";

function ESaralLoader() {
  return (
    <div
      className="Background "
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
       backgroundColor: "rgba(255, 255, 324, 0.4)",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        zIndex: "10000",
        padding: "15%",
        // backgroundColor: 'rgba(0,0,0, 0.4)',
        bottom: 0,
        right: 0,
        top: 0,
        overflow: "hidden",
      }}>
      <LottieImage animationData={loader} />
    </div>
  );
}

export default ESaralLoader;
