import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AxiosClient } from '../../Axios/AxiosClient';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../URL/URL';
import { DefualtImages } from '../../../Constants/DefaultImages/DefaultImages';
import PDFOpener from '../../PDF/PDF';
import DoubutSolveHeader from '../DoubutSolve/DoubtSolveChatComp/DoubutSolveHeader';
import HeaderWithState from '../../Header/HeaderWithState';
import ESaralLoader from '../../Spinner/ESaralLoader.js';
import NotFound from '../../Error/NotFound.js';

const TimeTable = () => {

    const student_user_id = JSON.parse(localStorage.getItem('user'))?.user?.user_id;
    const [timetables, setTimetables] = useState();
    const [whichTimetable, setWhichTimetable] = useState();
    const [timetableName, setTimetableName] = useState();

    useEffect(
        () => {
            let ignore = false;
            setTimetables(null);

            AxiosClient.post(
                BACKEND_URLS.GET_TIMETABLE,
                { "student_user_id": student_user_id }
            ).then(
                (res) => {
                    if (!ignore && res.data.success) {
                        setTimetables(res.data.data);
                    }
                }
            ).catch(
                (err) => {
                }
            )

            return () => { ignore = true; }

        }, []
    )

    return (
        <Container fluid='md' className='p-0' style={{
            height: '100%'
        }}>
            {
                timetables
                    ?
                    timetables.length > 0
                        ?
                        !whichTimetable
                            ?
                            timetables.map(
                                (timetable, id) => {
                                    return (
                                        <Row key={id} className='Background p-2 mb-2 Psudo-Button ms-2' onClick={() => {
                                            setWhichTimetable(timetable.content_data.content_info)
                                            setTimetableName(timetable.display_name)
                                        }}>
                                            <Col xs={4} md={2} >
                                                <img draggable="false" style={{
                                                    width: '50%',
                                                    maxWidth: '44px',
                                                }} src={DefualtImages.pdf} alt={timetable.display_name} />
                                            </Col>
                                            <Col xs={8} md={10} className='d-flex align-items-center'>{timetable.display_name}</Col>
                                        </Row>
                                    )
                                }
                            )
                            :
                            <>
                                <PDFOpener timetable={whichTimetable} name={timetableName} handleClose={() => { setWhichTimetable() }} />
                            </>
                        :
                        <NotFound message='We are working on your personalised Timetable. Check back after some time 😉.' />
                    :
                    <ESaralLoader />
            }
        </Container>
    );
};

export default TimeTable;