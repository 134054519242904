import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { mediaBaseUrl } from '../../URL/URL';
import { FiZoomOut, FiZoomIn, FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai'
import { Col, NavDropdown, Row } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import LottieImage from '../LottieImage/LottieImage';
import loader from "../../Images/LottieImages/esaral_loader.json";
import { useDispatch } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addDataInDb } from '../../DB/Function';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ZoomRate = .5
const MZoomRate = .25


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const sizeOfZoom = 25;


function PDFOpener({ timetable, name = 'test', handleClose = () => { } }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState('')
    const [passward, setPassward] = useState('')
    const [scale, setScale] = useState(1)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [pdfHight, setPdfHight] = useState(73)
    const [mscale, setMScale] = useState(.5)

    const dispatch = useDispatch()
    const { subject_node_id } = useParams();

    const { tab, chapter_id, content_id } = useSelector((state) => state.app);
    const treakIntervalKey = useSelector(state => state.app.treakIntervalKey)


    function onLoadSuccessFun() {
        console.log({tab})
        const content_group = tab?.split('-')[2];
        const content_parent_group = content_group?.charAt(0).toUpperCase() + content_group?.slice(1);

        const treakData = {
            content: content_id,
            subject: subject_node_id,
            chapter: chapter_id,
            content_parent_group: content_parent_group,
        };
        if (treakIntervalKey) {
            dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });
        }
        dispatch({ type: AppReducerConstants.TRACK_INTERVAL_ID, payload: addDataInDb(treakData) });
    }


    useEffect(
        () => {
            if (window.innerWidth > 773) {
                setPdfHight(80)
            }
            if (timetable?.encrypted_file_url && timetable?.pdf_password.length > 0) {
                setFile((timetable?.encrypted_file_url).includes(mediaBaseUrl) ? timetable?.encrypted_file_url : mediaBaseUrl + timetable?.encrypted_file_url)
                setPassward(timetable?.pdf_password)
            }
            else {
                if ((timetable?.original_file_url).includes(mediaBaseUrl)) {
                    setFile(timetable?.original_file_url)
                }
                else {
                    setFile(mediaBaseUrl + timetable?.original_file_url)
                }
            }
        }, []
    )

    function onDocumentLoadSuccess({ numPages }) {
        onLoadSuccessFun()
        setNumPages(numPages);
    }

    function onDocumentLoadError(e) {
    }

    const toggleFullScreen = () => {
        const element = document.getElementById('pdfView');
        if (!isFullScreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
            setIsFullScreen(true);
            setPdfHight(93)
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            setIsFullScreen(false);
        }
    };

    return (
        <div className='ms-1' id='pdfView' style={{
            overflow: 'hidden',
        }} >
            <Row className='m-0 p-2 isDesktop d-flex align-items-center' style={{
                background: '#CDCFD3 ',
                boxShadow: '0px 0px 4px 0px black',
                maxHeight: '7vh',
            }}>
                <Col xs={3}>
                    <strong>{name}</strong>
                </Col>
                <Col xs={6} className='d-flex justify-content-evenly align-items-center'>
                    <div>
                        <FiChevronLeft size={sizeOfZoom} name='back' className='Psudo-Button' onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1)
                            }
                        }} style={{
                            color: pageNumber == 1 ? '#00000085' : 'black'
                        }} />

                        <input value={pageNumber} onChange={(e) => { setPageNumber(Number(e.target.value)) }} style={{
                            width: '32px',
                            textAlign: 'center',
                            paddingLeft: '2px',
                        }} className='mx-2' />
                        of
                        <span className='ms-2'> {numPages} </span>

                        <FiChevronRight size={sizeOfZoom} name='next' className='Psudo-Button ' onClick={() => {
                            if (numPages > pageNumber) {
                                setPageNumber(pageNumber + 1)
                            }
                        }} style={{
                            color: numPages == pageNumber ? '#00000085' : 'black'
                        }} />
                    </div>
                    <div>
                        <FiZoomOut size={sizeOfZoom} onClick={() => {
                            if (scale > 1) {
                                setScale(scale - ZoomRate)
                            }
                        }} className='Psudo-Button me-1' style={{
                            color: scale == 1 ? '#00000085' : 'black'
                        }} />
                        <FiZoomIn size={sizeOfZoom} onClick={() => {
                            if (scale < 3) {
                                setScale(scale + ZoomRate)
                            }
                        }} className='Psudo-Button' style={{
                            color: scale >= 3 ? '#00000085' : 'black'
                        }} />
                    </div>
                    <div>
                        {!isFullScreen
                            ?
                            <AiOutlineFullscreen size={sizeOfZoom} onClick={toggleFullScreen} className='Psudo-Button' />
                            :
                            <AiOutlineFullscreenExit size={sizeOfZoom} onClick={toggleFullScreen} className='Psudo-Button' />
                        }

                    </div>
                </Col>
                <Col xs={3} className='text-end'>
                    <RxCross2 size={30} onClick={handleClose} className='Psudo-Button' />
                </Col>
            </Row>


            {/* <Row className='m-0 p-1 isPhoneAndTab' style={{
                background: '#CDCFD3 ',
                boxShadow: '0px 0px 4px 0px black',
                height: '6vh',
            }} >

                <Col xs={10} className='d-flex align-items-center justify-content-between ps-0'>
                    <div>
                        <FiChevronLeft size={sizeOfZoom} name='back' className='Psudo-Button' onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1)
                            }
                        }} style={{
                            color: pageNumber == 1 ? '#00000085' : 'black'
                        }} />

                        <input value={pageNumber} onChange={(e) => { setPageNumber(Number(e.target.value)) }} style={{
                            width: '32px',
                            textAlign: 'center',
                            paddingLeft: '2px',
                        }} className='mx-2' />
                        of
                        <span className='ms-2'> {numPages} </span>

                        <FiChevronRight size={sizeOfZoom} name='next' className='Psudo-Button ' onClick={() => {
                            if (numPages > pageNumber) {
                                setPageNumber(pageNumber + 1)
                            }
                        }} style={{
                            color: numPages == pageNumber ? '#00000085' : 'black'
                        }} />
                    </div>
                    <div>
                        <FiZoomOut size={sizeOfZoom} onClick={() => {
                            if (mscale > .25) {
                                setMScale(mscale - MZoomRate)
                            }
                        }} className='Psudo-Button me-1' style={{
                            color: mscale == .5 ? '#00000085' : 'black'
                        }} />
                        <FiZoomIn size={sizeOfZoom} onClick={() => {
                            if (mscale < 3) {
                                setMScale(mscale + MZoomRate)
                            }
                        }} className='Psudo-Button' style={{
                            color: mscale >= 3 ? '#00000085' : 'black'
                        }} />
                    </div>
                    <div>
                        {!isFullScreen
                            ?
                            <AiOutlineFullscreen size={sizeOfZoom} onClick={toggleFullScreen} className='Psudo-Button' />
                            :
                            <AiOutlineFullscreenExit size={sizeOfZoom} onClick={toggleFullScreen} className='Psudo-Button' />
                        }

                    </div>
                </Col>
                <Col xs={2} className='text-end align-self-center'>
                    <RxCross2 size={30} onClick={handleClose} className='Psudo-Button' />
                </Col>
            </Row> */}

            <Row id='pdf' style={{
                height: `${pdfHight}vh`,
                overflow: 'auto',
            }}>
                <Document
                    className={'Background Centering'}
                    options={options}
                    file={file}
                    onLoadError={onDocumentLoadError}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onPassword={(callback) => callback(passward)}
                    loading={
                        <div className='flex justify-content-center align-items-center h-100 w-100'>
                            <div className=' justify-content-center align-items-center m-auto w-50 h-100'>
                                <LottieImage animationData={loader} />
                            </div>
                        </div>
                    }

                >
                    <Page scale={(window.innerWidth > 773) ? scale : mscale} pageNumber={pageNumber} />
                </Document>
            </Row>


        </div>
    );
}

export default PDFOpener;
