import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResultDataFetcher, convertDate } from '../../../functions/Functions.js';
import Select from 'react-select';
import { FiArrowLeft } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppReducerConstants } from '../../../../Redux/Reducers/Constants/AppReducerConstant.js';
import { FRONTEND_URLS } from '../../../../URL/URL.js';

function ResultHeader() {
  const dispatch = useDispatch();
  const quiz_attempts = useSelector(state => state.test.student_attempt?.quiz_attempts);
  const quiz_attempts_len = quiz_attempts.length
  const navigate = useNavigate()
  const previousPages = useSelector(state => state.app.previousPages)
  const location = useLocation()

  const options = quiz_attempts.map((quiz_attempt, id) => ({
    label: ` Attempt(${quiz_attempts_len - id})-` + convertDate(quiz_attempt.attempted_on),
    value: id,
  }));

  const [selectedOption, setSelectedOption] = useState(options[0]); // Set the first option as default

  const handleChange = selected => {
    setSelectedOption(selected);
    ResultDataFetcher(dispatch, quiz_attempts[selected.value].quiz_attempt_id);
  };

  const hadleNavigate = () => {
      navigate(FRONTEND_URLS.HOME_ROUTE)
  }

  return (
    <header id='quiz-header' className='mb-2 Background d-flex justify-content-between align-items-center p-3'>
      <h4>
        <FiArrowLeft size={25} className='me-3 Psudo-Button' onClick={hadleNavigate} />
        Result
      </h4>

      <Select
        className='ResultHeaderMenu'
        options={options}
        value={selectedOption}
        onChange={handleChange}
        isSearchable={false}
      />
    </header>
  );
}

export default ResultHeader;
