import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import SideBar from '../../../SideBar/SideBar.js'
import SiderBarContent from '../../Home/SiderBarContent.js'
import Header from '../../../Header/Header.js'
import CourseInfoDeskTop from './CourseInfoDeskTop.js'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosClient } from '../../../Axios/AxiosClient.js'
import { BACKEND_URLS } from '../../../../URL/URL.js'
import { AppReducerConstants } from '../../../../Redux/Reducers/Constants/AppReducerConstant.js'
import HeaderWithState from '../../../Header/HeaderWithState.js'
import ESaralLoader from '../../../Spinner/ESaralLoader.js'
import InformationModal from '../../../Modals/InformationModal.js'
import { handleSetShowAlertModal } from '../../../../helpers/helperFunctions.js'

const BUTTON_HIGHT = 60;

const CourseDetailsDeskTop = () => {
    const [course, setCourse] = useState()
    const [faculty, setFaculty] = useState()
    const [testimonials, setTestimonials] = useState()
    const { course_id } = useParams()
    const menu = useSelector(state => state.app.CourseMenu)
    const activeMenu = menu.find(item => item.is_true).name
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [modalShow, setModalShow] = useState()


    useEffect(
        () => {
            let ignore = false;
            setCourse(null)
            setFaculty(null)
            setTestimonials(null)

            AxiosClient.post(
                BACKEND_URLS.COURSES_DETAILS, {
                "course_id": course_id
            }
            ).then(
                (res) => {
                    if (!ignore) {
                        if (res.data.success) {
                            setCourse(res.data.data.course)
                            setFaculty(res.data.data.faculty)
                            setTestimonials(res.data.data.testimonials)
                        }
                        else {
                    handleSetShowAlertModal({dispatch, message:'Something went wrong. Please try after some time.'})
                        }
                    }
                }
            ).catch(
                (err) => {
                }
            )

            return () => {
                ignore = true;
            }
        }, []
    )

    function HightLighter() {

        const sections = document.querySelectorAll('#CourseInfo section');
        const windowHight = window.innerHeight;

        for (let i = 0; i < sections.length; i++) {
            const { bottom, top } = sections[i].getBoundingClientRect();

            if (((windowHight - top) > BUTTON_HIGHT) && ((windowHight - bottom) < BUTTON_HIGHT) && (activeMenu != menu[i].name)) {
                dispatch({
                    type: AppReducerConstants.COURSE_MENU, payload: i
                })
                break;
            }
        }
    }


    return (
        <>
            {
                course
                    ?
                    <>
                        <Header />
                        
                        <div className='container-xxl Vertical-gap' >
                            <Row>
                                <SideBar >
                                    <SiderBarContent />
                                </SideBar>
                                <Col md={9} className='p-0' id='CourseDetails' style={{ overflow: 'hidden', height: '88vh' }} >
                                    <div className='ms-2' style={{ position: 'sticky', top: 0, zIndex: '1000', }}>
                                        <HeaderWithState state={() => navigate(-1)} subject_name={course.name} />
                                    </div>
                                    <Row className='ms-2 ps-0' style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        height: '80vh',
                                    }}>
                                        <CourseInfoDeskTop testimonials={testimonials} faculties={faculty} course={course} HightLighter={HightLighter} />
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <><ESaralLoader /></>
            }
        </>
    )
}

export default CourseDetailsDeskTop;