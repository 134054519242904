import React, { useEffect, useState } from 'react';
import HelpAndSupportBody from '../HelpAndSupport/HelpAndSupportBody.js';
import { HelpAndSupportSampleData } from '../HelpAndSupport/HelpAndSupportSampleData.js';
import ESaralLoader from '../../Spinner/ESaralLoader.js';

const HelpAndSupport = () => {
    const [data, setData] = useState() 
    
    useEffect(
        () => {

            const sampleData = HelpAndSupportSampleData;

            if (sampleData.success) {
                const data = sampleData.data
                const faqs = data.faqs
                const filterdData = []
                const filteredCategories = []

                for (let i = 0; i < faqs.length; i++) {

                    const isData = filteredCategories.indexOf(faqs[i].category)

                    if (isData < 0) {
                        filterdData.push({ categorie: faqs[i].category, faqs: [faqs[i]] })
                        filteredCategories.push(faqs[i].category)
                    }

                    else {
                        filterdData[isData].faqs.push(faqs[i])
                    }
                }
                setData(filterdData)
            }

        }, []
    )

    return (
        <>
            {
                data
                    ?
                    <HelpAndSupportBody data={data} />
                    :
                    <><ESaralLoader /></>
            }
        </>
    );
};

export default HelpAndSupport;