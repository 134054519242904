import React from 'react';

const ResultPageCard = ({logo, title, value}) => {
    return (
        <div className='ResultPageCard'>
               {logo}
            <div className='ResultPageCardTitle'>{title}</div>
            <div className='ResultPageCardvalue'> {value} </div>
        </div>
    );
};

export default ResultPageCard;