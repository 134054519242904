import React, { useMemo, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import { FRONTEND_URLS, mediaBaseUrl } from '../URL/URL.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatEpochTimeRange } from './Function.js';
import LottieImage from '../Components/LottieImage/LottieImage.js';
import liveLottie from '../Images/Live/live.json';
import { startClass } from '../Zoom/Function.js';
import NotFound from '../Components/Error/NotFound.js';
import { handleSetShowAlertModal } from '../helpers/helperFunctions.js';
import { useDispatch } from 'react-redux';

const LiveClasses = () => {
    const { mentorship: slug } = useParams();
    const navigate = useNavigate();
    const liveContent = useSelector(state => state.app.live);
    const dispatch = useDispatch()

    const liveData = useMemo(() => {
        return JSON.parse(localStorage.getItem('user'))?.live?.Upcoming;
    }, []);

    const mentorshipClasses = useMemo(() => {
        return liveData?.data?.filter(cls => cls?.series_category === 'MENTORSHIP');
    }, [liveData]);

    const handleAccordionClick = useCallback((content, navigateTo) => {
        if (content === 'All') {
            navigate(navigateTo);
        }
    }, [navigate]);

    const handleClassClick = useCallback((isLive, classItem, navigateTo) => {
        if (liveContent === 'Upcoming') {
            if (isLive) {
                startClass(classItem, navigate, navigateTo);
            } else {
                handleSetShowAlertModal({ dispatch, message: 'Your class is starting soon.'})
            }
        }
    }, [liveContent, navigate]);


    const renderAccordionItem = useCallback((item, index) => {
        return (
            <Accordion key={index} flush>
                <Accordion.Item className='mb-2 mx-0' eventKey={`${index}`}>
                    <Accordion.Header className='Background-Color' onClick={() => handleAccordionClick(liveContent, FRONTEND_URLS.ALL)}>
                        {liveContent === 'All' && (
                            <img
                                draggable={false}
                                className='Lecture-Img me-3'
                                src={item.series_thumbnail.length > 0 ? (item.series_thumbnail.includes(mediaBaseUrl) ? item.series_thumbnail : mediaBaseUrl + item.series_thumbnail) : require('../Images/DefaultImage/video_default_thumb.png')}
                                alt=''
                                onError={(e) => {
                                    e.target.src = require('../Images/DefaultImage/video_default_thumb.png');
                                }}
                            />
                        )}
                        {item.series_name}
                    </Accordion.Header>
                    <Accordion.Body className='Background-Color px-0 py-1'>
                        {item.data?.map((secItem, key) => {
                            const systemTime = Math.floor(Date.now() / 1000);
                            const isLive = secItem.end_date_time >= systemTime && systemTime >= secItem.start_date_time;
                            return (
                                <Accordion.Item key={key} className='Background my-1 p-2 Psudo-Button d-flex justify-content-between align-items-center' onClick={() => handleClassClick(isLive, secItem, FRONTEND_URLS.LIVE)}>
                                    <div className='d-flex align-items-center ps-3'>
                                        <div>
                                            <div>{secItem.name}</div>
                                            {isLive ? (
                                                <div className='live-now-series'>
                                                    Live Now {formatEpochTimeRange(secItem.start_date_time, secItem.end_date_time)}
                                                </div>
                                            ) : (
                                                <div className='LIVE-NOW-SERIES-NAME'>
                                                    {formatEpochTimeRange(secItem.start_date_time, secItem.end_date_time)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {isLive && (
                                        <div style={{ width: '40px' }}>
                                            <LottieImage animationData={liveLottie} Isloop={true} />
                                        </div>
                                    )}
                                </Accordion.Item>
                            );
                        })}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    }, [handleAccordionClick, handleClassClick, liveContent]);

    return (
        <div>
            {slug === 'mentorship' ? (
                mentorshipClasses?.length > 0 ? mentorshipClasses.map(renderAccordionItem) : <NotFound />
            ) : (
                liveData?.data?.length > 0 ? liveData.data.map(renderAccordionItem) : <NotFound />
            )}
        </div>
    );
};

export default LiveClasses;
