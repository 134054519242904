import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { admissionFormKeys, calculateFieldPercentage, indian_states, updateAdmissionFormDetails } from '../../../helpers/admissionForm';
import { AppReducerConstants } from '../../../Redux/Reducers/Constants/AppReducerConstant';
import { FRONTEND_URLS } from '../../../URL/URL';
import ButtonGroup from './ButtonGroup';
import LottieImage from '../../LottieImage/LottieImage';
import done from '../../../Images/LottieImages/done.json'
import { admissionReducerConstant } from '../../../Redux/ReducerConstants/admissionReducerConstant';
import AutoHideAlert from '../../Alerts/AutoHideAlert';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';


function AdmissionFormAddressDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const admissionFormData = useSelector(state => state.admission.data);
    const completedPercentage = useSelector(state => state.admission.compeltePercentage)

    const [isDone, setIsDone] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')


    const [formData, setFormData] = useState({
        permanentPincode: admissionFormData.permanent_pincode,
        permanentState: admissionFormData.permanent_state,
        permanentCity: admissionFormData.permanent_city,
        permanentAddressDetails: admissionFormData.permanent_address2,
        permanentHouseNo: admissionFormData.permanent_address1,
        permanentLandmark: admissionFormData.permanent_landmark,
        sameAsPermanent: admissionFormData.permanent_pincode === admissionFormData.current_pincode,
        currentPincode: admissionFormData.current_pincode,
        currentState: admissionFormData.current_state,
        currentCity: admissionFormData.current_city,
        currentHouseNo: admissionFormData.current_address1,
        currentAddressDetails: admissionFormData.current_address2,
        currentLandmark: admissionFormData.current_landmark
    });

    const [errors, setErrors] = useState({});
    const errorRefs = useRef({});

    const handleChange = (e) => {
        let { name, value, type, checked } = e.target;

        if (name.includes('Pincode')) {
           value =  value.replace(/[^0-9]/g, '');        
        }
        
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSameAsPermanent = () => {
        if (!formData.sameAsPermanent) {
            setFormData(prevState => ({
                ...prevState,
                currentPincode: prevState.permanentPincode,
                currentState: prevState.permanentState,
                currentCity: prevState.permanentCity,
                currentAddressDetails: prevState.permanentAddressDetails,
                currentHouseNo: prevState.permanentHouseNo,
                currentLandmark: prevState.permanentLandmark
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                currentPincode: '',
                currentState: '',
                currentCity: '',
                currentAddressDetails: '',
                currentHouseNo: '',
                currentLandmark: ''
            }));
        }
        setFormData(prevState => ({ ...prevState, sameAsPermanent: !prevState.sameAsPermanent }));
    };

    const handleUpdateData = async () => {
        const newErrors = {};
        if (!formData.permanentPincode) {
            newErrors.permanentPincode = 'Permanent Pincode is required';
            setMessage('Permanent Pincode is required')
            handleAlert() 
            return
        }
        if (!formData.permanentState) {
            newErrors.permanentState = 'Permanent State is required';
            setMessage('Permanent State is required')
            handleAlert()
            return

        }
        if (!formData.permanentCity) {
            newErrors.permanentCity = 'Permanent City is required';
            setMessage('Permanent City is required')
            handleAlert()
            return

        }
        if (!formData.permanentAddressDetails) {
            newErrors.permanentAddressDetails = 'Permanent Address Details are required';
            setMessage('Permanent Address Details are required')
            handleAlert()
            return

        }

        if (formData.currentPincode && formData.currentPincode.length != 6) {
            newErrors.currentPincode = 'Current Pincodes is incorrect';
            setMessage('Current Pincodes is incorrect')
            handleAlert()
            return
        
        }

        if (formData.permanentPincode.length != 6) {
            newErrors.permanentPincode = 'Permanent Pincodes is incorrect';
            setMessage('Permanent Pincodes is incorrect')
            handleAlert()
            return

        }


        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const formInfo = {
                    [admissionFormKeys.PERMANENT_ADDRESS1]: formData.permanentHouseNo,
                    [admissionFormKeys.PERMANENT_ADDRESS2]: formData.permanentAddressDetails,
                    [admissionFormKeys.PERMANENT_PINCODE]: formData.permanentPincode,
                    [admissionFormKeys.PERMANENT_CITY]: formData.permanentCity,
                    [admissionFormKeys.PERMANENT_STATE]: formData.permanentState,
                    [admissionFormKeys.PERMANENT_LANDMARK]: formData.permanentLandmark,

                    [admissionFormKeys.CURRENT_ADDRESS1]: formData.currentHouseNo,
                    [admissionFormKeys.CURRENT_ADDRESS2]: formData.currentAddressDetails,
                    [admissionFormKeys.CURRENT_PINCODE]: formData.currentPincode,
                    [admissionFormKeys.CURRENT_CITY]: formData.currentCity,
                    [admissionFormKeys.CURRENT_STATE]: formData.currentState,
                    [admissionFormKeys.CURRENT_LANDMARK]: formData.currentLandmark
                }

                const percentage = 100

                dispatch({ type: admissionReducerConstant.COMPELTE_PERCENTAGE, payload: percentage })
                formInfo[admissionFormKeys.USER_PROFILE_PROGRESS] = percentage

                const data = await updateAdmissionFormDetails(formInfo);

                if (data.success) {
                    dispatch({ type: AppReducerConstants.IS_ADMISSION_FORM });
                    dispatch({ type: admissionReducerConstant.PAGE, payload: 3 })

                    setIsDone(true)
                    setTimeout(
                        () => {
                            dispatch({ type: admissionReducerConstant.PAGE, payload: 1 })
                            navigate(FRONTEND_URLS.HOME_ROUTE)
                        }, 2000
                    )
                } else {
                handleSetShowAlertModal({ dispatch, message: 'Please try after some time. There is issue.'})
                }
            } catch (error) {
            }
        } else {
            const firstErrorField = Object.keys(newErrors)[0];
            if (errorRefs.current[firstErrorField]) {
                // errorRefs.current[firstErrorField].scrollIntoView({ behavior: 'smooth' });
                errorRefs.current[firstErrorField].focus();
            }
        }
    };

    const handlePreviosPage = () => {
        //  navigate(FRONTEND_URLS.ADMISSION + '/' + 2)
        dispatch({ type: admissionReducerConstant.PAGE, payload: 2 })
    }


    const handleAlert = () => {
        setShowAlert(true)
        setTimeout(
            () => {
                setShowAlert(false)
            }, 2000
        )
    }

    return (
        <>
            {
                isDone
                    ?
                    <div
                        className="Background "
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "92vh",
                            backgroundColor: "rgba(255, 255, 324, 0.4)",
                            zIndex: "10000",
                            padding: "15%",
                            bottom: 0,
                            right: 0,
                            top: 0,
                            overflow: "hidden",
                        }}>
                        <LottieImage animationData={done} Isloop={false} />
                        <h3 className='text-center'>You'r now redriecting to home page.</h3>
                    </div>
                    :
                    <>
                        <div style={{
                            position: 'fixed',
                            right: 10,
                            top: 10,
                            zIndex: 10000,
                            width: 'auto'
                        }}>
                            <AutoHideAlert message={message} setShow={setShowAlert} show={showAlert} key={100} />
                        </div>
                        <h4>Permanent Address</h4>
                        <Form.Group className="mb-3" controlId="formPermanentHouseNo">
                            <Form.Label>House/Flat No.</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="House/Flat No."
                                name="permanentHouseNo"
                                value={formData.permanentHouseNo}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPermanentAddressDetails" ref={el => errorRefs.current.permanentAddressDetails = el}>
                            <Form.Label>Address Details</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address Details"
                                name="permanentAddressDetails"
                                value={formData.permanentAddressDetails}
                                onChange={handleChange}
                                isInvalid={!!errors.permanentAddressDetails}
                                style={errors.permanentAddressDetails ? { borderColor: 'red' } : {}}
                            />
                            {errors.permanentAddressDetails && <div style={{ color: 'red' }}>* {errors.permanentAddressDetails}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPermanentLandmark">
                            <Form.Label>Landmark</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Landmark"
                                name="permanentLandmark"
                                value={formData.permanentLandmark}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPermanentPincode" ref={el => errorRefs.current.permanentPincode = el}>
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Pincode"
                                name="permanentPincode"
                                value={formData.permanentPincode}
                                onChange={handleChange}
                                isInvalid={!!errors.permanentPincode}
                                style={errors.permanentPincode ? { borderColor: 'red' } : {}}
                            />
                            {errors.permanentPincode && <div style={{ color: 'red' }}>* {errors.permanentPincode}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPermanentCity" ref={el => errorRefs.current.permanentCity = el}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                name="permanentCity"
                                value={formData.permanentCity}
                                onChange={handleChange}
                                isInvalid={!!errors.permanentCity}
                                style={errors.permanentCity ? { borderColor: 'red' } : {}}
                            />
                            {errors.permanentCity && <div style={{ color: 'red' }}>* {errors.permanentCity}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPermanentState">
                            <Form.Label>State</Form.Label>
                            <Form.Select
                                name="permanentState"
                                value={formData.permanentState}
                                onChange={handleChange}
                                isInvalid={!!errors.permanentState}
                                style={errors.permanentState ? { borderColor: 'red' } : {}}
                            >
                                <option value="">---select---</option>
                                {Object.keys(indian_states).map((state, id) => (
                                    <option key={id} value={state}>
                                        {indian_states[state]}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.permanentState && <div style={{ color: 'red' }}>* {errors.permanentState}</div>}
                        </Form.Group>

                        <h4>Current Address</h4>

                        <Form.Group className="mb-3" controlId="formSameAsPermanent">
                            <Form.Check
                                type="checkbox"
                                label="Same as Permanent address."
                                name="sameAsPermanent"
                                checked={formData.sameAsPermanent}
                                onChange={handleSameAsPermanent}
                            />
                        </Form.Group>



                        {!formData.sameAsPermanent && (
                            <>
                                <Form.Group className="mb-3" controlId="formCurrentHouseNo">
                                    <Form.Label>House/Flat No.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="House/Flat No."
                                        name="currentHouseNo"
                                        value={formData.currentHouseNo}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCurrentAddressDetails">
                                    <Form.Label>Address Details</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Address Details"
                                        name="currentAddressDetails"
                                        value={formData.currentAddressDetails}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCurrentLandmark">
                                    <Form.Label>Landmark</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Landmark"
                                        name="currentLandmark"
                                        value={formData.currentLandmark}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCurrentPincode">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Pincode"
                                        name="currentPincode"
                                        value={formData.currentPincode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.currentPincode}
                                        style={errors.currentPincode ? { borderColor: 'red' } : {}}
                                    />
                                    {errors.currentPincode && <div style={{ color: 'red' }}>* {errors.currentPincode}</div>}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCurrentCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        name="currentCity"
                                        value={formData.currentCity}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formCurrentState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        name="currentState"
                                        value={formData.currentState}
                                        onChange={handleChange}
                                    >
                                        <option value="">---select---</option>
                                        {Object.keys(indian_states).map((state, id) => (
                                            <option key={id} value={state}>
                                                {indian_states[state]}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}
                        <ButtonGroup primaryFun={handleUpdateData} secondaryFun={handlePreviosPage} />
                    </>
            }
        </>
    );
}

export default AdmissionFormAddressDetails;
