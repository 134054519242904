import React from "react";
import { memo } from "react";

const Question = ({ label, question_type, paragraph, instructions, question_text }) => {


    return (
        <div>
            <div className="meta-data">
                {label}
            </div>
            <div dangerouslySetInnerHTML={{ __html: paragraph }} />
            <div dangerouslySetInnerHTML={{ __html: instructions }} />
            <div dangerouslySetInnerHTML={{ __html: question_text }} />
        </div>
    )
}

export default memo(Question);
