import React, { useEffect } from 'react';
import { Navbar, Row, Tabs, Tab } from 'react-bootstrap';
import { TabConstants } from './TabConstants';
import { useDispatch, useSelector } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant';

const ContentTab = ({ learn, test, revise, practice }) => {
    const dispatch = useDispatch()
    const tab = useSelector(state => state.app.tab)?.split('-')[2]
    const videoTab = useSelector(state => state.app.videoTab)
    const videoStatus = useSelector(state => state.app.videoStatus)


    useEffect(() => {
        let initialTab = 'Learn';

        if (learn && learn.length > 0) {
            initialTab = 'Learn';
        } else if (practice && practice.length > 0) {
            initialTab = 'Practice';
        } else if (test && test.length > 0) {
            initialTab = 'Test';
        } else if (revise && revise.length > 0) {
            initialTab = 'Revise';
        }
        dispatch({ type: AppReducerConstants.TAB, payload: 'fill-tab-' + initialTab });

        return () => {
            dispatch({ type: AppReducerConstants.TAB, payload: 'fill-tab-Learn' });
        }

    }, [learn, practice, test, revise, dispatch])


    function tabFun(id) {
        dispatch({ type: AppReducerConstants.TAB, payload: id })
        dispatch({ type: AppReducerConstants.VIDEO_ID, payload: null })
        dispatch({ type: AppReducerConstants.IS_PDF, payload: null });
        dispatch({ type: AppReducerConstants.CLEAR_INTERVAL });

        try {

            if (videoTab && videoTab === id) {
                if (videoStatus === 'playing') {
                    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: true });
                    const videoPlayer = document.getElementById('videoPlayer');
                    const player = new window.VdoPlayer.getInstance(videoPlayer);
                    player.video.play()
                }
            } else {
                const videoPlayer = document.getElementById('videoPlayer');
                if (videoPlayer) {
                    const player = new window.VdoPlayer.getInstance(videoPlayer);

                    dispatch({ type: AppReducerConstants.IS_VIDEO, payload: false });
                    if (videoStatus === 'playing') {
                        dispatch({ type: AppReducerConstants.CLEAR_INTERVAL })
                        player.video.pause();
                    }
                } else {
                }
            }
        } catch (error) {
        }

    }


    return (
        <Row className='mx-0 BoxShadow h-10 mb-2' id='subject-tabs'>
            <Navbar sticky="top" className='Background BoxShadow  justify-content-center' style={{
                paddingBottom: '0px',
                borderRadius: '8px',
                paddingTop: "12px"
            }}>
                <Tabs
                    variant="underline"
                    defaultActiveKey={tab}
                    activeKey={tab}
                    id="fill"
                    style={{ width: "100%", }}
                    fill
                    onClick={(e) => { tabFun(e.target.id) }}
                >
                    {learn && learn?.length > 0 &&
                        <Tab eventKey="Learn" title={TabConstants.Learn} />
                    }
                    {practice && practice?.length > 0 &&
                        <Tab eventKey="Practice" title={TabConstants.Practice} />
                    }
                    {test && test?.length > 0 &&
                        <Tab eventKey="Test" title={TabConstants.Test} />
                    }
                    {revise && revise?.length > 0 &&
                        <Tab eventKey="Revise" title={TabConstants.Revise} />
                    }
                </Tabs>
            </Navbar>
        </Row>
    );
};

export default ContentTab;