import React, { memo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import ESaralLoader from '../../../Components/Spinner/ESaralLoader.js'
import Qno from './Qno.js'
import { Col, Container, Row } from 'react-bootstrap'


function Qnos() {
  const active_index = useSelector(state => state.test.active_index)
  const questions = useSelector(state => state.test.const_quiz_details?.questions)
  const scrollRef = useRef();

  useEffect(() => {
    if (active_index > 1) {
      scrollRef.current.scrollLeft = (active_index - 2) * 56;
    }
  }, [active_index]);

  return (
    <div id='quiz-Qno' className='me-2 '>
      <Container fluid='md' >
        <Row className="scrolling-wrapper flex-row flex-nowrap Background">
          <div id='Qnos-scrollRef' ref={scrollRef} className='quetion-numbers' style={{
            scrollBehavior:'smooth',
          }}>
            {
              questions
                ?
                questions.map(
                  (qno, id) => {
                    return (
                      <Qno key={id} qno={id} is_correct={qno.is_correct} attempt_state={qno.attempt_state} isActive={id === active_index} />
                    )
                  }
                )
                :
                <ESaralLoader />
            }
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default memo(Qnos)