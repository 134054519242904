import { AlertReducersConstants } from "../Redux/ReducerConstants/AlertReducersConstants"



export const handleSetShowAlertModal = ({dispatch, message}) => {
    dispatch({ type: AlertReducersConstants.MESSAGE, payload: message })
    dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, payload: true })
}


export function indexedDBStuff ({dispatch}) {
    if (!('indexedDB' in window)) {
      dispatch({ type: AlertReducersConstants.MESSAGE, payload: "this browser is not supported by us. Please use another browsers like Google Chrome or Microsoft Edge."})
      dispatch({ type: AlertReducersConstants.IS_ALERT_MODAL, payload: true })
      return false;
    } 
    return true
}


export function getBrowserVersion() {
    const userAgent = navigator.userAgent;
    let browserName, fullVersion;

    if (userAgent.indexOf("Chrome") !== -1) {
        browserName = "Chrome";
        fullVersion = userAgent.substring(userAgent.indexOf("Chrome") + 7).split(" ")[0];
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
        browserName = "Safari";
        fullVersion = userAgent.substring(userAgent.indexOf("Version") + 8).split(" ")[0];
    } else if (userAgent.indexOf("Firefox") !== -1) {
        browserName = "Firefox";
        fullVersion = userAgent.substring(userAgent.indexOf("Firefox") + 8).split(" ")[0];
    } else if (userAgent.indexOf("MSIE") !== -1 || !!document.documentMode) {
        browserName = "Internet Explorer";
        fullVersion = userAgent.indexOf("MSIE") !== -1
            ? userAgent.substring(userAgent.indexOf("MSIE") + 5).split(";")[0]
            : "11"; // IE11's version doesn't have MSIE in the UA
    } else {
        return false; // Unsupported browser
    }

    // Convert the version to a number (e.g., "105.0.5195.102" -> 105)
    const majorVersion = parseInt(fullVersion.split('.')[0]);

    // Check for IndexedDB support based on browser and version
    switch (browserName) {
        case "Chrome":
            return majorVersion >= 23;
        case "Safari":
            return majorVersion >= 7; // Safari 7 and above support IndexedDB
        case "Firefox":
            return majorVersion >= 4;
        case "Internet Explorer":
            return majorVersion >= 10; // IE10+ has partial IndexedDB support
        default:
            return false; // Unsupported browser
    }
}
