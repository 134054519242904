import React, { memo, useEffect } from 'react'
import { findLongestList } from '../../../functions/Functions.js';


function Question({ question_text, question_type_label, question_type, matches, options, paragraph }) {
  const longestNumber = findLongestList(matches, options)

  const tableData = [];

  for (let i = 0; i < longestNumber; i++) {
    const option = options[i] || ''; // Handle unequal array lengths
    const match = matches[i] || ''; // Handle unequal array lengths

    tableData.push(
      <tr key={i}>
        <td className='td-class'>({String.fromCharCode(65 + i)}) &nbsp; {option.option_value}</td>
        <td className='td-class'>({String.fromCharCode(80 + i)}) &nbsp; {match.option_value}</td>
      </tr>
    );
  }

  useEffect(
    () => {
        window.MathJax.Hub.Queue([
          "Typeset",
          window.MathJax.Hub,
      ]);

    }, []
  )

  return (
    <div>
      {
        question_type === "match-the-column"
          ?
          <>
            <div className='quiz-question-label p-3'>{question_type_label}</div>
            <p className='px-3 pb-2' dangerouslySetInnerHTML={{ __html: paragraph }} />
            <div className='px-3 pb-2' dangerouslySetInnerHTML={{ __html: question_text }} />
            <table className='m-3 table-class'>
              <thead>
                <tr>
                  <th className='th-class'>Column-I</th>
                  <th className='th-class'>Column-II</th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </table>
          </>
          :
          <div id='Question'>
            <div className='quiz-question-label p-3'>{question_type_label}</div>
            <p className='px-3 pb-2' dangerouslySetInnerHTML={{ __html: paragraph }} />
            <div className='px-3 pb-2' dangerouslySetInnerHTML={{ __html: question_text }} />
          </div>
      }
    </div>
  )
}

export default memo(Question)