import React from 'react'
import { Container } from 'react-bootstrap'

function PriceSection({ discounted_fee, fee }) {
    return (
        <Container fluid='md' className='mb-5'>
            <div className='Home-Name Course-heading py-3 '>Pricing</div>
            {
                Number(discounted_fee) > 0
                    ?
                    <div style={{ color: '#008444' }} className='Course-name'><strong><del className='Price-cancel'> <span> {'\u20b9' + fee} </span> </del> &nbsp;  {'\u20b9' + discounted_fee} </strong></div>
                    :
                    <div style={{ color: '#008444' }} className='Course-name'><strong> {'\u20b9' + fee} </strong></div>
            }
        </Container>
    )
}

export default PriceSection