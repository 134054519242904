import React from 'react'
import SecondaryButtons from '../../Buttons/SecondaryButtons'
import PrimaryButtons from '../../Buttons/PrimaryButtons'
import { useSelector } from 'react-redux';

function ButtonGroup({primaryFun=()=>{}, secondaryFun=()=>{}}) {
    const step = useSelector(state =>state.admission.page)

    return (
        <div className='w-100 my-2 d-flex justify-content-end'>
            {
                step > 1
                &&
                <SecondaryButtons onClick={secondaryFun} className='mx-2' style={{
                    width: '100px'
                }}>
                    Prev
                </SecondaryButtons>
            }
            <PrimaryButtons onClick={primaryFun} className='mx-2' style={{
                width: '100px'
            }} 
            >
                {step < 3 ? "Next" : "Finish"}
                {/* Finish */}
            </PrimaryButtons>
        </div>
    )
}

export default ButtonGroup