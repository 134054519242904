import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { createRipple } from "../../../Functions/Functions.js";
import { FiArrowRight } from "react-icons/fi";
import CourseMenuDeskTop from "./CourseMenuDeskTop.js";
import ESaralLoader from "../../../Spinner/ESaralLoader.js";
import CourseEnrollButton from "../CourseEnrollButton.js";
import PriceSection from "../PriceSection.js";

function CourseInfoDeskTop({ course, testimonials, faculties, HightLighter }) {
  const [aboutData, setAboutData] = useState("");
  const [thumbnail, setThumbnail] = useState(
    course.thumbnail.includes("?")
      ? course.thumbnail.split("?")[0]
      : course.thumbnail
  );

  useEffect(() => {
    if (course.about_url) {
      dataFetcher(course.about_url);
    }
  }, []);

  function dataFetcher(url) {
    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        setAboutData(data.split("<body>")[1].split("</body>")[0]);
      });
  }

  return (
    <Col
      className="Home-Scroll-Content p-0 w-100"
      style={{
        height: `100%`,
      }}>
      <div
        className="course-grid"
        style={{
          height: `100%`,
          overflowY: "auto",
        }}
        id="CourseInfo">
        <div
          className="my-2 me-2"
          id="course-content"
          style={{ height: "100%" }}
          onScroll={HightLighter}>
          {/*---------------------------------------------------------About---------------------------------------------------------------------*/}
          <section id="About" className="Card-Format">
            <img
              draggable="false"
              src={thumbnail}
              alt=""
              style={{ width: "100%" }}
              onError={(e) => {
                e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
            }}
            />
            {aboutData && (
              <div className="ms-3" style={{ height: "100%" }}>
                <div dangerouslySetInnerHTML={{ __html: aboutData }} />
              </div>
            )}
          </section>

          {/*---------------------------------------------------------Faculty---------------------------------------------------------------------*/}
          <section id="Faculty" className="Card-Format mt-2">
            <Container fluid="md">
              <div className="Home-Name Course-heading py-3">Faculty</div>
              <Row className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2">
                {faculties.map((facultie, id) => {
                  return (
                    <Col
                      key={id}
                      className={`px-0 me-5 ${
                        id === 0 ? "custom-margin-left" : ""
                      }`}>
                      <img
                        draggable="false"
                        className="Faculity-img"
                        src={facultie.image.split("?")[0]}
                        alt={facultie.name}
                        onError={(e) => {
                          e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
                      }}
                      />
                      <div className="Subject-Button-Img-Title">
                        {facultie.name}
                      </div>
                      <div className="Home-Name-subtitle ">
                        {facultie.designation.charAt(0).toUpperCase() +
                          facultie.designation.slice(1).toLowerCase()}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>

          {/*---------------------------------------------------------Testimonials---------------------------------------------------------------------*/}
          <section id="Testimonials" className="Card-Format mt-2">
            <Container fluid="md">
              <div className="Home-Name Course-heading py-3">Testimonials</div>
              <Row className="scrolling-wrapper row flex-row flex-nowrap pb-4 pt-2">
                {testimonials.map((testimonial, id) => {
                  return (
                      <Col 
                      key={id}
                      className={`px-0 me-5 ${
                        id === 0 ? "custom-margin-left" : ""
                      }`}>
                      <div 
                        key={id}
                        className="Background-Color-1 m-2 ms-0 course-card p-3"
                        style={{ height: "100%" }}>
                        <div className="d-flex my-2 ">
                          <img
                            draggable="false"
                            src={testimonial.profile_image}
                            className="course-testimonial-img"
                            alt=".."
                            onError={(e) => {
                              e.target.src = require('../../../../Images/DefaultImage/video_default_thumb.png')
                          }}
                          />

                          <div className="mx-4">
                            <strong>{testimonial.student_name}</strong>
                            <div className="course-testimonial-name">
                              {testimonial.qualification}
                            </div>
                          </div>
                        </div>

                        <em>"{testimonial.details}"</em>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>

          {/*---------------------------------------------------------Pricing---------------------------------------------------------------------*/}
          <section
            id="Pricing"
            className="Card-Format"
            style={{
              height: "33%",
            }}>
            <PriceSection
              discounted_fee={course.discounted_fee}
              fee={course.fee}
            />
          </section>

          {/*---------------------------------------------------------Course-Button---------------------------------------------------------------------*/}
          <div
            className="p-3 Background"
            style={{
              position: "absolute",
              bottom: "0",
              width: "86%",
              boxShadow: "-5px 0px 4px 2px rgb(0 0 0 / 21%)",
            }}>
            <CourseEnrollButton course={course} />
          </div>
        </div>

        <div id="course-toc" className="mt-2 Background course-toc">
          <CourseMenuDeskTop />
        </div>
      </div>
    </Col>
  );
}

export default CourseInfoDeskTop;
