import { MentorshipReducerConstant } from "../ReducerConstants/mentorshipReducerConstant"


const initialState = {
    mentors: [],
    faqs: [],
    sessions: []
}

const Mentorship = (state=initialState, action) => {
    switch (action.type) {
        case MentorshipReducerConstant.MENTORS:
            return {
                ...state,
                mentors: action.payload
            }
        case MentorshipReducerConstant.FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        case MentorshipReducerConstant.SESSIONS:
            return {
                ...state,
                sessions: action.payload
            }
    
        default:
            return state
    }
}

export default Mentorship