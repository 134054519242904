import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PrimaryButtons from '../../Buttons/PrimaryButtons';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_URLS } from '../../../URL/URL';
import { admissionFormKeys, calculateFieldPercentage, education_boards, indian_states, updateAdmissionFormDetails } from '../../../helpers/admissionForm';
import { useSelector } from 'react-redux';
import ButtonGroup from './ButtonGroup';
import { useDispatch } from 'react-redux';
import { admissionReducerConstant } from '../../../Redux/ReducerConstants/admissionReducerConstant';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';




function AdmissionFormAcademicDetails() {
    const navigate = useNavigate();
    const admissionFormData = useSelector(state => state.admission.data)
    const dispatch = useDispatch()


    const [formData, setFormData] = useState({
        enrolledInOtherInstitute: !!admissionFormData.institute_name,
        instituteName: admissionFormData.institute_name,
        instituteState: admissionFormData.institute_state,
        instituteCity: admissionFormData.institute_city,
        schoolName: admissionFormData.school_name,
        schoolState: admissionFormData.school_state,
        schoolCity: admissionFormData.school_city,
        schoolBoard: admissionFormData.school_board
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (name == "enrolledInOtherInstitute") {
            if (!checked) {
                setFormData(prevState => ({
                    ...prevState,
                    instituteName: prevState.schoolName,
                    instituteState: prevState.schoolState,
                    instituteCity: prevState.schoolCity
                }));
            }
            else {
                setFormData(prevState => ({
                    ...prevState,
                    instituteName: '',
                    instituteState: '',
                    instituteCity: ''
                }));
            }
        }
    };


    const handleUpdateData = async () => {
        try {
            const formInfo = {
                [admissionFormKeys.INSTITUTE_NAME]: formData.instituteName,
                [admissionFormKeys.INSTITUTE_CITY]: formData.instituteCity,
                [admissionFormKeys.INSTITUTE_STATE]: formData.instituteState,

                [admissionFormKeys.SCHOOL_NAME]: formData.schoolName,
                [admissionFormKeys.SCHOOL_CITY]: formData.schoolCity,
                [admissionFormKeys.SCHOOL_STATE]: formData.schoolState,
                [admissionFormKeys.SCHOOL_BOARD]: formData.schoolBoard
            }

            var percentage;
            if (formData.enrolledInOtherInstitute) {
                percentage = calculateFieldPercentage(formInfo) + 44.11
            }
            else {
                percentage = calculateFieldPercentage(formInfo ) + 44.11 + 8.82
            } 

            
            dispatch({type: admissionReducerConstant.COMPELTE_PERCENTAGE, payload: percentage})
            
            formInfo[admissionFormKeys.USER_PROFILE_PROGRESS] = percentage
            
            const data = await updateAdmissionFormDetails(formInfo);
            if (data.success) {
                dispatch({type: admissionReducerConstant.PAGE, payload: 3})
            }
            else {
                handleSetShowAlertModal({ dispatch, message: 'Please try after some time. There is issue.'})
            }
        } catch (error) {
        }
    };

    const handlePreviosPage = () => {
        // navigate(FRONTEND_URLS.ADMISSION+'/'+1)
        dispatch({type: admissionReducerConstant.PAGE, payload: 1})
    }

    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Check
                    type="checkbox"
                    label="Enrolled in any institute other than eSaral."
                    name="enrolledInOtherInstitute"
                    checked={formData.enrolledInOtherInstitute}
                    onChange={handleChange}
                />
            </Form.Group>

            {formData.enrolledInOtherInstitute && (
                <>
                    <Form.Group className="mb-3" controlId="formInstituteName">
                        <Form.Label>Institute Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Institute Name"
                            name="instituteName"
                            value={formData.instituteName}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formInstituteCity">
                        <Form.Label>Institute City</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Institute City"
                            name="instituteCity"
                            value={formData.instituteCity}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formInstituteState">
                        <Form.Label>Institute State</Form.Label>
                        <Form.Select
                            name="instituteState"
                            value={formData.instituteState}
                            onChange={handleChange}
                        >
                            <option value="">---select---</option>
                            {Object.keys(indian_states).map((state, id) => (
                                <option key={id} value={state}>
                                    {indian_states[state]}
                                </option>
                            ))}
                        </Form.Select>

                    </Form.Group>

                </>
            )}

            <Form.Group className="mb-3" controlId="formSchoolName">
                <Form.Label>School Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="School Name"
                    name="schoolName"
                    value={formData.schoolName}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSchoolCity">
                <Form.Label>School City</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="School City"
                    name="schoolCity"
                    value={formData.schoolCity}
                    onChange={handleChange}
                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSchoolState">
                <Form.Label>School State</Form.Label>
                <Form.Select
                    name="schoolState"
                    value={formData.schoolState}
                    onChange={handleChange}
                >
                    <option value="">---select---</option>
                    {Object.keys(indian_states).map((state, id) => (
                        <option key={id} value={state}>
                            {indian_states[state]}
                        </option>
                    ))}
                </Form.Select>

            </Form.Group>


            <Form.Group className="mb-3" controlId="formSchoolBoard">
                <Form.Label>School Board</Form.Label>
                <Form.Select
                    name="schoolBoard"
                    value={formData.schoolBoard}
                    onChange={handleChange}
                >
                    <option value="">---select---</option>
                    {Object.keys(education_boards).map((board, id) => (
                        <option key={id} value={board}>
                            {education_boards[board]}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            <ButtonGroup primaryFun={handleUpdateData} secondaryFun={handlePreviosPage} />
        </>
    );
}

export default AdmissionFormAcademicDetails;
