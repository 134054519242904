import React from 'react'

function IndexTable({data, setIsIndex}) {
    return (
        <div style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000000000",
            // padding: '15%',
            backgroundColor: 'rgba(0,0,0, 0.4)',
        }} onClick={() => {
            setIsIndex(true);
        }} className='Psudo-Button' id='box'>

            <div className='subject-chapter-table Background px-3 py-4 subject-bottom'>
                {
                    data.map(
                        (item, id) => {
                            return <div key={id} className='p-2 Psudo-Button chapter-name' onClick={() => {
                                setIsIndex(true);
                                document.getElementById(id).scrollIntoView({ behavior: 'smooth'});
                                document.getElementById(id).classList.add("lectuers-whole-card")
                            }}> {item.display_name} </div>
                        }
                    )
                }
            </div>
        </div>
    )
}

export default IndexTable