import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import '../../../CSS/PhoneQuiz.css'
import '../../../CSS/Quiz.css'
import ViewSolutionHeader from '../Header/ViewSolutionHeader.js'
import VsQnos from './VsQnos.js'
import VSBody from './VSBody/VSBody.js'
import MessageModal from '../../../../Components/message-modal/MessageModal.jsx'


export const ViewSolution = () => {
  const { questions, display_name } = useSelector((state) => ({ display_name: state.test.quiz_details?.display_name, questions: state.test.quiz_details?.questions }))

  return (
    <div id='Quiz' className='quiz-grid CommanQuizElement ViewSolution'>
      <MessageModal title='No question' message={`There is no questions for your selected filter.`} butText='Okay'/>
      {
        display_name
          ?
          <>
            <ViewSolutionHeader display_name={display_name} />
            <VsQnos />
            <VSBody />
          </>
          :
          <Spinner />
      }
    </div>
  )
}
