import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import LoginCarusal from './LoginCarusal';
import LoginFormNumber from './LoginFormNumber';
import LoginFormOtp from './LoginFormOtp';
import { AxiosClient } from '../../Axios/AxiosClient';
import { BACKEND_URLS, FRONTEND_URLS } from '../../../URL/URL';
import { country_code } from '../../../Constants/CountryCode/CountryCode';
import { UserData } from '../../Functions/Functions';
import { useNavigate } from 'react-router-dom';
import { RegisterConstant } from '../Register/RegisterConstant';
import { useSelector } from 'react-redux';
import { AlertReducersConstants } from '../../../Redux/ReducerConstants/AlertReducersConstants';
import { useDispatch } from 'react-redux';
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions';

const LoginBody = () => {
    const [IsOtp, setIsOtp] = useState(false)
    const [OTP, setOTP] = useState('')
    const [number, setNumber] = useState('')
    const [nonce, setNonce] = useState('')
    const PhoneRef = useRef()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(
        () => {
            if (OTP.length === 4) {
                verifiOTP()
            }
        }, [OTP]
    )

    function OTPSender(phone_number) {

        const phoneNumber = phone_number

        if (phoneNumber.length === 10) {
            AxiosClient.post(BACKEND_URLS.OTP_SENDER, {
                country_code: country_code,
                phone_number: phoneNumber,
            })
                .then((response) => {
                    if (response.data.status === 0) {
                        dispatch({ type: AlertReducersConstants.MESSAGE, payload: response.data.message })
                        dispatch({ type: AlertReducersConstants.AlertVariants, payload: AlertReducersConstants.AlertVariants.SUCCESS })
                        dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })
                        setNumber(phoneNumber)
                        setIsOtp(true)
                        setNonce(response.data.data.nonce)
                    }
                    else {
                        dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.DANGER })
                        dispatch({ type: AlertReducersConstants.MESSAGE, payload: response.data.message })
                        dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })
                    }
                })
                .catch((error) => {
                    handleSetShowAlertModal({ dispatch, message: "Network Error" })
                });
        }
    }

    function verifiOTP() {
        AxiosClient.put(BACKEND_URLS.OTP_SENDER, {
            country_code: country_code,
            phone_number: number,
            nonce: nonce,
            otp: OTP,
        })
            .then((response) => {
                if (response.data.status !== 0) {
                    const OtpBoxes = document.getElementsByClassName('Otp_Box');
                    for (let i = 0; i < OtpBoxes.length; i++) {
                        OtpBoxes[i].style.border = "2px solid red";
                    }

                    dispatch({ type: AlertReducersConstants.VARIANT, payload: AlertReducersConstants.AlertVariants.DANGER })
                    dispatch({ type: AlertReducersConstants.MESSAGE, payload: 'Incorrect OTP!' })
                    dispatch({ type: AlertReducersConstants.IS_ALERT, payload: true })

                    return false
                }
                else {
                    setNonce(response.data.data.nonce)
                    UserDataProvider(response.data.data.nonce)
                    return true
                }
            })
            .catch((error) => {
                setNonce('')
                return false
            });
    }


    function UserDataProvider(nonce) {
        AxiosClient.post(BACKEND_URLS.USER_DATA_PROVIDER, {
            country_code: country_code,
            phone_number: number,
            nonce: nonce,
            widevine: "",
            widevine_level: "",
            imei: "",
            id_organization: "1",
            firebase_notification_token: "f5aoEZM9Tu6gdd7lEMy58p:APA91bHSt0cBMyb875QZ2SpNl9rnMZOo3rRjWWEIq-J0dtr7khAuNV3BG6ea4wUsVGPO4kuNgwWVhGF2gz99Fm2dk5kgmgcbKKW4XPah6UHHRkaKlxEvgI49rvTmkMxj7uzeKOsXAcri",
            device_name: "web",
            os: "web",
            os_version: 1,
            app_version: 'web'
        })
            .then(async (response) => {
                const access = response.data.data.tokens.access;
                const is_course_assigned = response.data.data.is_course_assigned;
                if (response.data.status === 0) {
                    localStorage.setItem('access', access)

                    if (is_course_assigned === false) {
                        navigate(FRONTEND_URLS.REGISTER_ROUTE + '/' + (RegisterConstant.NAME).toLowerCase())
                    }
                    else {
                        UserData(access).then(
                            (e) => {
                                navigate(FRONTEND_URLS.HOME_ROUTE)
                                window.location.reload()
                            }
                        ).catch(
                            (err) => {
                            }
                        )
                    }
                }
                else {
                    handleSetShowAlertModal({ dispatch, message: response.data.message })
                }
            })
            .catch((error) => {
            });
    }


    return (
        <div className='container-xxl px-0 Background overflow-y-hidden overflow-x-hidden h-100'>
            <Row className='overflow-y-hidden h-100'>
                <Col md={6} className='isDesktop'>
                    <LoginCarusal />
                </Col>

                <Col sm={12} md={6} className='overflow-y-hidden h-100 m-auto d-flex justify-content-center'>
                    {
                        !IsOtp
                            ?
                            <LoginFormNumber PhoneRef={PhoneRef} OTPSender={OTPSender} />
                            :
                            <LoginFormOtp OTP={OTP} setOTP={setOTP} verifiOTP={verifiOTP} number={number} OTPSender={OTPSender} nonce={nonce} />
                    }

                </Col>
            </Row>
        </div>
    );
};

export default LoginBody;