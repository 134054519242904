import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { FRONTEND_URLS } from '../../URL/URL.js';
import PrimaryButton from '../components/buttons/PrimaryButton.js';
import { useDispatch } from 'react-redux';
import { AppReducerConstants } from '../../Redux/Reducers/Constants/AppReducerConstant.js';

function QuizInstructionModal(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()?.pathname

    return (
        <>
            <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div dangerouslySetInnerHTML={{ __html: props.InstructionData }} />
                    <div className='w-100 text-center'>
                    <PrimaryButton onClick={() => {
                        dispatch({ type: AppReducerConstants.PREVIOUS_PAGE_ADD, payload: location })
                        navigate(FRONTEND_URLS.TEST_ROOT, { state: { quiz_id: props.quiz_id } })
                    }} name='Start Test' style={{
                        color:'#5F46E3',
                        borderColor:'#5F46E3',
                        width:'50%',
                        maxWidth:'300px',
                    }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default QuizInstructionModal;