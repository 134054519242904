import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { useDispatch, useSelector } from 'react-redux'
import Countdown from './CountDown.js';
import { useNavigate } from 'react-router-dom';
import { SubmitTest } from '../../functions/Functions.js';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup.js';
import SectionSelection from './SectionSelection.js';

function Header() {
  const [modalShow, setModalShow] = useState(false);

  const quiz_attempt_id = useSelector(state => state.test.student_attempt?.quiz_attempt_id)
  const quiz_id = useSelector(state => state.test?.quiz_id)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const previousPages = useSelector(state => state.app.previousPages)

  function submitTestFun() {
    SubmitTest({ dispatch, navigate, quiz_attempt_id, quiz_id, previosPage: previousPages[previousPages.length - 1] })
  }



  return (
    <header id='quiz-header' className='Background d-flex justify-content-between align-items-center p-3'>

      <ConfirmationPopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        onsubmitFun={submitTestFun}
      />

      <SectionSelection />

      <div>
        <div>Time Left</div>
        <Countdown submitTestFun={submitTestFun} />
      </div>

      <Button variant="outline-success" className='new-btn-outline-success'
        onClick={() => setModalShow(true)}
      >Submit</Button>

    </header>
  )
}

export default Header