import React, { useEffect, useState } from 'react'
import LiveClassesComponent from '../../../LiveClass/LiveClassesComponent';


function LiveClassComponent() {

    const live = JSON.parse(localStorage.getItem("user"))?.live?.Upcoming?.data;
    const [liveClasses, setLiveClasses] = useState([])

    const user = JSON.parse(localStorage.getItem('user'))
    const isCourseEpired = user?.is_current_subcourse_expired;


    useEffect(
        () => {
            let data = [];
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);
            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 999);

            const todayEndTimestamp = Math.floor(todayEnd.getTime() / 1000);
            const todayStartTimestamp = Math.floor(todayStart.getTime() / 1000)

            if (live && live.length > 0) {
                for (let index = 0; index < live.length; index++) {
                    const element = live[index].data;
                    // Filter sessions that end before the end of today
                    const filteredSessions = element.filter(session => session.end_date_time <= todayEndTimestamp && session.start_date_time >= todayStartTimestamp);
                    data.push(...filteredSessions);
                }
                // Sort the sessions by start_date_time
                data.sort((a, b) => a.start_date_time - b.start_date_time);

                setLiveClasses(data.slice(0, 2))
            }

        }, []
    )



    return (

        // !isCourseEpired && 
        liveClasses && liveClasses.length > 0 ?
            liveClasses.map((e, id) => {
                return <LiveClassesComponent liveClass={e} key={id} />
            })
            :
            <div className="d-flex justify-content-center align-items-center h-100 m-2">
                <strong>There are no live classes scheduled.</strong>
            </div>
    )
}

export default LiveClassComponent