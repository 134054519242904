import React from 'react';
import { Col } from 'react-bootstrap';


const SideBar = ({ children, className }) => {
    return (
        <Col md={3} className={`Background md-3 Home-menu-item sideBar isDesktop px-0 py-3 ${className} Card-Format`} style={{
            height:'93vh'
        }}>
            {children}
        </Col>
    );
};

export default SideBar;