import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FiArrowRight } from 'react-icons/fi'
import { AxiosClient } from '../../Axios/AxiosClient.js'
import { BACKEND_URLS, redirect_url } from '../../../URL/URL.js'
import { createRipple } from './../../Functions/Functions';
import axios from 'axios'
import { handleSetShowAlertModal } from '../../../helpers/helperFunctions.js'
import { useDispatch } from 'react-redux'

function CourseEnrollButton({ course }) {

    const data = {
        phase_id: course.phase_id,
        fee: course.fee,
        discounted_fee: course.discounted_fee,
    }

    const dispatch = useDispatch()

    const [isPaymentDone, setIsPaymentDone] = useState(false)

    function PaymentCollector({discounted_fee, fee}) {
        AxiosClient.post(
            BACKEND_URLS.PAYMENT_URL,
            {
                "phase_id":  Number(data.phase_id),
                "amount": Number(discounted_fee) > 0 ? Number(discounted_fee) : fee,
                "redirect_url": redirect_url,
            },
        ).then(
            (res) => {
                if (res.data.success) {

                    const data = res.data.data

                    if (data.service == "INSTAMOJO") {
                        const INSTAMOJO = data.data;
                        const INSTAMOJO_id = INSTAMOJO.id;
                        const longurl = INSTAMOJO.longurl;
                        sessionStorage.setItem('Inst_id', INSTAMOJO_id)
                        setIsPaymentDone(true)

                        // Instamojo  
                        window.Instamojo.open(longurl)

                    }
                }
                else {
                    handleSetShowAlertModal({dispatch, message:'Something went wrong. Please try after some time.'})
                }
            }
        ).catch(
            (err) => {
            }
        )
    }

    return (
        <>

            <div id='this-is-trial'></div>

            <Button disabled={isPaymentDone} type='submit' className='Custom-button-ripple'
                style={{ backgroundColor: '#5F46E3', borderColor: '#5F46E3', width: '100%' }}
                onClick={
                    (e) => {
                        createRipple({ event: e, className: "ripple-primary" })
                        PaymentCollector({discounted_fee:data.discounted_fee, fee:data.fee})
                    }}                            >
                Enroll Now <FiArrowRight size={20} />
            </Button>
        </>
    )
}

export default CourseEnrollButton