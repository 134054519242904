import React, { useEffect, useRef } from 'react';
import AdmissionFormPersonalDetails from './AdmissionFormPersonalDetails';
import AdmissionFormAcademicDetails from './AdmissionFormAcademicDetails';
import AdmissionFormAddressDetails from './AdmissionFormAddressDetails';
import ESaralLoader from '../../Spinner/ESaralLoader';
import { useSelector } from 'react-redux';
import { admissionReducerConstant } from '../../../Redux/ReducerConstants/admissionReducerConstant';
import { useDispatch } from 'react-redux';
import { getAdmissionFormDetails } from '../../../helpers/admissionForm';



function Admission() {
  const ref = useRef();
  const loading = useSelector(state => state.admission.loading)
  const currentStep = useSelector(state => state.admission.page)
  const admissionFormData = useSelector(state => state.admission.data);
  const dispatch = useDispatch()

  const handleGetAdimssionFormData = async () => {
    try {
      const data = await getAdmissionFormDetails()
      if (data.success) {
        dispatch({ type: admissionReducerConstant.GET_DATA, payload: data.data })
      }
      else {
        dispatch({ type: admissionReducerConstant.ERROR, payload: data.errors })
      }
    }
    catch (error) {
      dispatch({ type: admissionReducerConstant.ERROR, payload: error })
    }
    finally {
      dispatch({ type: admissionReducerConstant.Loading, payload: false })
    }
  }


  useEffect(
    () => {
      dispatch({ type: admissionReducerConstant.Loading, payload: true })
      handleGetAdimssionFormData()
    }, [currentStep]
  )


  return (
    <>
      {
        loading
          ?
          <ESaralLoader />
          :
          <div className='Background px-5 py-3 position-relative' style={{
            borderRadius: '8px'
          }}>
            <div ref={ref}></div>
            <h3>Step {currentStep} of 3</h3>
            {
              currentStep === 1
                ? <AdmissionFormPersonalDetails key={1} />
                : currentStep === 2
                  ? <AdmissionFormAcademicDetails key={2} />
                  : <AdmissionFormAddressDetails key={3} />
            }
          </div>
      }
    </>
  );
}

export default Admission;
