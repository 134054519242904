import React, { useEffect } from 'react';
import RegisterName from '../../Components/Body/Register/RegisterName';
import { Col, Row } from 'react-bootstrap';
import LoginCarusal from '../../Components/Body/Login/LoginCarusal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RegisterConstant } from '../../Components/Body/Register/RegisterConstant';
import AttemptYear from '../../Components/Body/Register/AttemptYear';
import Exam from '../../Components/Body/Register/Exam';
import { FRONTEND_URLS } from '../../URL/URL.js';


const Register = () => {
    const { register_var } = useParams()

    const navigate = useNavigate()

    useEffect(
        () => {
            const access = localStorage.getItem('user')

            if(access){
                navigate(FRONTEND_URLS.HOME_ROUTE)
            }

        }, []
    )
    return (
        <div className='container-xxl px-0 Background'>
            <Row >
                <Col md={6} className='isDesktop'>
                    <LoginCarusal />
                </Col>

                <Col sm={12} md={6}>
                    {
                        register_var === (RegisterConstant.NAME).toLowerCase()
                            ?
                            <RegisterName />
                            :
                            register_var === RegisterConstant.ATTEMPT_YEAR.toLowerCase()
                                ?
                                <AttemptYear />
                                :
                                <Exam />
                    }
                </Col>
            </Row>
        </div>
    );
};

export default Register;