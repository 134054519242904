import React from 'react'
import Button from 'react-bootstrap/Button';


function PrimaryButton(prop) {
  return (
    <Button variant={"outline-primary"} className={`m-2 new-btn-outline ${prop.classes}`} {...prop}>{prop.name}</Button>
  )
}

export default PrimaryButton